import React from 'react';
import { Files } from '../../utils/fileConstants';


type PromoDisclosureOptions = {
    listIndexSymbol?: string;    
};


export const BasicEligibleDisclosure: React.FC<PromoDisclosureOptions> = ({listIndexSymbol}) => {
    return (
        <>
            <li style={{listStyle: 'none'}}>
                {listIndexSymbol ?? '*'}This offer is eligible to applicants who apply, are approved and fully fund their security deposit between February 22, 2024 and April 15, 2024. OpenSky will contribute a one-time promotional security deposit of $50 following the customer's application, approval and funding on each OpenSky Secured Visa Credit Card obtained. If approved, customer must fund the entire amount of the security deposit based on the customer's approved credit line, less $50 in order to be eligible for the promotional security deposit offer. Please see Terms and Conditions for additional information.
            </li>
        </>
    );
}

export const PlusEligibleDisclosure: React.FC<PromoDisclosureOptions> = ({listIndexSymbol}) =>  {
    return (
        <>
            <li style={{listStyle: 'none'}}>
                {listIndexSymbol ?? '*'}To be eligible for this offer, applicants must apply, be approved, and fully fund their security deposit between February 22nd, 2024 & March 31st, 2024. The minimum credit line and security deposit for the OpenSky Plus Secured Visa Credit Card is reduced to $200 from $300 for a limited time only.
            </li>
        </>
    );
}

export const PreApprovedDisclosure: React.FC<PromoDisclosureOptions> = ({listIndexSymbol}) => {
    return (
        <>
            <li style={{listStyle: 'none'}}>
                {listIndexSymbol ?? '*'}Pre-Approved means that you have met the initial criteria for this credit card; however, your application may be rejected if any of the following apply: You are a non-United States resident; the social security number you provide is invalid or we are unable to verify your identity under federal law; you are under 18 years old; your monthly expenses are greater than your monthly income; you lack sufficient income or assets to pay the monthly payment required for this card; you have two or more open credit card accounts with Capital Bank; or your account history on any current or previous relationship with Capital Bank is not in good standing.
            </li>
        </>
    );
}

export const AlternateEligibleDisclosure: React.FC<PromoDisclosureOptions> = ({listIndexSymbol}) => {
    return (
        <>
            <li style={{listStyle: 'none'}}>
                {listIndexSymbol ?? '*'}This offer is eligible to applicants who apply, are approved and fully fund their security deposit between February 22, 2024 and April 15, 2024. OpenSky will contribute a one-time promotional security deposit of $50 following the customer's application, approval and funding on each OpenSky Secured Visa Credit Card obtained. If approved, customer must fund the entire amount of the security deposit based on the customer's approved credit line, less $50 in order to be eligible for the promotional security deposit offer. Please see Terms and Conditions for additional information.
            </li>
        </>
    );
}

export const TestimonialDisclosure: React.FC<PromoDisclosureOptions> = ({listIndexSymbol}) =>  {
    return (
        <>
            <li style={{listStyle: 'none'}}>
                {listIndexSymbol ?? '*'}Customer testimonials are solicited by OpenSky - a division of Capital Bank N.A.
            </li>
        </>
    );
}

export const IncreaseLimitDisclosure: React.FC<PromoDisclosureOptions> = ({listIndexSymbol}) =>  {
    return (
        <>
            <li style={{listStyle: 'none'}}>
                {listIndexSymbol ?? '*'}Getting considered for a limit increase and/or the OpenSky Gold Unsecured Card is subject to approval
            </li>
        </>
    );
}

export const RewardsDisclosure: React.FC<PromoDisclosureOptions> = ({listIndexSymbol}) => {
    return (
        <>
            <li style={{listStyle: 'none'}}>
                {listIndexSymbol ?? '*'}<a className="disclosure-link" href={Files.GetFiles().currentRewardsTermsConditions.toString()}>OpenSky Rewards Terms and Conditions.</a>
            </li>
        </>
    );
}

export const LaunchAnnualFeeDisclosure: React.FC<PromoDisclosureOptions> = ({listIndexSymbol}) => {
    return (
        <>
            <li style={{listStyle: 'none'}}>
                {listIndexSymbol ?? '*'}The OpenSky Launch Secured Visa Credit Card requires a monthly fee. The monthly fee is billed at a rate of $2 per month, annualized at $24 for the first 12 months. Beginning year two and for as long as the account remains open, the monthly fee is billed at a rate of $3 per month, annualized at $36.
            </li>
        </>
    );
}
