import React, { useEffect, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { ApplicationFlowLayout } from '../../layouts';
import { iconClockGray } from '../../assets/img';
import { useIdleTimerContext } from 'react-idle-timer';

export type TimeoutWarningPageProps = {
    showPrompt: boolean,
};

const TimeoutWarningPage: React.FC<TimeoutWarningPageProps> = (props) => {
    const { showPrompt } = props;
    
    const [remaining, setRemaining] = useState<number>(0);
    const { activate, getRemainingTime } = useIdleTimerContext()

    const minutes = Math.floor((remaining ?? 0) / 60);
    const remainingSeconds = (remaining ?? 0) - (minutes * 60);
    const formattedMinutes = `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  
    useEffect(() => {
      const interval = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }, 500)
  
      return () => {
        clearInterval(interval)
      }
    })
   
    return (
        <div style={{
            display: showPrompt ? 'block' : 'none'
        }}>
            <ApplicationFlowLayout progress='0%'>
                <Row className='mobile-centered'>
                    <div>
                        <img alt='error' src={iconClockGray} role='presentation' className='icon-warning' />
                        <div className="heading">
                            <h1>Your session is about to expire!</h1>
                            For security purposes, your current session will be ending in {formattedMinutes}. Click to continue your session. 
                        </div>
                    </div>
                    <div className='text-center'>
                        <Button onClick={activate}>{"Stay Connected"}</Button>
                    </div>
                </Row>
            </ApplicationFlowLayout>
        </div>
    );
};

export default TimeoutWarningPage;
