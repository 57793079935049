import React, { useEffect, useState } from 'react';
import { UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import "./consent.scss";

interface ConsentProps {
  paragraph: string;
  name: string;
  setValue?: UseFormSetValue<any>;
  trigger?:  UseFormTrigger<any>;
}

const Consent: React.FC<ConsentProps> = ({ paragraph, setValue, trigger, name }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setValue && setValue(name, checked);
  }, [checked, name, setValue]);

  const handleClickCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    
    if(setValue){
      setValue(name, isChecked);
      trigger && trigger();
    }
  }

  const handleTextClick = () => {
    setChecked(!checked);

    if(setValue){
      setValue(name, !checked);
      trigger && trigger();
    }
  }
  
  return (
    <div className='consent-container'>
      <input
        name={name}
        type='checkbox'
        className='checkbox'
        checked={checked}
        onChange={handleClickCheckbox}
      />
      <p className='paragraph' onClick={handleTextClick}>
        {paragraph.split('<br/>').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </p>
    </div>
  );
};

export default Consent;