import classnames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { iconFile, iconUploadImage, iconX } from '../../assets/img';
import './photo-document-upload.scss';

type PhotoDocumentUploadProps = {
    name: string;
    register?: UseFormRegister<any>;
    initialFile?: File;
    errors?: FieldErrorsImpl<any>;
    onClear: () => void;
};

const ACCEPTED_FILE_TYPES = [
    'image/jpeg',
    'image/gif',
    'image/png',
    'application/pdf',
    'application/doc',
    'application/docx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

const PhotoDocumentUpload: FC<PhotoDocumentUploadProps> = ({
    name,
    register,
    initialFile,
    errors,
    onClear,
}) => {
    const [file, setFile] = useState<File | undefined>(initialFile);
    const [objectUrl, setObjectUrl] = useState<string>();
    let fileInputRef: HTMLInputElement | null;

    const handleFileChange = (event: React.SyntheticEvent) => {
        const eventTarget = event.target as HTMLInputElement;
        const file = eventTarget?.files?.[0];

        if (file?.size && file?.size > 10485760) {
            alert('File is too big!');
            if (eventTarget) {
                eventTarget.value = '';
                setFile(undefined);
            }
        } else if ((file?.type || file?.type === '') && !ACCEPTED_FILE_TYPES.includes(file?.type)) {
            alert('Wrong file type');
            setFile(undefined);
        } else if (file) {
            setFile(file);
            // setObjectUrl(URL.createObjectURL(file));
        } else {
            setFile(undefined);
        }

        onRegisterChange && onRegisterChange(event);
    };

    const handleAddPhotoClick = () => {
        console.log('handleAddPhotoClick');
        fileInputRef && fileInputRef.click();
    };

    const handleClearFileUpload = () => {
        if (fileInputRef) {
            fileInputRef.value = '';

            const event = new Event('input');
            fileInputRef?.dispatchEvent(event);
        }
        setFile(undefined);
        onClear && onClear();
    };

    useEffect(() => {
        if (file) {
            const objectUrl = URL.createObjectURL(file);
            setObjectUrl(objectUrl);

            return () => {
                URL.revokeObjectURL(objectUrl);
            };
        } else {
            setObjectUrl(undefined);
        }
    }, [file]);

    useEffect(() => {
        setFile(initialFile);
    }, [initialFile]);

    const {
        onChange: onRegisterChange,
        ref: registerRef,
        ...restRegisterProps
    } = (register && register(name)) || {};

    const refCallback = (node: HTMLInputElement) => {
        registerRef && registerRef(node);
        fileInputRef = node;
    };

    return (
        <div className="photo-document-upload-container">
            <div
                className={classnames('photo-document-upload-box', {
                    'file-chosen': !!file,
                })}
            >
                {file && (
                    <>
                        <img
                            src={iconX}
                            alt="Clear Upload"
                            onClick={handleClearFileUpload}
                            className="clear-file-upload"
                        />
                        <UploadPreview
                            fileType={file.type}
                            fileName={file.name}
                            objectUrl={objectUrl}
                        />
                    </>
                )}

                <Form.Group
                    className={classnames('photo-document-upload-form', {
                        hidden: !!file,
                    })}
                >
                    <Form.Label
                        className="photo-document-upload-form-label"
                        onClick={handleAddPhotoClick}
                        htmlFor={name}
                    >
                        <img src={iconUploadImage} alt="Upload Icon" />
                        <span>Add photo/choose file</span>
                    </Form.Label>
                    <Form.Control
                        name={name}
                        className="photo-document-upload-form-input"
                        type="file"
                        accept={ACCEPTED_FILE_TYPES.join(',')}
                        onChange={handleFileChange}
                        ref={refCallback}
                        {...restRegisterProps}
                    />
                </Form.Group>
            </div>
            {!file && (
                <>
                    The file must be either a .jpg, .png, .tif, .pdf, .doc or .docx. Files
                    must be no larger than 10MB in size.
                </>
            )}

            {/* {!!errors && !!errors[name] && (
                <Form.Group>
                    <FormErrorMessage errors={errors} name={name} />
                </Form.Group>
            )} */}
        </div>
    );
};

type UploadPreviewProps = {
    fileType: string;
    objectUrl?: string;
    fileName: string;
};

const UploadPreview: FC<UploadPreviewProps> = ({
    fileType,
    objectUrl,
    fileName,
}) => {
    switch (fileType) {
        case 'image/jpeg':
        case 'image/gif':
        case 'image/png':
            return (
                <>
                    <img
                        src={objectUrl}
                        alt="upload preview"
                        className="image-upload"
                        height="34px"
                    />
                    {fileName}
                </>
            );
        // case 'application/pdf':
        // return (
        //     <Document
        //         file={objectUrl}
        //         // onLoadSuccess={onDocumentLoadSuccess}
        //         className="disclosure-document"
        //     >
        //         <Page pageNumber={1} />
        //     </Document>
        // );
        default:
            return (
                <>
                    <img
                        src={iconFile}
                        alt="File Icon"
                        height="34px"
                        className="file-icon"
                    />
                    {fileName}
                </>
            );
    }
};

export default PhotoDocumentUpload;
