import React from 'react';
import { Button } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import ApplicationInfoStatus from '../../components/application-info-status';
import { LandingPageLayout } from '../../layouts';
import {
    cardTypeState,
    decisionsResponseState,
    firstNameState,
} from '../../store/atoms';
import {
    applicationApproved,
    creditcardTilted,
    creditcardPlusTilted,
    creditcardLaunchTilted,
    iconCalendar,
    iconCreditcardPayment,
    iconGraph,
} from '../../assets/img';

const ApplicationBoarded = () => {
    const decisionsResponse = useRecoilValue(decisionsResponseState);
    const firstName = useRecoilValue(firstNameState);
    const cardType = useRecoilValue(cardTypeState);

    const getCardImageByCardType = () => {
        switch(cardType){
            case 'launch':
                return <img alt="" src={creditcardLaunchTilted} className="bottom-36" />
            case 'plus':
                return <img alt="" src={creditcardPlusTilted} className="bottom-36" />
            default:
                return <img alt="" src={creditcardTilted} className="bottom-36" />
        }
    }

    return (
        <LandingPageLayout showFDIC={true}>
            <ApplicationInfoStatus
                appStatus="You've been approved!"
                appMessage="Your new card is coming soon"
                appStatusType="approved"
                appStatusIcon={applicationApproved}
                appDetailMessage={`We're busy making sure your new $${decisionsResponse?.creditLimit}`}
                appDetailMessageLine2="OpenSky card is good to go before shipment."
                appAdditionalContent={
                    <div className="text-start">
                        {getCardImageByCardType()}
                        <h2 className="ginto-header">Congrats, <span style={{textTransform: 'capitalize'}}>{firstName}</span>!</h2>
                        <h2 className="ginto-header gradient">You're officially part of the</h2>
                        <h2 className="ginto-header gradient"> OpenSky family.</h2>
                        <p>Your card will be mailed soon, however this<br />
                        process can take up to 4 days. Don't worry — we'll<br />
                        give you a heads up when it ships.</p>
                        <p style={{textAlign: 'left'}}>What's next?</p>
                        <ul className="bottom-36">
                            <li>Your card should arrive in 12-14 business days.</li>
                            <li>Look for an unmarked, white envelope.</li>
                            <li>If you haven't received your card after 12-14 business days, please call <a href={`${process.env.REACT_APP_HOMEPAGE_URL}/faq?cat1-faq20`}>customer service</a><br />
                            for assisstance.</li>
                            <li>Activate your card as soon as it arrives to<br />
                            start building credit!</li>
                        </ul>
                        <h2 className="ginto-header bottom-16"  style={{textAlign: 'left'}}>What is a secured card?</h2>
                        <p className="bottom-36" style={{textAlign: 'left'}}>Secured cards are a type of credit card that are<br />
                        backed by a cash deposit, but otherwise work<br />
                        just like unsecured cards. The refundable deposit<br />
                        used to fund your OpenSky card is equal to your<br />
                        spending limit. Using it for everyday purchases<br />
                        and making on-time monthly payments helps to<br />
                        grow and improve your score over time.</p>
                        <Button
                            className="bottom-36"
                            disabled={false}
                            onClick={() => window.location.href = `${process.env.REACT_APP_HOMEPAGE_URL}/know-your-card`}>
                            Learn more
                        </Button>
                        <div className="card dark card-tall-padding bottom-36">
                            <h2 className="ginto-header large-h2 bottom-24">Quick Tips to Build<br />Credit Fast</h2>
                            <div className="icon-card">
                                <img alt="calendar icon" src={iconCalendar} className="icon" /><span className="text">Make on-time monthly<br />payments.</span>
                            </div>
                            <div className="icon-card">
                                <img alt="payment icon" src={iconCreditcardPayment} className="icon" /><span className="text">Use less than 30% of your<br />available credit.</span>
                            </div>
                            <div className="icon-card">
                                <img alt="graph icon"  src={iconGraph} className="icon" /><span className="text">Opt-in on your OpenSky<br />account to track your FICO<br />score for free.</span>
                            </div>
                        </div>
                    </div>
                }
                appMessageAdditionalClasses="force-text-left"
                appMessageAdditionalGradiantClasses="force-text-left"
                appDetailAdditionalClasses="force-text-left"
                onNext={() => window.location.href = `${process.env.REACT_APP_HOMEPAGE_URL}`}
                buttonText="Ok, Got it"
            />
        </LandingPageLayout>
    );
};

export default ApplicationBoarded;
