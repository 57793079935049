import React from 'react';
import '../process-application/process-application.scss'
import { LandingPageLayout } from '../../layouts';

const PaymentProcessing = () => {
    return (
        <LandingPageLayout>
            <div className="process-application-container">
                <div className="spinner">
                    <div className="inner-circle" />
                    <div className="circle-corner" />
                </div>
                <div id="header">
                    <h1>Please wait as we process your payment</h1>
                    <p>Shouldn't be long, please keep this page open.</p>
                </div>
            </div>
        </LandingPageLayout>
    );
};
export default PaymentProcessing;
