import React from "react";
import "./terms.scss";

interface TermsProps {
    rateDisclosuresLink: string;
}

const Terms: React.FC<TermsProps> = ({rateDisclosuresLink}) => {
    return (
        <div className="terms-container">
            <div className="download-terms-container">
                <p className="download-terms-text">Important Disclosures</p>
                <a href={rateDisclosuresLink} className="download-terms-link" download={rateDisclosuresLink}>Download/Print</a>
            </div>
            <div className="terms-and-conditions-box">
                <div className="content">
                    <iframe title="rateDisclosuresLink" src={rateDisclosuresLink}/>
                </div>
            </div>
        </div>);
}

export default Terms;