import React, { useCallback, useState } from "react";
import { useForm } from 'react-hook-form';
import { useRecoilState } from "recoil";
import { useHistory } from "react-router";
import { routeHashes } from '../../../app-routes';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ApplicationFlowLayout from "../../layouts/application-flow";
import { maxBirthdate } from '../../../utils/dates';
import { applicationState, gaDataLayerState } from "../../../store/atoms";
import { CardTypes } from "../../../utils/constants";
import { Subheadline, Form, BirthdateForm, Modal } from "../../components";
import { iconCalendarDate } from '../../../assets/img';
import "./birthdate.scss";
import "../../scss/main.scss";
import { BirthdateVerificationModel } from "../../types";
import { pushToDataLayer } from "../../../utils/analytics";

interface BirthdatePageProps {
    isModal?: boolean;
    onModalClose?: () => void;
}

const Birthdate: React.FC<BirthdatePageProps> = ({isModal, onModalClose}) => {
    const history = useHistory();
    const [application, setApplication] = useRecoilState(applicationState);
    const [isSubmitted] = useState<boolean>(false);
    const [gaDataLayer, setGADataLayer] = useRecoilState(gaDataLayerState);

    const validationSchema = Yup.object().shape({
        dateOfBirth: Yup.date()
            .typeError('Birthday is required.')
            .max(maxBirthdate, 'Birthday must be at least 18 years in the past')
            .required('Birthday is required'),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const {
        handleSubmit,
        setValue,
        trigger,
        formState: { errors, isValid },
    } = useForm<BirthdateVerificationModel>({
        ...formOptions,
        defaultValues: validationSchema.getDefault(),
        mode: 'all',
    });

    const onSubmit = useCallback(async (event: BirthdateVerificationModel) => {
        const updateDataLayer = {
            ...gaDataLayer,
            'step_name': 'Birthdate',
        };
        setGADataLayer(updateDataLayer);
        pushToDataLayer("application_step_3", updateDataLayer);

        setApplication((current) => ({
            ...current,
            ...event,
        }));

        history.push(routeHashes['/v1_5/ssn']);
    }, []);

    const onSubmitModal = useCallback(async (event: BirthdateVerificationModel) => {
        setApplication((current) => ({
            ...current,
            ...event,
        }));

        onModalClose && onModalClose();
    }, []);

    return (
        <>
            {!isModal && (
            <ApplicationFlowLayout progress='50%' currentStep='4' isArrowBackButton={true} cardType={application.cardType as CardTypes} displayVisaLogo={true}>
                <div className="main-container">
                    <Subheadline
                        headline="What's your date of birth?"
                        headlineIcon={iconCalendarDate}
                    />
                    <Form onSubmit={handleSubmit(onSubmit)} submitText='Next' isSubmitDisabled={!isValid} isSubmitting={isSubmitted}>
                        <BirthdateForm setValue={setValue} trigger={trigger} errors={errors} />
                    </Form>
                </div>
            </ApplicationFlowLayout>)}
            {isModal && (
            <Modal onClose={onModalClose || (() => {})}>
                <Subheadline
                headline="What's your date of birth?"
                headlineIcon={iconCalendarDate}
                />
                <Form onSubmit={handleSubmit(onSubmitModal)} submitText='Save' isSubmitDisabled={!isValid} isSubmitting={isSubmitted}>
                    <BirthdateForm setValue={setValue} trigger={trigger} errors={errors} />
                </Form>
            </Modal>)}
        </>
    );
}

export default Birthdate;
