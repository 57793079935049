import { useMutation } from 'react-query';
import { microserviceClient } from '../../api-clients';
import { SubmitLeadResponse } from '../../types';

const submitApplication = (payload: any) =>
    microserviceClient.post<SubmitLeadResponse>('/api/application/submitLead', payload);

export const useSubmitApplication = () => {
    return useMutation(
        submitApplication,
        {
            onError: (err, variables, context) => {
                console.log('Error submitting application', err);
            },
        }
    );
};
