import { rest } from "msw";
import { CheckApplicationResponse, DecisionsResponse, SubmitLeadResponse } from "../types";

const responses: SubmitLeadResponse[] = [
    {
        done: {
            creditLimit: 200,
            checkAppResponseList: [
                {
                    applicationID: "00001",
                    responseCode: "WT_FND", //"WT_FND", //"FundFailure", //'WT_DOC', //"WT_FRE"
                    submittedDateUtc: new Date().toISOString(),
                    responseObjects: {
                        doC_AddressYN: true,
                        doC_DobYN: false,
                        doC_SsnYN: true
                    },
                    incentiveName: 'MinimumDepositAmount', // 'DepositReductionAmount', 'MinimumDepositAmount'
                    incentiveId: '1234567891098496854',
                    incentive: {
                        id: '1234-5678-90AB-CDEF',
                        name: 'OpenSky Nov-23 Promotion Deposit Reduction',
                        //name: 'OpenSky Nov-23 Promotion Minimum Deposit',
                        productTypeId: 1, // 1 is basic, 5 is plus
                        productTypeName: '',
                        startDateUtc: '11-1-2023 00:00:00',
                        endDateUtc: '11-30-2023 00:00:00',
                        isActive: true,
                        incentiveDetails: [
                            {
                                incentiveTypeId: 1,
                                incentiveTypeName: 'DepositReductionAmount', //MinimumDepositAmount, DepositReductionAmount
                                value: '51'
                            },
                            // {
                            //     incentiveTypeId: 1,
                            //     incentiveTypeName: 'MinimumDepositAmount', //MinimumDepositAmount, DepositReductionAmount
                            //     value: '200'
                            // },
                        ]
                    },
                    prn: "1000"
                } as CheckApplicationResponse,
                {
                    applicationID: "00002",
                    responseCode: "WT_FND", //"WT_FND", //"FundFailure", //'WT_DOC', //"WT_FRE"
                    submittedDateUtc: new Date().toISOString(),
                    responseObjects: {
                        doC_AddressYN: true,
                        doC_DobYN: false,
                        doC_SsnYN: true
                    },
                    incentiveName: 'MinimumDepositAmount', // 'DepositReductionAmount', 'MinimumDepositAmount'
                    incentiveId: '1234567891098496854',
                    incentive: {
                        id: '456-7890-12AB-CDEF',
                        name: 'OpenSky Nov-23 Plus Promotion Minimum Deposit',
                        productTypeId: 5,
                        productTypeName: '',
                        startDateUtc: '11-1-2023 00:00:00',
                        endDateUtc: '11-30-2023 00:00:00',
                        isActive: true,
                        incentiveDetails: [                           
                            {
                                incentiveTypeId: 2,
                                incentiveTypeName: 'MinimumDepositAmount', //MinimumDepositAmount, DepositReductionAmount
                                value: '200'
                            },
                        ]
                    },
                    prn: "9000"
                } as CheckApplicationResponse,
                {
                    applicationID: "00003",
                    responseCode: "WT_FND", //"WT_FND", //"FundFailure", //'WT_DOC', //"WT_FRE"
                    submittedDateUtc: new Date().toISOString(),
                    responseObjects: {
                        doC_AddressYN: true,
                        doC_DobYN: false,
                        doC_SsnYN: true
                    },
                    incentiveName: 'MinimumDepositAmount', // 'DepositReductionAmount', 'MinimumDepositAmount'
                    incentiveId: '1234567891098496854',
                    incentive: {
                        id: '1234-5678-90AB-CDEF',
                        name: 'OpenSky Nov-23 Promotion Deposit Reduction',
                        //name: 'OpenSky Nov-23 Promotion Minimum Deposit',
                        productTypeId: 1, // 1 is basic, 5 is plus
                        productTypeName: '',
                        startDateUtc: '11-1-2023 00:00:00',
                        endDateUtc: '11-30-2023 00:00:00',
                        isActive: true,
                        incentiveDetails: [
                            {
                                incentiveTypeId: 1,
                                incentiveTypeName: 'DepositReductionAmount', //MinimumDepositAmount, DepositReductionAmount
                                value: '51'
                            },
                            // {
                            //     incentiveTypeId: 1,
                            //     incentiveTypeName: 'MinimumDepositAmount', //MinimumDepositAmount, DepositReductionAmount
                            //     value: '200'
                            // },
                        ]
                    },
                    prn: "6100"
                } as CheckApplicationResponse,
            ]
        } as DecisionsResponse,
        // done: null as unknown as DecisionsResponse,
        applicationId: '00001',
        responseCode: 'WT_FND'//'FundFailure' // 'ExceededDailyPaymentTransactions' ///'WT_FIS', // 'UI_MRV', // 'WT_M83', // 'WT_DOC',
    },
    {
        done: {
            creditLimit: 200,
            checkAppResponseList: [
                {
                    applicationID: "00001",
                    responseCode: "WT_DOC",
                    submittedDateUtc: new Date().toISOString(),
                    responseObjects: {
                        doC_AddressYN: true,
                        doC_DobYN: false,
                        doC_SsnYN: true
                    }
                } as CheckApplicationResponse
            ]
        } as DecisionsResponse,
        // done: null as unknown as DecisionsResponse,
        applicationId: "00001",
        responseCode: "WT_DOC" // 'UI_MRV', // 'WT_M83', // 'WT_DOC',
    }
];

const throw400WithResponseCode = {
    done: {
        creditLimit: 200,
        checkAppResponseList: [
            {
                applicationID: "00001",
                responseCode: "END_WDR",
                submittedDateUtc: new Date().toISOString(),
                responseObjects: {
                    doC_AddressYN: true,
                    doC_DobYN: false,
                    doC_SsnYN: true
                }
            } as CheckApplicationResponse
        ]
    } as DecisionsResponse,
    applicationId: "400_WITH_RESPONSECODE",
    responseCode: "END_WDR"
};

let counter = 0;

export const submitApplication = [
    rest.post(`${process.env.REACT_APP_DEFAULT_API_BASE_URL}/api/application/submitLead`, async (req, res, ctx) => {
        const json = await req.json();

        if (json.id === "400_WITH_RESPONSECODE") {
            return res(ctx.status(400), ctx.json(throw400WithResponseCode));
        } else if (json.id === "400_WITHOUT_RESPONSECODE") {
            return res(ctx.status(400), ctx.json({}));
        } else if (json.id === "THROW_500") {
            return res(ctx.status(500), ctx.json({}));
        } else if (json.id === "THROW_403") {
            return res(ctx.status(403), ctx.json({}));
        }

        await new Promise(r => setTimeout(r, 3000));
        console.log("payload", json);
        const response = responses[Math.min(counter, responses.length)];
        counter++;
        return res(ctx.status(200), ctx.json(response));
    })
];
