import { rest } from 'msw';

export const calculateIncome = [
    rest.post(`${process.env.REACT_APP_DEFAULT_API_BASE_URL}/api/application/calculateIncome`, async (req, res, ctx) => {
        const json = await req.json();
        return res(
            ctx.status(200),
            ctx.json({
                calculatedIncome: json.monthlyHousingPayment * 2,
            })
        );
    }),
];
