import { useEffect, useRef } from 'react';

export const useInterval = (callback: any, delay: number, triggerOnChange=false) => {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    function tick() {
        // @ts-ignore
        savedCallback.current();
    }

    useEffect(() => {
        if (delay && triggerOnChange) {
            tick();
        }
    }, [delay, triggerOnChange]);

    useEffect(() => {
        if (delay) {
            const id = setInterval(tick, delay);
            return () => {
                clearInterval(id);
            };
        }
    }, [callback, delay]);
};
