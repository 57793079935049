import { rest } from 'msw';

export const addressData = [
    '15 Magnolia Dr., Cedar Rapids, IA 52402',
    '49 Alderwood Avenue, Enterprise, AL 36330',
    '9752 Hanover St., Fairburn, GA 30213',
    '7304 Randall Mill Dr., North Andover, MA 01845',
    '9005 Bowman St., Mahopac, NY 10541',
    '9925 Princeton Rd., Palatine, IL 60067',
    '715 South Jennings Street, Athens, GA 30605',
    '7010 Lake View Dr., Elmont, NY 11003',
    '9464 NW. Brookside Street, Gainesville, VA 20155',
    '576 Golden Star Dr., Elkhart, IN 46514',
    '8070 Harrison Lane, Shirley, NY 11967',
    '832 Victoria Lane, Cranberry Twp, PA 16066',
    '135 Temple Drive, Dallas, GA 30132',
    '9720 Inverness Ave., Manchester Township, NJ 08759',
    '113 Brandywine Drive, Phillipsburg, NJ 08865',
    '8582 Newbridge Street, Forney, TX 75126',
    '475 Crescent Drive, Sewell, NJ 08080',
    '7363 Valley Drive, Royal Oak, MI 48067',
    '7080 Sheffield St., Amarillo, TX 79106',
    '232 Creek St., Richmond Hill, NY 11418',
];

export const addressLookup = [
    rest.get(`${process.env.REACT_APP_SMARTY_API_BASE_URL}/lookup`, (req, res, ctx) => {
        const search = req.url.searchParams.get('search');
        const matches = search
            ? addressData.filter(x => x.toUpperCase().includes(search.toUpperCase()))
            : addressData;

        return res(
            ctx.status(200),
            ctx.json({
                matches,
            })
        );
    }),
];
