import React from 'react';
import './heading.scss';

interface HeadingProps {
    icon?: string;
    title: string;
    smallText: string;
    centerText?: boolean;
}

const Heading: React.FC<HeadingProps> = ({ icon, title, smallText, centerText }) => {
    return (
        <div className="heading-main">
            <div className="heading-content">
                {icon && (
                    <img
                        src={icon}
                        alt="heading icon"
                        className="heading-icon"
                    />
                )}
                <div className="heading-text-container">
                    <h1 className={centerText ? "heading-text-center" : "heading-text"}>{title}</h1>
                    <p className={centerText ? "heading-small-text-center" : "heading-small-text"}>
                        {smallText.split('<br/>').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Heading;
