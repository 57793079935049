import React from 'react';
import { Button, Card, Tab, Tabs } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { ApplicationFlowLayout } from '../../layouts';
import { Files } from '../../utils/fileConstants';
import { useRecoilValue } from 'recoil';
import { configurationResponseState, applicationState } from '../../store/atoms';
import { currentCardInfo } from '../../utils/product-info';

type ISelectedDisclosure = {
    buttonText: string;
    title: string;
    tab: string;
    link: string;
};

const DisclosureViewer = () => {
    const application = useRecoilValue(applicationState);
    const configurationResponse = useRecoilValue(configurationResponseState);
    const basicCardInfo = currentCardInfo('basic', configurationResponse?.securedProducts);
    const plusCardInfo = currentCardInfo('plus', configurationResponse?.securedProducts);
    const launchCardInfo = currentCardInfo('launch', configurationResponse?.securedProducts);

    const origDisclosures: ISelectedDisclosure[] = [
        {
            buttonText: 'E-Sign Consent',
            title: 'E-Sign Disclosure and Consent Statement',
            tab: 'esign-consent',
            link: Files.GetFiles().electronicDisclosure.toString(),
        },
        {
            buttonText: 'Privacy Notice',
            title: 'Privacy Notice',
            tab: 'privacy-notice',
            link: Files.GetFiles().privacyNotice.toString(),
        },
        {
            buttonText: 'Privacy Policy',
            title: 'Privacy Policy',
            tab: 'privacy-policy',
            link: Files.GetFiles().privacyPolicy.toString(),
        },
    ];

    const basicDisclosures: ISelectedDisclosure[] = [
        {
            buttonText: 'Terms and Conditions',
            title: 'Terms and Conditions',
            tab: 'basic-terms-and-conditions',
            link: basicCardInfo.currentTermsConditions,
        },
        {
            buttonText: 'Cardholder Agreement',
            title: 'Cardholder Agreement',
            tab: 'basic-cardholder-agreement',
            link: basicCardInfo.currentCardHolderAgreement,
        },
    ];

    const plusDisclosures: ISelectedDisclosure[] = [
        {
            buttonText: 'Terms and Conditions',
            title: 'Terms and Conditions',
            tab: 'plus-terms-and-conditions',
            link: plusCardInfo.currentTermsConditions,
        },
        {
            buttonText: 'Cardholder Agreement',
            title: 'Cardholder Agreement',
            tab: 'plus-cardholder-agreement',
            link: plusCardInfo.currentCardHolderAgreement,
        },
    ];

    const launchDisclosures: ISelectedDisclosure[] = [
        {
            buttonText: 'Terms and Conditions',
            title: 'Terms and Conditions',
            tab: 'launch-terms-and-conditions',
            link: launchCardInfo.currentTermsConditions,
        },
        {
            buttonText: 'Cardholder Agreement',
            title: 'Cardholder Agreement',
            tab: 'launch-cardholder-agreement',
            link: launchCardInfo.currentCardHolderAgreement,
        },
    ];

    const {
        state: { tab, cardType },
    } = useLocation<{ tab: string, cardType: string }>();

    let disclosures;

    console.log('Original CardType:', cardType);
    console.log('Application CardType:', application?.cardType);

    if (application?.cardType === 'basic') {
        disclosures = origDisclosures.concat(basicDisclosures);
    } 
    else if (application?.cardType === 'plus') {
        disclosures = origDisclosures.concat(plusDisclosures);
    }
    else {
        disclosures = origDisclosures.concat(launchDisclosures);
    }

    return (
        <ApplicationFlowLayout
            showBackButton
            progress="99%"
            cardType={application.cardType}
        >
            <div className="disclosures">
                <Tabs
                    defaultActiveKey={tab || disclosures[0].link}
                    className="disclosure-tabs"
                    justify
                >
                    {disclosures.map((disclosure, index) => (
                        <Tab
                            key={index}
                            eventKey={disclosure.tab}
                            title={disclosure.buttonText}
                        >
                            <div className="disclosure-header">
                                <p className="section-title">
                                    {disclosure.title}
                                </p>
                                <Button
                                    variant="link"
                                    className="download-button"
                                    href={disclosure.link}
                                    target="_blank"
                                >
                                    Download/Print
                                </Button>
                            </div>
                            <Card>
                                <Card.Body>
                                    <div className="disclosure-content">
                                        <iframe
                                            title="disclosureLink"
                                            src={disclosure.link}
                                        />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Tab>
                    ))}
                </Tabs>
            </div>
        </ApplicationFlowLayout>
    );
};

export default DisclosureViewer;