/* eslint-disable react-hooks/exhaustive-deps */
import { UseComboboxStateChange } from 'downshift';
import React, { FC, useEffect, useState } from 'react';
import {
    FieldErrorsImpl,
    Path,
    UseFormRegister,
    UseFormSetValue,
    UseFormTrigger,
} from 'react-hook-form';
import { useAddressLookup, useDebounce } from '../../hooks';
import {
    FundingSourceModel,
    IdentityVerificationModel,
    MailingAddressModel,
} from '../../types';
import AutoComplete from '../auto-complete';
import { Item } from '../auto-complete/types';
import SelectDropdown from '../select-dropdown';
import TextInput from '../text-input';
import './address-form.scss';
import { usStates } from './constants';
import { Address } from '../../types';
import { invalidAddressCharacters } from '../../utils/validations';

type AddressItem = Address & Item;

type AddressLabels = {
    addressLookupLabel?: string;
    addressLookupFloatingLabel?: string;
    addressLine1Label?: string;
    addressLine2Label?: string;
    cityLabel?: string;
    stateLabel?: string;
    zipCodeLabel?: string;
};

type AddressFormProps = {
    name?: Path<
        IdentityVerificationModel | MailingAddressModel | FundingSourceModel
    >;
    register?: UseFormRegister<any>;
    trigger?: UseFormTrigger<any>;
    labels?: AddressLabels;
    errors?: FieldErrorsImpl<any>;
    initialAddress?: Address;
    onChange?: () => void;
    setValue?: UseFormSetValue<any>;
    validPattern?: RegExp;
};

const MANUAL_ENTRY_OPTION = 'MANUAL_ENTRY_OPTION';

const AddressForm: FC<AddressFormProps> = ({
    labels,
    name,
    register,
    trigger,
    errors,
    initialAddress,
    onChange,
    setValue,
    validPattern,
}) => {
    const [isManualEntryForm, setIsManualEntryForm] = useState(false);
    const [selectedAddress, setSelectedAddress] =
        useState<AddressItem | null>();
    const [address, setAddress] = useState<Address>({});

    const [fullAddress, setFullAddress] = useState('');
    const debouncedFullAddress = useDebounce(fullAddress, 500);

    const { data } = useAddressLookup(debouncedFullAddress, validPattern);

    const handleManualEntrySelect = () => {
        setFullAddress('');
        setSelectedAddress(manualEntrySelectItem);
    };

    const handleChange = () => {
        onChange && onChange();
    };

    // const { onChange: registerOnChange, ...restRegisterProps } =
    //     (register && register(name || '')) || {};

    const manualEntrySelectItem: Item = {
        value: MANUAL_ENTRY_OPTION,
        display: (
            <span style={{ fontWeight: 700, color: "#0000EE", textDecoration: "Underline" }}>I don't see my address</span>
        ),
        onSelect: handleManualEntrySelect,
        shouldNotChangeInputValue: true,
    };

    const items: Item[] = [
        ...(data?.suggestions || []).map((value) => {
            const addressString = `${value.street_line}, ${value.city}, ${value.state} ${value.zipcode}`;
            return {
                value: addressString,
                display: <span>{addressString}</span>,
            };
        }),
        manualEntrySelectItem,
    ];

    /**
     *
     * @param value
     * @returns Address object with items parsed out
     */
    const parseFullAddress = (value: string) => {
        // Since we are parsing a set format we can use this
        // If we want to parse what the user types into the search, we will have to do some extra work:
        // TODO: depending on values we get back from google api or any other address lookup api change this parse function
        const addressSplit = value.split(',');
        if (addressSplit.length === 4) {
            const stateZipSplit = addressSplit[3].trim().split(' ');
            return {
                addressLine1: addressSplit[0].trim(),
                addressLine2: addressSplit[1].trim(),
                city: addressSplit[2].trim(),
                state: stateZipSplit[0],
                zipCode: stateZipSplit[1],
            };
        } else if (addressSplit.length === 3) {
            const stateZipSplit = addressSplit[2].trim().split(' ');
            return {
                addressLine1: addressSplit[0].trim(),
                addressLine2: undefined,
                city: addressSplit[1].trim(),
                state: stateZipSplit[0],
                zipCode: stateZipSplit[1],
            };
        } else {
            return {
                addressLine1: value,
            };
        }
    };

    const handleFullAddressChange = (
        changes: UseComboboxStateChange<AddressItem>
    ) => {
        setFullAddress(changes?.inputValue || '');
    };

    const handleSelect = (item?: AddressItem | null) => {
        setSelectedAddress(item);        
    };

    const handleManualEntryAddressChange = (newAddress: Address | null) => {
        setAddress({
            ...address,
            ...newAddress,
        });
        handleChange();
    };

    const handleSwitchToAddressLookup = () => {
        // TODO: if we want to change full based on manual entry form we need more work
        setFullAddress('');
        setSelectedAddress(undefined);
        setAddress({});
    };
    // Because of a race condition between address changes and rerendering of the autocomplete component
    // we need to change forms after the selectedAddress changes:
    useEffect(() => {
        if (selectedAddress) {
            setIsManualEntryForm(true);
        } else {
            if (name && setValue) {
                setValue(`${name}.addressLine1`, '');
                setValue(`${name}.addressLine2`, '');
                setValue(`${name}.city`, '');
                setValue(`${name}.state`, '');
                setValue(`${name}.zipCode`, '');
            }
            setIsManualEntryForm(false);
        }
    }, [selectedAddress]);

    // And then after switching forms we set address values:
    useEffect(() => {
        if (isManualEntryForm) {
            if (
                selectedAddress &&
                selectedAddress?.value !== MANUAL_ENTRY_OPTION
            ) {
                const parsedAddress = parseFullAddress(selectedAddress.value);
                setAddress(parsedAddress);
                name && setValue && setValue(name, parsedAddress);
            } else {
                setAddress({});
                name && setValue && setValue(name, {});
            }
        } else {
            setAddress({});
        }

        // switching between form/autocomplete does not trigger react-hook-form updates, so we need to force it
        trigger && trigger();
        handleChange();
        // we dont want this useEffect to run every time selectedAddress changes and we want the value
        // of selectedAddress at the time that we switch to the manual entry form
        // so we can disable eslint:
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isManualEntryForm]);

    useEffect(() => {
        if (initialAddress) {
            // @ts-ignore
            setSelectedAddress({
                value: `${initialAddress.addressLine1}, 
        ${initialAddress.addressLine2 ? `${initialAddress.addressLine2},` : ''} 
        ${initialAddress.city}, ${initialAddress.state} ${
                    initialAddress.zipCode
                }`,
            });
        }
    }, [initialAddress]);

    return (
        <div className="address-lookup-container">
            {isManualEntryForm ? (
                <>
                    <TextInput
                        type="text"
                        name={`${name}.addressLine1`}
                        data-testid='address-form-address-line1-input'
                        label={labels?.addressLine1Label || 'Address'}
                        placeholder={labels?.addressLine1Label || 'Address'}
                        aria-label={labels?.addressLine1Label || 'Address'}
                        onChange={(event) =>                                                        
                            handleManualEntryAddressChange({
                                addressLine1: event.target.value.replace(invalidAddressCharacters, ""),
                            })
                        }
                        register={register}
                        errors={errors}
                        showErrorsOnLoad={true}
                        value={address?.addressLine1 || ''}
                        maxLength="50"
                    />
                    <TextInput
                        type="text"
                        name={`${name}.addressLine2`}
                        data-testid='address-form-address-line2-input'
                        label={
                            labels?.addressLine2Label ||
                            'Apt, Suite, Building (optional)'
                        }
                        placeholder={
                            labels?.addressLine2Label ||
                            'Apt, Suite, Building (optional)'
                        }
                        aria-label={
                            labels?.addressLine2Label ||
                            'Apt, Suite, Building (optional)'
                        }
                        onChange={(event) =>
                            handleManualEntryAddressChange({
                                addressLine2: event.target.value.replace(invalidAddressCharacters, ""),
                            })
                        }
                        register={register}
                        errors={errors}
                        showErrorsOnLoad={true}
                        value={address?.addressLine2 || ''}
                        maxLength="10"
                    />
                    <TextInput
                        type="text"
                        name={`${name}.city`}
                        data-testid='address-form-city-input'
                        label={labels?.cityLabel || 'City'}
                        placeholder={labels?.cityLabel || 'City'}
                        aria-label={labels?.cityLabel || 'City'}
                        onChange={(event) =>
                            handleManualEntryAddressChange({
                                city: event.target.value.replace(invalidAddressCharacters, ""),
                            })
                        }
                        register={register}
                        errors={errors}
                        showErrorsOnLoad={true}
                        value={address?.city || ''}
                        maxLength="18"
                    />
                    <SelectDropdown
                        label={labels?.stateLabel || 'State'}
                        name={`${name}.state`}
                        data-testid='address-form-state-input'
                        options={usStates.map((state) => ({
                            value: state.abbreviation,
                        }))}
                        value={address?.state || ''}
                        register={register}
                    />
                    <TextInput
                        type="text"
                        name={`${name}.zipCode`}
                        data-testid='address-form-zipcode-input'
                        label={labels?.zipCodeLabel || 'Zip Code'}
                        placeholder={labels?.zipCodeLabel || 'Zip Code'}
                        aria-label={labels?.zipCodeLabel || 'Zip Code'}
                        onChange={(event) =>
                            handleManualEntryAddressChange({
                                zipCode: event.target.value.replace(/[^0-9-]/gi, ""),
                            })
                        }
                        register={register}
                        errors={errors}
                        showErrorsOnLoad={true}
                        value={address?.zipCode || ''}
                        maxLength="10"
                    />
                    <div className="back-to-address-lookup-link-container">
                        <span onClick={handleSwitchToAddressLookup}>
                            Not your address? Try again
                        </span>
                    </div>
                </>
            ) : (
                <AutoComplete
                    onInputValueChange={handleFullAddressChange}
                    onSelectChange={handleSelect}
                    initialInputValue={fullAddress}
                    items={items}
                    label={
                        labels?.addressLookupLabel ||
                        'Address listed on your billing statement'
                    }
                    floatingLabel={
                        labels?.addressLookupFloatingLabel ||
                        'Search for Billing Address'
                    }
                    enableClear={true}
                />
            )}
        </div>
    );
};

export default AddressForm;
