import React from 'react';
import { Card } from 'react-bootstrap';
import {
    creditcard,
    creditcardLaunch,
    creditcardPlusNoRibbon,
} from '../../assets/img';
import './index.scss';

type BalanceInfoOptions = {
    balanceDue?: string,
    programFee?: string,
    securityDeposit?: string,
}

type CardInfoOptions = {
    cardType?: string,
    creditLimit?: string,
    balanceDue?: string,
    programFee?: string,
    securityDeposit?: string,
};

export const BalanceInfo= (props: BalanceInfoOptions) => {
    return (
        <>
            <div className="card-bottom">
                <div className="balance-due">
                    <span className="balance-text">Balance due</span><span className="balance-numbers">${props.balanceDue}</span>
                </div>
                <div className="fees">
                    <div className="individual-fee">
                        <span className="fee-text">Program fee*</span><span className="fee-numbers">${props.programFee}</span>
                    </div>
                    <div className="individual-fee">
                        <span className="fee-text">Refundable security deposit</span><span className="fee-numbers">${props.securityDeposit}</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export const CardInfo = (props: CardInfoOptions) => {
    return (
        <>
            <div className="card-info-container">
                <Card>
                    <Card.Body>
                        <div className="card-horizontal">
                            <img
                                src={props.cardType?.toLowerCase() === 'basic' ? creditcard :
                                    props.cardType?.toLowerCase() === 'plus' ? creditcardPlusNoRibbon :
                                    creditcardLaunch}
                                alt="cool blue credit card"
                            />
                            <div className="divider"></div>
                            <div>
                                <div className="credit-limit">
                                    ${props.creditLimit}
                                </div>
                                <div className="credit-limit-text">
                                    Credit Limit
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};
