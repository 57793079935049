import React, { FC } from 'react';
import { iconQuestion } from '../../assets/img';

const Header: FC = (props) => (
    <h1
        style={{
            borderBottom: '1px solid #a8a8a8',
            paddingTop: '1rem',
            paddingBottom: '1rem',
            marginBottom: '1rem',
            fontFamily: 'Inter',
            fontSize: '20px',
            fontWeight: '500',
            lineHeight: '30px',
        }}
    >
        {props.children}
    </h1>
)

const Section: FC = (props) => (
    <div className='help-tray-section'>
        {props.children}
    </div>
)

const SectionTitle: FC = (props) => (
    <h2
        style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontSize: '15px',
            fontWeight: '600',
            lineHeight: '24px',
            paddingTop: '18px',
        }}
    >
        {props.children}
    </h2>
)

const SectionText: FC = (props) => (
    <p
        style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontSize: '15px',
            fontWeight: '400',
            lineHeight: '24px',
            paddingTop: '10px',
        }}
    >
        {props.children}
    </p>
)

const HelpTray: FC<any> = (props) => (
    <div
        className='help-tray'
        style={{
            paddingBottom: '18px',
        }}
    >
        <img src={iconQuestion} alt='' />
        {props.children}
    </div>
);

export default Object.assign(HelpTray, {
    Header,
    Section,
    SectionTitle,
    SectionText,
});
