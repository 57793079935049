import { rest } from "msw";

let counterLead = 0;
let counterCheck = 0;

export const verifyOtp = [
    // create lead otp
    rest.post(`${process.env.REACT_APP_DEFAULT_API_BASE_URL}/api/security/verifyLeadOtp`, async (req, res, ctx) => {
        counterLead++;
        const json = await req.json();
        await new Promise(r => setTimeout(r, 1000));
        let status = 200;
        if (counterLead > 1 && json.otpCode !== "999999" && json.otpCode !== "000000") {
            status = 403;
        } else if (json.otpCode === "000000") {
            status = 400;
        }
        return res(ctx.status(status), ctx.json({}));
    }),
    // check app status otp
    rest.post(
        `${process.env.REACT_APP_DEFAULT_API_BASE_URL}/api/security/verifyCheckAppStatusOtp`,
        async (req, res, ctx) => {
            counterCheck++;
            const json = await req.json();
            await new Promise(r => setTimeout(r, 1000));
            let status = 200;
            if (counterCheck > 1 && json.otpCode !== "999999" && json.otpCode !== "000000") {
                status = 403;
            } else if (json.otpCode === "000000") {
                status = 400;
            }
            return res(ctx.status(status), ctx.json({}));
        }
    )
];
