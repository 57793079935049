import React from "react";
import "./card-heading.scss";
import "../../scss/main.scss";

interface CardHeadingProps {
    headingText: string;
    headingLogoSource: string;
    subheadText?: string;
}

const CardHeading: React.FC<CardHeadingProps> = ({
    headingText,
    headingLogoSource,
    subheadText
}) => {
    return (
        <div className='heading-container'>
            <div className='headline'>
                <h1 className='heading'>{headingText}</h1>
                <div className='vertical-line' />
                <img src={headingLogoSource} className='logo' />
            </div>
            <div className='horizontal-line'></div>
            {subheadText && <p className='card-heading-subhead'>{subheadText}</p>}
        </div>);
}

export default CardHeading;