import React from 'react';
import './dropdown.scss';
import { UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { dropdownArrowIcon } from '../../../assets/img';

interface Option {
  label?: string;
  value: string | number;
}

interface DropdownProps {
  name?: string;
  options: Option[];
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
  defaultValue?: string | number;
  selectedValue: string;
  label?: string;
  className?: string;
  setValue?: UseFormSetValue<any>;
  trigger?: UseFormTrigger<any>;
}

const Dropdown: React.FC<DropdownProps> = ({
  name,
  options,
  onChange,
  selectedValue,
  defaultValue,
  label,
  className,
  setValue,
  trigger
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (setValue) {
      setValue(name || '', event.target.value);
      trigger && trigger(name);
    }
    onChange && onChange(event.target.value);
  };

  return (
    <div>
      {label && <label className='label'>{label}</label>}
      <div className="select-container">
        <select value={defaultValue || selectedValue} onChange={handleChange} className={className ? className : 'standard-dropdown'}>
          {!defaultValue && <option value="" disabled />}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <img src={dropdownArrowIcon} className="dropdown-arrow" alt="dropdown arrow" />
      </div>
    </div>
  );
};

export default Dropdown;