import { useMutation } from 'react-query';
import { microserviceClient } from '../../api-clients';
import { WithdrawApplicationRequest, WithdrawApplicationResponse } from '../../types';

const withdrawApplication = (payload: WithdrawApplicationRequest) =>
    microserviceClient.post<WithdrawApplicationResponse>('/api/application/withdrawApplication', payload);

export const useWithdrawApplication = () => {
    return useMutation(
        withdrawApplication,
        {
            onError: (err, variables, context) => {
                console.log('Error attempting to withdraw application', err);
            },
        }
    );
};
