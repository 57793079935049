import React, { FC } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { iconCircleCheckMark } from '../../assets/img';
import { LandingPageLayout } from '../../layouts';
import './index.scss';

export type PartialPaymentProps = {
    paymentAmount: number;
    partialAmount: number;
    remainder: number;
    daysToFund: number;
    onNext: () => void;
};

const PartialPayment: FC<PartialPaymentProps> = ({
    paymentAmount,
    partialAmount,
    remainder,
    daysToFund,
    onNext,
}) => {
    const onSubmit = () => {
        onNext();
    };

    return (
        <LandingPageLayout>
            <div className="default">
                <Row>
                    <Col>
                        <img alt="" src={iconCircleCheckMark} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h1>
                            Your OpenSky account was successfully funded with $
                            {paymentAmount}{' '}
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="partial-payment-span" style={{width: "360px", height: "150 px"}}>
                            <hr/>
                            <div>
                                <span>
                                    <p>Security Deposit Received</p>
                                    <p>${partialAmount}</p>
                                </span>
                            </div>
                            <div>
                                <span>
                                    <p>Security Deposit Remaining</p>
                                    <p>${remainder}</p>
                                </span>
                            </div>
                            <hr/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span>
                            You have {daysToFund} days left to fund your outstanding
                            security deposit amount.
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button onClick={onSubmit}>Ok, Got it.</Button>
                    </Col>
                </Row>
            </div>
        </LandingPageLayout>
    );
};

export default PartialPayment;
