import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import valid from 'card-validator'; //import statement
import { useMakePayment, useGetApplicationsPaymentStatus } from './hooks';
import { useProcessResponseCode } from '../../hooks';
import { AddressForm, NumberFormatInput, TextInput, PromotionSpecific } from '../../components';
import { isIncentiveFeatureActive, getPrn } from '../../utils/incentives';
import { ApplicationFlowLayout } from '../../layouts';
import {
    applicationState,
    selectedApplicationIdState,
    sendCheckAppStatusOtpRequestState,
    decisionsResponseState,
    responseCodeState,
    paymentSucceededState,
    checkAppState,
    configurationResponseState,
    gaDataLayerState,
    downsellState,
    downsellSourcesState,
} from '../../store/atoms';
import { 
    ErrorPageProps, 
    FundingEvent, 
    FundingSourceModel, 
    MakePaymentRequest, 
    MakePaymentResponse, 
    ResponseCodes, 
    IncentiveFeatures 
} from '../../types';    
import FundingSourceHelp from './funding-source-help';
import PartialPayment from './partial-payment';
import PaymentProcessing from './payment-processing';
import {
    FundingError,
    FundingErrorAlert,
    FundingHeader,
    PartialFundingVariation,
} from './variations';
import { isValidName, invalidNameCharacters, zipCodePattern } from '../../utils/validations';
import { checkForUnauthorized, routeHashes } from '../../app-routes';
import * as FullStory from '@fullstory/browser';
import { iconMastercard, iconVisa } from '../../assets/img';
import { debuglog } from '../../utils/debug';
import { pushToDataLayer } from '../../utils/analytics';
import { CardTypes } from '../../utils/constants';

const FundingSource: FC = () => {
    const [application, setApplication] = useRecoilState(applicationState);
    const responseCode = useRecoilValue(responseCodeState);
    const decisionsResponse = useRecoilValue(decisionsResponseState);
    const applicationId = useRecoilValue(selectedApplicationIdState);
    const sendCheckAppStatusOtpRequest = useRecoilValue(sendCheckAppStatusOtpRequestState);
    const [fundToday, setFundToday] = useState(application.fundToday ?? true);
    const [toggleForm, setToggleForm] = useState(false);
    const [isError, setIsError] = useState<boolean>();
    const [fundWithDebit, setFundWithDebit] = useState<boolean>();
    const [remainder, setRemainder] = useState<number>();
    const [partialAmount, setPartialAmount] = useState<number>();
    const [amount, setAmount] = useState<number>();
    const [daysLeftToFund, setDaysLeftToFund] = useState<number>();
    const [responseCodeToProcess, setResponseCodeToProcess] = useState<string>();
    const [fundingResponse, setFundingResponse] = useState<MakePaymentResponse>();
    const [fundingAmount, setFundingAmount] = useState(0);
    const [paymentSucceeded, setPaymentSucceeded] = useRecoilState(paymentSucceededState);
    const isCheckApp = useRecoilValue(checkAppState);
    const [debounceActive, setDebounceActive] = useState(false);
    const debitCardRef = useRef<HTMLDivElement>(null);
    const [showCardValidationError, setShowCardValidationError] = useState(false);
    const configurationResponse = useRecoilValue(configurationResponseState);
    const [promoValue, setPromoValue] = useState<string>();
    const [gaDataLayer, setGaDataLayer] = useRecoilState(gaDataLayerState);
    const downsell = useRecoilValue(downsellState);
    const downsellSources = useRecoilValue(downsellSourcesState);

    // TODO: since the fundToday button has moved, this can be deleted?
    const changeFundTodayStatus = (event: any) => {
        setApplication((current) => ({
            ...current,
            ...{ fundToday: !!event.target.checked },
        }));
        setFundToday(!!event.target.checked);
    }

    const paymentFailureResponseCodes = [
        ResponseCodes.FundFailure,
        ResponseCodes.FundFailureFinal       
    ];

    const history = useHistory();
    const makePaymentMutation = useMakePayment();
    const { data: getApplicationsPaymentStatus } = useGetApplicationsPaymentStatus(applicationId);    

    const selectedApplication = decisionsResponse?.checkAppResponseList?.filter(app => app.applicationID === applicationId).find(a=>a!==undefined);
    // eslint-disable-next-line no-self-compare
    const showPartialPaymentInput = !!(fundWithDebit && !fundToday && (remainder ?? 0 > 0));
    // eslint-disable-next-line no-self-compare
    const showPartialPaymentSummary = !!(fundWithDebit && (partialAmount ?? 0 > 0) && (remainder ?? 0 > 0));

    const handleRedirect = () => {
        debuglog('redirecting');
        debuglog('===fundToday-is', fundToday);

        const updateDataLayer = {
            ...gaDataLayer,
            'step_name': 'Funding Source',
            'card_fund_selection': fundToday ? 'fund_now' : 'fund_later'
        }

        setGaDataLayer(updateDataLayer);

        pushToDataLayer('application_step_7', updateDataLayer);

        setApplication((current) => ({
            ...current,
            ...{ fundToday: fundToday },
        }));

        if(downsellSources.includes(application.cardType as CardTypes)){
            history.push(routeHashes['/launch-downsell']);
        }
        else{
            history.push(routeHashes['/credit-line-fund-later']);
        }
    }

    // process response code and redirect
    useProcessResponseCode(responseCodeToProcess);

    const validationSchema = Yup.object().shape({
        amount: Yup.number()
            .when('showPartialPaymentInput', {
                is: true,
                then: Yup.number()
                    .required('Credit line amount is required')
                    .typeError('You must specify a numeric value')
                    .min(Math.min(25, remainder ?? 0), `Amount must be at least $${Math.min(25, remainder ?? 0)}`)
                    .max(remainder ?? 1000, `Amount must be at most $${remainder ?? 1000}`)
                    .default(amount),
            }),
        cardHolderFirstName: Yup.string()
            .required('First Name is required')
            .test(
                'contains-alpha-characters-firstname',
                `First Name should only contain alphabetical characters.`,
                (value) => isValidName(value)
            ),
        cardHolderLastName: Yup.string() 
            .required('Last Name is required.')
            .test(
                'contains-alpha-characters-lastname',
                `Last Name should only contain alphabetical characters.`,
                (value) => isValidName(value)
            ),
        cardNumber: Yup.string()
            .test(
                'test-credit-card-number',
                'Card number is invalid',
                (value) => valid.number(value).isValid
            )
            .test(
                'test-credit-card-type',
                'Credit Card must be Visa or MasterCard',
                (value) =>
                    valid.number(value).card?.type === 'visa' ||
                    valid.number(value).card?.type === 'mastercard'
            )
            .required('Card number is required'),
        cardExpiration: Yup.string()
            .test(
                'test-expiration',
                'Expiration is invalid',
                (value) => valid.expirationDate(value).isValid
            )
            .required('Expiration date is required'),
        cardSecurityCode: Yup.string()
            .test(
                'test-cvv',
                'CVV code is invalid',
                (value) => valid.cvv(value).isValid
            )
            .required('Security code is required'),
        bankingAddress: Yup.object().shape({
            addressLine1: Yup.string()
                .required('Please enter a street address')
                .max(50, 'Total Exceeds Maximum Characters'),
            city: Yup.string()
                .required('City is required')
                .max(18),
            state: Yup.string()
                .required('State is required'),
            zipCode: Yup.string()
                .required('Zip Code is required')
                .min(5, 'Zip Code is required')
                .max(10, 'Zip Code is required')
                .test(
                    'zipcode',
                    'Zip Code is required',
                    (value) => value !== ''
                )                
                .matches(zipCodePattern, 'Must be a U.S. Zip Code')
                .test('first-five-valid-range', 'Must be a U.S. Zip Code', 
                    (value) =>  {       
                        let zipCodeValue = parseInt((value ?? '0').substring(0,5));                 
                        return zipCodeValue > 500 && zipCodeValue < 99951; 
                }),
            addressLine2: Yup.string()
                .max(10),
        }),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const {
        handleSubmit,
        register,
        reset,
        setValue,
        trigger,
        watch,
        formState: { errors, isValid },
    } = useForm<any>({
        ...formOptions,
        defaultValues: useMemo(() => ({
            cardHolderFirstName: application?.cardHolderFirstName,
            cardHolderLastName: application?.cardHolderLastName,
            cardNumber: application?.cardNumber,
            cardExpiration: application?.cardExpiration,
            cardSecurityCode: application?.cardSecurityCode,
            bankingAddress: {
                addressLine1: application?.bankingAddress?.addressLine1,
                city: application?.bankingAddress?.city,
                state: application?.bankingAddress?.state,
                zipCode: application?.bankingAddress?.zipCode,
                addressLine2: application?.bankingAddress?.addressLine2,
            },
            showPartialPaymentInput,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }), [showPartialPaymentInput]),
        mode: 'all',
    });

    const amountWatch = watch('amount');

    useEffect(() => {
        reset({
            showPartialPaymentInput,
        });
        trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showPartialPaymentInput]);

    useEffect(() => {
        if (isError && isValid) {
            setShowCardValidationError(false);
        } else if (isError && !isValid) {
            setShowCardValidationError(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid]);

    const resetForm = () => {
        setToggleForm(true);

        const defaultState: FundingSourceModel = {
            cardHolderFirstName: '',
            cardHolderLastName: '',
            cardNumber: '',
            cardExpiration: '',
            cardSecurityCode: '',
            bankingAddress: undefined,
        };

        // @ts-ignore
        Object.keys(defaultState).forEach((x: any) => setValue(x, defaultState[x]));
        // need both this reset call and the setValue calls above
        reset(defaultState);        
        trigger();

        // hack to run this after setValue(s) have propagated
        setTimeout(() => {
            setApplication(current => ({
                ...current,
                ...defaultState,
            }));
            setToggleForm(false);
        }, 100);
    }

    useEffect(() => {               
        if ((!showPartialPaymentInput && !showPartialPaymentSummary) && !fundToday) {            
            resetForm();
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amountWatch, fundToday]);

    useEffect(() => {
        // need this additional trigger or we get validation errors on load if existing data
        trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const isErrorResponseCode = responseCode === ResponseCodes.FundFailure;
        setIsError(isErrorResponseCode);
        if (isErrorResponseCode) {
            resetForm();
            setShowCardValidationError(true);
            setTimeout(() => {
                debitCardRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 2);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseCode]);

    useEffect(() => {
        const isErrorResponseCode = responseCode === ResponseCodes.FundFailure;
        let fundingHistory: FundingEvent[] | undefined = decisionsResponse?.fundingHistory ?? [];
        let selectedAppCreditLimit = selectedApplication?.responseObjects?.creditLimit ?? undefined;

        if (getApplicationsPaymentStatus && decisionsResponse){            
            fundingHistory = getApplicationsPaymentStatus?.done?.fundingHistory ?? [];
        }
        debuglog('fundingHistory', fundingHistory);

        debuglog('decisionsResponse', decisionsResponse);

        if (decisionsResponse) {
            const funded = fundingHistory?.map(x => x.amountReceived)?.reduce((total, current) => total + current, 0) ?? 0;            
            debuglog('Current Balance:', decisionsResponse.currentBalance);
            const incentiveAddOn = decisionsResponse.incentiveValue ?? 0;
            let currentBalance: number = decisionsResponse.currentBalance ?? funded + incentiveAddOn;
            debuglog('incentiveAddOn:', incentiveAddOn);
            setDaysLeftToFund(getApplicationsPaymentStatus?.done?.daysLeftToFund ?? decisionsResponse.daysLeftToFund);
            setAmount(selectedAppCreditLimit ?? decisionsResponse.creditLimit);
            setPartialAmount(currentBalance);
            setRemainder((selectedAppCreditLimit ?? decisionsResponse.creditLimit) - currentBalance);
            setFundWithDebit(!isErrorResponseCode && ((selectedAppCreditLimit ?? decisionsResponse.creditLimit) > currentBalance));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [decisionsResponse, responseCode, getApplicationsPaymentStatus, applicationId, remainder]);

    useEffect(() => {        
        if (paymentSucceeded) {
            history.replace(routeHashes['/error'], {
                title: 'Oops, your payment was already submitted.',
                buttonText: 'Ok, Got it',
                navigateTo: process.env.REACT_APP_HOMEPAGE_URL,
            } as ErrorPageProps);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNext = () => {
        // Used when processing partial payment.        
        window.location.href = `${process.env.REACT_APP_HOMEPAGE_URL}`;
    }

    const onToggleFundToday = (newValue: boolean) => {
        // NOTE: toggle fundToday
        setFundToday(newValue);

        debuglog('========setting fund today to', newValue);
        trigger();       
    }

    const onSubmit = async (event: FundingSourceModel) => {        
        if (debounceActive) 
            return;

        debuglog('======funding-source-fund-today', fundToday);
        setFundToday(fundToday);

        setDebounceActive(true);

        const updateDataLayer = {
            ...gaDataLayer,
            'step_name': 'Funding Source',
            'card_fund_selection': fundToday ? 'fund_now' : 'fund_later'
        }

        setGaDataLayer(updateDataLayer);

        pushToDataLayer('application_step_7', updateDataLayer);

        if (isError) {
            // error in lead submission
            setApplication((current) => ({
                ...current,
                ...event,
            }));

            history.push(routeHashes['/submit-application']);
        } else if (application?.emailAddress && !responseCode) {
            switch (application?.cardType) {
                case "basic": {
                    FullStory.event('FundingSourceNext', { leadId: application.leadId });
                    break;
                }
                case "plus": {
                    FullStory.event('Plus_FundingSourceNext', { leadId: application.leadId });
                    break;
                }
                case "launch": {
                    FullStory.event('Launch_FundingSourceNext', { leadId: application.leadId });
                    break;
                }
                default: {
                    FullStory.event('FundingSourceNext', { leadId: application.leadId });
                    break;
                }
            }

            // initial funding source collection
            setApplication((current) => ({
                ...current,
                ...event,
                ...{ fundToday: true },
            }));

            //Forward Credit-Line-Confirm & funding section to end of process
            history.push(routeHashes['/terms-and-conditions']);
        } else {
            // fund card after lead submission (fund later)
            const payload: MakePaymentRequest = {
                /* TODO: Check if the billing address name is where the name is transmitted on the back end */
                // cardHolderFirstName: event.cardHolderFirstName!,                
                // cardHolderLastName: event.cardHolderLastName,
                cardNumber: event.cardNumber!,
                expirationDateMMYY: event.cardExpiration!,
                cvv: event.cardSecurityCode!,
                amount: fundToday ? remainder! : fundingAmount!,
                email: application?.emailAddress ?? sendCheckAppStatusOtpRequest?.emailAddress ?? '',
                billingAddress: {
                    firstName: event.cardHolderFirstName!,
                    lastName: event.cardHolderLastName!,
                    line1: event.bankingAddress?.addressLine1!,
                    city: event.bankingAddress?.city!,
                    state: event.bankingAddress?.state!,
                    zip: event.bankingAddress?.zipCode!,
                },
                attemptNumber: 0,
                isFullyFunded: fundToday || fundingAmount === remainder,
            };
            makePaymentMutation.mutate({ applicationId: applicationId!, payload }, {
                onSuccess: (response) => {                                    
                    if (!paymentFailureResponseCodes.includes((response?.data?.responseCode as ResponseCodes))) {
                        setPaymentSucceeded(true);
                    }   
                    // Sets the funding response to display receipt of funds. 
                    if (response?.data?.responseCode === ResponseCodes.ApplicationInGoodFunds) {
                        setResponseCodeToProcess(response.data.responseCode);
                    } else if (response?.data?.responseCode === ResponseCodes.ExceededDailyPaymentTransactions) {                        
                        setResponseCodeToProcess(response.data.responseCode);
                    } else {
                        setFundingResponse(response.data);              
                    }                    
                },
                onError: (error: any) => {
                    if (error?.response?.data?.responseCode === ResponseCodes.ExceededDailyPaymentTransactions) {  
                        setResponseCodeToProcess(error.response.data.responseCode);
                    } else if (!checkForUnauthorized(error, history)) {
                        history.replace(routeHashes['/error'], {
                            title: 'An error occurred with your payment. Please try again.',
                            message: 'We were unable to process your card payment. The information you provided was not verified by your bank.',
                            buttonText: 'Try again',
                            navigateTo: routeHashes['/funding-source'],
                        } as ErrorPageProps);
                    }
                },
            });
        }
    };
    
    debuglog('funding-source page');
    debuglog('Application (funding-source):', application);
    debuglog('funding-source-card-type:', application.cardType);
    return (
        <ApplicationFlowLayout
            showBackButton
            HelpComponent={FundingSourceHelp}
            progress="80%"
            cardType={application.cardType}
            currentStep={isCheckApp || isError ? undefined : "8" }
            promo={!isError && isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn(application.cardType), configurationResponse)}
            showBasicInfoFooter={true}
            footerPreApprovedDisclosure={!downsell}
            footerAlternateEligibleDisclosure={(application.cardType === 'basic') && isIncentiveFeatureActive(IncentiveFeatures.DepositReductionAmount, getPrn(application.cardType), configurationResponse)}
            promoPreApprovedDisclosureIndex='*'
            promoAlternateEligibleDisclosureIndex='**'
        >
            {makePaymentMutation.isLoading ? (
                <Row>
                    <Col>
                        <PaymentProcessing />
                    </Col>
                </Row>
            ) : (
                <>
                    {fundingResponse?.responseCode ? (
                        <>                     
                            <PartialPayment
                                paymentAmount={fundingResponse?.paymentResponse?.approvedAmount}
                                partialAmount={fundingResponse?.paymentResponse?.creditLimit - fundingResponse?.paymentResponse?.balance}
                                remainder={fundingResponse?.paymentResponse?.balance}
                                daysToFund={fundingResponse?.paymentResponse?.daysLeftToFund}
                                onNext={onNext}
                            />
                        </>
                    ) : (
                        <>
                            <Row>
                                <Col>
                                    {isError ? (
                                        <FundingError />
                                    ) : (
                                        <FundingHeader
                                            amount={application?.amount || amount}
                                            fundWithDebit={fundWithDebit}
                                            fundToday={fundToday}
                                            changeFundTodayStatus={changeFundTodayStatus}
                                            daysLeft={daysLeftToFund}
                                            showSlider={(showPartialPaymentInput || showPartialPaymentSummary)}
                                            creditLimit={application.creditLimit}
                                            cardType={application?.cardType}
                                        />
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="fundingTodayRadios">
                                {(!showPartialPaymentInput && !showPartialPaymentSummary) 
                                    && !isError
                                    && !downsell
                                    && (
                                <>
                                    <Form.Check
                                    inline
                                    type="radio"
                                    label={
                                        <div>
                                            <div className="funding-source-title"><strong>I'm ready to fully fund my card today.</strong>
                                            <PromotionSpecific
                                                cardType={application.cardType}
                                                incentiveFeatureName={IncentiveFeatures.DepositReductionAmount}
                                                config={configurationResponse}
                                                setPromoValue={setPromoValue}
                                            >
                                               <>
                                               <strong>Save ${promoValue} on my security deposit.</strong>
                                                </>                                               
                                            </PromotionSpecific>
                                            </div>
                                            <div className="funding-source-text">Making your deposit today typically means you'll receive your card faster.
                                            </div>
                                        </div>
                                    }
                                    name="fundToday"
                                    id="fundTodayTrue"
                                    className="fundTodayRadio"
                                    defaultChecked={
                                        fundToday === true
                                    }
                                    value="true"
                                    onClick={() => onToggleFundToday(true)}
                                    />
                                    
                                    <Form.Check
                                    inline
                                    type="radio"
                                    label={
                                        <div>
                                            <div className="funding-source-title"><strong>I'd like to fund my card at a later date.</strong></div>                                            
                                            <PromotionSpecific
                                                cardType={application.cardType}
                                                incentiveFeatureName={IncentiveFeatures.DepositReductionAmount}
                                                config={configurationResponse}
                                                setPromoValue={setPromoValue}
                                            >
                                               <>
                                               <div className='promoLastChance'>                                               
                                                I'm not ready to save ${promoValue} today.** 
                                                </div>
                                                </>                                               
                                            </PromotionSpecific>
                                            <div className="funding-source-text">Not a problem. You can still submit your application, but your card won't be sent until your card is fully funded.</div>                                            
                                        </div>
                                    }
                                    name="fundToday"
                                    id="fundTodayFalse"
                                    className={(!fundToday) ? "fundTomorrowRadioFundLater" : "fundTomorrowRadio" } 
                                    defaultChecked={
                                        !!fundToday === false
                                    }
                                    value="false"
                                    onClick={() => onToggleFundToday(false)}
                                    />
                                   
                                </>
                                ) }   
                                </Col>
                            </Row>                                              
                            {(showPartialPaymentInput || showPartialPaymentSummary) && (
                                <Row>
                                    <Col>                                                                        
                                        <PartialFundingVariation
                                            fullyFund={fundToday}
                                            remainder={remainder}
                                            received={partialAmount}
                                            onValueChange={amt => setFundingAmount(amt)}
                                            register={register}
                                            errors={errors}
                                        />
                                    </Col>
                                </Row>
                            )}
                            <div ref={debitCardRef}></div>
                            {(isCheckApp || fundToday) && (
                                <>
                                    {!toggleForm && (
                                        <>
                                            <Row>
                                                <Col>
                                                    <div className={showCardValidationError ? "card dark card-border-error" : "card dark"}>
                                                        <div className="card-body">
                                                            <p className="title">Debit Card Details
                                                            <span className="paymentTypesAccepted">
                                                                <img src={iconVisa} alt='Visa' />
                                                                <img src={iconMastercard} alt='Mastercard' />
                                                            </span>
                                                            </p>
                                                            {isError && showCardValidationError && (
                                                                <>
                                                                <FundingErrorAlert />
                                                                <div className="error-text">
                                                                    *1 attempt remaining
                                                                </div>
                                                                </>
                                                            )}
                                                            <TextInput
                                                                type="text"
                                                                name="cardHolderFirstName"
                                                                label="Cardholder First Name"
                                                                aria-label="Cardholder First Name"
                                                                errors={errors}
                                                                register={register}
                                                                maxLength="20"
                                                                onChange={(event) => {
                                                                    event.target.value = event.target.value.replace(invalidNameCharacters, "");
                                                                }}
                                                            />
                                                            <TextInput
                                                                type="text"
                                                                name="cardHolderLastName"
                                                                label="Cardholder Last Name"
                                                                aria-label="Cardholder Last Name"
                                                                errors={errors}
                                                                register={register}
                                                                maxLength="20"
                                                                onChange={(event) => {
                                                                    event.target.value = event.target.value.replace(invalidNameCharacters, "");
                                                                }}
                                                            />
                                                            <NumberFormatInput
                                                                secure
                                                                type="text"
                                                                format="#### #### #### ####"
                                                                name="cardNumber"
                                                                label="Card Number"
                                                                aria-label="Card Number"
                                                                defaultValue={application?.cardNumber}
                                                                errors={errors}
                                                                register={register}
                                                            />
                                                            <NumberFormatInput
                                                                secure
                                                                type="text"
                                                                format="##/##"
                                                                name="cardExpiration"
                                                                label="Expiration Date (MM/YY)"
                                                                aria-label="Expiration Date (MM/YY)"
                                                                defaultValue={application?.cardExpiration}
                                                                errors={errors}
                                                                register={register}
                                                            />
                                                            <NumberFormatInput
                                                                secure
                                                                type="text"
                                                                format="###"
                                                                name="cardSecurityCode"
                                                                label="Security Code"
                                                                aria-label="Security Code"
                                                                instructionText="Security code is a 3-digit number on the back of your card, on or above the signature line"
                                                                defaultValue={application?.cardSecurityCode}
                                                                errors={errors}
                                                                register={register}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className={showCardValidationError ? "card dark card-border-error" : "card dark"}>
                                                        <div className="card-body">
                                                            <p className="title">Billing Address</p>
                                                            <AddressForm
                                                                name="bankingAddress"
                                                                register={register}
                                                                errors={errors}
                                                                setValue={setValue}
                                                                trigger={trigger}
                                                                initialAddress={application?.bankingAddress}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </>
                            )}                                                   
                            <Row className="row mt-36">
                                <Col className="col text-center">
                                    <Form.Group>
                                        {isCheckApp || fundToday ? (
                                            <Button
                                                disabled={!isValid || debounceActive}
                                                onClick={handleSubmit(onSubmit)}
                                            >
                                                Next
                                            </Button>
                                        ) : (
                                            <Button
                                                onClick={handleRedirect}
                                            >
                                                Next
                                            </Button>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </>
                    )}
                </>
            )}
        </ApplicationFlowLayout>
    );
};

export default FundingSource;
