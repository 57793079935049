import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import Confetti from 'react-confetti';
import { useWindowDimensions } from '../../hooks';
import { routeHashes } from '../../app-routes';

export type ApplicationStatusType = 'info' | 'error' | 'approved' | 'closed' | 'docrequest';

export type ApplicationInfoStatusProps = {
    appIcon?: string;
    appStatus: string;
    appStatusType: ApplicationStatusType;
    appStatusIcon: string;
    appMessage?: string | React.ReactNode;
    appMessageLine2?: string;
    appMessageAdditionalClasses?: string;
    appMessageAdditionalGradiantClasses?: string;
    appDetailMessage?: string;
    appDetailMessageLine2?: string;
    appDetailAdditionalClasses?: string;
    appGradientMessage?: string;
    appHeaderGradientBorder?: boolean;
    appGradientButton?: boolean;
    appPromo?: boolean;
    appNotificationMessage?: ReactElement;
    appAdditionalContent?: ReactElement;
    buttonText?: string;
    onNext?: () => void;
    promoFundButtons?: boolean;
};

const ApplicationInfoStatus: React.FC<ApplicationInfoStatusProps> = ({
    buttonText = 'Ok, Got it',
    onNext,
    ...props
}) => {
    const history = useHistory();
    const handleNext = () => {
        // go to the next page
        onNext && onNext();
    };
    const handleFundToday = () => {
        history.push(routeHashes['/funding-source'])
    }
    const handleFundLater = () => {
        /* TODO: where does this go? */
        //history.push(routeHashes['/funding-source'])
    }

    const windowDimensions = useWindowDimensions();
    const [fullHeight, setFullHeight] = useState(0);
    useEffect(() => {
        setFullHeight(
            Math.max(
                window.screen.height,
                document.body.scrollHeight,
                document.documentElement.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.offsetHeight,
                document.body.clientHeight,
                document.documentElement.clientHeight
            )
        );
    }, []);

    if (props.appPromo) {
        return (
            <>
                {
                    props.appStatusType === 'approved' &&
                    <Confetti 
                        width={windowDimensions.width}
                        height={fullHeight}
                    />
                }
                <Row>
                    <Alert variant='light' className={`app-status app-status-${props.appStatusType}`}>
                        <img alt="" src={props.appStatusIcon} role="presentation" className="app-info-status-message-icon" />
                        <span style={{ paddingLeft: '14px' }}>{props.appStatus}</span>
                    </Alert>
                </Row>
                <div className={props.appHeaderGradientBorder ? "general-gradient-border" : ""}>
                    <span>
                        {props.appIcon && (
                            <Row>
                                <Col>
                                    <img
                                        alt=""
                                        src={props.appIcon}
                                        className="app-info-status-app-icon"
                                    />
                                </Col>
                            </Row>
                        )}
                        <Row className="no-margins">
                            {props.appMessage && (
                                <Row className="no-margins">
                                    <Col>
                                        <h1
                                            className={`${props.appMessageAdditionalClasses} text-center`}
                                        >
                                            {props.appMessage}
                                            {props.appMessageLine2 && (
                                                <>
                                                    <br /><span>{props.appMessageLine2}</span>
                                                </>
                                            )}
                                        </h1>
                                        {props.appGradientMessage && (
                                            <h2 className={`${props.appMessageAdditionalGradiantClasses} gradient italic text-center`}>
                                                {props.appGradientMessage}
                                            </h2>
                                        )}
                                    </Col>
                                </Row>
                            )}
                            {props.appDetailMessage && (
                                <Row className="no-bottom-margin">
                                    <Col>
                                        <p className={`${props.appDetailAdditionalClasses} text-center`}>
                                            {props.appDetailMessage}
                                            {props.appDetailMessageLine2 && (<><br />{props.appDetailMessageLine2}</>)}
                                        </p>
                                    </Col>
                                </Row>
                            )}
                        </Row>
                    </span>
                </div>
                {props.appNotificationMessage && (
                    <Row>
                        <Col>
                            <div className="card dark">
                                <div className="card-body">
                                    {props.appNotificationMessage}
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}
                {props.appAdditionalContent && (
                    <Row>
                        <Col>
                            {props.appAdditionalContent}
                        </Col>
                    </Row>
                )}
                {onNext && (
                    <Row className="row mt-27">
                        <Col className="col text-center">
                            <Button disabled={false} onClick={handleNext}>
                                {buttonText}
                            </Button>
                        </Col>
                    </Row>
                )}
                {props.promoFundButtons && (
                    <div className="close-buttons">
                        <Row className="row mt-27 no-margins full-width">
                            <Col className="col text-center">
                                <Button disabled={false} onClick={handleFundToday} className="">
                                    I'd like to fund today and save $
                                </Button>
                            </Col>
                        </Row>
                        <Row className="row mt-27 no-margins full-width">
                            <Col className="col text-center">
                                <Button disabled={false} onClick={handleFundLater} className="btn-outline-dark">
                                    I'll fund at a later date
                                </Button>
                            </Col>
                        </Row>
                    </div>
                )}
            </>
        )
    }
    
    return (
        <>
            {
                props.appStatusType === 'approved' &&
                <Confetti 
                    width={windowDimensions.width}
                    height={fullHeight}
                    data-testid='confetti'
                />
            }
            <Row>
                <Alert variant='light' className={`app-status app-status-${props.appStatusType}`}>
                    <img alt="" src={props.appStatusIcon} role="presentation" className="app-info-status-message-icon" />
                    <span style={{ paddingLeft: '14px' }}>{props.appStatus}</span>
                </Alert>
            </Row>
            <div className={props.appHeaderGradientBorder ? "general-gradient-border" : ""}>
                <span>
                    {props.appIcon && (
                        <Row>
                            <Col>
                                <img
                                    alt=""
                                    src={props.appIcon}
                                    className="app-info-status-app-icon"
                                />
                            </Col>
                        </Row>
                    )}
                    <Row className='no-margins'>
                        {props.appMessage && (
                            <Row>
                                <Col>
                                    <h1
                                        className={`${props.appMessageAdditionalClasses} text-center`}
                                    >
                                        {props.appMessage}
                                        {props.appMessageLine2 && (
                                            <>
                                                <br /><span>{props.appMessageLine2}</span>
                                            </>
                                        )}
                                    </h1>
                                </Col>
                            </Row>
                        )}
                        {props.appDetailMessage && (
                            <Row>
                                <Col>
                                    <p className={`${props.appDetailAdditionalClasses} text-center`}>
                                    {props.appDetailMessage}
                                    {props.appDetailMessageLine2 && (<><br />{props.appDetailMessageLine2}</>)}
                                    </p>
                                </Col>
                            </Row>
                        )}
                    </Row>
                </span>
            </div>
            {props.appNotificationMessage && (
                <Row>
                    <Col>
                        <div className="card dark">
                            <div className="card-body">
                                {props.appNotificationMessage}
                            </div>
                        </div>
                    </Col>
                </Row>
            )}
            {props.appAdditionalContent && (
                <Row className='no-margins'>
                    <Col>
                        {props.appAdditionalContent}
                    </Col>
                </Row>
            )}
            {onNext && (
                <Row className='no-left-margin mt-27'>
                    <Col>
                        <Button disabled={false} onClick={handleNext} className={props.appGradientButton ? "gradient" : ""}>
                            {buttonText}
                        </Button>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default ApplicationInfoStatus;