import classnames from 'classnames';
import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { iconError, iconLockGray } from '../../assets/img';
import { ValidatedInputProps } from '../../types';
import FormErrorMessage from '../form-error-message';


export type TextInputProps = Omit<ValidatedInputProps, 'id'> & {
    onEditClicked?: () => void;
    inputGroupClassName?: string;
    inputGroupTypingClassName?: string;
    instructionText?: string;
    noMaterialStyle?: boolean;
    prefixText?: string;
    registerOptions?: any;
    showEdit?: boolean;
    secure?: boolean;
    showErrorsOnLoad?: boolean;
    subtitleText?: string | React.ReactNode;
    onSecuredClicked?: () => void;
}; 

const TextInput: React.FC<TextInputProps> = (props) => {
    const {
        disabled,
        errors,
        inputGroupClassName,
        inputGroupTypingClassName,
        instructionText,
        label,
        maxLength,
        name,
        noMaterialStyle,
        onBlur,
        onChange,
        onKeyDown,
        onEditClicked,
        onFocus,
        placeholder,
        prefixText,
        register,
        registerOptions,
        showEdit,
        secure,
        showErrorsOnLoad,
        subtitleText,
        type,
        onSecuredClicked,
        ...rest
    } = props;

    const {
        onBlur: onBlurRegister,
        onChange: onChangeRegister,
        ...restRegister
    } = (register && register(name, registerOptions)) || {};

    const [showErrors, setShowErrors] = useState(showErrorsOnLoad);
    const [isFocused, setIsFocused] = useState(false);

    const errorsForName = errors && name && name.split('.').reduce((p, prop) => p && (p as unknown as any)[prop], errors);
    const hasErrors = !!(showErrors && errors && errorsForName);
    // debuglog('inputGroupClassName', inputGroupClassName);
    // debuglog('hasErrors', hasErrors);
    // debuglog('isFocused', isFocused);

    return (
        <Form.Group
            className={classnames({
                'form-input': noMaterialStyle,
                'form-floating': !noMaterialStyle,
                // 'input-error': hasErrors,
                // 'input-secure': showLock
            })}
        >
            {noMaterialStyle && (
                <Form.Label
                    className={classnames({ error: hasErrors })}
                    htmlFor={name}
                    data-testid="text-input-no-material-style"
                >
                    {label}
                </Form.Label>
            )}
            {subtitleText && <div className='paragraph'>{subtitleText}</div>}

            <InputGroup 
                className={classnames({
                    [String(inputGroupClassName)]: true,
                    [String(inputGroupTypingClassName)]: !hasErrors,
                })}
            >
                {hasErrors && (
                    <InputGroup.Text
                        className={classnames('error', { focused: isFocused })}
                    >
                        <img
                            alt="error"
                            src={iconError}
                            role="presentation"
                            className="icon-error"
                        />
                    </InputGroup.Text>
                )}
                <Form.Control
                    id={name}
                    type={type}
                    data-testid="text-input-form-control"
                    onBlur={(e) => {
                        onBlur && onBlur(e);
                        onBlurRegister && onBlurRegister(e);
                        setShowErrors(true);
                        setIsFocused(false);
                    }}
                    onChange={(e) => {
                        onChange && onChange(e);
                        onChangeRegister && onChangeRegister(e);
                    }}
                    onKeyDown={(e) => {
                        onKeyDown && onKeyDown(e);                        
                    }}
                    onFocus={(e) => {
                        onFocus && onFocus(e);
                        setIsFocused(true);
                    }}
                    maxLength={maxLength}
                    placeholder={noMaterialStyle ? placeholder : label}
                    className={classnames({
                        error: hasErrors,
                        prefixed: prefixText,
                        'icon-right': secure || showEdit,
                    })}
                    disabled={disabled}
                    {...restRegister}
                    {...rest}
                />
                {showEdit &&
                    <InputGroup.Text
                        className={classnames('input-icon-right', 'input-edit', {
                            'disabled': disabled,
                            'error': hasErrors,
                            'focused': isFocused,
                        })}
                    >
                        <span
                            onClick={onEditClicked}
                            data-testid="text-input-edit-button"
                        >
                            Edit
                        </span>
                    </InputGroup.Text>
                }
                {secure &&
                    <InputGroup.Text
                        data-testid="text-input-secure-text"
                        className={classnames('input-icon-right', {
                            'disabled': disabled,
                            'error': hasErrors,
                            'focused': isFocused,
                        })}
                    >
                        <img alt='' src={iconLockGray} role='presentation' />
                    </InputGroup.Text>
                }
                {prefixText &&
                    <div
                        className={classnames('input-group-addon', 'prefix', {
                            'error': hasErrors,
                            'disabled': disabled,
                            'has-material-label': !noMaterialStyle && label,
                        })}
                    >
                        {prefixText}
                    </div>
                }
                {!noMaterialStyle && (
                    <Form.Label
                        className={classnames({ error: hasErrors })}
                        htmlFor={name}
                        data-testid="text-input-material-style"
                    >
                        {label}
                    </Form.Label>
                )}
            </InputGroup>
            {instructionText && !hasErrors && (
                <div className="input-instructions">
                    <span>{instructionText}</span>
                </div>
            )}
            {showErrors && <FormErrorMessage errors={errors} name={name} />}
        </Form.Group>
    );
};

export default TextInput;
