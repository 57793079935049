import { rest } from "msw";

export const resendOtp = [
    // create lead otp
    rest.post(`${process.env.REACT_APP_DEFAULT_API_BASE_URL}/api/security/resendLeadOtp`, async (req, res, ctx) => {
        const json = await req.json();
        if (json.emailAddress === "throw403@test.com") {
            return res(ctx.status(403), ctx.json({}));
        }
        return res(ctx.status(200), ctx.json({}));
    }),
    // check app status otp
    rest.post(
        `${process.env.REACT_APP_DEFAULT_API_BASE_URL}/api/security/resendCheckAppStatusOtp`,
        async (req, res, ctx) => {
            const json = await req.json();
            if (json.emailAddress === "throw403@test.com") {
                return res(ctx.status(403), ctx.json({}));
            }
            return res(ctx.status(200), ctx.json({}));
        }
    )
];
