import React from 'react';
import { Link } from 'react-router-dom';
import { routeHashes } from '../../app-routes';
import { fdic, logoOpensky } from '../../assets/img';
import {
    BasicEligibleDisclosure,
    PlusEligibleDisclosure,
    PreApprovedDisclosure,
    AlternateEligibleDisclosure,
    TestimonialDisclosure,
    IncreaseLimitDisclosure,
    RewardsDisclosure,
    LaunchAnnualFeeDisclosure,
} from '../../components';
import './landing-page.scss';

export type LandingPageLayoutProps = {
    showDisclosures?: boolean;
    showWithdrawal?: boolean;
    showFDIC?: boolean;
    showPrivacyPolicy?: boolean;
    showBasicEligibleDisclosure?: boolean;
    showPlusEligibleDisclosure?: boolean;
    showPreApprovedDisclosure?: boolean;
    showAlternateEligibleDisclosure?: boolean;
    showIncreaseLimitDisclosure?: boolean;
    showTestimonialDisclosure?: boolean;
    showRewardsDisclosure?: boolean;
    showLaunchAnnualFeeDisclosure?: boolean;
    promoBasicEligibleDisclosureIndex?: string;    
    promoPlusEligibleDisclosureIndex?: string;    
    promoAlternateEligibleDisclosureIndex?: string;    
    promoPreApprovedDisclosureIndex?: string;    
    promoIncreaseLimitDisclosureIndex?: string;    
    promoTestimonialDisclosureIndex?: string;    
    promoRewardsDisclosureIndex?: string;    
    promoLaunchAnnualFeeDisclosureIndex?: string;    
};
 
const LandingPageLayout: React.FC<LandingPageLayoutProps> = ({
    showDisclosures,
    showWithdrawal,
    showFDIC,
    showPrivacyPolicy,
    showBasicEligibleDisclosure,
    showPlusEligibleDisclosure,
    showPreApprovedDisclosure,
    showAlternateEligibleDisclosure,
    showIncreaseLimitDisclosure,
    showTestimonialDisclosure,
    showRewardsDisclosure,
    showLaunchAnnualFeeDisclosure,
    promoBasicEligibleDisclosureIndex,
    promoPlusEligibleDisclosureIndex,
    promoAlternateEligibleDisclosureIndex,
    promoPreApprovedDisclosureIndex,
    promoIncreaseLimitDisclosureIndex,
    promoTestimonialDisclosureIndex,
    promoRewardsDisclosureIndex,
    promoLaunchAnnualFeeDisclosureIndex,
    children,
}) => (
    <>
        <div className='navbar has-logo'>        
            <a href={`${process.env.REACT_APP_HOMEPAGE_URL}`} className="logo">
                <img src={logoOpensky} alt="OpenSky" />
            </a>
        </div>        
        <main id="main">
            {children}
        </main>
        <div className='opensky-footer'>
                <hr />
                {showDisclosures &&
                    <>
                        <p>
                            *Getting considered for a limit increase and/or the OpenSky
                            Gold Unsecured Card is subject to approval
                        </p>
                        <p>
                            Not ready to fund via debit card?
                            <br />
                            Keep an eye out for emails from OpenSky.
                        </p>
                        <p>
                            We cannot send your card until you fund.
                            <br />
                            You have 60 days left to fund.
                        </p>
                    </>
                }
                {showPreApprovedDisclosure && (
                    <p>
                        <PreApprovedDisclosure
                            listIndexSymbol={promoPreApprovedDisclosureIndex}
                        />
                    </p>
                )}
                {showRewardsDisclosure && (
                    <p>
                        <RewardsDisclosure
                            listIndexSymbol={promoRewardsDisclosureIndex}
                        />
                    </p>
                )}
                {showLaunchAnnualFeeDisclosure && (
                    <p>
                        <LaunchAnnualFeeDisclosure
                            listIndexSymbol={promoLaunchAnnualFeeDisclosureIndex}
                        />
                    </p>
                )}
                {showBasicEligibleDisclosure && (
                    <p>
                        <BasicEligibleDisclosure
                            listIndexSymbol={promoBasicEligibleDisclosureIndex}
                        />
                    </p>
                )}
                {showPlusEligibleDisclosure && (
                    <p>
                        <PlusEligibleDisclosure
                            listIndexSymbol={promoPlusEligibleDisclosureIndex}
                        />
                    </p>
                )}
                {showAlternateEligibleDisclosure && (
                    <p>
                        <AlternateEligibleDisclosure
                            listIndexSymbol={promoAlternateEligibleDisclosureIndex}
                        />
                    </p>
                )}
                {showIncreaseLimitDisclosure && (
                    <p>
                        <IncreaseLimitDisclosure
                            listIndexSymbol={promoIncreaseLimitDisclosureIndex}
                        />
                    </p>
                )}
                {showTestimonialDisclosure && (
                    <p>
                        <TestimonialDisclosure
                            listIndexSymbol={promoTestimonialDisclosureIndex}
                        />
                    </p>
                )}
                {showWithdrawal &&
                    <p>
                        If you would like to withdraw your application, we will stop
                        all processing and mark your application closed at your
                        request.
                        <br />
                        <Link to={routeHashes['/withdrawal']}>Click here to withdraw your application</Link>
                    </p>
                }
                {showFDIC && (
                    <>
                        <p>
                            &copy; {new Date().getFullYear()} OpenSky is a division of Capital Bank, N.A. All rights reserved.
                        </p>
                        <img src={fdic} alt="" />
                    </>
                )}
                {showPrivacyPolicy && (
                    <>
                        <p>
                            &copy; Copyright {new Date().getFullYear()}. Capital Bank, N.A.<br/>
                            <a href="https://capitalbankmd.com/capital-bank-privacy-policy" className='link'>Capital Bank, N.A. Privacy Policy</a>
                        </p>
                        <img src={fdic} alt="" />
                    </>
                )}
            </div>
    </>
);

export default LandingPageLayout;
