import { useQuery } from "react-query";
import { microserviceClient } from "../../api-clients";
import { GetApplicationsStatusResponse } from "../../types";
import { queryKeys } from "../../utils/constants";

export const getApplicationsStatus = async (): Promise<GetApplicationsStatusResponse> => {
    const response = await microserviceClient.get<GetApplicationsStatusResponse>(
        "/api/application/getApplicationsStatus"
    );

    return response.data;
};

export const useGetApplicationStatus = () => {
    return useQuery<GetApplicationsStatusResponse, Error>([...queryKeys.getApplicationsStatus], getApplicationsStatus, {
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false
    });
};
