import React, { FC } from 'react';
import { applicationEmail, applicationProcessing } from '../../assets/img';
import ApplicationInfoStatus from '../../components/application-info-status';

export const DocumentsSubmitted: FC = () => {
    const onSubmit = () => {
        window.location.href = `${process.env.REACT_APP_HOMEPAGE_URL}`;
    };

    return (
        <ApplicationInfoStatus
            appIcon={applicationEmail}
            appStatus="Your application is being processed"
            appMessage="Look for emails from OpenSky"
            appStatusType="info"
            appStatusIcon={applicationProcessing}
            appDetailMessage="We will email you updates with additional information regarding your application status."
            appNotificationMessage={
                <>
                    <p>
                        <b>Thank you for applying to OpenSky!</b>
                    </p>
                    <p>
                        We've successfully received your documents and will
                        resume processing your application.
                    </p>
                </>
            }
            onNext={onSubmit}
        />
    );
};
