import React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ApplicationFlowLayout } from '../../layouts';
import { applicationState, downsellState, gaDataLayerState } from '../../store/atoms';
import { 
    TermsAndConditionsModel,
    IncentiveFeatures, 
    ProductTypePrns, 
} from '../../types';
import { routeHashes } from '../../app-routes';
import { useRecoilValue } from 'recoil';
import { configurationResponseState } from '../../store/atoms'
import { isIncentiveFeatureActive, getPrn } from '../../utils/incentives';
import { currentCardInfo } from '../../utils/product-info';
import { pushToDataLayer } from '../../utils/analytics';
import * as FullStory from '@fullstory/browser';
import { iconBlueCheck, safetyFill } from '../../assets/img';
import { getIncentive, getDetailFromIncentive } from '../../utils/incentives';
import './index.scss';
import { debuglog } from '../../utils/debug';
import { CardInfo } from '../../components';

const TermsConditions: React.FC = props => {
    const [application, setApplication] = useRecoilState(applicationState);
    const [debounceActive, setDebounceActive] = useState(false);
    let [gaDataLayer, setGADataLayer] = useRecoilState(gaDataLayerState);
    const downsell = useRecoilValue(downsellState);

    const configurationResponse = useRecoilValue(configurationResponseState);
    let cardInfo;

    debuglog('=========T&C---FUND TODAY--->', application?.fundToday);
    const history = useHistory();

    let rateDisclosuresLink;
    let termsAndConditionsTab;
    let cardholderAgreementTab;

    if (application.cardType === "basic") {
        cardInfo = currentCardInfo("basic", configurationResponse?.securedProducts);
        rateDisclosuresLink = cardInfo.currentTermsConditions;
        termsAndConditionsTab = "basic-terms-and-conditions";
        cardholderAgreementTab = "basic-cardholder-agreement";
    } 
    else if(application.cardType === "plus") {
        cardInfo = currentCardInfo("plus", configurationResponse?.securedProducts);
        rateDisclosuresLink = cardInfo.currentTermsConditions;
        termsAndConditionsTab = "plus-terms-and-conditions";
        cardholderAgreementTab = "plus-cardholder-agreement";
    }
    else{
        cardInfo = currentCardInfo("launch", configurationResponse?.securedProducts);
        rateDisclosuresLink = cardInfo.currentTermsConditions;
        termsAndConditionsTab = "launch-terms-and-conditions";
        cardholderAgreementTab = "launch-cardholder-agreement";
    }
    
    const getPrnFromCardType = (cardType: string | undefined) => {
        if (cardType?.toLowerCase() !== 'basic') {
            return ProductTypePrns.Plus;
        } else {
            return ProductTypePrns.Basic;
        }
    }
    const depositReductionIncentive = getIncentive(IncentiveFeatures.DepositReductionAmount, 
        getPrnFromCardType(application.cardType), configurationResponse);

    const validationSchema = Yup.object().shape({
        eSignConsent: Yup.boolean()
            .oneOf([true])
            .test("eSignConsent", "Please agree to E-Sign Consent", x => !!x)
            .default(application.eSignConsent),
        termsAndConditions: Yup.boolean()
            .oneOf([true])
            .test("termsAndConditions", "Please agree to terms and conditions", x => !!x)
            .default(application.termsAndConditions)
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const {
        handleSubmit,
        register,
        watch,
        formState: { isValid }
    } = useForm<any>({
        ...formOptions,
        defaultValues: validationSchema.getDefault(),
        mode: "all"
    });

    const eSignConsent = watch("eSignConsent");
    const termsAndConditions = watch("termsAndConditions");

    const onSubmit = async (event: TermsAndConditionsModel) => {
        // given that the button is disabled when debounceActive this is unreachable
        // istanbul ignore if
        if (debounceActive) return;

        setDebounceActive(true);

        switch (application?.cardType) {
            case "basic": {
                FullStory.event("AppSubmit", { leadId: application.leadId });
                break;
            }
            case "plus": {
                FullStory.event("Plus_AppSubmit", { leadId: application.leadId });
                break;
            }
            case "launch": {
                FullStory.event('Launch_AppSubmit', { leadId: application.leadId });
                break;
            }
            default: {
                FullStory.event("AppSubmit", { leadId: application.leadId });
                break;
            }
        }

        const updateDataLayer = {
            ...gaDataLayer,
            'step_name': 'Terms and Conditions',
        };
        setGADataLayer(updateDataLayer);
        pushToDataLayer("application_step_8", updateDataLayer);

        setApplication(current => ({
            ...current,
            ...event
        }));

        history.push(routeHashes["/submit-application"]);
    };

    return (
        <ApplicationFlowLayout
            showBackButton
            progress="99%"
            cardType={application.cardType}
            currentStep="9"
            promo={isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn(application.cardType), configurationResponse)}
            showBasicInfoFooter={true}
            footerRewardsDisclosure={application.cardType !== 'launch'}
            promoRewardsDisclosureIndex='*'
        >
            <Row>
                <Col>
                    <div id="header">
                        {downsell && (
                            <Row>
                                <Col>
                                    <CardInfo creditLimit={application.creditLimit?.toString()}/>
                                </Col>
                            </Row>
                        )}
                        <h1>Last step to start building credit.</h1>
                        <p>
                            Please read through these Important Disclosures that contain additional information about
                            eligibility, rates, fees, and other costs, as applicable.
                        </p>
                        <p style={{ fontWeight: 'bold', paddingTop: "16px"}}><img src={iconBlueCheck} alt=""/> No credit check to apply.</p>
                        {application.cardType === 'plus' && (
                            <p style={{fontWeight: 'bold'}}><img src={iconBlueCheck} alt=""/> No annual fee.</p>
                        )}
                        {application.cardType !== 'launch' && (
                            <p style={{ fontWeight: 'bold', paddingTop: "16px"}}><img src={iconBlueCheck} alt=""/> Up to 10% cash back rewards*</p>
                        )}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="toc-body">
                        <Card.Body>
                            <div className="label-disclosures">
                                <span>Important Disclosures</span>
                                <Button variant="link" href={rateDisclosuresLink} target="_blank">
                                    Download/Print
                                </Button>
                            </div>
                            <div className="toc-rates">
                                <div className="content">
                                    <iframe title="rateDisclosuresLink" src={rateDisclosuresLink} />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <fieldset>
                        <legend>Review and agree to terms</legend>
                        <Form.Group className="input-group">
                            <Form.Check
                                {...register("eSignConsent")}
                                inline
                                type="checkbox"
                                label={
                                    <span>
                                        I agree to your&nbsp;
                                        <Link
                                            to={{
                                                pathname: routeHashes["/disclosures"],
                                                state: { tab: "esign-consent" }
                                            }}
                                        >
                                            E-Sign Consent
                                        </Link>
                                    </span>
                                }
                                name="eSignConsent"
                                id="eSignConsent"
                                defaultChecked={!!(eSignConsent || application.eSignConsent)}
                            />
                            <Form.Check
                                {...register("termsAndConditions")}
                                inline
                                type="checkbox"
                                label={
                                    <span>
                                        I agree to OpenSky's&nbsp;
                                        <Link
                                            to={{
                                                pathname: routeHashes["/disclosures"],
                                                state: {
                                                    tab: termsAndConditionsTab,
                                                    cardType: application.cardType
                                                }
                                            }}
                                        >
                                            Terms and Conditions
                                        </Link>
                                        ,&nbsp;
                                        <Link
                                            to={{
                                                pathname: routeHashes["/disclosures"],
                                                state: {
                                                    tab: "privacy-notice"
                                                }
                                            }}
                                        >
                                            Privacy Notice
                                        </Link>
                                        ,&nbsp;
                                        <Link
                                            to={{
                                                pathname: routeHashes["/disclosures"],
                                                state: {
                                                    tab: "privacy-policy"
                                                }
                                            }}
                                        >
                                            Privacy Policy
                                        </Link>
                                        , and&nbsp;
                                        <Link
                                            to={{
                                                pathname: routeHashes["/disclosures"],
                                                state: {
                                                    tab: cardholderAgreementTab,
                                                    cardType: application.cardType
                                                }
                                            }}
                                        >
                                            Cardholder Agreement
                                        </Link>
                                    </span>
                                }
                                name="termsAndConditions"
                                id="termsAndConditions"
                                defaultChecked={!!(termsAndConditions || application.termsAndConditions)}
                                disabled={!eSignConsent}
                            />
                        </Form.Group>
                    </fieldset>
                </Col>
            </Row>
            {application.fundToday && (
                <Row>
                    <Col>
                        <p>
                            By submitting your application, you are authorizing
                            OpenSky to process the full security deposit of{' '}                            
                            {(depositReductionIncentive && (
                                <><b>${
                                    ((application.amount! - 
                                        Number(getDetailFromIncentive(IncentiveFeatures.DepositReductionAmount, 
                                            depositReductionIncentive!)?.value ?? 0)).toString())
                                    }</b>,</>
                            ))}
                            {(!depositReductionIncentive  && (
                                <><b>${application.amount}</b>,</>
                            ))}
                            {' '}
                            
                            placing an immediate
                            hold on the funds selected as your credit line.
                        </p>
                        <p>
                            If your application is not approved, your funds will be posted back to your account within
                            five days.
                        </p>
                    </Col>
                </Row>
            )}
            <Row style={{ paddingTop: "2rem", borderTop: "1px solid #a8a8a8" }}>
                <Col className="text-center">
                    <Button
                        className="btn btn-primary gradient"
                        onClick={handleSubmit(onSubmit)}
                        disabled={!isValid || debounceActive}
                        data-testid='terms-conditions-submit-button'
                    >
                        <div>
                            <div>Submit Application
                            <span style={{display: 'block', fontSize: '10px', padding: '0px', marginTop: '-10px'}}>
                            <img alt="no hard credit pull" src={safetyFill} style={{color: 'black', width: '10px'}}/> NO HARD CREDIT PULL
                            </span>
                            </div>                            
                        </div>
                    </Button>
                </Col>
            </Row>
        </ApplicationFlowLayout>
    );
};

export default TermsConditions;
