import React from "react";
import "./resend-code.scss";

interface ResendCodeProps {
    onResendCodeClicked?: () => void;
    seconds?: number;
    hasBeenResent?: boolean;
}

const ResendCode: React.FC<ResendCodeProps> = ({ onResendCodeClicked, seconds, hasBeenResent }) => {
    const minutes = Math.floor((seconds ?? 0) / 60);
    const remainingSeconds = (seconds ?? 0) - (minutes * 60);
    const formatted = `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds} ${minutes < 1 ? 'seconds' : 'minutes'}`;

    if ((seconds ?? 0) <= 0) {
        return (
            <div className="resend-code-button-container">
                <p className="resend-code-text">(CODE EXPIRED)</p>
                <button className="resend-code-button" onClick={onResendCodeClicked}>Resend code</button>
            </div>
        );
    } else if (hasBeenResent) {
        return (
            <div>
                <p className="resend-code-text">Code sent! ({formatted})</p>
            </div>
        );
    }
    return (
        <div className="resend-code-button-container">
            <button className="resend-code-button" onClick={onResendCodeClicked}>Resend code ({formatted})</button>
        </div>
    );
}
 
export default ResendCode;