import { rest } from 'msw';
import { CheckApplicationResponse, DecisionsResponse, GetApplicationsStatusResponse } from '../types';

const response: GetApplicationsStatusResponse = {
    done: {
        checkAppResponseList: [
            {
                applicationID: '00001',
                responseCode: 'WT_DOC',
                submittedDateUtc: new Date().toISOString(),
                responseObjects: {
                    doC_AddressYN: true,
                    doC_DobYN: false,
                    doC_SsnYN: true,
                },
            } as CheckApplicationResponse,
        ]
    } as DecisionsResponse,
    responseCode: 'WT_DOC', // 'WT_M83', // 'WT_DOC',
};

export const submitMiskey = [
    rest.post(`${process.env.REACT_APP_DEFAULT_API_BASE_URL}/api/application/submitMiskey`, async (req, res, ctx) => {
        const json = await req.json();
        await new Promise(r => setTimeout(r, 1000));
        console.log('payload', json);
        return res(
            ctx.status(200),
            ctx.json(response)
        );
    }),
];
