import React, { FC, useState } from 'react';
import TextInput from '../text-input';
import Dropdown from '../dropdown';
import './address-form.scss';
import { useDebounce } from '../../../hooks';
import {
    Address,
    FundingSourceModel,
    IdentityVerificationModel,
    MailingAddressModel,
} from '../../../types';
import {
    FieldErrorsImpl,
    Path,
    UseFormRegister,
    UseFormSetValue,
    UseFormTrigger,
} from 'react-hook-form';
import Autocomplete from '../autocomplete';
import { usStates } from '../../../components/address-form/constants';
import '../../scss/main.scss';
import { useRecoilValue } from 'recoil';
import { applicationState } from '../../../store/atoms';
import { selectedApplicationResponse } from '../../../store/selectors';
import { useAddressLookup } from './hooks';

type AddressLabels = {
    addressLookupLabel?: string;
    addressLookupFloatingLabel?: string;
    addressLine1Label?: string;
    addressLine2Label?: string;
    cityLabel?: string;
    stateLabel?: string;
    zipCodeLabel?: string;
};

interface AddressFormProps {
    validPattern?: RegExp;
    name?: Path<
        IdentityVerificationModel | MailingAddressModel | FundingSourceModel
    >;
    initialAddress?: Address;
    labels?: AddressLabels;
    streetAddressInputLabel?: string;
    className?: string;
    inputClassName?: string;
    cityInputClassName?: string;
    stateInputClassName?: string;
    setValue?: UseFormSetValue<Address>;
    trigger?: UseFormTrigger<Address>;
    register?: UseFormRegister<Address>;
    errors?: Partial<FieldErrorsImpl<Address>>;
}

const AddressForm: FC<AddressFormProps> = ({
    validPattern,
    streetAddressInputLabel,
    className,
    inputClassName,
    cityInputClassName,
    stateInputClassName,
    setValue,
    trigger,
    register,
    errors,
}) => {
    const application = useRecoilValue(applicationState);
    const selectedApplication = useRecoilValue(selectedApplicationResponse);

    const [streetAddress, setStreetAddress] = useState(
        application.homeAddress?.addressLine1 ||
            selectedApplication?.homeAddress
    );
    const [streetAddress2, setStreetAddress2] = useState(
        application.homeAddress?.addressLine2 ||
            (selectedApplication?.aptSuite ?? '')
    );
    const [city, setCity] = useState(
        application.homeAddress?.city || selectedApplication?.city
    );
    const [state, setState] = useState(
        application.homeAddress?.state || selectedApplication?.state || ''
    );
    const [zipCode, setZipCode] = useState(
        application.homeAddress?.zipCode || selectedApplication?.zipCode
    );

    const debouncedFullAddress = useDebounce(streetAddress, 500);
    const { data: addressOptions } = useAddressLookup(
        debouncedFullAddress,
        validPattern
    );

    const usStatesOptions = usStates.map((state) => ({
        label: state.abbreviation,
        value: state.abbreviation,
    }));

    const handleChangeAddress = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setStreetAddress(event.target.value);
    };

    const handleSelectAddress = (value: string) => {
        const selectedAddress = parseFullAddress(value);

        setStreetAddress(selectedAddress.addressLine1);
        selectedAddress.city && setCity(selectedAddress.city);
        selectedAddress.state && setState(selectedAddress.state);
        selectedAddress.zipCode && setZipCode(selectedAddress.zipCode);

        setValue && setValue('city', selectedAddress.city);
        setValue && setValue('state', selectedAddress.state);
        setValue && setValue('zipCode', selectedAddress.zipCode);
        trigger && trigger();
    };

    const handleChangeStreetAddress2 = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setStreetAddress2(event.target.value);
    };

    const handleChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCity(event.target.value);
    };

    const handleSelectState = (value: string) => {
        setState(value);
    };

    const handleChangeZipCode = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setZipCode(event.target.value);
    };

    const parseFullAddress = (value: string) => {
        const addressSplit = value.split(',');
        if (addressSplit.length === 4) {
            const stateZipSplit = addressSplit[3].trim().split(' ');
            return {
                addressLine1: addressSplit[0].trim(),
                addressLine2: addressSplit[1].trim(),
                city: addressSplit[2].trim(),
                state: stateZipSplit[0],
                zipCode: stateZipSplit[1],
            };
        } else if (addressSplit.length === 3) {
            const stateZipSplit = addressSplit[2].trim().split(' ');
            return {
                addressLine1: addressSplit[0].trim(),
                addressLine2: undefined,
                city: addressSplit[1].trim(),
                state: stateZipSplit[0],
                zipCode: stateZipSplit[1],
            };
        } else {
            return {
                addressLine1: value,
            };
        }
    };

    return (
        <>
            <div className={className ?? 'address-form-container'}>
                <Autocomplete
                    name="addressLine1"
                    type="text"
                    label={streetAddressInputLabel ?? 'Street address'}
                    options={addressOptions ?? []}
                    inputSize="long"
                    onChange={handleChangeAddress}
                    onSelect={handleSelectAddress}
                    trigger={trigger}
                    setValue={setValue}
                    errors={errors}
                    value={streetAddress}
                    className={inputClassName}
                />
                <TextInput
                    name="addressLine2"
                    type="text"
                    value={streetAddress2}
                    inputSize="long"
                    placeholder="Apt, Suite (optional)"
                    register={register}
                    errors={errors}
                    onChange={handleChangeStreetAddress2}
                    inputClassName={inputClassName}
                />
                <div className="vertical-aligned-inputs">
                    <TextInput
                        name="city"
                        type="text"
                        inputSize="short"
                        label="City"
                        value={city}
                        onChange={handleChangeCity}
                        register={register}
                        errors={errors}
                        inputClassName={cityInputClassName}
                    />
                    <Dropdown
                        name="state"
                        options={usStatesOptions}
                        label="State"
                        selectedValue={state}
                        onChange={handleSelectState}
                        setValue={setValue}
                        trigger={trigger}
                        className={stateInputClassName}
                    />
                </div>
                <TextInput
                    name="zipCode"
                    type="text"
                    inputSize="long"
                    label="Zip code"
                    value={zipCode}
                    onChange={handleChangeZipCode}
                    register={register}
                    errors={errors}
                    inputClassName={inputClassName}
                />
            </div>
        </>
    );
};

export default AddressForm;
