import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames';
import SelectDropdown from '../select-dropdown';
import {
    FieldErrorsImpl,
    UseFormSetValue,
    UseFormTrigger,
} from 'react-hook-form';
import { SelectOptions } from '../select-dropdown/types';
import FormErrorMessage from '../form-error-message';
import { currentYear, daysInMonth, maxBirthdate } from '../../utils/dates';
import { isValidDate } from '../../utils/validations';
import './date-picker.scss';

export type DatePickerProps = {
    id?: string;
    name?: string;
    initialValue?: string | Date;
    isBirthday?: boolean;
    minYear?: number;
    maxYear?: number;
    onValidDateChange?: (date: Date) => void;
    onDateChange?: (date?: Date) => void;
    errors?: FieldErrorsImpl<any>;
    setValue?: UseFormSetValue<any>;
    trigger?: UseFormTrigger<any>;
};

const DatePicker: FC<DatePickerProps> = ({
    id,
    name,
    initialValue,
    isBirthday,
    minYear = 1900,
    maxYear = currentYear,
    onDateChange,
    onValidDateChange,
    errors,
    setValue,
    trigger,
}) => {
    const [year, setYear] = useState<string | undefined>();
    const [monthIndex, setMonthIndex] = useState<string | undefined>();
    const [day, setDay] = useState<string | undefined>();
    const [hasValidDateBeenSet, setHasValidDateBeenSet] = useState<boolean>();

    const calculatedMaxYear = isBirthday
        ? Math.min(maxYear, maxBirthdate.getFullYear())
        : maxYear;

    const months: SelectOptions[] = Array.from({ length: 12 }, (_, i) => ({
        value: `${i}`,
        label: new Date(2022, i, 1).toLocaleString('default', { month: 'long' })
    }));
    
    const days: SelectOptions[] = Array.from({ length: daysInMonth(year, monthIndex) }, (_, i) => ({
        value: `${(i + 1)}`,
    }));

    const years: SelectOptions[] = Array.from({ length: calculatedMaxYear - minYear + 1 }, (_, i) => ({
        value: `${calculatedMaxYear - i}`,
    }));

    const hasErrors = (monthIndex || day || year) && errors && name && name.split('.').reduce((p, prop) => p && (p as unknown as any)[prop], errors);

    useEffect(() => {
        if (initialValue) {
            const initialDate = new Date(initialValue);
            setYear(initialDate.getFullYear().toString());
            setMonthIndex(initialDate.getMonth().toString());
            setDay(initialDate.getDate().toString());
        }
    }, [initialValue]);

    useEffect(() => {
        if (isValidDate(year, monthIndex, day)) {
            const selectedDate = new Date(
                parseInt(year!, 10),
                parseInt(monthIndex!, 10),
                parseInt(day!, 10)
            );            
            setValue && name && setValue(name, selectedDate);
            onValidDateChange && onValidDateChange(selectedDate);
            onDateChange && onDateChange(selectedDate);
            setHasValidDateBeenSet(true);
            trigger && trigger();
        } else {
            setValue && name && setValue(name, undefined);
            onDateChange && onDateChange(undefined);
            if (hasValidDateBeenSet) {
                trigger && trigger();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [monthIndex, day, year]);

    return (
        <>
            <div className="date-picker-container">
                <SelectDropdown
                    label="Month"
                    initialValue={monthIndex}
                    value={monthIndex}
                    onChange={(value: string) => setMonthIndex(value)}
                    options={months}
                    name={`${name}.month`}
                    id={id && `${id}-month`}
                    className={classnames('date-picker-month', { error: hasErrors })}
                />
                <SelectDropdown
                    label="Day"
                    initialValue={day}
                    value={day}
                    onChange={(value: string) => setDay(value)}
                    options={days}
                    id={id && `${id}-day`}
                    name={`${name}.day`}
                    className={classnames('date-picker-day', { error: hasErrors })}
                />
                <SelectDropdown
                    label="Year"
                    initialValue={year}
                    value={year}
                    onChange={(value: string) => setYear(value)}
                    options={years}
                    id={id && `${id}-year`}
                    name={`${name}.year`}
                    className={classnames('date-picker-year', { error: hasErrors })}
                />
            </div>
            {hasErrors && <FormErrorMessage errors={errors} name={name} />}
        </>
    );
};

export default DatePicker;
