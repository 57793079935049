import React from 'react';
import './processing-message.scss';

interface ProcessingApplicationProps {
    message: string;
    smallText?: string;
}

const ProcessingMessage: React.FC<ProcessingApplicationProps> = ({
    message,
    smallText,
}) => {
    return (
        <div className="processing-message-content-outer">
            <div className="processing-message-content-inner">
                <div className="spinner">
                    <div className="inner-circle" />
                    <div className="circle-corner" />
                </div>
                <p className="processing-application-text">
                    {message.split('<br/>').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    ))}
                </p>
            </div>
            <p className="processing-application-small-text">{smallText}</p>
        </div>
    );
};

export default ProcessingMessage;
