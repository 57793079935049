import React, { MouseEventHandler } from 'react';
import './page-link.scss';

interface PageLinkProps {
    icon: any;
    title: string;
    smallText?: string;
    buttonText?: string;
    hideButton?: boolean;
    onButtonLinkClicked?: MouseEventHandler<HTMLButtonElement>;
}

const PageLink: React.FC<PageLinkProps> = ({
    icon,
    title,
    smallText,
    buttonText,
    hideButton,
    onButtonLinkClicked,
}) => {
    return (
        <div className="page-link-container">
            <div className="page-link-left">
                <div className="page-link-icon">
                    <img src={icon} alt="page link icon" />
                </div>
                <div className="page-link-text">
                    <div className="page-link-title">{title}</div>
                    {smallText && (
                        <div className="page-link-small-text">
                            {smallText.split('<br/>').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            {!hideButton && (
                <div className="page-link-right">
                    <button
                        onClick={onButtonLinkClicked}
                        className="page-link-button"
                    >
                        {buttonText}
                    </button>
                </div>
            )}
        </div>
    );
};

export default PageLink;
