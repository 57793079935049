import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Form } from 'react-bootstrap';

type FormErrorMessageProps = {
    name: string,
    errors?: any,
};

const FormErrorMessage: React.FC<FormErrorMessageProps> = ({ name, errors }) => {
    const errorMessage = (data: { message: string, messages?: any }) => (
        <Form.Text className='input-group-error'>
            {data.message}
        </Form.Text>
    );
    return (
        <>
            {errors && <ErrorMessage errors={errors} name={name} render={errorMessage} />}
        </>
    );
};

export default FormErrorMessage;
