/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ModalPage } from '../../../components';
import { useWindowDimensions } from '../../../hooks';
import { BreakPoints, ProgressUnits, ErrorPageProps } from '../../../types';
import { timeoutExpiredState } from '../../../store/atoms';
import { routeHashes } from '../../../app-routes';
import {
    fdic,
    logoOpenskyNoPadding,
    iconChevronLeft,
    visaLogoBlack,
} from '../../../assets/img';
import './application-flow.scss';
import { debuglog } from '../../../utils/debug';
import versionInfo from '../../../buildVersion.json';
import { ApprovalDisclosure } from '../../components';
import { CardTypes } from '../../../utils/constants';
import '../../scss/main.scss';

const webVersion: string = versionInfo
    ? `${versionInfo.buildVersionMajor}.${versionInfo.buildMinorVersion}.${versionInfo.buildRevision}`
    : '1-0-350';

export type ApplicationFlowLayoutProps = {
    showHelpNow?: boolean;
    showBasicInfoFooter?: boolean;
    hideNavigation?: boolean;
    hideProgress?: boolean;
    onHelpTrayClosed?: () => void;
    HelpComponent?: React.ComponentType;
    progress?: ProgressUnits;
    currentStep?: string;
    backgroundImage?: string;
    hideCardTypeNameInHeader?: boolean;
    showApprovalDisclosure?: boolean;
    isArrowBackButton?: boolean;
    cardType?: CardTypes;
    displayOpenSkyLogo?: boolean;
    displayVisaLogo?: boolean;
    hideProgressBar?: boolean;
};

const ApplicationFlowLayout: React.FC<ApplicationFlowLayoutProps> = ({
    showHelpNow,
    showBasicInfoFooter,
    onHelpTrayClosed,
    hideNavigation,
    hideProgress,
    HelpComponent,
    progress,
    currentStep,
    children,
    backgroundImage,
    showApprovalDisclosure,
    isArrowBackButton,
    cardType,
    displayOpenSkyLogo,
    displayVisaLogo,
    hideProgressBar
}) => {
    const history = useHistory();
    const dimensions = useWindowDimensions();

    const [showHelpTray, setShowHelpTray] = useState(false);
    const [showChildren, setShowChildren] = useState(true);

    const timeoutExpired = useRecoilValue(timeoutExpiredState);

    useEffect(() => {
        console.log('Version:', webVersion);
        debuglog('page-flow:', {
            currentStep: currentStep,
            progress: progress,
        });
        debuglog();
        setShowChildren(
            !showHelpTray || dimensions.breakpoint < BreakPoints.sm
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dimensions.breakpoint, showHelpTray]);

    useEffect(() => {
        setShowHelpTray(!!showHelpNow);
    }, [showHelpNow]);

    useEffect(() => {
        if (timeoutExpired) {
            history.replace(routeHashes['/error'], {
                title: 'For security reasons, your session with OpenSky has expired.',
                buttonText: 'Ok, Got it',
                navigateTo: process.env.REACT_APP_HOMEPAGE_URL,
            } as ErrorPageProps);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeoutExpired]);

    const renderCardTypeHeading = () => {
        switch (cardType) {
            case CardTypes.basic:
                return <h1 className="navbar-heading">OpenSky Secured</h1>;
            case CardTypes.plus:
                return <h1 className="navbar-heading">OpenSky Plus</h1>;
            case CardTypes.launch:
                return <h1 className="navbar-heading">OpenSky Launch</h1>;
            default:
                return <></>;
        }
    };

    const closeHelpTray = () => {
        setShowHelpTray(false);
        onHelpTrayClosed && onHelpTrayClosed();
    };

    const goBack = () => {
        history.goBack();
    };

    const navMarkup = useMemo(() => {
        return isArrowBackButton ? (
            <button className="back-button" onClick={goBack}>
                <img
                    className="arrow-back-icon"
                    src={iconChevronLeft}
                    alt="navigate back"
                />
            </button>
        ) : (
            <a className="navbar-text" onClick={goBack}>
                Back
            </a>
        );
    }, []);

    return (
        <>
            <span
                className={
                    'web_version_with_promo_' + webVersion.replaceAll('.', '_')
                }
            ></span>
            <header className="header">
                <div
                    className={
                        !hideNavigation && !hideProgress
                            ? 'navbar-content'
                            : 'navbar-content-center-items'
                    }
                >
                    {!hideNavigation && navMarkup}
                    {(cardType || displayVisaLogo) && (
                        <div className="navbar-middle-content">
                            {cardType && renderCardTypeHeading()}
                            <div className="vertical-line-thin" />
                            {displayVisaLogo && (
                                <img
                                    src={visaLogoBlack}
                                    className="navbar-logo-visa"
                                    alt="navigate back"
                                />
                            )}
                        </div>
                    )}
                    {displayOpenSkyLogo && (
                        <img
                            src={logoOpenskyNoPadding}
                            alt="OpenSky"
                            className="navbar-logo"
                        />
                    )}
                    {!hideProgress && (
                        <span className="navbar-text">{currentStep}/8</span>
                    )}
                </div>
            </header>
            {!hideProgressBar && (
                <div className="progress page-progress">
                    <div
                        className="progress-bar"
                        role="progressbar"
                        aria-valuenow={10}
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{ width: progress ?? '25%' }}
                    ></div>
                </div>
            )}
            <main
                id="main"
                className="main"
                style={{
                    background: `url(${backgroundImage})`,
                    marginTop: '0px',
                }}
            >
                {showChildren && children}
                {showHelpTray && (
                    <ModalPage breakpoint={dimensions.breakpoint}>
                        {HelpComponent && <HelpComponent />}
                        <Button
                            onClick={() => closeHelpTray()}
                            className="btn-outline-dark"
                        >
                            Back to Application
                        </Button>
                    </ModalPage>
                )}
            </main>
            <footer>
                <div className="footer-container">
                    {!showHelpTray && HelpComponent && (
                        <button
                            className="link-button"
                            onClick={() => setShowHelpTray(!showHelpTray)}
                        >
                            Help
                        </button>
                    )}
                    {showBasicInfoFooter && (
                        <>
                            <div className="footer-section">
                                <p className="footer-text">
                                    &copy; {new Date().getFullYear()} OpenSky is
                                    a division of Capital Bank, N.A. All rights
                                    reserved.
                                </p>
                                <img
                                    className="applicationFlowLayout-fdic"
                                    src={fdic}
                                    alt=""
                                />
                            </div>
                            <div className="footer-section">
                                {showApprovalDisclosure && (
                                    <ApprovalDisclosure />
                                )}
                            </div>
                        </>
                    )}
                </div>
            </footer>
        </>
    );
};

export default ApplicationFlowLayout;
