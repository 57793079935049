import React, { FC, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { selectedApplicationResponse } from '../../store/selectors';
import { PhotoDocumentUpload, SelectDropdown } from '../../components';
import { LandingPageLayout } from '../../layouts';
import { DocumentUpload } from './document-upload';
import { DocumentSelection as DocumentSelectionType, DocumentSelectionModel, RequiredDocumentOptions } from '../../types';
import './document-selection.scss';

const DocumentSelection: FC = () => {
    const [documentSelections, setDocumentSelections] = useState<DocumentSelectionType[]>();
    const [showUploadStatus, setShowUploadStatus] = useState(false);
    const [requiredDocumentOptions, setRequiredDocumentOptions] = useState<RequiredDocumentOptions[]>([]);
    const applicationResponse = useRecoilValue(selectedApplicationResponse);

    useEffect(() => {
        const docsRequired: RequiredDocumentOptions[] = [];

        if (applicationResponse?.responseObjects.doC_AddressYN) {
            docsRequired.push({
                label: 'Address',
                documentTypeOptions: [
                    'Valid Driver\'s license',
                    'Valid State Issue ID',
                    'Utility Bill from last 6 months',
                    'Last Paycheck Stub',
                    'Most recent W2',
                    'Most recent Bank statement',
                ],
            })
        }
        if (applicationResponse?.responseObjects.doC_SsnYN) {
            docsRequired.push({
                label: 'Social Security Number',
                documentTypeOptions: [
                    'Social Security Card',
                ],
            })
        }
        if (applicationResponse?.responseObjects.doC_DobYN) {
            docsRequired.push({
                label: 'Date of Birth',
                documentTypeOptions: [
                    'Valid Driver\'s license',
                    'Valid State Issue ID',
                    'Passport',
                    'Birth Certificate',
                ],
            })
        }
        if (applicationResponse?.responseObjects.doC_IncomeYN) {
            docsRequired.push({
                label: 'Income',
                documentTypeOptions: [
                    'Last Paycheck Stub',
                    'Most recent W2',
                    'Most recent Bank statement',
                ],
            })
        }
        if (applicationResponse?.responseObjects.doC_NameYN) {
            docsRequired.push({
                label: 'Name',
                documentTypeOptions: [
                    'Valid Driver\'s license',
                    'Valid State Issue ID',
                    'Passport',
                    'Marriage Certificate',
                ],
            })
        }
        if (applicationResponse?.responseObjects.doC_ItinYN) {
            docsRequired.push({
                label: 'Individual Taxpayer Identification Number',
                documentTypeOptions: [
                    'ITIN Letter',
                ],
            })
        }

        setRequiredDocumentOptions(docsRequired);
    }, [applicationResponse]);

    const validationSchema = Yup.object().shape({
        documentSelections: Yup.array()
            .of(
                Yup.object().shape({
                    type: Yup.string().required('Document Type is Required'),
                    fileList: Yup.mixed()
                        .test(
                            'has-files',
                            'File is Required',
                            (value) => value?.length > 0
                        )
                        .required('File is required'),
                })
            ),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, isValid },
        watch,
        trigger,
    } = useForm<DocumentSelectionModel>({
        ...formOptions,
        defaultValues: validationSchema.getDefault(),
        mode: 'all',
    });

    const watchDropdowns = requiredDocumentOptions.map((value, index) => {
        return watch(`documentSelections.${index}.type`);
    });

    const onSubmit = async (event: DocumentSelectionModel) => {
        setDocumentSelections(
            event?.documentSelections?.map((value) => {
                const nameTokens = value.type.split('_');
                return {
                    category: nameTokens[0],
                    type: nameTokens[1],
                    file: value.fileList?.[0],
                }
            }),
        );

        setShowUploadStatus(true);
    };

    const handleSelectChange = (index: number, value: string) => {
        if (!value.length) {
            handleFileClear(index);
        }
    };

    const handleFileClear = (index: number) => {
        // ** TODO: fix
        // setApplication((current) => {
        //     return {
        //         ...current,
        //         documentSelections: current?.documentSelections?.map(
        //             (value, valueIndex) =>
        //                 valueIndex === index ? { type: value.type } : value
        //         ),
        //     };
        // });
        setValue(`documentSelections.${index}.file`, undefined);
        setValue(`documentSelections.${index}.fileList`, undefined);
        trigger();
    };

    const resetDocumentUpload = () => {
        if (documentSelections) {
            for (let i = 0; i < documentSelections.length; i++) {
                handleFileClear(i);
                setValue(`documentSelections.${i}.type`, '');
            };
        };
        setShowUploadStatus(false);
    };

    return (
        <LandingPageLayout>
            {showUploadStatus ? (
                <DocumentUpload
                    leadId={applicationResponse?.leadID!}
                    applicationId={applicationResponse?.applicationID!}
                    documentSelections={documentSelections || []}
                    resetDocumentUpload={resetDocumentUpload}
                />
            ) : (
                <div className="document-selection-page">
                    <div id="header">
                        <h1>
                            Please provide documents to help us verify your identity
                        </h1>
                        <p>
                            Provide one document for each category below. Images
                            must be in color and in focus.
                        </p>
                    </div>
                    <Form>
                        {requiredDocumentOptions.map((document, index) => (
                            // <Form.Group key={index}>
                            <div className="document-selection" key={index}>
                                {document.label}
                                <SelectDropdown
                                    name={`documentSelections.${index}.type`}
                                    options={document.documentTypeOptions.map(
                                        (option) => ({
                                            label: option,
                                            value: `${document.label}_${option}`,
                                        })
                                    )}
                                    register={register}
                                    setValue={setValue}
                                    onChange={(value) =>
                                        handleSelectChange(index, value)
                                    }
                                />
                                {!!watchDropdowns?.[index]?.length && (
                                    <PhotoDocumentUpload
                                        name={`documentSelections.${index}.fileList`}
                                        register={register}
                                        // initialFile={
                                        //     application?.documentSelections?.[
                                        //         index
                                        //     ]?.file
                                        // }
                                        onClear={() => handleFileClear(index)}
                                        errors={errors as any}
                                    />
                                )}
                                {index !== requiredDocumentOptions.length - 1 && (
                                    <hr />
                                )}
                            </div>
                            // </Form.Group>
                        ))}
                        <Form.Group>
                            <Button
                                type="submit"
                                disabled={!isValid}
                                onClick={handleSubmit(onSubmit)}
                            >
                                Submit for Review
                            </Button>
                        </Form.Group>
                    </Form>
                </div>
            )}
        </LandingPageLayout>
    );
};

export default DocumentSelection;
