import React, { FC } from 'react';
import { Button } from 'react-bootstrap';
import { iconWarning } from '../../assets/img';
import './document-upload-fail.scss';

type DocumentUploadFailProps = {
    resetDocumentUpload: Function;
};

export const DocumentUploadFail: FC<DocumentUploadFailProps> = ({ resetDocumentUpload }) => {
    const onSubmit = () => {
        resetDocumentUpload();
    };

    return (
        <div className="upload-fail-container">
            {/* TODO: should all of these elements be centered/wrapped in the header div? */}
            <img src={iconWarning} alt="warning" />
            <div id="header">
                <h1>Document upload was unsuccessful, please try again</h1>
                <p>
                    The file must be in the following formats and no larger than
                    10mb in size: .jpg, .jpeg, .png, .tif, or pdf
                </p>
            </div>
            <Button onClick={onSubmit}>Try again</Button>
        </div>
    );
};
