import React, { FC, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { NumberFormatValues } from 'react-number-format';
import { NumberFormatInput } from '../../../components';

export type PartialFundingVariationProps = {
    fullyFund?: boolean;
    remainder?: number;
    received?: number;
    onValueChange?: (newValue: number) => void;
    register?: UseFormRegister<any>;
    errors?: FieldErrorsImpl<any>;
};

const PartialFundingVariation: FC<PartialFundingVariationProps> = ({ fullyFund, received, remainder, onValueChange, register, errors }) => {
        const handleOnChange = (values: NumberFormatValues) => {
            onValueChange && onValueChange(parseInt(values.value?.trim() || '0'));
        };

        const [fundingClass, setFundingClass] = useState('gradient-border partial-funding');
        const onFundingLostFocus = () => {
            setFundingClass('gray-border partial-funding');
        };

        return (
            <Card>
                <div className="card">
                    <div className="card-body">
                        <Row>
                            <Col>
                                <h6>
                                    <b>Your Funding Balance</b>
                                </h6>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="partial-payment-span">
                                    <div>
                                        <span>
                                            <p>Security Deposit Received</p>
                                            <p>${received ?? 0}</p>
                                        </span>
                                        <span>
                                            <p>
                                                Security Deposit Remaining
                                            </p>
                                            <p>${remainder ?? 0}</p>
                                        </span>                                    
                                        <hr/>
                                    </div>
                                    {fullyFund ? (
                                        <div>
                                            <span className="totals">
                                                <p>
                                                    Amount you are funding today
                                                </p>
                                                <p>${remainder ?? 0}</p>
                                            </span>
                                        </div>
                                    ) : (
                                        <NumberFormatInput
                                            type="text"
                                            register={register}
                                            errors={errors}
                                            name="amount"
                                            maxLength="5"
                                            noMaterialStyle
                                            inputGroupClassName={fundingClass}
                                            thousandSeparator=","
                                            decimalSeparator="."
                                            decimalScale={0}
                                            fixedDecimalScale={true}
                                            prefixText="$"
                                            label="How much do you want to fund today?"
                                            instructionText={
                                                (remainder ?? 0) > 25
                                                    ? `Fund any amount from $${Math.min(25, remainder ?? 0)} to $${remainder ?? 0} for your account's security deposit.`
                                                    : `Fund $${remainder} for your account's security deposit.`
                                            }
                                            aria-label="Credit line amount"
                                            onValueChange={handleOnChange}
                                            onBlur={onFundingLostFocus}
                                        />
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Card>
        );
    };

export default PartialFundingVariation;
