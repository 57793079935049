import React from 'react';
// import { Alert } from 'react-bootstrap';
// import { iconError } from '../../../assets/img';

const FundingError = () => {
    return (
        <>
            {/*
            // old Alert here
            <Alert variant="danger">
                <img
                    alt="error"
                    src={iconError}
                    role="presentation"
                    className="icon-error"
                />
                <span style={{ paddingLeft: '14px' }}>
                    An error has occurred 
                </span>
            </Alert>
            */}
            <h1 className="text-center">Let's double check your funding info</h1>
            <p className="text-center">
                We were unable to process your card payment. The information
                you provided was not verified by your bank.
            </p>
        </>
    );
};

export default FundingError;
