export enum BreakPoints {
    xs = 0,
    sm = 1,
    md = 2,
    lg = 4,
    xl = 5,
    xxl = 6
};

export enum YesNo {
    Yes = 'Yes',
    No = 'No'
}

export enum PrimaryPhoneTypes {
    Mobile = 'C',
    Home = 'H'
}

export enum OtpMethods {
    Mobile = 'Mobile',
    Email = 'Email'
}

export enum ApplicationStatusType {
    Processing,
    Error,
    InfoRequired,
    FundingRequired,
    Approved,
    Closed
}

export enum ResponseCodes {
    // Decisions Response Codes
    ApplicationInDocumentRequest = 'WT_DOC',
    ApplicationInWaitingForFunds = 'WT_FND',
    ApplicationInGoodFunds = 'WT_FRE',
    ApplicationHasBeenApproved = 'WT_FIS',
    UploadToBlobStorageSuccessful = 'FL_SUC',
    UploadToBlobStorageNotSuccessful = 'FL_UNC',
    ApplicationClosed = 'END_CLS',
    ApplicationExpiredOrIncomplete = 'END_EXP',
    ApplicationDeclined = 'END_DCL',
    ApplicationWithdrawn = 'END_WDR',
    ApplicationApprovedAndAccountBoarded = 'END_BRD',
    CheckAppStatusMultipleMatches = 'END_EMY',
    CheckAppStatusNoMatches = 'END_EMN',
    CheckAppStatusError = 'END_ERR',
    DuplicateLeadId = 'END_DUPELEAD',
    DuplicateApplicationId = 'END_DUPEAPP',
    DuplicateApplicationIdAndLeadId = 'END_APPANDLEAD',
    OneTimePasscodeIncorrect = 'CB_OTP',
    DecisionsNotAccessible = 'CB_CACHE',
    APIConnectivityError = 'API_ERR',
    MiskeyDOBAndZIP = 'WT_MSK',
    MiskeyDOB = 'WT_M83',
    MiskeyZIP = 'WT_MZI',
    ApplicationInManualReview = 'UI_MRV',
    ApplicationInManualDocumentReview = 'UI_MDR',
    ApplicationInSecurityDepositRefund = 'UI_SDR',

    // UnprocessableResults Response Codes
    FundFailure = 'FundFailure',
    FundFailureFinal = 'FundFailureFinal',
    GenericError = 'GenericError',
    InvalidModel = 'InvalidModel',
    InvalidLead = 'InvalidLead',
    InvalidApplication = 'InvalidApplication',
    InvalidEmail = 'InvalidEmail',
    CheckApplicationStatusesSuccess = 'CheckApplicationStatusesSuccess',
    InvalidRequest = 'InvalidRequest',
    ExceededDailyPaymentTransactions = 'ExceededDailyPaymentTransactions',
    MinimumPaymentNotValid = 'MinimumPaymentNotValid',
    PaymentExceedsBalance = 'PaymentExceedsBalance',
    GenericDecisionsError = 'GenericDecisionsError',
    VoidPaymentError = 'VoidPaymentError',    
}

export enum IncentiveFeatures {
    Any = 'Any',
    None = '',
    DepositReductionAmount = 'DepositReductionAmount',
    MinimumDepositAmount = 'MinimumDepositAmount'
}

export enum ProductTypePrns {
    Unknown = 'UNKNOWN',
    Basic = '1000',
    Plus = '9000',
    Launch = '6100'
}
