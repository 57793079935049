import React from "react";
import "./ssn-form.scss";
import NumberFormatInput from "../number-format-input";
import { useRecoilValue } from "recoil";
import { applicationState } from "../../../store/atoms";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { SsnVerificationModel } from "../../types";
import SecuredSsnEncryptionImage from "../secured-ssn-encryption-image";
import "../../scss/main.scss";

interface SsnFormProps {
    register?: UseFormRegister<SsnVerificationModel>;
    errors?: Partial<FieldErrorsImpl<SsnVerificationModel>>;
}

const SsnForm: React.FC<SsnFormProps> = ({ register, errors }) => {
    const application = useRecoilValue(applicationState);

    return (
            <div className="form-layout">
                <NumberFormatInput 
                secure 
                name="ssn" 
                label="SSN or ITIN" 
                type="text" 
                inputSize="long" 
                format="###-##-####"
                description={"OpenSky's identity verification will not impact your credit score."}
                defaultValue={application?.ssn} 
                errors={errors}
                register={register}/>
                <div className="bottom-spacing-150"/>
                <SecuredSsnEncryptionImage />
            </div>
        );
}

export default SsnForm;