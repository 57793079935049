import React from 'react';
import { applicationClosed, applicationRefund } from '../../assets/img';
import ApplicationInfoStatus from '../../components/application-info-status';
import { LandingPageLayout } from '../../layouts';

const ApplicationClosed = () => {
    return (
        <LandingPageLayout>
            <ApplicationInfoStatus
                appIcon={applicationRefund}
                appStatus="Your application has been closed"
                appMessage="Any funds provided for your deposit will be returned"
                appStatusType="closed"
                appStatusIcon={applicationClosed}
                appDetailMessage="If you provided a security deposit, a refund for the full amount will be processed within 5–10 business days."
                appNotificationMessage={
                    <>
                        <p>
                            <b>We're sorry to see you go!</b>
                        </p>
                        <p>
                            Your application has been processed and is now closed.
                            Please look out for an email from OpenSky for more
                            information.
                        </p>
                    </>
                }
                onNext={() => window.location.href = `${process.env.REACT_APP_HOMEPAGE_URL}`}
                buttonText="Ok, Got it"
            />
        </LandingPageLayout>
    );
};

export default ApplicationClosed;
