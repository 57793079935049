import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AxiosResponse, AxiosError } from 'axios';
import { microserviceClient } from './api-clients';
import { ErrorPageProps } from './types';
import { routeHashes } from './app-routes';

const AxiosInterceptor: React.FC = ({ children }) => {
    const history = useHistory();
    const [isInitialized, setIsInitialized] = useState<boolean>();

    useEffect(() => {
        const resInterceptor = (response: AxiosResponse) => {
            return response;
        }
        const errInterceptor = (error: AxiosError) => {
            if ([500, 400].includes(error.response?.status ?? -1)) {
                history.replace(routeHashes['/error'], {
                    title: 'Oops, something went wrong processing your application.',
                    buttonText: 'Ok, Got it',
                    navigateTo: process.env.REACT_APP_HOMEPAGE_URL,
                } as ErrorPageProps);                
            }

            if ([401, 403].includes(error.response?.status ?? -1)) {
                // *** NOTE: this only applies if there are no redirects in the error handler for 
                // ***       this response, as that would execute after this and override this redirect
                history.replace(routeHashes['/error'], {
                    title: 'For security reasons, your session with OpenSky has expired.',
                    buttonText: 'Ok, Got it',
                    navigateTo: process.env.REACT_APP_HOMEPAGE_URL,
                } as ErrorPageProps);
            }
            
            return Promise.reject(error);
        }

        const interceptor = microserviceClient.interceptors.response.use(resInterceptor, errInterceptor);
        setIsInitialized(true);

        return () => microserviceClient.interceptors.response.eject(interceptor);

    }, [history])

    return (
        <>
            {isInitialized && children}
        </>
    );
}

export default AxiosInterceptor;
