import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { isIncentiveFeatureActive, getPrn } from '../../utils/incentives';
import { applicationState, configurationResponseState, gaDataLayerState } from '../../store/atoms';
import { DebtToIncomeModel, IncentiveFeatures } from '../../types';
import { ApplicationFlowLayout } from '../../layouts';
import { NumberFormatInput } from '../../components';
import { useCalculateIncome } from './hooks';
import DebtToIncomeHelp from './debt-to-income-help';
import { routeHashes } from '../../app-routes';
import { iconError } from '../../assets/img';
import * as FullStory from '@fullstory/browser';
import './index.scss';
import { pushToDataLayer } from '../../utils/analytics';

const DebtToIncome = () => {
    const history = useHistory();
    const [application, setApplication] = useRecoilState(applicationState);
    const [nextEnabled, setNextEnabled] = useState(false);
    const [housingPaymentToCalculate, setHousingPaymentToCalculate] = useState<number>(application.housingPayment || 0);
    const [showHelpNow, setShowHelpNow] = useState(false);    
    const configurationResponse = useRecoilValue(configurationResponseState);
    const [gaDataLayer, setGaDataLayer] = useRecoilState(gaDataLayerState);

    const validationSchema = Yup.object().shape({
        housingPayment: Yup.number()
            .required('Monthly Housing Payment is required')
            .typeError('You must specify a numeric value')
            .lessThan(1000000, 'Monthly Housing Payment must be less than $1,000,000')
            .default(() => application.housingPayment),
        annualIncome: Yup.number()
            .required('Annual Income is required')
            .typeError('You must specify a numeric value')
            .moreThan(0, "Must be greater than $0.00")
            .lessThan(1000000, 'Annual Income must be less than $1,000,000')
            .default(() => application.annualIncome),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { control, getValues, register, reset, setValue, formState: { errors, isValid } } =
        useForm<DebtToIncomeModel>({
            ...formOptions,
            defaultValues: validationSchema.getDefault(),
            mode: 'all'
        });

    const housingPayment = useWatch({ control, name: 'housingPayment' });
    const annualIncome = useWatch({ control, name: 'annualIncome' });
    const annualIncomeValidated = true;        
    const { data: minimumIncomeAmount } = useCalculateIncome(housingPaymentToCalculate);

    const handleBlur = () => {
        const data: DebtToIncomeModel = getValues();
        
        setHousingPaymentToCalculate(housingPayment ?? 0);

        setApplication(current => ({
            ...current,
            ...data,
        }));
    }

    const handleNext = () => {
        const data: DebtToIncomeModel = getValues();

        if (data.annualIncome && data.housingPayment) {
            switch (application?.cardType) {
                case "basic": {
                    FullStory.event('IncomeNext', { leadId: application.leadId });
                    FullStory.event('HousingRentNext', { leadId: application.leadId });
                    break;
                }
                case "plus": {
                    FullStory.event('Plus_IncomeNext', { leadId: application.leadId });
                    FullStory.event('Plus_HousingRentNext', { leadId: application.leadId });
                    break;
                }
                case "launch": {
                    FullStory.event('Launch_IncomeNext', { leadId: application.leadId });
                    FullStory.event('Launch_HousingRentNext', { leadId: application.leadId });
                    break;
                }
                default: {
                    FullStory.event('IncomeNext', { leadId: application.leadId });
                    FullStory.event('HousingRentNext', { leadId: application.leadId });
                    break;
                }
            }
        }

        if (data.housingPayment) {
            setHousingPaymentToCalculate(data.housingPayment);            
        }

        setApplication(current => ({
            ...current,
            ...data,
        }));

        const okToNavigate = isValid && data.housingPayment //&& data.minimumIncome 
            && data.annualIncome;

        const updateDataLayer = {
            ...gaDataLayer,
            'step_name': 'Get Options',
            'est_housing_payment': data.housingPayment,
            'total_yearly_income': data.annualIncome
        }

        setGaDataLayer(updateDataLayer);
        pushToDataLayer('application_step_2', updateDataLayer);
        
        if (okToNavigate) {
            history.push(routeHashes['/credit-line']);
        }
    }

    const resetForm = () => {
        const defaultState: DebtToIncomeModel = {
            housingPayment: undefined,
            annualIncome: undefined,
            annualIncomeValidated: false,
        };

        // @ts-ignore
        Object.keys(defaultState).forEach((x: any) => setValue(x, defaultState[x]));
        // need both this reset call and the setValue calls above
        reset(defaultState);        

        // hack to run this after setValue(s) have propagated
        setTimeout(() => setApplication(current => ({
            ...current,
            ...defaultState,
        })), 100);
    }

    const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter')
        {                         
            // force validation for the control
            event.currentTarget.blur();
            
            event.preventDefault();
        }
    } 

    useEffect(() => {
        
        if (!!application.housingPayment && application.housingPayment > 0 &&
            !!application.annualIncome && application.annualIncome > 0 &&
            !(!!errors.annualIncome || !!errors.annualIncomeValidated)) {
                setNextEnabled(true);
        } else {
            setNextEnabled(false);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        annualIncome,
        annualIncomeValidated,
        housingPayment,
        application.housingPayment,
        errors.annualIncome,
        errors.annualIncomeValidated,
        errors.housingPayment,
        errors.minimumIncome,
        
    ]);

    if (isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn(application.cardType), configurationResponse)) {
        return (
            <>
                <ApplicationFlowLayout
                    showBackButton
                    showHelpNow={showHelpNow}
                    onHelpTrayClosed={() => setShowHelpNow(false)}
                    HelpComponent={DebtToIncomeHelp}
                    progress='30%'
                    cardType={application.cardType}
                    currentStep="3"
                    promo={true}
                    showBasicInfoFooter={true}
                >
                    <Row>
                        <Col>
                            <div id="header">
                                <div style={{padding: '5px'}}>
                                    <h1>Let's find your<br /> credit options.</h1>
                                    <p>We need a little bit of financial information to give you our credit line recommendations.</p>
                                </div>
                            </div>
                            <Form autoComplete='off' onSubmit={(event) => event.preventDefault()}>
                                <NumberFormatInput
                                    type='text'
                                    maxLength='13' // includes suffix and comma
                                    noMaterialStyle
                                    showEdit={!!application.housingPayment}
                                    disabled={!!application.housingPayment}
                                    onEditClicked={resetForm}
                                    thousandSeparator=','
                                    decimalSeparator='.'
                                    prefixText='$'
                                    suffix=' / month'
                                    name='housingPayment'
                                    label='Monthly Housing Payment'
                                    aria-label='Monthly Housing Payment'
                                    placeholder='Ex. 500'
                                    errors={errors}
                                    register={register}
                                    value={housingPayment || ''}
                                    onBlur={() => handleBlur()}
                                    onKeyPress={handleEnter}
                                />
                                {//application.housingPayment !== undefined && 
                                    <>                                
                                        {//minimumIncomeAmount &&
                                            <>
                                                <NumberFormatInput
                                                    type='text'
                                                    maxLength='7' // includes comma
                                                    noMaterialStyle
                                                    thousandSeparator=','
                                                    decimalSeparator='.'
                                                    prefixText='$'
                                                    name='annualIncome'
                                                    label='Total Yearly Income'
                                                    placeholder='Ex. 35,000'
                                                    subtitleText={
                                                        <>
                                                            Please include all types of income, in addition to wages. 
                                                            <div>
                                                                <button
                                                                    className="link-button"
                                                                    style={{ paddingLeft: 0 }}
                                                                    onClick={() => setShowHelpNow(true)}
                                                                >
                                                                    Learn More
                                                                </button>
                                                            </div>
                                                        </>
                                                    }
                                                    aria-label='Yearly Income'
                                                    value={annualIncome || ''}
                                                    onChange={() => setValue('annualIncomeValidated', true)}
                                                    onBlur={() => handleBlur()}
                                                    onKeyDown={handleEnter}
                                                    errors={errors}
                                                    register={register}
                                                />
                                                 {(!!minimumIncomeAmount && minimumIncomeAmount > 0 && 
                                                   !!annualIncome && annualIncome < minimumIncomeAmount) &&
                                                    <>
                                                    <div style={{'paddingTop': '15px'}}>
                                                        <Alert variant='danger'>
                                                            <img alt='error' src={iconError} role='presentation' className='icon-error' />
                                                            <span className="total-income-amount-error-message"><strong>Oops!</strong> It looks like you might be forgetting some of your <strong>Total Yearly Income</strong> </span>
                                                        </Alert>
                                                    </div>                                            
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </Form>
                        </Col>
                    </Row>
                    <Row className='row mt-36'>
                        <Col className='col text-center'>
                            <Form.Group>
                                <Button                            
                                    disabled={!(isValid || nextEnabled)}
                                    onClick={handleNext}>
                                    Next
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </ApplicationFlowLayout>
            </>
        )
    } 
    else 
    {
        return (
            <ApplicationFlowLayout
                showBackButton
                showHelpNow={showHelpNow}
                onHelpTrayClosed={() => setShowHelpNow(false)}
                HelpComponent={DebtToIncomeHelp}
                progress='30%'
                cardType={application.cardType}
                currentStep="3"
                showBasicInfoFooter={true}
            >
                <Row>
                    <Col>
                        <div id="header">
                            <h1>Let's find your available options.</h1>
                            <p>We need a little bit of financial information to give you our best credit line recommendations.</p>
                        </div>
                        <Form autoComplete='off' onSubmit={(event) => event.preventDefault()}>
                            <NumberFormatInput
                                type='text'
                                maxLength='13' // includes suffix and comma
                                noMaterialStyle
                                showEdit={!!application.housingPayment}
                                disabled={!!application.housingPayment}
                                onEditClicked={resetForm}
                                thousandSeparator=','
                                decimalSeparator='.'
                                prefixText='$'
                                suffix=' / month'
                                name='housingPayment'
                                label='Monthly Housing Payment'
                                aria-label='Monthly Housing Payment'
                                placeholder='Ex. 500'
                                errors={errors}
                                register={register}
                                value={housingPayment || ''}
                                onBlur={() => handleBlur()}
                                onKeyPress={handleEnter}
                            />
                            {//application.housingPayment !== undefined && 
                                <>                                
                                    {//minimumIncomeAmount &&
                                        <>
                                            <NumberFormatInput
                                                type='text'
                                                maxLength='7' // includes comma
                                                noMaterialStyle
                                                thousandSeparator=','
                                                decimalSeparator='.'
                                                prefixText='$'
                                                name='annualIncome'
                                                label='Total Yearly Income'
                                                placeholder='Ex. 35,000'
                                                subtitleText={
                                                    <>
                                                        Please include all types of income, in addition to wages. 
                                                        <div>
                                                            <button
                                                                className="link-button"
                                                                style={{ paddingLeft: 0 }}
                                                                onClick={() => setShowHelpNow(true)}
                                                            >
                                                                Learn More
                                                            </button>
                                                        </div>
                                                    </>
                                                }
                                                aria-label='Yearly Income'
                                                value={annualIncome || ''}
                                                onChange={() => setValue('annualIncomeValidated', true)}
                                                onBlur={() => handleBlur()}
                                                onKeyDown={handleEnter}
                                                errors={errors}
                                                register={register}
                                            />
                                            {(!!minimumIncomeAmount && minimumIncomeAmount > 0 && 
                                            !!annualIncome && annualIncome < minimumIncomeAmount) &&
                                                <>
                                                <div style={{'paddingTop': '15px'}}>
                                                    <Alert variant='danger'>
                                                        <img alt='error' src={iconError} role='presentation' className='icon-error' />
                                                        <span className="total-income-amount-error-message"><strong>Oops!</strong> It looks like you might be forgetting some of your <strong>Total Yearly Income</strong> </span>
                                                    </Alert>
                                                </div>                                            
                                                </>
                                            }
                                        </>
                                    }
                                </>
                            }
                        </Form>
                    </Col>
                </Row>
                <Row className='row mt-36'>
                    <Col className='col text-center'>
                        <Form.Group>
                            <Button                            
                                disabled={!(isValid || nextEnabled)}
                                onClick={handleNext}>
                                Next
                            </Button>
                        </Form.Group>
                    </Col>
                </Row>
            </ApplicationFlowLayout>
        )
    }
};

export default DebtToIncome;
