import { isValidRawPhone, rawPhonePattern } from './validations';

const formatPhone = (phone?: string): string | undefined => {
    if (!isValidRawPhone(phone)) {
        return undefined;
    }

    var slugs = phone?.match(rawPhonePattern);
    if (!slugs) {
        return undefined;
    }

    return `${slugs[1]}-${slugs[2]}-${slugs[3]}`;
}

const formatRawPhoneNumber = (phoneNumber: string | number): string  => {
    const phoneStr = phoneNumber.toString();

    if (phoneStr.length !== 10 || !/^\d+$/.test(phoneStr)) {
        throw new Error("Invalid phone number. Must contain exactly 10 digits.");
    }

    const formattedPhone = `(${phoneStr.slice(0, 3)}) ${phoneStr.slice(3, 6)}-${phoneStr.slice(6)}`;
    
    return formattedPhone;
}

const sanitizePhoneNumber = (phoneNumber: string): string => {
    return phoneNumber.replace(/[^\d]/g, '');
  }

export {
    formatPhone, formatRawPhoneNumber, sanitizePhoneNumber
};
