import React, { useState } from 'react';
import * as Yup from 'yup';
import ApplicationFlowLayout from '../../layouts/application-flow';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    applicationState,
    configurationResponseState,
    gaDataLayerState,
} from '../../../store/atoms';
import { CardTypes } from '../../../utils/constants';
import '../../scss/main.scss';
import { Form, Subheadline, Terms } from '../../components';
import { debuglog } from '../../../utils/debug';
import { useHistory } from 'react-router';
import { currentCardInfo } from '../../../utils/product-info';
import {
    TermsAndConditionsModel,
} from '../../../types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import './terms-page.scss';
import { Link } from 'react-router-dom';
import { routeHashes } from '../../../app-routes';
import { validationErrors } from '../../utils/constants';
import { pushToDataLayer } from '../../../utils/analytics';
import * as FullStory from '@fullstory/browser';

const TermsPage = () => {
    const [application, setApplication] = useRecoilState(applicationState);
    const [debounceActive, setDebounceActive] = useState(false);
    const [gaDataLayer, setGADataLayer] = useRecoilState(gaDataLayerState);

    const configurationResponse = useRecoilValue(configurationResponseState);
    let cardInfo;

    debuglog('=========T&C---FUND TODAY--->', application?.fundToday);
    const history = useHistory();

    let rateDisclosuresLink;
    let termsAndConditionsTab;
    let cardholderAgreementTab;

    if (application.cardType === 'basic') {
        cardInfo = currentCardInfo(
            'basic',
            configurationResponse?.securedProducts
        );
        rateDisclosuresLink = cardInfo.currentTermsConditions;
        termsAndConditionsTab = 'basic-terms-and-conditions';
        cardholderAgreementTab = 'basic-cardholder-agreement';
    } else if (application.cardType === 'plus') {
        cardInfo = currentCardInfo(
            'plus',
            configurationResponse?.securedProducts
        );
        rateDisclosuresLink = cardInfo.currentTermsConditions;
        termsAndConditionsTab = 'plus-terms-and-conditions';
        cardholderAgreementTab = 'plus-cardholder-agreement';
    } else {
        cardInfo = currentCardInfo(
            'launch',
            configurationResponse?.securedProducts
        );
        rateDisclosuresLink = cardInfo.currentTermsConditions;
        termsAndConditionsTab = 'launch-terms-and-conditions';
        cardholderAgreementTab = 'launch-cardholder-agreement';
    }

    cardInfo = currentCardInfo('basic', configurationResponse?.securedProducts);
    rateDisclosuresLink = cardInfo.currentTermsConditions;
    termsAndConditionsTab = 'basic-terms-and-conditions';
    cardholderAgreementTab = 'basic-cardholder-agreement';

    const validationSchema = Yup.object().shape({
        eSignConsent: Yup.boolean()
            .oneOf([true])
            .test('eSignConsent', validationErrors.eSignConsentError, (x) => !!x)
            .default(application.eSignConsent),
        termsAndConditions: Yup.boolean()
            .oneOf([true])
            .test(
                'termsAndConditions',
                validationErrors.termsAndConditionsError,
                (x) => !!x
            )
            .default(application.termsAndConditions),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const {
        handleSubmit,
        register,
        formState: { isValid },
    } = useForm<any>({
        ...formOptions,
        defaultValues: validationSchema.getDefault(),
        mode: 'all',
    });

    const onSubmit = async (event: TermsAndConditionsModel) => {
        if (debounceActive) return;

        setDebounceActive(true);

        switch (application?.cardType) {
            case "basic": {
                FullStory.event("AppSubmit", { leadId: application.leadId });
                break;
            }
            case "plus": {
                FullStory.event("Plus_AppSubmit", { leadId: application.leadId });
                break;
            }
            case "launch": {
                FullStory.event('Launch_AppSubmit', { leadId: application.leadId });
                break;
            }
            default: {
                FullStory.event("AppSubmit", { leadId: application.leadId });
                break;
            }
        }

        setApplication((current) => ({
            ...current,
            ...event,
        }));

        const updateDataLayer = {
            ...gaDataLayer,
            'step_name': 'Terms and Conditions',
        };
        setGADataLayer(updateDataLayer);
        pushToDataLayer("application_step_7", updateDataLayer);

        history.push(routeHashes["/v1_5/processing-application"]);
    };

    return (
        <ApplicationFlowLayout
            isArrowBackButton
            cardType={application.cardType as CardTypes}
            displayVisaLogo
            currentStep="8"
            progress="99%"
        >
            <div className="main-container">
                <div className='padding-left-24'>
                    <Subheadline
                        headline="Now let’s review your card details"
                        smallText="Please take a moment to carefully review the<br/> Terms & Conditions below."
                    />
                </div>
                <Terms rateDisclosuresLink={rateDisclosuresLink} />
                <Form
                    onSubmit={handleSubmit(onSubmit)}
                    submitText="Submit my application"
                    isSubmitDisabled={!isValid}
                >
                    <h5 className="terms-form-legend">
                        Review and agree to terms
                    </h5>
                    <div className="terms-inputs">
                        <div className="terms-input">
                            <input
                                type="checkbox"
                                className="terms-checkbox"
                                {...register('eSignConsent')}
                            />
                            <p className="terms-input-text">
                                I agree to your{' '}
                                <Link
                                    to={{
                                        pathname: routeHashes['/disclosures'],
                                        state: { tab: 'esign-consent' },
                                    }}
                                >
                                    E-Sign Consent
                                </Link>
                            </p>
                        </div>
                        <div className="terms-input">
                            <input
                                type="checkbox"
                                className="terms-checkbox"
                                {...register('termsAndConditions')}
                            />
                            <p className="terms-input-text">
                                I agree to OpenSky's&nbsp;
                                <Link
                                    to={{
                                        pathname: routeHashes['/disclosures'],
                                        state: {
                                            tab: termsAndConditionsTab,
                                            cardType: application.cardType,
                                        },
                                    }}
                                >
                                    Terms and Conditions
                                </Link>
                                ,&nbsp;
                                <Link
                                    to={{
                                        pathname: routeHashes['/disclosures'],
                                        state: {
                                            tab: 'privacy-notice',
                                        },
                                    }}
                                >
                                    Privacy Notice
                                </Link>
                                ,&nbsp;
                                <Link
                                    to={{
                                        pathname: routeHashes['/disclosures'],
                                        state: {
                                            tab: 'privacy-policy',
                                        },
                                    }}
                                >
                                    Privacy Policy
                                </Link>
                                , and&nbsp;
                                <Link
                                    to={{
                                        pathname: routeHashes['/disclosures'],
                                        state: {
                                            tab: cardholderAgreementTab,
                                            cardType: application.cardType,
                                        },
                                    }}
                                >
                                    Cardholder Agreement
                                </Link>
                            </p>
                        </div>
                    </div>
                </Form>
            </div>
        </ApplicationFlowLayout>
    );
};

export default TermsPage;