import React, { useState } from 'react';
import { Button, Carousel, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {
    iconQuestion,
    testimonialUser1,
    testimonialUser2,
    testimonialUser3,
    testimonialUser4,
    testimonialUser5,
} from '../../assets/img';
import { ModalPage } from '../../components';
import { useWindowDimensions } from '../../hooks';
import { ApplicationFlowLayout } from '../../layouts';
import { isIncentiveFeatureActive, getPrn } from '../../utils/incentives';
import { IncentiveFeatures } from '../../types';
import { rotatedArray } from '../../utils/arrays';
import { routeHashes } from '../../app-routes';
import { useRecoilValue, useRecoilState } from 'recoil';
import { applicationState, configurationResponseState } from '../../store/atoms';
import * as FullStory from '@fullstory/browser';

type Testimonial = {
    avatarImage: string;
    name: string;
    identity: string;
    testimonial: string;
};

const testimonials: Testimonial[] = [
    {
        avatarImage: testimonialUser1,
        name: 'Michael B.',
        identity: 'OpenSky Cardholder',
        testimonial: 'I started with $200, made my payments on time...I had a 400 credit score and now my score is 680.',
    },
    {
        avatarImage: testimonialUser2,
        name: 'Donna N.',
        identity: 'OpenSky Cardholder',
        testimonial: 'In one year, OpenSky doubled my credit limit. ... I\'m in a very good place now with my car and home paid off.',
    },
    {
        avatarImage: testimonialUser3,
        name: 'Juan T.',
        identity: 'OpenSky Cardholder',
        testimonial: 'Whenever I needed it the most, OpenSky was there for me to help me build my credit. Thank you, OpenSky.',
    },
    {
        avatarImage: testimonialUser4,
        name: 'Lashae K.',
        identity: 'OpenSky Cardholder',
        testimonial: 'Through the knowledge and tools, you can become a success story. I went from 545 to 700 in just under two years.',
    },
    {
        avatarImage: testimonialUser5,
        name: 'Lance T.',
        identity: 'OpenSky Cardholder',
        testimonial: 'OpenSky was the stepping stone...if you\'re trying to repair your credit, this is what you need to do.',
    },
];

const CreditLineFundLater = () => {    
    const focusedIndexRotation = 3;
    const history = useHistory();
    const dimensions = useWindowDimensions();
    const [carouselIndex, setCarouselIndex] = useState(0);
    const [avatars, setAvatars] = useState(rotatedArray(testimonials.map(x => x.avatarImage), focusedIndexRotation));
    const [application, setApplication] = useRecoilState(applicationState);
    const configurationResponse = useRecoilValue(configurationResponseState);

    const handleCarouselSelection = (index: number) => {
        setCarouselIndex(index);
        setAvatars(rotatedArray(testimonials.map(x => x.avatarImage), index + focusedIndexRotation));
    };

    const handleFundToday = () => {
        switch (application?.cardType) {
            case "basic": {
                FullStory.event('FundToday', { leadId: application.leadId });
                break;
            }
            case "plus": {
                FullStory.event('Plus_FundToday', { leadId: application.leadId });
                break;
            }
            case "launch": {
                FullStory.event('Launch_FundToday', { leadId: application.leadId });
                break;
            }
            default: {
                FullStory.event('FundToday', { leadId: application.leadId });
                break;
            }
        }
        setApplication((current) => ({
            ...current,
            ...{ fundToday: true },
        }));
        
        history.push(routeHashes['/funding-source']);
    };

    const handleFundLater = () => {
        switch (application?.cardType) {
            case "basic": {
                FullStory.event('FundAfterApproval', { leadId: application.leadId });
                break;
            }
            case "plus": {
                FullStory.event('Plus_FundAfterApproval', { leadId: application.leadId });
                break;
            }
            default: {
                FullStory.event('FundAfterApproval', { leadId: application.leadId });
                break;
            }
        }
        
        //Forward Credit-Line-Confirm & funding section to end of process
        history.push(routeHashes['/terms-and-conditions']);
    };
    const minimumDepositPromo = isIncentiveFeatureActive(IncentiveFeatures.MinimumDepositAmount, getPrn(application.cardType), configurationResponse);

    return (
        <ApplicationFlowLayout
            showBackButton
            progress="80%"
            cardType={application.cardType}
            currentStep="8"
            promo={isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn(application.cardType), configurationResponse)}
            showBasicInfoFooter={true}
            footerAlternateEligibleDisclosure={(application.cardType === 'basic') && isIncentiveFeatureActive(IncentiveFeatures.DepositReductionAmount, getPrn(application.cardType), configurationResponse)}
            footerPlusEligibleDisclosure={(application.cardType === 'plus') && isIncentiveFeatureActive(IncentiveFeatures.MinimumDepositAmount, getPrn(application.cardType), configurationResponse)}
            footerTestimonialDisclosure={true}
            promoAlternateEligibleDisclosureIndex='*'
            promoPlusEligibleDisclosureIndex='*'
            promoTestimonialDisclosureIndex={isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn(application.cardType), configurationResponse) ? '**' : '*'}
        >
            <ModalPage breakpoint={dimensions.breakpoint}>
                <Row>
                    <Col>
                        <div id="header">
                            <img
                                src={iconQuestion}
                                className="icon-question"
                                alt=""
                            />
                            <p className="intro text-center">
                                {minimumDepositPromo && (<>
                                    Are you sure you're not interested in building your credit with a lower 
                                    minimum deposit and no annual fee?*
                                </>)}
                                {!minimumDepositPromo && (<>
                                    Are you sure you do not want to fund your card
                                today?{isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn(application.cardType), configurationResponse) ? '*' : ''}
                                </>)}
                                
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="h-rule"></div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="avatar-list">
                            {avatars.map((x, i) => (
                                <div className="avatar" key={i}>
                                    <div className="gradient-border">
                                        <div className="gradient-cover">
                                            <img
                                                src={x}
                                                alt=""
                                                className="avatar-image"
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <p className="helper-light text-center" style={{ color: 'black' }}>
                            {testimonials[carouselIndex].name}
                        </p>
                        <p className="helper-light text-center">
                            {testimonials[carouselIndex].identity}
                            {isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn(application.cardType), configurationResponse) ? '**' : '*'}
                        </p>
                        <Carousel
                            slide={false}
                            fade={true}
                            controls={false}
                            onSelect={handleCarouselSelection}
                        >
                            {testimonials.map((x, i) => (
                                <Carousel.Item key={i}>
                                    <div
                                        className="feedback"
                                        style={{ paddingBottom: '15px' }}
                                    >
                                        <p className="intro text-center">
                                            "{x.testimonial}"
                                        </p>
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Col>
                </Row>
                <Row>
                    <div className="text-center">
                        <Button onClick={handleFundToday}>Fund today</Button>
                    </div>
                    <div className="text-center" style={{ paddingTop: '20px' }}>
                        <Button
                            variant="outline-dark"
                            onClick={handleFundLater}
                        >
                            Fund Later
                        </Button>
                    </div>
                </Row>
            </ModalPage>
        </ApplicationFlowLayout>
    );
};

export default CreditLineFundLater;
