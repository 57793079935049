import React, { useEffect, useState } from "react";
import ApplicationFlowLayout from "../../layouts/application-flow";
import { useRecoilState, useRecoilValue } from "recoil";
import { applicationState, gaDataLayerState } from "../../../store/atoms";
import { CardTypes } from "../../../utils/constants";
import { Subheadline } from "../../components";
import { phoneAction } from "../../../assets/img";
import { formatPhone } from "../../../utils/phone-numbers";
import "./otp.scss";
import "../../scss/main.scss";
import { BlockCode } from "../../components/block-code";
import ResendCode from "../../components/resend-code";
import { ErrorPageProps, OtpCheckProps, OtpMethods, VerifyCheckAppStatusOtpRequest, VerifyLeadOtpRequest } from "../../../types";
import { useHistory } from "react-router";
import { useResendOtpCode, useVerifyOtpCode } from "../../../pages/otp-check/hooks";
import { CompleteValue } from "../../components/block-code/types";
import { useInterval } from "../../../hooks";
import { checkForUnauthorized, routeHashes } from "../../../app-routes";
import { Spinner } from "react-bootstrap";
import { pushToDataLayer } from "../../../utils/analytics";
import * as FullStory from '@fullstory/browser';

const Otp: React.FC<OtpCheckProps> = (props) => {
    const { navigateTo, navigationState, otpSentTime, isInitialOtp } = props;
    let otpData = props.otpData;

    const history = useHistory();
    const application = useRecoilValue(applicationState);

    const resendOtpMutation = useResendOtpCode(otpData.isAppStatusCheck ?? false);
    const verifyOtpMutation = useVerifyOtpCode(otpData.isAppStatusCheck ?? false);

    const emailAddress = otpData.emailAddress;

    const [hasBeenResent, setHasBeenResent] = useState(!isInitialOtp);
    const [seconds, setSeconds] = useState(0);
    const [codeValue, setCodeValue] = useState<string>();
    const [startTime, setStartTime] = useState<Date>(otpSentTime);
    const [gaDataLayer, setGADataLayer] = useRecoilState(gaDataLayerState);

    if (!props.otpData) {
        otpData = {
            emailAddress: '',
            primaryPhone: '',
            otpMethod: OtpMethods.Mobile,
        }
    }

    const handleComplete = (data: CompleteValue) => {
        switch (application?.cardType) {
            case "basic": {
                FullStory.event('OTPentry', {});
                break;
            }
            case "plus": {
                FullStory.event('Plus_OTPentry', {});
                break;
            }
            case "launch": {
                FullStory.event('Launch_OTPentry', { leadId: application.leadId });
                break;
            }
            default: {
                FullStory.event('OTPentry', {});
                break;
            }
        }

        setCodeValue(data.value);
    }

    const handleResendCode = () => {
        const payload = otpData.isAppStatusCheck
            ? {
                emailAddress
            }
            : {
                leadId: otpData.id,
                firstName: otpData.firstName,
                emailAddress: otpData.emailAddress,
                primaryPhone: otpData.primaryPhone
            };

        resendOtpMutation.mutate(payload, {
            onSuccess: () => {
                setStartTime(new Date());
                setHasBeenResent(true);
            }
        });
    };

    useInterval(() => seconds > 0 && setSeconds(seconds - 1), 1000);

    useEffect(() => {
        if (startTime) {
            const expirationMins = 5;
            const expirationTime = new Date(startTime.getTime() + expirationMins * 60 * 1000);
            const now = new Date();
            const newSeconds = Math.ceil((expirationTime.getTime() - now.getTime()) / 1000);
            setSeconds(newSeconds > 0 ? newSeconds : /* istanbul ignore next */ 0);
        }
    }, [startTime]);

    useEffect(() => {
        if (codeValue) {
            const payload = otpData.isAppStatusCheck
                ? {
                    emailAddress,
                    otpCode: codeValue
                }
                : {
                    leadId: otpData.id,
                    otpCode: codeValue
                };
            verifyOtpMutation.mutate(payload as VerifyLeadOtpRequest | VerifyCheckAppStatusOtpRequest, {
                onSuccess: () => {
                    const updateDataLayer = {
                        ...gaDataLayer,
                        'step_name': 'OTP Check',
                    };
                    setGADataLayer(updateDataLayer);
                    pushToDataLayer("application_step_1", updateDataLayer);

                    history.push(navigateTo, navigationState);
                },
                onError: (error: any) => {
                    if (!checkForUnauthorized(error, history)) {
                        history.replace(routeHashes['/error'], {
                            title: 'The code you entered was invalid, please try again!',
                            buttonText: 'Try again',
                            navigateTo: routeHashes['/v1_5/otp'],
                            navigationState: { otpData, navigateTo, navigationState, otpSentTime: startTime, isInitialOtp: !hasBeenResent },
                        } as ErrorPageProps);
                    }
                }
            });
        }
    }, [codeValue]);

    return (
        <ApplicationFlowLayout progress='25%' currentStep='2' isArrowBackButton={true} cardType={application.cardType as CardTypes} displayVisaLogo={true}>
            <div className="main-container">
                <div className="otp-main-container-layout">
                    <div className="otp-section">
                        <div className="otp-section-layout">
                            <Subheadline
                                headline="Verify your mobile number"
                                headlineIcon={phoneAction}
                                smallText={`We sent a code to ${formatPhone(application.primaryPhone)}<br/>(Valid for 5 minutes)`} />
                            <BlockCode inputProps={{ disabled: !!codeValue, className: !codeValue ? "otp-form-control" : "otp-form-control-grey-text", type: "tel" }} onComplete={handleComplete} />
                        </div>
                        {!codeValue ? (
                            <ResendCode onResendCodeClicked={handleResendCode} seconds={seconds} hasBeenResent={hasBeenResent} />
                        ) : (
                            <div className="otp-spinner-container">
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </ApplicationFlowLayout>);
}

export default Otp;