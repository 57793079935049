import { rest } from 'msw';
import { DocumentUploadRequest } from '../types';

export const uploadDocuments = [
    rest.post<DocumentUploadRequest>(`${process.env.REACT_APP_DEFAULT_API_BASE_URL}/api/application/uploaddocuments`, async (req, res, ctx) => {
        //const { applicationId, leadId, files } = (await req.json()) as DocumentUploadRequest;
        
        const body: DocumentUploadRequest = {
            applicationId: req.body.applicationId,
            leadId: req.body.leadId,
            files: req.body.files,
        };

        //const { leadId } = req.bodyUsed;
        await new Promise(r => setTimeout(r, 500));
        console.log('leadId', body.leadId);
        console.log('applicationId', body.applicationId);
        console.log('headers', req.headers.all());
        return res(
            ctx.status(200),
            ctx.json({})
        );
    }),
];
