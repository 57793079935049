import { useMutation } from 'react-query';
import { microserviceClient } from '../../api-clients';
import { DocumentSelection, GetApplicationsStatusResponse } from '../../types';

export type UploadDocumentsArgs = {
    leadId: string;
    applicationId: string;
    documentSelections: DocumentSelection[];
};

const uploadDocuments = ({ leadId, applicationId, documentSelections }: UploadDocumentsArgs) => {
    const formData = new FormData();
    formData.append('ApplicationId', applicationId);
    formData.append('LeadId', leadId);
        
    let counter: number = 0;

    for (const documentSelection of documentSelections) {  
        formData.append(`files[${counter}].Category`, documentSelection.category);
        formData.append(`files[${counter}].Name`, documentSelection.type);
        formData.append(`files[${counter}].File`, documentSelection.file!);
        counter++;        
    }        
    

    return microserviceClient.post<GetApplicationsStatusResponse>(`/api/application/uploaddocuments`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const useUploadDocuments = () => {
    return useMutation(
        uploadDocuments,
        {
            onError: (err, variables, context) => {
                console.log('Error uploading documents', err);
            },
        }
    );
};
