import { rest } from "msw";

export const withdrawApplication = [
    rest.post(
        `${process.env.REACT_APP_DEFAULT_API_BASE_URL}/api/application/withdrawApplication`,
        async (req, res, ctx) => {
            const json = await req.json();
            await new Promise(r => setTimeout(r, 1000));
            if (json.applicationId === undefined) {
                return res(
                    ctx.status(400),
                    ctx.json({
                        responseCode: ""
                    })
                );
            }
            console.log("payload", json);
            return res(
                ctx.status(200),
                ctx.json({
                    responseCode: ""
                })
            );
        }
    )
];
