import React from 'react';
import '../../scss/main.scss';
import './static-radio-button.scss';
import { radioButton } from '../../../assets/img';

interface StaticRadioButtonProps {
    label: string;
    value: string;
}

const StaticRadioButton: React.FC<StaticRadioButtonProps> = ({
    label,
    value,
}) => {
    return (
        <div className="radio-button-container">
            <label className="label">{label}</label>
            <div className="input-and-label">
                <img
                    src={radioButton}
                    alt="radio button"
                    className="radio-button-layout"
                />
                <p className="radio-button-typography">
                    {value.split('<br/>').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    ))}
                </p>
            </div>
        </div>
    );
};

export default StaticRadioButton;