import React, { useState } from 'react';
import ApplicationFlowLayout from '../../layouts/application-flow';
import { visaLogoBlack, iconBlueCheck, creditCardIcon } from '../../../assets/img';
import './basic-info.scss';
import "../../scss/main.scss";
import { useCreateLead } from '../../../pages/basic-info/hooks';
import { useRecoilState, useRecoilValue } from 'recoil';
import { applicationState, configurationResponseState, createLeadResponseState, emailAddressState, firstNameState, gaDataLayerState, nidState, productIdBeforeDownsellState } from '../../../store/atoms';
import { debuglog } from '../../../utils/debug';
import * as Yup from 'yup';
import { isValidName } from '../../../utils/validations';
import { ApplicationModel, BasicInfoModel, CreateLeadRequest, IncentiveFeatures, OtpCheckProps, OtpMethods, PrimaryPhoneTypes } from '../../../types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { getIncentive, getPrn } from '../../../utils/incentives';
import { useHistory } from 'react-router';
import {
    TextInput,
    NumberFormatInput,
    Form
} from '../../components';
import { currentCardInfo } from '../../../utils/product-info';
import { formatRawPhoneNumber, sanitizePhoneNumber } from '../../../utils/phone-numbers';
import ValueProps from '../../components/value-props';
import CardHeading from '../../components/card-heading';
import Consent from '../../components/consent';
import { validationErrors } from '../../utils/constants';
import { routeHashes } from '../../../app-routes';
import { generateSHA256Hash } from '../../../utils/hashing';
import { pushToDataLayer } from '../../../utils/analytics';
import * as FullStory from '@fullstory/browser';

const BasicInfo: React.FC = () => {
    const history = useHistory();

    const [application, setApplication] = useRecoilState(applicationState);
    const [, setCreateLeadResponse] = useRecoilState(createLeadResponseState);
    const configurationResponse = useRecoilValue(configurationResponseState);
    const [, setEmailAddress] = useRecoilState(emailAddressState);
    const [, setFirstName] = useRecoilState(firstNameState);
    const [, setProductIdBeforeDownsell] = useRecoilState(productIdBeforeDownsellState);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [gaDataLayer, setGADataLayer] = useRecoilState(gaDataLayerState);
    let nid = useRecoilValue(nidState);

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required(validationErrors.firstNameIsRequiredError)
            .test(
                'contains-alpha-characters-firstname',
                validationErrors.firstNameAlphabeticalCharactersError,
                (value) => isValidName(value)
            )
            .default(() => application.firstName),
        lastName: Yup.string()
            .required(validationErrors.lastNameRequiredError)
            .test(
                'contains-alpha-characters-lastname',
                validationErrors.lastNameAlphabeticalCharactersError,
                (value) => isValidName(value)
            )
            .test(
                'firstLastMaxLength',
                validationErrors.nameExceedsMaximumCharactersError,
                function (item) {
                    return (this.parent.firstName.length + this.parent.lastName.length) < 27
                }
            )
            .default(() => application.lastName),
        emailAddress: Yup.string()
            .email(validationErrors.invalidEmailAddressError)
            .required(validationErrors.emailAddressIsRequiredError)
            .default(() => application.emailAddress),
        primaryPhone: Yup.string()
            .matches(/^\(\d{3}\) \d{3}-\d{4}$/, validationErrors.invalidPhoneNumberError)
            .required(validationErrors.phoneIsRequiredError)
            .default(() => application.primaryPhone && formatRawPhoneNumber(application.primaryPhone)),
        primaryPhoneType: Yup.string()
            .oneOf([PrimaryPhoneTypes.Mobile])
            .required()
            .default(
                () => PrimaryPhoneTypes.Mobile
            ),
        otpMethod: Yup.string()
            .default(
                () => application.otpMethod ?? OtpMethods.Mobile
            ),
        phoneConsent: Yup.bool().oneOf([true])
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const {
        handleSubmit,
        register,
        watch,
        setValue,
        trigger,
        formState: { errors, isValid },
    } = useForm<BasicInfoModel>({
        ...formOptions,
        defaultValues: validationSchema.getDefault(),
        mode: 'all',
    });

    const primaryPhone = watch('primaryPhone');

    const createLeadMutation = useCreateLead();
    debuglog('Basic-Info-Application', application);

    const onSubmit = async (event: BasicInfoModel) => {
        setEmailAddress(event.emailAddress ?? '');
        setFirstName(event.firstName ?? '');

        event.instanceId = Math.round(2000000000 * Math.random());
        event.primaryPhone = event.primaryPhone ? sanitizePhoneNumber(event.primaryPhone) : '';

        let cardSelected;

        switch (application?.cardType) {
            case "basic": {
                FullStory.event('BasicInfoNext', { leadId: application.leadId, productId: application.productId });
                cardSelected = 'Legacy';
                break;
            }
            case "plus": {
                FullStory.event('Plus_BasicInfoNext', { leadId: application.leadId, productId: application.productId });
                cardSelected = 'Plus';
                break;
            }
            case "launch": {
                FullStory.event('Launch_BasicInfoNext', { leadId: application.leadId });
                cardSelected = 'Launch';
                break;
            }
            default: {
                FullStory.event('BasicInfoNext', { leadId: application.leadId, productId: application.productId });
                cardSelected = 'Legacy';
                break;
            }
        }

        let curCard = currentCardInfo((application?.cardType ?? "basic"), configurationResponse?.securedProducts);
        event.productId = curCard.productId;

        if(event.productId){
            setProductIdBeforeDownsell(event.productId?.toString());
        }

        const incentive = getIncentive(IncentiveFeatures.Any, getPrn(application.cardType), configurationResponse);

        setIsSubmitted(true);

        setApplication((current: ApplicationModel) => ({
            ...current,
            ...event,
            incentiveName: incentive?.name,
            incentiveId: incentive?.id,
        }));

        const updateDataLayer = {
            ...gaDataLayer,
            'user_id': generateSHA256Hash(event?.emailAddress ?? ''),
            'NID_': nid,
            'step_name': 'About Me',
            'card_selected': cardSelected
        };

        setGADataLayer(updateDataLayer);
        pushToDataLayer("application_step_0", updateDataLayer);

        createLeadMutation.mutate(event as CreateLeadRequest, {
            onSuccess: (response) => {
                setApplication((current: ApplicationModel) => ({
                    ...current,
                    leadId: response.data.id,
                    instanceId: event.instanceId,
                }));

                setCreateLeadResponse(response.data);

                history.push(routeHashes['/v1_5/otp'], {
                    navigateTo: routeHashes['/v1_5/address'],
                    otpData: {
                        id: response.data.id,
                        isAppStatusCheck: false,
                        firstName: event.firstName,
                        emailAddress: event.emailAddress,
                        primaryPhone: event.primaryPhone,
                        otpMethod: response.data.otpMethod as OtpMethods,
                    },
                    otpSentTime: new Date(),
                    isInitialOtp: true,
                } as OtpCheckProps);
            },
        });
    };

    const valueProps = [
        {
            valuePropIconSource: iconBlueCheck,
            valuePropText: "No credit check to apply"
        },
        {
            valuePropIconSource: iconBlueCheck,
            valuePropText: "89% approval rate"
        }
    ]

    return (
        <ApplicationFlowLayout progress='12.5%' currentStep='1' showBasicInfoFooter={true} showApprovalDisclosure={true} displayOpenSkyLogo={true}>
            <div className='main-container'>
                <CardHeading headingText='OpenSky Secured' headingLogoSource={visaLogoBlack} subheadText='Approvals in under 3 minutes*'/>
                <ValueProps valuePropItems={valueProps}/>
                <div className='deposit-note-container'>
                    <img src={creditCardIcon} className='card-icon'/>
                    <p className='deposit-note-text'>Once approved, a security deposit is required to receive your card.</p>
                </div>
                <Form heading='Personal Information' onSubmit={handleSubmit(onSubmit)} submitText='Next' isSubmitDisabled={!isValid} isSubmitting={isSubmitted}>
                    <div className='vertical-aligned-inputs'>
                        <TextInput name='firstName' label='Legal first name' register={register} type='text' inputSize='short' errors={errors}/>
                        <TextInput name='lastName' label='Legal last name' register={register} type='text' inputSize='short' errors={errors}/>
                    </div>
                    <TextInput name='emailAddress' label='Email address' register={register} type='email' errors={errors} inputSize='long'/>
                    <NumberFormatInput format="(###) ###-####" name="primaryPhone" label="Mobile number" value={primaryPhone} register={register} type="tel" errors={errors} inputSize="long"/>
                    <Consent name='phoneConsent'
                            paragraph="I consent to allow Capital Bank,
                            directly or by third parties acting on its behalf,
                            to send marketing and promotional messages, about Capital Bank and OpenSky products,
                            via text at the mobile number provided or to the email address provided.
                            I <br/> understand that I can opt out of promotional <br/> messages any time. Message and data rates apply."
                            setValue={setValue}
                            trigger={trigger}/>
                </Form>
            </div>
        </ApplicationFlowLayout>
    );
};

export default BasicInfo;