import React, { FC } from 'react';
import { getIncentive } from '../../utils/incentives';
import './promotion-specific.scss';
import { IncentiveFeatures, ProductTypePrns, ConfigurationResponse, IncentiveResponse } from '../../types';
import { debuglog } from '../../utils/debug';


type PromotionSpecificProps = {
    className?: string | undefined,
    cardType?: string | undefined,  // current card type determines the product used
    config?: ConfigurationResponse | undefined, // contains the active list of products, incentives, and incentive features
    appliedIncentive?: IncentiveResponse | undefined, // if incentive is already applied, passes it in use that instead
    incentiveFeatureName?: string | undefined, // name of the incentive this instance of the component is representing    
                                               // TODO: Do Not assume that there is only ever one incentive to a product
    showIfPromoNotActive?: boolean | undefined,                                               
    setPromoValue?: (promoValue: string) => void,
    setPromoEndDate?: (promoEndDate: string) => void,    
    children: React.ReactNode
};

const PromotionSpecific: FC<PromotionSpecificProps> = ({className, cardType, incentiveFeatureName, config, appliedIncentive, showIfPromoNotActive, setPromoValue, setPromoEndDate, children}) => {     
    debuglog('');
    //debuglog('Promotion Specific:');
    
    debuglog('promotionSpecific incentiveFeatureName', incentiveFeatureName);
    debuglog('promotionSpecific show if NOT active:', showIfPromoNotActive);
    debuglog('promotionSpecific className', className);

    const getPrnFromCardType = (cardType: string | undefined) => {
        if (cardType?.toLowerCase() === 'basic') {
            return ProductTypePrns.Basic;
        } else if (cardType?.toLowerCase() === 'plus') {
            return ProductTypePrns.Plus;
        } else {
            return ProductTypePrns.Launch;
        }
    }
    const getFeatureName = (featureName: string | undefined) => {
        if (!featureName) {
            return IncentiveFeatures.None;
        }        
        debuglog(featureName +' featureName lookup',  IncentiveFeatures[featureName as keyof typeof IncentiveFeatures]);
        return IncentiveFeatures[featureName as keyof typeof IncentiveFeatures];
    }
    debuglog('appliedIncentive - promotion specific:', appliedIncentive);
    
    let incentive: IncentiveResponse | null = getIncentive(getFeatureName(incentiveFeatureName), getPrnFromCardType(cardType), config);
    debuglog('promotionSpecific-incentive-from-config:', incentive);    
    if (appliedIncentive && appliedIncentive.incentiveDetails) {
        let incentiveOverride = appliedIncentive?.incentiveDetails.find(activeIncentive => activeIncentive.incentiveTypeName === incentiveFeatureName 
            || activeIncentive.incentiveTypeName === IncentiveFeatures.None
            || activeIncentive.incentiveTypeName === IncentiveFeatures.Any) ?? null;
        if (incentiveOverride) {
                debuglog('applying incentive override from config: ', incentiveOverride);
            incentive = appliedIncentive;
        }
    }
    
  
    debuglog('promo-specific-class', className);
    debuglog('incentiveFeatureName', incentiveFeatureName);
    debuglog(`promotion specific card type (${cardType}):`, getPrnFromCardType(cardType));
    debuglog('incentive', incentive);

    if (!incentive && showIfPromoNotActive && !appliedIncentive) {
        debuglog('displaying NO incentive block');
        debuglog('End Promo Specific NONE ACTIVE, Show Content');
        return(<>    
            <div className={('promotion-specific-container ' + className)}>
                <div className="promotion-specific">
                    <>
                    {/*children({promoEndDate: promoEndDate, promoValue: promoValue})*/}
                    {children}
                    </>
                </div>
            </div>
        </>);     
    } else if (incentive && showIfPromoNotActive) {
        debuglog('promo is active on no promo section');
        debuglog('End Promo Specific - Promo is Active, Hide Content');
        return(<></>);
    }


    if (!incentiveFeatureName || !incentive) {
        debuglog('no promo');
        debuglog('End Promo Specific - No Promo Exists, Hide Content');
        return(<></>);
    }

    debuglog('Incentive Any:', incentive);
    debuglog('incentiveFeatureName', incentiveFeatureName);
    debuglog('Any:', IncentiveFeatures.Any);
    if (incentive && incentiveFeatureName === IncentiveFeatures.Any) {
        debuglog('displaying any incentive block');
        debuglog('End Promo Specific - ANY PROMO, Show Content');
        return(<>    
            <div className={('promotion-specific-container ' + className)}>
                <div className="promotion-specific">
                    <>
                    {/*children({promoEndDate: promoEndDate, promoValue: promoValue})*/}
                    {children}
                    </>
                </div>
            </div>
        </>);
    }
    

    // TODO: Remove assumption that there is only one incentive detail for a promotion.
    const incentiveDetail = incentive.incentiveDetails.find(id => id.incentiveTypeName === incentiveFeatureName) ??
                                    incentive?.incentiveDetails[0];
    //debuglog('promotionSpecific Incentive Returned', incentiveDetail);
    const promoEndDate = incentive.endDateUtc;
    
    const promoValue = incentiveDetail.value;

    if (setPromoValue) {
        setPromoValue(promoValue);
    }

    if (setPromoEndDate) {
        setPromoEndDate(promoEndDate);
    }

    debuglog('Returning incentive content for incentive', incentiveDetail);
    debuglog('End Promo Specific Standard Promo, Show Content');
    return(<>    
        <div className={('promotion-specific-container ' + className)}>
            <div className="promotion-specific" id={incentiveDetail.incentiveTypeName}>
                <>
                {/*children({promoEndDate: promoEndDate, promoValue: promoValue})*/}
                {children}
                </>
            </div>
        </div>
    </>);
};

export default PromotionSpecific;
