import React from 'react';
import { useHistory } from 'react-router-dom';
import { applicationApproved } from '../../assets/img';
import ApplicationInfoStatus from '../../components/application-info-status';
import { LandingPageLayout } from '../../layouts';
import { CardPerks } from '../../components';
import { useRecoilValue } from 'recoil';
import { decisionsResponseState, selectedApplicationIdState, applicationState } from '../../store/atoms';
import { routeHashes } from '../../app-routes';
import { ProductTypePrns } from '../../types';
import { debuglog } from '../../utils/debug';


const ApplicationApprovedWaitingForFunds = () => {
    const history = useHistory();
    const decisionsResponse = useRecoilValue(decisionsResponseState);
    const selectedApplicationId = useRecoilValue(selectedApplicationIdState); 
    const application = useRecoilValue(applicationState);

    const selectedApplication = decisionsResponse?.checkAppResponseList?.filter(app => app.applicationID === selectedApplicationId).shift();

    const getCardTypeByPrn = (prn: string | null | undefined) => {
        switch(prn){
            case ProductTypePrns.Plus:
                return 'plus';
            case ProductTypePrns.Launch:
                return 'launch';
            default:
                return 'basic';
        }
    }

    const cardType = getCardTypeByPrn(selectedApplication?.prn);

    debuglog('awaiting-for-funds->cardType', cardType);
    debuglog('awaiting-for-funds->application.cardType', application?.cardType);
    debuglog('app', application);
    debuglog('decisionResponse', decisionsResponse);
    debuglog('decisionResponse-IncentiveName', decisionsResponse?.checkAppResponseList?.find(app => !!app.incentiveName));
    debuglog('decisionResponse-IncentiveId', decisionsResponse?.checkAppResponseList?.find(app => !!app.incentiveId));
    //console.log('selectedApplication', selectedApplication);
    const incentiveActive = (selectedApplication?.incentiveName !== undefined && selectedApplication?.incentiveName !== '' && selectedApplication?.incentiveName !== null);

    return (
        <LandingPageLayout
            showDisclosures={false}
            showWithdrawal={true}
            showFDIC={true}
            showAlternateEligibleDisclosure={cardType === 'basic' && incentiveActive}
            showIncreaseLimitDisclosure={true}
            showRewardsDisclosure={true}
            promoRewardsDisclosureIndex="*"
            promoAlternateEligibleDisclosureIndex={incentiveActive && (cardType === 'basic') ? '**' : ''}
            promoIncreaseLimitDisclosureIndex={incentiveActive && (cardType === 'basic') ? '***' : '**'}
        >
            <ApplicationInfoStatus
                appStatusIcon={applicationApproved}
                appStatus="You're approved!"
                appPromo={true}
                appMessage="You're approved!"
                appMessageLine2="Fund your card now"
                appGradientMessage="Last chance to fund now!"
                appMessageAdditionalClasses="reduced"
                appGradientButton={true}
                appStatusType="approved"
                appDetailMessage=""
                appAdditionalContent={
                    <CardPerks
                        amount={selectedApplication?.responseObjects?.creditLimit ?? decisionsResponse?.creditLimit ?? 0}
                        activeIncentive={selectedApplication?.incentive}
                        cardType={cardType}
                    />
                }
                //promoFundButtons={true}
                onNext={() => history.push(routeHashes['/funding-payment'])}
                buttonText="Fund my card"
            />
        </LandingPageLayout>
    )
};

export default ApplicationApprovedWaitingForFunds;
