import { useMutation } from 'react-query';
import { microserviceClient } from '../../api-clients';
import { GetApplicationsStatusResponse, SubmitMiskeyRequest } from '../../types';

const submitMiskey = (payload: SubmitMiskeyRequest) =>
    microserviceClient.post<GetApplicationsStatusResponse>('/api/application/submitMiskey', payload);

export const useSubmitMiskey = () => {
    return useMutation(
        submitMiskey,
        {
            onError: (err, variables, context) => {
                console.log('Error submitting miskey data', err);
            },
        }
    );
};
