import React, { useState, useEffect } from 'react';
import './autocomplete.scss';
import { ValidatedInputProps } from '../../../types';
import { UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import FormErrorMessage from '../form-error-message';
import { Option } from '../../types';

type AutocompleteProps = Omit<ValidatedInputProps, 'onSelect'> & {
    options: Option[];
    label?: string;
    inputSize?: 'short' | 'long';
    className?: string;
    setValue?: UseFormSetValue<any>;
    onSelect?: (value: string) => void;
    trigger?: UseFormTrigger<any>;
}

const Autocomplete: React.FC<AutocompleteProps> = ({ 
    options,
    placeholder,
    label,
    inputSize,
    className,
    setValue,
    onChange,
    onBlur,
    onSelect,
    trigger,
    name,
    errors,
    value
}) => {
    const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
    const [isDropdownVisible, setDropdownVisible] = useState<boolean>(false);

    useEffect(() => {
        if (value) {
            const filtered = options.filter(option =>
                option.label.toLowerCase().includes(value.toString().toLowerCase())
            );
            setFilteredOptions(filtered);
        } else {
            setFilteredOptions([]);
        }
    }, [value, options]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (setValue) {
            setValue(name, event.target.value);
            trigger && trigger(name);
        }
        onChange && onChange(event);
        setDropdownVisible(true);
    };

    const handleSelect = (option: Option) => {
        if (setValue) {
            setValue(name, option.value);
            trigger && trigger(name);
        }
        onSelect && onSelect(option.label);
        setDropdownVisible(false);
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
        if (setValue) {
            setValue(name, event.target.value);
            trigger && trigger(name);
        }
        onBlur && onBlur(event);
        trigger && trigger(name);
        setDropdownVisible(false);
    };

    const getInputClassName = () => {
        if (className) {
            return className;
        } else {
            return inputSize === 'short' ? 'short-input' : 'standard-input';
        }
    };

    return (
        <div className={inputSize === 'short' ? 'flex-input-no-shrink' : 'flex-input'}>
            <label className='label'>{label}</label>
            <input
                type="text"
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={placeholder}
                className={getInputClassName()}
            />
            {isDropdownVisible && filteredOptions.length > 0 && (
                <ul className={inputSize === 'short' ? 'autocomplete-options-short' : 'autocomplete-options-standard'}>
                    {filteredOptions.map(option => (
                        <li
                            key={option.value}
                            onMouseDown={() => handleSelect(option)}
                            className="autocomplete-option"
                        >
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
            {errors && errors[name] && <FormErrorMessage errors={errors} name={name} />}
        </div>
    );
};

export default Autocomplete;