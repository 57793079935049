import React from 'react';
import { applicationEmail, applicationProcessing } from '../../assets/img';
import ApplicationInfoStatus from '../../components/application-info-status';
import { LandingPageLayout } from '../../layouts';

const ApplicationManualReview = () => {
    return (
        <LandingPageLayout showWithdrawal>
            <ApplicationInfoStatus
                appIcon={applicationEmail}
                appStatus="Your application is being processed"
                appMessage="Look for emails"
                appMessageLine2="from OpenSky"
                appStatusType="info"
                appStatusIcon={applicationProcessing}
                appDetailMessage="We will email you updates with additional information regarding your application status."
                appNotificationMessage={
                    <>
                        <p>
                            <b>Thank you for applying to OpenSky!</b>
                        </p>
                        <p>
                            Your application is a priority to us and currently being
                            processed. You will receive an email from OpenSky once
                            our review is complete.
                        </p>
                    </>
                }
                onNext={() => window.location.href = `${process.env.REACT_APP_HOMEPAGE_URL}`}
                buttonText="Ok, Got it"
            />
        </LandingPageLayout>
    );
};

export default ApplicationManualReview;
