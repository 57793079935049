import { rest } from 'msw';


export const postProductUpdate = [
    rest.post(`${process.env.REACT_APP_DEFAULT_API_BASE_URL}/api/application/lead/:productId/updateProduct`, async (req, res, ctx) => {        
        await new Promise(r => setTimeout(r, 1000));
        return res(
            ctx.status(200),
        );
    }),
];