import { rest } from "msw";
import { SendCheckAppStatusOtpRequest, SendCheckAppStatusOtpResponse } from "../types";

export const sendCheckAppStatusOtp = [
    rest.post(
        `${process.env.REACT_APP_DEFAULT_API_BASE_URL}/api/security/sendCheckAppStatusOtp`,
        async (req, res, ctx) => {
            const json = (await req.json()) as SendCheckAppStatusOtpRequest;
            await new Promise(r => setTimeout(r, 3000));
            console.log("payload", json);

            if (json.emailAddress === "throwerror@test.com") {
                return res(ctx.status(404));
            }

            return res(
                ctx.status(200),
                ctx.json({
                    emailAddress: json.emailAddress,
                    otpMethod: "Mobile",
                    primaryPhone: "5555551212"
                } as SendCheckAppStatusOtpResponse)
            );
        }
    )
];
