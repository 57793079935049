import React from 'react';
import { applicationClosed, applicationRefund } from '../../assets/img';
import ApplicationInfoStatus from '../../components/application-info-status';
import { LandingPageLayout } from '../../layouts';

const ApplicationWithdrawn = () => {
    return (
        <LandingPageLayout>
            <ApplicationInfoStatus
                appIcon={applicationRefund}
                appStatus="Your application has been closed"
                appMessage="Any funds provided for your deposit will be returned"
                appStatusType="closed"
                appStatusIcon={applicationClosed}
                appDetailMessage="If you provided a security deposit, a refund for the full amount will be processed within 5-10 business days."
                appNotificationMessage={
                    <>
                        <p>
                            <b>We're sorry to see you go!</b>
                        </p>
                        <p>
                            Your request to withdraw your application has been
                            received and your application has been withdrawn.
                        </p>
                        <p>
                            <h6>
                                If you made your security deposit via debit card:
                            </h6>
                            <p>
                                The full refund of your deposit will be returned to
                                your account in as little as 5 business days.
                            </p>
                        </p>
                        <p>
                            <h6>If you paid by any other means:</h6>
                            <p>
                                Your refund will be sent via Standard U.S. Mail. You
                                should receive it within 10 business days from when
                                we processed your request to withdraw your
                                application. (Please refer to your local United
                                States Postal Service timelines for delivery
                                schedules).
                            </p>
                        </p>
                    </>
                }
                onNext={() => window.location.href = `${process.env.REACT_APP_HOMEPAGE_URL}`}
                buttonText="Ok, Got it"
            />
        </LandingPageLayout>
    );
};

export default ApplicationWithdrawn;
