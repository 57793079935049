import { useQuery } from "react-query";
import { microserviceClient } from '../../api-clients';
import { queryKeys } from '../../utils/constants';

type CalculateIncomeDto = {
    monthlyHousingPayment: number,
};

type CalculatedIncomeDto = {
    calculatedIncome: number,
};

const calculateIncome = async (monthlyHousingPayment?: number): Promise<number> => {
    const res = await microserviceClient.post<CalculatedIncomeDto>('/api/application/calculateIncome', {
        monthlyHousingPayment
    } as CalculateIncomeDto);
    
    return res.data.calculatedIncome;
};

export const useCalculateIncome = (monthlyHousingPayment?: number) => {
    return useQuery<number, Error>(
        [...queryKeys.calculateIncome, monthlyHousingPayment],
        () => calculateIncome(monthlyHousingPayment),
        {
            staleTime: Infinity,
            cacheTime: Infinity,
            retry: false,
            enabled: !!monthlyHousingPayment,
        }
    );
};
