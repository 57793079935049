import React from "react";
import { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import { LandingPageLayout } from "../../layouts";
import { SelectDropdown } from "../../components";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { selectedApplicationIdState } from "../../store/atoms";
import { useWithdrawApplication } from "./hooks";
import { WithdrawApplicationRequest } from "../../types";
import { routeHashes } from "../../app-routes";

type WithdrawApplicationData = {
    reason: string;
};

const reasonsForWithdrawal = [
    "No longer interested",
    "Unable to fund at this time",
    "Received another offer",
    "Process too long",
    "Cannot provide documents requested",
    "Duplicate/another application present",
    "Advised to withdraw",
    "Other"
];

const WithdrawApplication: React.FC = () => {
    const [debounceActive, setDebounceActive] = useState(false);
    const applicationId = useRecoilValue(selectedApplicationIdState);
    const history = useHistory();
    const withdrawApplicationMutation = useWithdrawApplication();

    const validationSchema = Yup.object().shape({
        reason: Yup.string().required("A reason must be selected")
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const {
        register,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm<WithdrawApplicationData>({
        ...formOptions,
        defaultValues: validationSchema.getDefault(),
        mode: "all"
    });

    const onSubmit = (event: WithdrawApplicationData) => {
        // given that the button is disabled when debounceActive this is unreachable
        // istanbul ignore if
        if (debounceActive) return;

        setDebounceActive(true);
        const payload: WithdrawApplicationRequest = {
            applicationId: applicationId!,
            withdrawReason: event.reason
        };
        withdrawApplicationMutation.mutate(payload, {
            onSuccess: response => {
                history.push(routeHashes["/app-closed-withdrawn"]);
            },
            onError: response => {
                setDebounceActive(false);
            }
        });
    };

    return (
        <LandingPageLayout>
            <Row>
                <Col>
                    <h1>Withdraw your application</h1>
                    <p>Please let us know the reason why you are withdrawing your OpenSky application.</p>
                </Col>
            </Row>
            <Row className="withdraw-application">
                <Col>
                    <SelectDropdown
                        label="Reason for Withdrawal"
                        name="reason"
                        register={register}
                        options={reasonsForWithdrawal.map(reason => ({
                            value: reason
                        }))}
                        errors={errors}
                    />
                </Col>
            </Row>
            <Row className="row mt-36">
                <Col className="col text-center">
                    <Form.Group>
                        <Button disabled={!isValid || debounceActive} onClick={handleSubmit(onSubmit)}>
                            Withdraw Application
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
        </LandingPageLayout>
    );
};

export default WithdrawApplication;
