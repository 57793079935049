import { setupWorker } from 'msw';
import { calculateIncome } from './calculate-income';
import { createLead } from './create-lead';
import { getApplicationsStatus, getApplicationsPaymentStatus } from './get-applications-status';
import { makePayment } from './make-payment';
import { resendOtp } from './resend-otp';
import { sendCheckAppStatusOtp } from './send-check-app-status-otp';
import { startSession } from './start-session';
import { submitApplication } from './submit-application';
import { submitMiskey } from './submit-miskey';
import { uploadDocuments } from './upload-documents';
import { verifyOtp } from './verify-otp';
import { withdrawApplication } from './withdraw-application';
import { configuration } from './configuration';
import { postProductUpdate } from './update-product';

export const worker = setupWorker(
    ...[
        ...calculateIncome,
        ...createLead,
        ...getApplicationsStatus,
        ...getApplicationsPaymentStatus,
        ...makePayment,
        ...resendOtp,
        ...sendCheckAppStatusOtp,
        ...startSession,
        ...submitApplication,
        ...submitMiskey,
        ...uploadDocuments,
        ...verifyOtp,
        ...withdrawApplication,
        ...configuration,
        ...postProductUpdate,
    ]
);
