import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ApplicationFlowLayout } from '../../layouts';
import { applicationState, configurationResponseState, gaDataLayerState } from '../../store/atoms';
import { isIncentiveFeatureActive, getPrn } from '../../utils/incentives';
import {monthNames} from '../../utils/dates';
import { pushToDataLayer } from '../../utils/analytics';
import { IncentiveFeatures } from '../../types';
import IdentityVerificationHelp from './identity-verification-help';
import { iconLockGray } from '../../assets/img';
import { routeHashes } from '../../app-routes';
import './identity.scss';
import * as FullStory from '@fullstory/browser';

const IdentityConfirm = () => {
    const history = useHistory();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [application, setApplication] = useRecoilState(applicationState);
    const configurationResponse = useRecoilValue(configurationResponseState);
    let [gaDataLayer, setGADataLayer] = useRecoilState(gaDataLayerState);
    const ordinal = (value: string) => {
        const ordinalRules = new Intl.PluralRules('en', {
            type: 'ordinal',
        });
        const suffixes: any = {
            one: 'st',
            two: 'nd',
            few: 'rd',
            other: 'th',
        };
        const suffix = suffixes[ordinalRules.select(Number(value))];
        return value + suffix;
    };

    const onGood = async () => {
        switch (application?.cardType) {
            case "basic": {
                FullStory.event('ConfirmPIILooksGood', { leadId: application.leadId });
                break;
            }
            case "plus": {
                FullStory.event('Plus_ConfirmPIILooksGood', { leadId: application.leadId });
                break;
            }
            case "launch": {
                FullStory.event('Launch_ConfirmPIILooksGood', { leadId: application.leadId });
                break;
            }
            default: {
                FullStory.event('ConfirmPIILooksGood', { leadId: application.leadId });
                break;
            }
        }

        const updateDataLayer = {
            ...gaDataLayer,
            'step_name': 'ID Confirm',
        };
        setGADataLayer(updateDataLayer);
        pushToDataLayer("application_step_5", updateDataLayer);
        history.push(routeHashes['/mailing-address']);
    };

    const onNeedsChange = async () => {
        switch (application?.cardType) {
            case "basic": {
                FullStory.event('ConfirmPIIChange', { leadId: application.leadId });
                break;
            }
            case "plus": {
                FullStory.event('Plus_ConfirmPIIChange', { leadId: application.leadId });
                break;
            }
            case "launch": {
                FullStory.event('Launch_ConfirmPIIChange', { leadId: application.leadId });
                break;
            }
            default: {
                FullStory.event('ConfirmPIIChange', { leadId: application.leadId });
                break;
            }
        }
        history.push(routeHashes['/identity-verification']);
    };      
    return (
        <ApplicationFlowLayout
            showBackButton
            HelpComponent={IdentityVerificationHelp}
            progress="60%"
            cardType={application.cardType}
            currentStep="6"
            promo={isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn(application.cardType), configurationResponse)}
            showBasicInfoFooter={true}
        >          
            <Row className="fs-exclude">
                <Col>
                    <div id="header">
                        <h1>Does everything look good?</h1>
                        <p className="subtitle">
                            Please double check this information, we use this to
                            report your credit history to all three credit bureaus.
                        </p>
                    </div>
                    <div className="content-container">
                        <div>
                            <span className="header top-header">Name</span>
                            <span className="text">
                                {application.firstName} {application.lastName}
                            </span>
                        </div>
                        <div>
                            <span className="header">Birthday</span>
                            <span className="text">
                                {monthNames[application.dateOfBirth?.getMonth() ?? 0]}{' '}
                                {ordinal(application.dateOfBirth?.getDate()?.toString() || '')},{' '}
                                {application.dateOfBirth?.getFullYear()}
                                <img
                                    alt="error"
                                    src={iconLockGray}
                                    role="presentation"
                                    className="icon-lock-gray"
                                />
                            </span>
                        </div>
                        <div>
                            <span className="header">
                                Social Security (SSN or ITIN) Number
                            </span>
                            <span className="text">
                                {' '}
                                {application.ssn?.toString().padStart(9,'0').replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3')}
                                <img
                                    alt="error"
                                    src={iconLockGray}
                                    role="presentation"
                                    className="icon-lock-gray"
                                />
                            </span>
                        </div>
                        <div>
                            <span className="header">Home Address</span>
                            <span className="text">
                                {application.homeAddress?.addressLine1}
                            </span>
                            {application.homeAddress?.addressLine2 ? (
                                <span className="text">
                                    {application.homeAddress?.addressLine2}
                                </span>
                            ) : (
                                <span></span>
                            )}
                            <span className="text">
                                {application.homeAddress?.city},{' '}
                                {application.homeAddress?.state}{' '}
                                {application.homeAddress?.zipCode}
                            </span>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <div className="text-center">
                    <Button onClick={(evnt) => onGood()}>Looks Good</Button>
                </div>
                <div className="text-center" style={{ paddingTop: '20px' }}>
                    <Button
                        variant="outline-dark"
                        onClick={(evnt) => onNeedsChange()}
                    >
                        I need to make a change
                    </Button>
                </div>
            </Row>           
        </ApplicationFlowLayout>
    );
};

export default IdentityConfirm;
