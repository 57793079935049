import { useQuery } from 'react-query';
import { smartyAutocompleteClient } from '../../../api-clients';
import { debuglog } from '../../../utils/debug';
import { queryKeys } from '../../../utils/constants';
import { AxiosError } from 'axios';
import { SmartyApiErrorResponses } from '../../../types';
import { apiErrors } from '../../utils/constants';
import { addressData } from '../../../mocks/address-lookup';
import { Option } from '../../types';

type Address = {
    street_line: string;
    secondary: string;
    city: string;
    state: string;
    zipcode: string;
    entries: number;
};

type AddressLookupData = {
    suggestions: Address[];
};

const smartyApiKey = process.env.REACT_APP_SMARTY_API_KEY;

const baseUrl = window.location.origin;
const isLowerEnv =
    baseUrl.includes('localhost') ||
    baseUrl.includes('uat') ||
    baseUrl.includes('dev');

const fetchAddressLookup = async (
    search?: string
): Promise<{ data?: AddressLookupData; error?: any }> => {
    debuglog('fetching address...');
    try {
        const res = await smartyAutocompleteClient.get<AddressLookupData>(
            `/lookup?key=${smartyApiKey}&search=${search}`
        );
        debuglog('res', res);

        const suggestions = res.data.suggestions;
        return { data: { suggestions } };
    } catch (error) {
        // Log the error and return it instead of throwing
        debuglog('Error fetching address:', error);
        return { error };
    }
};

const getAddressOptions = async (search?: string) => {
    const result = await fetchAddressLookup(search);
    if (isLowerEnv && result.error) {
        const axiosErrorResponseData = (result.error as AxiosError)?.response
            ?.data as SmartyApiErrorResponses;

        // Only for testing autocomplete functionality in lower envs.
        // Use mock addresses due to embedded key authentication not allowed from VDIs.
        if (
            axiosErrorResponseData.errors[0].message.includes(
                apiErrors.smartyEmbeddedKeyAuthNotAllowedError
            )
        ) {
            const matches = search
                ? addressData.filter((x) =>
                      x.toUpperCase().includes(search.toUpperCase())
                  )
                : addressData;

            return matches.map((uniqueAddress) => ({
                label: uniqueAddress,
                value: uniqueAddress.split(',')[0],
            }));
        } else {
            return [];
        }
    } else {
        return Array.from(
            new Set(
                (result.data?.suggestions || []).map(
                    (value) =>
                        `${value.street_line}, ${value.city}, ${value.state} ${value.zipcode}`
                )
            )
        ).map((uniqueAddress) => ({
            label: uniqueAddress,
            value: uniqueAddress.split(',')[0],
        }));
    }
};

export const useAddressLookup = (search?: string, validPattern?: RegExp) => {
    let keepPreviousData;
    if (search && validPattern && !validPattern.test(search)) {
        search = '';
        keepPreviousData = false;
    } else {
        keepPreviousData = true;
    }

    return useQuery<Option[], Error>(
        [...queryKeys.addressLookup, search],
        () => getAddressOptions(search),
        {
            staleTime: Infinity,
            cacheTime: Infinity,
            retry: false,
            keepPreviousData: keepPreviousData,
            enabled: !!search,
        }
    );
};
