import { useMutation, useQuery } from 'react-query';
import { microserviceClient } from '../../api-clients';
import { MakePaymentRequest, MakePaymentResponse, GetApplicationsStatusResponse } from '../../types';
import { queryKeys } from '../../utils/constants';


export type MakePaymentArgs = {
    applicationId: string;
    payload: MakePaymentRequest;
};

const makePayment = ({ applicationId, payload }: MakePaymentArgs) => 
    microserviceClient.post<MakePaymentResponse>(`/api/application/${applicationId}/makePayment`, payload);

export const useMakePayment = () => {
    return useMutation(
        makePayment,
        {
            onError: (err, variables, context) => {
                console.log('Error submitting payment', err);
            },
        }
    );
};

// Due to an error in Decisions a new endpoint was created to specificially get the application payment history
// This follows the same pattern as the traditional getApplicationStatus however.
const getApplicationsPaymentStatus = async (applicationId: string): Promise<GetApplicationsStatusResponse> => {
    const response = await microserviceClient.get<GetApplicationsStatusResponse>(`/api/application/${applicationId}/getApplicationPaymentStatus`);

    return response.data;
};

export const useGetApplicationsPaymentStatus = (applicationId?: string) => {
    return useQuery<GetApplicationsStatusResponse, Error>(
        [...queryKeys.getApplicationPaymentStatus],
        () => getApplicationsPaymentStatus(applicationId!),
        {
            staleTime: Infinity,
            cacheTime: Infinity,
            retry: false,
            enabled: !!applicationId,
        }
    )
}

