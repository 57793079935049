import React from "react";
import * as Yup from 'yup';
import Modal from "../modal";
import Subheadline from "../subheadline";
import { phoneAction } from "../../../assets/img";
import Form from "../form";
import NumberFormatInput from "../number-format-input";
import { validationErrors } from "../../utils/constants";
import { useRecoilState } from "recoil";
import { applicationState } from "../../../store/atoms";
import { formatRawPhoneNumber, sanitizePhoneNumber } from "../../../utils/phone-numbers";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { PhoneVerificationModel } from "../../types";
import { ApplicationModel } from "../../../types";

interface PhoneModalProps {
    onClose: () => void;
}

const PhoneModal: React.FC<PhoneModalProps> = ({onClose}) => {
    const [application, setApplication] = useRecoilState(applicationState);
    
    const validationSchema = Yup.object().shape({
        primaryPhone: Yup.string()
            .matches(/^\(\d{3}\) \d{3}-\d{4}$/, validationErrors.invalidPhoneNumberError)
            .required(validationErrors.phoneIsRequiredError)
            .default(() => application.primaryPhone && formatRawPhoneNumber(application.primaryPhone))
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const {
        handleSubmit,
        register,
        watch,
        formState: { errors, isValid },
    } = useForm<PhoneVerificationModel>({
        ...formOptions,
        defaultValues: validationSchema.getDefault(),
        mode: 'all',
    });

    const primaryPhone = watch('primaryPhone');

    const onSubmit = async (event: PhoneVerificationModel) => {
        event.primaryPhone = event.primaryPhone ? sanitizePhoneNumber(event.primaryPhone) : '';

        setApplication((current: ApplicationModel) => ({
            ...current,
            ...event
        }));

        onClose();
    };

    return (
        <Modal onClose={onClose}>
            <Subheadline
                headline="Verify your phone number"
                smallText="This helps us verify you’re you, and will allow you to check on your application status later."
                headlineIcon={phoneAction} />
            <Form submitText="Save" onSubmit={handleSubmit(onSubmit)} isSubmitDisabled={!isValid}>
                <NumberFormatInput 
                format="(###) ###-####" 
                name="primaryPhone" 
                label="Phone number" 
                value={primaryPhone} 
                register={register}
                type="tel" 
                inputSize="long"
                errors={errors}/>
                <div className="bottom-spacing-230" />
            </Form>
        </Modal>);
}
 
export default PhoneModal;