import React from 'react';
import { HelpTray } from '../../components';

const CreditLineHelp = () => {
    return (
        <HelpTray>
            <HelpTray.Header>
                Credit Line Help
            </HelpTray.Header>
            <HelpTray.Section>
                <HelpTray.SectionText>
                    Your credit line is determined by your security deposit. This amount determines how much you are 
                    able to spend with your OpenSky Secured Visa Credit Card.
                </HelpTray.SectionText>
            </HelpTray.Section>
        </HelpTray>
    );
}

export default CreditLineHelp;
