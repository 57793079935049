import React from 'react';
import { HelpTray } from '../../components';

const IdentityVerificationHelp = () => {
    return (
        <HelpTray>
            <HelpTray.Header>
                Identity Verification Help
            </HelpTray.Header>
            <HelpTray.Section>
                <HelpTray.SectionTitle>
                    Federal law requires us to obtain, verify, and record information that 
                    identifies each person who opens an account.
                </HelpTray.SectionTitle>
            </HelpTray.Section>
            <HelpTray.Section>
                <HelpTray.SectionTitle>Social Security Number (SSN or ITIN)</HelpTray.SectionTitle>
                <HelpTray.SectionText>
                    Your Social Security Number is required and used for identification purposes.
                </HelpTray.SectionText>
            </HelpTray.Section>
            <HelpTray.Section>
                <HelpTray.SectionTitle>Home Address</HelpTray.SectionTitle>
                <HelpTray.SectionText>
                    In order to help the government fight the funding of terrorism and money laundering 
                    activities, your home street address is required for identification purposes.
                </HelpTray.SectionText>
            </HelpTray.Section>
        </HelpTray>
    );
}

export default IdentityVerificationHelp;
