import React, { VFC } from 'react'
import { useBlockCode } from './hooks';
import { UseBlockCodeProps } from './types';
import './block-code.scss'

export interface BlockCodeProps extends UseBlockCodeProps {
  variant?: 'default' | 'circular' | 'underline'
}

export const BlockCode: VFC<BlockCodeProps> = props => {
  const { variant = '', ...restProps } = props
  const { ids, onCreateInputProps } = useBlockCode(restProps)

  return (
    <div className={`row validation-code ${variant}`}>
      {ids.map((id, index) => (
        <div className="col" key={`col-${id}`}>
          <input key={id} {...onCreateInputProps(index)} />
        </div>
      ))}
    </div>
  )
}