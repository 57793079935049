import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import ProcessApplication from '../process-application';
import {
    selectedApplicationIdState,
    applicationState,
    decisionsResponseState,
    responseCodeState,
    gaDataLayerState
} from '../../store/atoms';
import { useProcessResponseCode } from '../../hooks';
import { useSubmitApplication } from './hooks';
import { ErrorPageProps, ResponseCodes, SubmitLeadRequest, YesNo } from '../../types';
import { checkForUnauthorized, routeHashes } from '../../app-routes';
import { pushToDataLayer } from '../../utils/analytics';

const SubmitApplication = () => {
    const [, setResponseCode] = useRecoilState(responseCodeState);
    const application = useRecoilValue(applicationState);
    const [, setApplicationId] = useRecoilState(selectedApplicationIdState);
    const [, setDecisionsResponse] = useRecoilState(decisionsResponseState);
    const [responseCodeToProcess, setResponseCodeToProcess] = useState<string>();
    const [gaDataLayer, setGaDataLayer] = useRecoilState(gaDataLayerState);

    const history = useHistory();
    const submitApplicationMutation = useSubmitApplication();

    // process response code and redirect
    useProcessResponseCode(responseCodeToProcess);

    const ifFundToday = (value: any) => application.cardNumber ? value : null;

    // const incentiveIs10PercentOffFullyFunded = (value: any) => {
    //     if (application.incentiveName === 'TenPercentOffFullyFunded') {
    //         if (value && !isNaN(value)) {
    //             return value - (value * 0.10);
    //         }
    //     }
    //     return value;
    // };

    const updateDataLayerAndPush = (responseCode: ResponseCodes) => {
        const updateDataLayer = {
            ...gaDataLayer,
            'step_name': 'App Funded',
            'card_fund_result': responseCode === ResponseCodes.ApplicationInGoodFunds ? 'immediate' : 'see_email'
        }
    
        setGaDataLayer(updateDataLayer);
    
        pushToDataLayer('application_step_9', updateDataLayer);
    }

    useEffect(() => {
        const month = (application.dateOfBirth?.getMonth() ?? 0) + 1;
        const dobMonth = month.toString().padStart(2, '0');
        const dobDay = application.dateOfBirth?.getDate()?.toString()?.padStart(2, '0');
        const payload: SubmitLeadRequest = {
            instanceId: application.instanceId,
            id: application.leadId!,
            isEmailVerified: !application.phoneConsent, // TODO: *** this is supposed to come from createLead response? ***
            applicationId: null,
            ignoreEmailVerificationErrors: true, // TODO: *** what is this? ***
            submitPayment: true, // TODO: *** what is this? ***  // ** BRIAN
            ignorePaymentErrors: false, // TODO: *** what is this? ***  // ** BRIAN
            incentiveId: application.incentiveId ?? null,
            incentiveName: application.incentiveName ?? null,
            downsellFrom: application.downsellFrom,
            basicInfo: {
                firstName: application.firstName!,
                lastName: application.lastName!,
                emailAddress: application.emailAddress!,
                primaryPhone: application.primaryPhone!,
                primaryPhoneType: application.primaryPhoneType!,
                phoneConsent: application.phoneConsent!,
            },
            dti: {
                monthlyHousingPayment: application.housingPayment!,
                income: application.annualIncome!,
                minimumEligibility: application.minimumIncome === YesNo.Yes, // Will always be false since we removed the value from front end.
                incomeConfirmation: application.annualIncomeValidated,
            },
            creditLine: {
                creditLimit: application.amount!,
            },
            payment: {
                debitCardNumber: ifFundToday(application.cardNumber),
                debitCvv2CvcCode: ifFundToday(application.cardSecurityCode),
                debitExpirationMonth: ifFundToday(application.cardExpiration?.slice(0, 2)),
                debitExpirationYear: ifFundToday(`20${application.cardExpiration?.slice(2)}`),
                debitAmount: ifFundToday(application.amount),
                debitFirstName: ifFundToday(application.cardHolderFirstName),
                debitLastName: ifFundToday(application.cardHolderLastName),
                attemptNumber: 0,
                fundingType: application.cardNumber ? 'DBT' : 'FDL',
                isFullyFunded: ifFundToday(true) ?? false,
                debitTransId: null,
                debitAuthCode: null,
                debitAuthorizedFlag: null,
                debitSettleFlag: null,
                debitAuthApprovedAmount: null,
                debitAuthorizationCode: null,
                debitAuthRefTransId: null,
                debitAuthRequestedAmount: null,
                debitAvsResponse: null,
                debitCavResponse: null,
            },
            terms: {
                esignConsent: application.eSignConsent!,
                termsConsent: application.termsAndConditions!,
            },
            mailToType: application.mailToType!,
            mailingAddress: application.mailingAddress ? {
                mailingAddress: application.mailingAddress?.addressLine1!,
                mailingAptSuite: application.mailingAddress?.addressLine2 ?? null,
                mailingCity: application.mailingAddress?.city!,
                mailingState: application.mailingAddress?.state!,
                mailingZip: application.mailingAddress?.zipCode!,
            } : null,
            homeAddress: {
                homeAddress: application.homeAddress?.addressLine1!,
                aptSuite: application.homeAddress?.addressLine2 ?? null,
                city: application.homeAddress?.city!,
                state: application.homeAddress?.state!,
                zipCode: application.homeAddress?.zipCode!,
            },
            billingAddress: {
                debitFirstName: application.firstName!,
                debitLastName: application.lastName!,
                debitAddress: application.bankingAddress?.addressLine1!,
                debitAptSuite: application.bankingAddress?.addressLine2 ?? null,
                debitCity: application.bankingAddress?.city!,
                debitState: application.bankingAddress?.state!,
                debitZip: application.bankingAddress?.zipCode!,
            },
            pii: {
                ssn: application.ssn?.toString().padStart(9, '0')!,
                dateOfBirth: `${dobMonth}/${dobDay}/${application.dateOfBirth?.getFullYear()}`,
            },
        };

        submitApplicationMutation.mutate(payload, {
            onSuccess: (response) => {
                setApplicationId(response.data.applicationId);
                setDecisionsResponse(response.data.done);
                setResponseCode(response.data.responseCode);
                setResponseCodeToProcess(response.data.responseCode);
                updateDataLayerAndPush(response.data.responseCode as ResponseCodes);
            },
            onError: (error: any) => {
                if (error?.response?.data?.responseCode) {
                    // some error responses will still have a response with responseCode
                    setResponseCode(error.response.data.responseCode);
                    setResponseCodeToProcess(error.response.data.responseCode);
                    updateDataLayerAndPush(error.response.data.responseCode as ResponseCodes);
                } else {
                    if (!checkForUnauthorized(error, history)) {
                        // no responseCode found, so show generic error page with option to try again
                        if (error?.response?.status === 400) {
                            history.replace(routeHashes['/error'], {
                                title: 'Oops, something went wrong processing your application.',
                                buttonText: 'Ok, Got it',
                                navigateTo: `${process.env.REACT_APP_HOMEPAGE_URL}`,
                            } as ErrorPageProps);
                        } else {
                            history.replace(routeHashes['/error'], {
                                title: 'Oops, something went wrong processing your application.',
                                buttonText: 'Submit again',
                                navigateTo: routeHashes['/submit-application'],
                            } as ErrorPageProps);
                        }
                    }
                }
            },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application]);
    
    return (
        <ProcessApplication />
    );
};
export default SubmitApplication;