import React from 'react';
import ApplicationFlowLayout from '../../layouts/application-flow';
import { useRecoilValue } from 'recoil';
import { applicationState, emailAddressState } from '../../../store/atoms';
import { CardTypes } from '../../../utils/constants';
import '../../scss/main.scss';
import { PageLink, ShippingStatus } from '../../components';
import { mailboxIn } from '../../../assets/img';
import './review-card.scss';
import { selectedApplicationResponse } from '../../../store/selectors';
import { ReviewCardProps } from '../../types';

const ReviewCard : React.FC<ReviewCardProps> = ({isBoarded}) => {
    const application = useRecoilValue(applicationState);
    const selectedApplication = useRecoilValue(selectedApplicationResponse);
    const emailAddress = useRecoilValue(emailAddressState);

    const shippingAddressText = `${
        application.firstName ?? selectedApplication?.firstName
    }  ${application.lastName ?? selectedApplication?.lastName}<br/>
                                 ${
                                     application.homeAddress?.addressLine1 ??
                                     selectedApplication?.homeAddress
                                 }<br/>
                                 ${
                                     application.homeAddress?.city ??
                                     selectedApplication?.city
                                 }, ${
        application.homeAddress?.state ?? selectedApplication?.state
    } ${application.homeAddress?.zipCode ?? selectedApplication?.zipCode}`;

    const handleCardBenefitsButtonClick = () => {
        window.location.href = 'https://www.openskycc.com/know-your-card';
    };

    return (
        <ApplicationFlowLayout
            cardType={application.cardType as CardTypes}
            displayVisaLogo
            hideNavigation
            hideProgress
        >
            <div className="main-container-flex-start">
                <div className="review-card-subheading">
                    <h1 className="review-card-header">
                        Your card is being made!
                    </h1>
                    <p className="review-card-paragraph">
                        We’ve got your deposit and will start creating
                        <br /> your card. You’ll receive an email at <br />
                        <span className="review-card-span">
                            {application.emailAddress ?? emailAddress}
                        </span>{' '}
                        from OpenSky letting you know when to look out for your
                        new card.
                    </p>
                </div>
                <PageLink
                    icon={mailboxIn}
                    title="Shipping address"
                    smallText={shippingAddressText}
                    hideButton={true}
                />
                <ShippingStatus shipDateEstimated={isBoarded} />
            </div>
            <div className="review-card-footer">
                <button
                    className="button"
                    onClick={handleCardBenefitsButtonClick}
                >
                    See my card benefits
                </button>
            </div>
        </ApplicationFlowLayout>
    );
};

export default ReviewCard;
