import React from "react";
import ApplicationFlowLayout from "../../layouts/application-flow";
import { useRecoilState } from "recoil";
import { applicationState, gaDataLayerState } from "../../../store/atoms";
import { CardTypes } from "../../../utils/constants";
import { Form, Modal, Subheadline } from "../../components";
import { mailboxIn } from "../../../assets/img";
import "./address.scss";
import "../../scss/main.scss";
import AddressForm from "../../components/address-form";
import { Address } from "../../../types";
import { useHistory } from "react-router";
import { routeHashes } from '../../../app-routes';
import { isNotPOBoxPattern, noSpecialCharactersPattern, zipCodePattern } from "../../../utils/validations";
import * as Yup from 'yup';
import { validationErrors } from "../../utils/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { pushToDataLayer } from "../../../utils/analytics";

interface AddressPageProps {
    isModal?: boolean;
    onModalClose?: () => void;
}

const AddressPage: React.FC<AddressPageProps> = ({ isModal, onModalClose }) => {
    const history = useHistory();
    const [application, setApplication] = useRecoilState(applicationState);
    const [gaDataLayer, setGADataLayer] = useRecoilState(gaDataLayerState);

    const validationSchema = Yup.object().shape({
        addressLine1: Yup.string()
            .required(validationErrors.streetAddressRequiredError)
            .max(26, validationErrors.streetAddressMaxCharactersError)
            .matches(noSpecialCharactersPattern, validationErrors.invalidCharactersError)
            .matches(isNotPOBoxPattern, validationErrors.poBoxesNotAllowedError)
            .test(
                'address',
                validationErrors.invalidPrimaryAddressError,
                (value) => value !== ''
            )
            .default(() => application.homeAddress?.addressLine1 || ''),
        city: Yup.string()
            .required(validationErrors.cityIsRequiredError)
            .max(18, validationErrors.cityMaxCharactersError)
            .matches(noSpecialCharactersPattern, validationErrors.invalidCharactersError)
            .test('city', validationErrors.cityIsRequiredError, (value) => value !== '')
            .default(() => application.homeAddress?.city || ''),
        state: Yup.string()
            .required(validationErrors.stateIsRequiredError)
            .test('state', validationErrors.stateIsRequiredError, (value) => value !== '')
            .default(() => application.homeAddress?.state || ''),
        zipCode: Yup.string()
            .required(validationErrors.zipCodeIsRequiredError)
            .min(5, validationErrors.zipCodeIsRequiredError)
            .max(10, validationErrors.zipCodeIsRequiredError)
            .test(
                'zipcode',
                validationErrors.zipCodeIsRequiredError,
                (value) => value !== ''
            )
            .matches(zipCodePattern, validationErrors.zipCodeFormatError)
            .test('first-five-valid-range', validationErrors.zipCodeFormatError,
                (value) => {
                    let zipCodeValue = parseInt((value ?? '0').substring(0, 5));
                    return zipCodeValue > 500 && zipCodeValue < 99951;
                })
            .default(() => application.homeAddress?.zipCode || ''),
        addressLine2: Yup.string()
            .matches(noSpecialCharactersPattern, validationErrors.invalidCharactersError)
            .default(
                () => application.homeAddress?.addressLine2 || ''
            )
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const {
        register,
        handleSubmit,
        setValue,
        trigger,
        formState: { errors, isValid }
    } = useForm<Address>({
        ...formOptions,
        defaultValues: validationSchema.getDefault(),
        mode: 'all'
    });

    const onSubmit = async (event: Address) => {
        setApplication((current) => ({
            ...current,
            homeAddress: event
        }));

        const updateDataLayer = {
            ...gaDataLayer,
            'step_name': 'Address',
        };
        setGADataLayer(updateDataLayer);
        pushToDataLayer("application_step_2", updateDataLayer);

        history.push(routeHashes['/v1_5/birthdate']);
    };

    const onSubmitModal = async (event: Address) => {
        setApplication((current) => ({
            ...current,
            homeAddress: event
        }));

        onModalClose && onModalClose();
    };

    return (
        <>
            {!isModal && (
                <ApplicationFlowLayout progress="37.5%" isArrowBackButton={true} cardType={application.cardType as CardTypes} displayVisaLogo={true} currentStep="3">
                    <div className="main-container">
                        <Subheadline headline="Whats your home address?" headlineIcon={mailboxIn} smallText="Use your current residential address, this<br/> is where we'll send your card." />
                        <Form submitText="Next" onSubmit={handleSubmit(onSubmit)} isSubmitDisabled={!isValid}>
                            <AddressForm validPattern={isNotPOBoxPattern} register={register} setValue={setValue} trigger={trigger} errors={errors} />
                        </Form>
                    </div>
                </ApplicationFlowLayout>)}
            {isModal && (
                <Modal onClose={onModalClose || (() => {})}>
                    <Subheadline headline="Whats your home address?" headlineIcon={mailboxIn} smallText="Use your current residential address, this<br/> is where we'll send your card." />
                        <Form submitText="Save" onSubmit={handleSubmit(onSubmitModal)} isSubmitDisabled={!isValid}>
                            <AddressForm validPattern={isNotPOBoxPattern} register={register} setValue={setValue} trigger={trigger} errors={errors} />
                        </Form>
                </Modal>)}
        </>);
}

export default AddressPage;
