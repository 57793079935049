import React from "react";
import * as Yup from 'yup';
import Modal from "../modal";
import Subheadline from "../subheadline";
import { idCardIcon } from "../../../assets/img";
import Form from "../form";
import TextInput from "../text-input";
import "../../scss/main.scss";
import { validationErrors } from "../../utils/constants";
import { isValidName } from "../../../utils/validations";
import { useRecoilState, useSetRecoilState } from "recoil";
import { applicationState, firstNameState } from "../../../store/atoms";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { NameVerificationModel } from "../../types";
import { ApplicationModel } from "../../../types";

interface NameModalProps {
    onClose: () => void;
}

const NameModal: React.FC<NameModalProps> = ({ onClose }) => {
    const [application, setApplication] = useRecoilState(applicationState);
    const setFirstName = useSetRecoilState(firstNameState);

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required(validationErrors.firstNameIsRequiredError)
            .test(
                'contains-alpha-characters-firstname',
                validationErrors.firstNameAlphabeticalCharactersError,
                (value) => isValidName(value)
            )
            .default(() => application.firstName),
        lastName: Yup.string()
            .required(validationErrors.lastNameRequiredError)
            .test(
                'contains-alpha-characters-lastname',
                validationErrors.lastNameAlphabeticalCharactersError,
                (value) => isValidName(value)
            )
            .test(
                'firstLastMaxLength',
                validationErrors.nameExceedsMaximumCharactersError,
                function (item) {
                    return (this.parent.firstName.length + this.parent.lastName.length) < 27
                }
            )
            .default(() => application.lastName)
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
    } = useForm<NameVerificationModel>({
        ...formOptions,
        defaultValues: validationSchema.getDefault(),
        mode: 'all',
    });

    const onSubmit = async (event: NameVerificationModel) => {
        setFirstName(event.firstName ?? '');

        setApplication((current: ApplicationModel) => ({
            ...current,
            ...event
        }));

        onClose();
    };

    return (
        <Modal onClose={onClose}>
            <Subheadline
                headline="Whats your legal name?"
                smallText="We require your legal name for identity verification and to help prevent fraud"
                headlineIcon={idCardIcon} />
            <Form submitText="Save" onSubmit={handleSubmit(onSubmit)} isSubmitDisabled={!isValid}>
                <TextInput name="firstName" label="Legal first name" type="text" inputSize="long" register={register} errors={errors} />
                <TextInput name="lastName" label="Legal last name" type="text" inputSize="long" register={register} errors={errors} />
                <div className="bottom-spacing-230" />
            </Form>
        </Modal>);
}

export default NameModal;