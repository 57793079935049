import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { ApplicationFlowLayout } from '../../layouts';
import { BlockCode } from '../../components';
import { CompleteValue } from '../../components/block-code/types';
import { useInterval } from '../../hooks';
import { useResendOtpCode, useVerifyOtpCode } from './hooks';
import { formatPhone } from '../../utils/phone-numbers';
import { isIncentiveFeatureActive, getPrn } from '../../utils/incentives';
import {
    ErrorPageProps,
    OtpCheckProps,
    OtpMethods,
    VerifyLeadOtpRequest,
    VerifyCheckAppStatusOtpRequest,
    IncentiveFeatures,
} from '../../types';
import { checkForUnauthorized, routeHashes } from '../../app-routes';
import {
    applicationState,
    checkAppState,
    configurationResponseState,
    gaDataLayerState,
} from '../../store/atoms';
import { pushToDataLayer } from '../../utils/analytics';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as FullStory from '@fullstory/browser';

type ResendCodeProps = {
    onResendCodeClicked?: () => void;
    seconds?: number;
    hasBeenResent?: boolean;
};

export const ResendCode: React.FC<ResendCodeProps> = ({ onResendCodeClicked, seconds, hasBeenResent }) => {
    const minutes = Math.floor((seconds ?? 0) / 60);
    const remainingSeconds = (seconds ?? 0) - (minutes * 60);
    const formatted = `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds} ${minutes < 1 ? 'seconds' : 'minutes'}`;

    if ((seconds ?? 0) <= 0) {
        return (
            <div>
                <p style={{ "color": "#A8A8A8" }}>(CODE EXPIRED)</p>
                <br />
                <p><Button className="link-sm countdown countdown-dark" variant="link" onClick={onResendCodeClicked}>Resend code</Button></p>
            </div>
        );
    } else if (hasBeenResent) {
        return (
            <div>
                <p><strong>(Valid for {formatted})</strong></p>
                <br />
                <p className="countdown countdown-light">Code sent!</p>
            </div>
        );
    }
    return (
        <div>
            <p><strong>(Valid for {formatted})</strong></p>
            <br />
            <p><Button className="link-sm countdown countdown-dark" variant="link" onClick={onResendCodeClicked}>Resend code</Button></p>
        </div>
    );
};

const OtpCheck: React.FC<OtpCheckProps> = (props) => {
    const { navigateTo, navigationState, otpSentTime, isInitialOtp } = props;
    let otpData = props.otpData;

    const history = useHistory();
    const [application] = useRecoilState(applicationState);
    let [gaDataLayer, setGADataLayer] = useRecoilState(gaDataLayerState);

    if (!otpData) {
        otpData = {
            emailAddress: '',
            primaryPhone: '',
            otpMethod: OtpMethods.Mobile,
        }
    }

    const configurationResponse = useRecoilValue(configurationResponseState);

    const resendOtpMutation = useResendOtpCode(otpData.isAppStatusCheck ?? false);
    const verifyOtpMutation = useVerifyOtpCode(otpData.isAppStatusCheck ?? false);

    const { emailAddress, primaryPhone, otpMethod } = otpData;
    const formattedPhone = formatPhone(primaryPhone);
    const contactIdentifier = otpData.otpMethod === OtpMethods.Mobile ? formattedPhone : emailAddress;

    const [hasBeenResent, setHasBeenResent] = useState(!isInitialOtp);
    const [seconds, setSeconds] = useState(0);
    const [codeValue, setCodeValue] = useState<string>();
    const [startTime, setStartTime] = useState<Date>(otpSentTime);
    const isCheckApp = useRecoilValue(checkAppState);

    const onComplete = (data: CompleteValue) => {
        setCodeValue(data.value);
        //
        //OTPentry Full Story Event
        switch (application?.cardType) {
            case "basic": {
                FullStory.event('OTPentry', {});
                break;
            }
            case "plus": {
                FullStory.event('Plus_OTPentry', {});
                break;
            }
            case "launch": {
                FullStory.event('Launch_OTPentry', { leadId: application.leadId });
                break;
            }
            default: {
                FullStory.event('OTPentry', {});
                break;
            }
        }
    };

    const resendCode = () => {
        const payload = otpData.isAppStatusCheck
            ? {
                  emailAddress
              }
            : {
                  leadId: otpData.id,
                  firstName: otpData.firstName,
                  emailAddress: otpData.emailAddress,
                  primaryPhone: otpData.primaryPhone
              };
        resendOtpMutation.mutate(payload, {
            onSuccess: () => {
                setStartTime(new Date());
                setHasBeenResent(true);
            }
        });
    };

    // decrement seconds when necessary
    useInterval(() => seconds > 0 && setSeconds(seconds - 1), 1000);

    useEffect(() => {
        // the else path here is entirely unreachable because startTime is never undefined
        // istanbul ignore else
        if (startTime) {
            //const expirationMillis = 30_000;
            const expirationMins = 5;
            const expirationTime = new Date(startTime.getTime() + expirationMins * 60 * 1000);
            const now = new Date();
            const newSeconds = Math.ceil((expirationTime.getTime() - now.getTime()) / 1000);
            setSeconds(newSeconds > 0 ? newSeconds : /* istanbul ignore next */ 0);
        }
    }, [startTime]);

    useEffect(() => {
        if (codeValue) {
            const payload = otpData.isAppStatusCheck
                ? {
                      emailAddress,
                      otpCode: codeValue
                  }
                : {
                      leadId: otpData.id,
                      otpCode: codeValue
                  };
            verifyOtpMutation.mutate(payload as VerifyLeadOtpRequest | VerifyCheckAppStatusOtpRequest, {
                onSuccess: () => {

                    const updateDataLayer = {
                        ...gaDataLayer,
                        'step_name': 'OTP Check',
                    };
                    setGADataLayer(updateDataLayer);
                    pushToDataLayer("application_step_1", updateDataLayer);

                    history.replace(navigateTo, navigationState);
                },
                onError: (error: any) => {
                    if (!checkForUnauthorized(error, history)) {
                        history.replace(routeHashes['/error'], {
                            title: 'The code you entered was invalid, please try again!',
                            buttonText: 'Try again',
                            navigateTo: routeHashes['/otp-check'],
                            navigationState: { ...props, otpSentTime: startTime, isInitialOtp: !hasBeenResent },
                        } as ErrorPageProps);
                    }
                }
            });
        }
        // (**TEMPORARY** - BlockCode onComplete needs to be fixed - called infinitely)
        // eslint-disable-next-line
    }, [codeValue]);

    return (
        <ApplicationFlowLayout
            showBackButton
            progress="20%"
            cardType={application.cardType}
            currentStep={isCheckApp ? undefined : "2"}
            promo={isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn(application.cardType), configurationResponse)}
            showBasicInfoFooter={true}
        >
            <Row>
                <Col className="text-center">
                    <div id="header">
                        <h1>Verify your {otpMethod === OtpMethods.Mobile ? "phone number" : "email"}</h1>
                        <p>
                            This helps us verify you're you, and will allow you to check on your application status
                            later.
                        </p>
                    </div>
                    <BlockCode
                        inputProps={{ disabled: !!codeValue, className: "form-control", type: "tel" }}
                        onComplete={onComplete}
                    />
                    <p>
                        Sent to <strong>{contactIdentifier}</strong>
                    </p>
                    {!codeValue ? (
                        <ResendCode onResendCodeClicked={resendCode} seconds={seconds} hasBeenResent={hasBeenResent} />
                    ) : (
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    )}
                </Col>
            </Row>
        </ApplicationFlowLayout>
    );
};

export default OtpCheck;
