import React, { MouseEventHandler, ReactNode } from "react";
import './form.scss';
import { Spinner } from "react-bootstrap";
import '../../scss/main.scss';

interface FormProps {
    heading?: string;
    onSubmit: MouseEventHandler<HTMLButtonElement>;
    isSubmitDisabled?: boolean;
    submitText: string;
    children: ReactNode;
    isSubmitting?: boolean;
}

const Form: React.FC<FormProps> = (props) => {
    return (
    <div className='form-container'>
        <form className='form'>
            {props.heading && <h2 className='form-heading'>{props.heading}</h2>}
            {props.children}
            {!props.isSubmitting ? (<button className='button' onClick={props.onSubmit} disabled={props.isSubmitDisabled}>{props.submitText}</button>) :
                (
                    <div className="spinner-container">
                        <Spinner animation="border" role="status">
                        <span className="visually-hidden">
                            Processing...
                        </span>
                        </Spinner>
                    </div>
                )}
        </form>
    </div>);
}

export default Form;