import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { applicationState, emailAddressState } from '../../../store/atoms';
import { useFormContext } from 'react-hook-form';
import { iconMastercard, visaIconGrey } from '../../../assets/img';
import NumberFormatInput from '../number-format-input';
import TextInput from '../text-input';
import {
    invalidNameCharacters,
    isNotPOBoxPattern,
} from '../../../utils/validations';
import BillingAddress from '../billing-address';
import AddressForm from '../address-form';
import './debit-card-form.scss';
import '../../scss/main.scss';
import { selectedApplicationResponse } from '../../../store/selectors';

interface DebitCardFormProps {
    onClickUsePreviousAddress: () => void;
    fundingError?: boolean;
}

export const DebitCardForm: React.FC<DebitCardFormProps> = ({
    onClickUsePreviousAddress,
    fundingError,
}) => {
    const application = useRecoilValue(applicationState);
    const selectedApplication = useRecoilValue(selectedApplicationResponse);
    const [displayBillingAddressForm, setDisplayBillingAddressForm] =
        useState(false);
    const formMethods = useFormContext();
    const [billingAddressText, setBillingAddressText] = useState('');
    const emailAddress = useRecoilValue(emailAddressState);

    useEffect(() => {
        const firstName =
            application.firstName || selectedApplication?.firstName;
        const lastName = application.lastName || selectedApplication?.lastName;
        const homeAddress =
            application?.homeAddress?.addressLine1 ||
            selectedApplication?.homeAddress;
        const city =
            application?.homeAddress?.city || selectedApplication?.city;
        const state =
            application?.homeAddress?.state || selectedApplication?.state;
        const zipCode =
            application?.homeAddress?.zipCode || selectedApplication?.zipCode;

        let smallText = `${firstName} ${lastName}<br/>`;

        smallText += homeAddress + '<br/>';

        smallText += city + ', ' + state + ' ' + zipCode;

        setBillingAddressText(smallText);
    }, [
        application?.homeAddress,
        application.firstName,
        application.lastName,
        selectedApplication?.homeAddress,
        selectedApplication?.firstName,
        selectedApplication?.lastName,
        selectedApplication?.aptSuite,
        selectedApplication?.city,
        selectedApplication?.state,
        selectedApplication?.zipCode,
    ]);

    const handleClickUseDifferentBillingAddress = () => {
        setDisplayBillingAddressForm(true);
    };

    const handleClickUsePreviousAddress = () => {
        setDisplayBillingAddressForm(false);
        onClickUsePreviousAddress();
    };

    return (
        <div className="debit-card-form">
            <div className="debit-card-form-details">
                <div className="debit-card-form-heading-content">
                    <h1 className="debit-card-form-heading">
                        Debit Card Details
                    </h1>
                    <div className="debit-card-form-heading-logos">
                        <div className="debit-card-form-heading-logos-content">
                            <div className="debit-card-form-heading-logo">
                                <img
                                    src={visaIconGrey}
                                    alt="visa icon"
                                    className="visa-icon-background"
                                />
                            </div>
                        </div>
                        <div className="debit-card-form-heading-logos-content">
                            <div className="debit-card-form-heading-logo">
                                <img
                                    src={iconMastercard}
                                    alt="Mastercard icon"
                                    className="mastercard-icon-background"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="debit-card-info">
                    <TextInput
                        type="text"
                        name="cardHolderFullName"
                        label="Full name"
                        aria-label="Cardholder Full name"
                        errors={formMethods.formState.errors}
                        register={formMethods.register}
                        maxLength="100"
                        onChange={(event) =>
                            (event.target.value = event.target.value.replace(
                                invalidNameCharacters,
                                ''
                            ))
                        }
                        inputSize={'long'}
                        inputClassName="standard-input-width-277"
                    />
                    <NumberFormatInput
                        secure
                        type="text"
                        format="#### #### #### ####"
                        name="cardNumber"
                        label="Card number"
                        aria-label="Card Number"
                        defaultValue={application?.cardNumber}
                        errors={formMethods.formState.errors}
                        register={formMethods.register}
                        inputClassName="standard-input-width-277"
                    />
                    <div className="vertical-aligned-inputs">
                        <NumberFormatInput
                            secure
                            type="text"
                            format="##/##"
                            name="cardExpiration"
                            label="Expiration"
                            aria-label="Expiration Date (MM/YY)"
                            defaultValue={application?.cardExpiration}
                            errors={formMethods.formState.errors}
                            register={formMethods.register}
                            inputClassName="standard-input-width-138"
                        />
                        <NumberFormatInput
                            secure
                            type="text"
                            format="###"
                            name="cardSecurityCode"
                            label="Security code"
                            aria-label="Security Code"
                            instructionText="Security code is a 3-digit number on the back of your card, on or above the signature line"
                            defaultValue={application?.cardSecurityCode}
                            errors={formMethods.formState.errors}
                            register={formMethods.register}
                            inputClassName="standard-input-width-126"
                        />
                    </div>
                    {!displayBillingAddressForm && !fundingError && (
                        <BillingAddress
                            onClickUseDifferentAddress={
                                handleClickUseDifferentBillingAddress
                            }
                            billingAddressText={billingAddressText}
                        />
                    )}
                    {fundingError && (
                        <AddressForm
                            className="address-form-container-no-background"
                            validPattern={isNotPOBoxPattern}
                            streetAddressInputLabel="Billing address"
                            register={formMethods.register}
                            setValue={formMethods.setValue}
                            trigger={formMethods.trigger}
                            errors={formMethods.formState.errors}
                            inputClassName="standard-input-width-277"
                            cityInputClassName="standard-input-width-138"
                            stateInputClassName="standard-dropdown-width-126"
                        />
                    )}
                    {displayBillingAddressForm && (
                        <>
                            <AddressForm
                                className="address-form-container-no-background"
                                validPattern={isNotPOBoxPattern}
                                streetAddressInputLabel="Billing address"
                                register={formMethods.register}
                                setValue={formMethods.setValue}
                                trigger={formMethods.trigger}
                                errors={formMethods.formState.errors}
                                inputClassName="standard-input-width-277"
                                cityInputClassName="standard-input-width-138"
                                stateInputClassName="standard-dropdown-width-126"
                            />
                            <button
                                className="transparent-blue-text-button"
                                onClick={handleClickUsePreviousAddress}
                            >
                                Use previous address
                            </button>
                        </>
                    )}
                </div>
            </div>
            {displayBillingAddressForm && (
                <p className="debit-card-form-small-text">
                    You’ll receive an email at
                    <br />
                    <span className="debit-card-form-small-text-weight-500">
                        {application.emailAddress || emailAddress}
                    </span>{' '}
                    from OpenSky when your card is on the way.
                </p>
            )}
        </div>
    );
};
