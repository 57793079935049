import { rest } from 'msw';

export const startSession = [
    rest.get(`${process.env.REACT_APP_DEFAULT_API_BASE_URL}/api/security/startSession`, (req, res, ctx) => {
        return res(
            ctx.status(200),
            ctx.json({})
        );
    }),
];
