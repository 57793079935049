import React from 'react';
import { creditCardIcon } from '../../../assets/img';
import Subheadline from '../subheadline';
import NumberFormatInput from '../number-format-input';
import { useFormContext } from 'react-hook-form';
import './deposit-form.scss';

export const DepositForm = () => {
    const formMethods = useFormContext();
    return (
        <div className="deposit-form">
            <div className="deposit-form-layout-outer">
                <div className="deposit-form-layout-inner">
                    <div className="deposit-form-container">
                        <div className="deposit-form-content">
                            <Subheadline
                                headlineIcon={creditCardIcon}
                                headline="Deposit"
                                smallText="Your security deposit will set<br/> your credit limit and
                                    must be<br/> paid before your card ships."
                            ></Subheadline>

                            <NumberFormatInput
                                secure
                                spellCheck={false}
                                name="amount"
                                label="Deposit Amount"
                                type="text"
                                inputSize="long"
                                inputClassName="standard-input-width-277"
                                prefix={'$'}
                                allowNegative={false}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                description={
                                    <p className="deposit-input-small-text">You can fund any amount between $200<br/> and $3,000</p>
                                }
                                stripFormatting={true}
                                errors={formMethods.formState.errors}
                                register={formMethods.register}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
