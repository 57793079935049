import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { ValidatedInputProps } from '../../../types';
import TextInput from '../text-input';

export type NumberFormatInputProps = Omit<ValidatedInputProps, 'id'> &
    NumberFormatProps<any> & {
        stripFormatting?: boolean
    }

const NumberFormatInput: React.FC<NumberFormatInputProps> = (props) => (
    <div style={{ whiteSpace: 'pre-line' }}>
        <NumberFormat
            customInput={TextInput}
            registerOptions={{
                setValueAs: (value: string) => {
                    if (props.decimalScale && props.decimalScale > 0) {
                        return (
                            value?.replace &&
                            Math.round(
                                parseFloat(value.replace(/[^0-9.]+/g, '')) *
                                    (10 * props.decimalScale)
                            ) /
                                (10 * props.decimalScale)
                        );
                    } else if (props.fixedDecimalScale) {
                        return (
                            value?.replace &&
                            Math.round(
                                parseFloat(value.replace(/[^0-9.]+/g, ''))
                            )
                        );
                    } else if (props.stripFormatting) {
                        return value?.replace && value.replace(/[^0-9]+/g, '');
                    } else {
                        return value
                    }
                },
            }}
            {...props}            
        />
    </div>
);

export default NumberFormatInput;
