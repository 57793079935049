import React, { useEffect } from 'react';
import { useMemo, useState } from 'react';
import Confetti from 'react-confetti';
import { IConfettiOptions } from 'react-confetti/dist/types/Confetti';
import { useWindowDimensions } from '../../hooks';

export type useConfettiProps = {
    /**
     * True to show the confetti, false for it to be omitted
     */
    show: boolean;
    /**
     * How long to show the confetti for when show flips to true, not setting this means it's shown forever.
     */
    showDuration?: number;
} & Partial<IConfettiOptions>;

export type useConfettiReturn = {
    confettiElement: JSX.Element;
};

export const useConfetti = ({
    show,
    showDuration,
    ...ConfettiProps
}: useConfettiProps) => {
    const [hideFromDelay, setHideFromDelay] = useState(false);
    const windowDimensions = useWindowDimensions();
    const [maxHeight] = useState<number>(() => {
        return Math.max(
            window.screen.height,
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.body.clientHeight,
            document.documentElement.clientHeight
        );
    });

    useEffect(() => {
        if (showDuration) {
            setTimeout(() => {
                setHideFromDelay(true);
            }, showDuration);
        }
    }, [showDuration]);

    console.log('show duration: ', showDuration);
    console.log('hide from delay: ', hideFromDelay);

    return useMemo(() => {
        const element = (
            <>
                {show && !hideFromDelay && (
                    <Confetti
                        width={windowDimensions.width}
                        height={maxHeight}
                        {...ConfettiProps}
                    />
                )}
            </>
        );

        return element;
    }, [ConfettiProps, hideFromDelay, maxHeight, show, windowDimensions.width]);
};
