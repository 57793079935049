import classnames from 'classnames';
import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import { ApplicationFlowLayout } from '../../layouts';
import { applicationState, configurationResponseState, gaDataLayerState } from '../../store/atoms';
import CreditLineHelp from './credit-line-help';
import { routeHashes } from '../../app-routes';
import { useRecoilValue } from 'recoil';
//import { currentCardInfo } from '../../utils/product-info';
import { isIncentiveFeatureActive, getIncentiveDetail, getPrn } from '../../utils/incentives';
import { CardInfo, PromotionSpecific } from '../../components';
import { IncentiveFeatures } from '../../types';
import * as FullStory from '@fullstory/browser';
import './index.scss';
import { pushToDataLayer } from '../../utils/analytics';


const CreditLine = () => {
    const [application, setApplication] = useRecoilState(applicationState); 
    const [promoValueMinimumAmount, setPromoValueMinimumAmount] = useState<string>();
    const [promoValueDepositReduction, setPromoValueDepositReduction] = useState<string>();
    const [gaDataLayer, setGaDataLayer] = useRecoilState(gaDataLayerState);
    const configurationResponse = useRecoilValue(configurationResponseState);

    /* NOTE: what is the best default value here? */
    // let balanceDue = 19.95;
    // if (application?.cardType === 'launch' && application.creditLimit && application.programFee) {
    //     balanceDue = application.creditLimit + application.programFee;
    // }
    
    const getAmountsBasedOnPromotionIfAny = () => {
        let depositAmounts = (application.cardType === 'plus') ? [300, 500, 1000] : [200, 300, 500];

        let incentiveDetail = getIncentiveDetail(IncentiveFeatures.MinimumDepositAmount, 
            getPrn(application.cardType), configurationResponse);
        
        //console.log('getAmountsBasedOnPromotionIfAny incentiveDetail', incentiveDetail);
        
        if (incentiveDetail && !isNaN(Number(incentiveDetail.value))) {            
            depositAmounts[0] = Number(incentiveDetail.value);                    
        }

        //console.log('getAmountsBasedOnPromotionIfAny depositAmounts', depositAmounts);

        return depositAmounts;                
    }

    const getRecommendedAmountBasedOnPromotionIfAny = () => {        
        if (isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn(application.cardType), configurationResponse)) 
        {
            return getAmountsBasedOnPromotionIfAny()[0];
        }

        return recommendedAmount;
    }

    const amounts = getAmountsBasedOnPromotionIfAny();

    const recommendedAmount = application.defaultCreditLimit;

    const [selectedAmount, setSelectedAmount] = useState(
        application.amount || getRecommendedAmountBasedOnPromotionIfAny()
    );
        
    //const plusCardInfo = currentCardInfo('plus', configurationResponse?.securedProducts);

    const history = useHistory();

    const handleConfirm = () => {
        switch (application?.cardType) {
            case "basic": {
                FullStory.event('CreditLineSelectConfirm', { leadId: application.leadId });
                break;
            }
            case "plus": {
                FullStory.event('Plus_CreditLineSelectConfirm', { leadId: application.leadId });
                break;
            }
            case "launch": {
                FullStory.event('Launch_CreditLineSelectConfirm', { leadId: application.leadId });
                break;
            }
            default: {
                FullStory.event('CreditLineSelectConfirm', { leadId: application.leadId });
                break;
            }        }
              
        setApplication((current) => ({
            ...current,
            ...{ amount: selectedAmount },
        }));

        if(!gaDataLayer.credit_line_amount){
            const updateDataLayer = {
                ...gaDataLayer,
                'step_name': 'Credit Line Selection',
                'credit_line_amount': selectedAmount,
                'credit_line_type': 'pre-defined'
            }
    
            setGaDataLayer(updateDataLayer);
            pushToDataLayer('application_step_3', updateDataLayer);
        }
        else{
            pushToDataLayer('application_step_3', gaDataLayer);
        }

        // if (!!plusCardInfo && application.cardType === 'plus' && selectedAmount < plusCardInfo.minimumDeposit) {
        //     history.push(routeHashes['/credit-line-switch']);
        //     return;
        // }
        //Forward Credit-Line-Confirm & funding section to end of process
        history.push(routeHashes['/identity-verification']);
    };

    const handleCustom = () => {
        switch (application?.cardType) {
            case "basic": {
                FullStory.event('CreditLineSelectChooseCustom', { leadId: application.leadId });
                break;
            }
            case "plus": {
                FullStory.event('Plus_CreditLineSelectChooseCustom', { leadId: application.leadId });
                break;
            }
            case "launch": {
                FullStory.event('Launch_CreditLineSelectChooseCustom', { leadId: application.leadId });
                break;
            }
            default: {
                FullStory.event('CreditLineSelectChooseCustom', { leadId: application.leadId });
                break;
            }
        }

        setApplication((current) => ({
            ...current,
            ...{ amount: undefined },
        }));
        history.push(routeHashes['/credit-line-custom']);
    };

    return (
        <>
            <ApplicationFlowLayout
                showBackButton
                HelpComponent={CreditLineHelp}
                progress="40%"
                cardType={application.cardType}
                currentStep="4"
                promo={true}
                showBasicInfoFooter={true}
                footerAlternateEligibleDisclosure={application.cardType === "basic" && isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn("basic"), configurationResponse)}
                footerPlusEligibleDisclosure={application.cardType === "plus" && isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn("plus"), configurationResponse)}
                footerPreApprovedDisclosure={application.cardType === "launch"}
                promoAlternateEligibleDisclosureIndex='*'
                promoPlusEligibleDisclosureIndex='*'
                promoPreApprovedDisclosureIndex='*'
            >
                {application.cardType === 'launch' ? (
                    <>
                    <Row>
                        <Col>
                            <div id="header" className="credit-line-header-launch">
                                <h1>You're pre-approved*<br /> for a ${application.amount ? application.amount : application.defaultCreditLimit} credit line</h1> 
                                <CardInfo
                                    cardType={application.cardType?.toString()}
                                    creditLimit={application.amount ? application?.amount.toString() : application?.defaultCreditLimit?.toString()}
                                    programFee={application.programFee?.toString()}
                                    securityDeposit={application.securityDeposit?.toString()}
                                />
                            </div>
                        </Col>
                    </Row>
                    </>
                ) : (
                    <Row>
                        <Col>
                            <div id="header" className="credit-line-header">
                                <h1>Let's choose your credit line.</h1>                                
                                <PromotionSpecific
                                    cardType={application.cardType}
                                    incentiveFeatureName={IncentiveFeatures.DepositReductionAmount}
                                    config={configurationResponse}
                                    setPromoValue={setPromoValueDepositReduction}
                                    className='credit-line-limited-time-offer'
                                >
                                    <>                                    
                                        <Button className="purple-button-small">
                                            Limited Time Offer*
                                        </Button>                                
                                        <Card
                                            className={'limited-time-offer-text'}
                                            style={{ border: 'none', paddingTop: '10px', paddingBottom: '15px' }}
                                        >                                            
                                            <span className='offer-highlight'></span><span style={{display: 'inline'}}>
                                                
                                            <span style={{fontWeight: 'bold'}}>Save ${promoValueDepositReduction}</span> on your refundable 
                                            security deposit for your credit line when you make 
                                            your deposit today.</span> 
                                        </Card>
                            
                                    </>                                               
                                </PromotionSpecific>
                                <PromotionSpecific
                                    cardType={application.cardType}
                                    incentiveFeatureName={IncentiveFeatures.MinimumDepositAmount}
                                    config={configurationResponse}
                                    setPromoValue={setPromoValueMinimumAmount}
                                    className='credit-line-limited-time-offer'
                                >
                                    <>                                    
                                        <Button className="purple-button-small">
                                            Limited Time Offer*
                                        </Button>                                
                                        <Card
                                            className={'limited-time-offer-text'}
                                            style={{ border: 'none', padding: '10px 0px 20px 0px' }}
                                        >                                            
                                            <div className="limited-offer-text">
                                                <strong><span className="full-amount"> ${recommendedAmount}</span><span className="promo-amount"> ${promoValueMinimumAmount}</span> Minimum deposit</strong>
                                                <div className="limited-offer-subtext">Enjoy a <span style={{fontWeight: 'bold'}}>lowered minimum deposit</span> and <span style={{fontWeight: 'bold'}}>no annual fee</span> when you apply and fund.</div>


                                            </div>
                                        </Card>
                            
                                    </>                                               
                                </PromotionSpecific>
                            </div>
                            <ul className="link-selector">
                                {amounts.map((s) => (
                                    <li key={s}>
                                        <button
                                            className={classnames({
                                                'selected-button': selectedAmount === s,
                                            }, 'promo-button')}
                                            onClick={() => {
                                                setSelectedAmount(s);

                                                const updateDataLayer = {
                                                    ...gaDataLayer,
                                                    'step_name': 'Credit Line Selection',
                                                    'credit_line_amount': s,
                                                    'credit_line_type': 'pre-defined'
                                                }
                                        
                                                setGaDataLayer(updateDataLayer);
                                            }}
                                            data-testid={`button-${s}`}
                                        >
                                            <div className="promo-text">
                                                ${s} Credit Line 
                                                <PromotionSpecific
                                                        cardType={application.cardType}
                                                        incentiveFeatureName={IncentiveFeatures.DepositReductionAmount}
                                                        config={configurationResponse}
                                                        setPromoValue={setPromoValueDepositReduction}
                                                        className='inline'
                                                    >
                                                        <span className="highlighted"> for ${(s - Number(promoValueDepositReduction)).toString()}</span>
                                                </PromotionSpecific>
                                            </div>
                                            {(application.cardType === 'plus') && (
                                                <>
                                                <div className="promo-text-subtext">No annual fee</div>
                                                </>
                                            )}                                            
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col className="text-center">
                        <Form.Group>
                            <Button
                                type="submit"
                                variant="simple"
                                onClick={handleCustom}
                                data-testid='credit-line-custom-amount-button'
                            >
                                Choose custom credit line amount
                            </Button>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-36">
                    <Col className="text-center">
                        <Form.Group>
                            <Button
                                className="btn btn-primary"
                                type="submit"
                                onClick={handleConfirm}
                            >
                                Confirm
                            </Button>
                        </Form.Group>
                    </Col>
                </Row>
            </ApplicationFlowLayout>
        </>
    )
};

export default CreditLine;
