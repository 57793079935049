import React, { FC, useEffect, useState } from 'react';
import { Loading } from '../../components';
import { DocumentSelection } from '../../types';
import { useUploadDocuments } from './hooks';
import { DocumentUploadFail } from './document-upload-fail';
import { DocumentsSubmitted } from './documents-submitted';

type DocumentUploadProps = {
    leadId: string;
    applicationId: string;
    documentSelections: DocumentSelection[];
    resetDocumentUpload: Function;
};

export const DocumentUpload: FC<DocumentUploadProps> = ({ leadId, applicationId, documentSelections, resetDocumentUpload }) => {
    const [isSuccess, setIsSuccess] = useState<boolean>();
    const [isFail, setIsFail] = useState<boolean>();

    const uploadDocumentsMutation = useUploadDocuments();

    useEffect(() => {
        uploadDocumentsMutation.mutate({ leadId, applicationId, documentSelections }, {
            onSuccess: (response) => {
                setIsSuccess(true);
            },
            onError: (error: any) => {
                setIsFail(true);
            },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentSelections]);

    /* TODO: should this Loading element be centered? */
    return (        
        <Loading
            headerText="Please wait as we upload your documents"
            bodyText="Shouldn't be long, please keep this page open"
            isLoading={!isSuccess && !isFail}
        >
            {isSuccess &&  <DocumentsSubmitted /> }

            {isFail &&
                <DocumentUploadFail
                    resetDocumentUpload={resetDocumentUpload}
                />
            }
        </Loading>
    );
};
