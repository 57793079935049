import { History } from 'history';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history }: { history: History }) {
    const unlisten = history.listen((location, action) => {
        // change the timing so that we either have no animation when it scrolls up from the bottom
        // set it to 500 as default so we can have animation, lower for no animation
        setTimeout(() => window.scrollTo(0, 0), 500);
    });
    useEffect(() => {
        return () => {
            unlisten();
        };
    });

    return null;
}

export default withRouter(ScrollToTop);
