import React from 'react';
import { Button } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import ApplicationInfoStatus from '../../components/application-info-status';
import { LandingPageLayout } from '../../layouts';
import {
    decisionsResponseState,
    emailAddressState,
    cardTypeState,
    firstNameState
} from '../../store/atoms';
import {
    applicationApproved,
    creditcardTilted,
    creditcardPlusTilted,
    iconCalendar,
    iconCreditcardPayment,
    iconGraph,
    creditcardLaunchTilted
} from '../../assets/img';
import './index.scss';

const ApplicationDepositApproved = () => {
    const decisionsResponse = useRecoilValue(decisionsResponseState);
    const emailAddress = useRecoilValue(emailAddressState);
    const firstName = useRecoilValue(firstNameState);
    const cardType = useRecoilValue(cardTypeState);

    const getCardImageByCardType = () => {
        switch(cardType){
            case 'launch':
                return <img alt="" src={creditcardLaunchTilted} className="bottom-36" />
            case 'plus':
                return <img alt="" src={creditcardPlusTilted} className="bottom-36" />
            default:
                return <img alt="" src={creditcardTilted} className="bottom-36" />
        }
    }

    return (
        <LandingPageLayout showPrivacyPolicy> 
            <ApplicationInfoStatus
                appStatus="You've been approved!"
                appMessage="Your new card is being prepared"
                appStatusType="approved"
                appStatusIcon={applicationApproved}
                appDetailMessage={`We're busy making sure your new $${decisionsResponse?.creditLimit}`}
                appDetailMessageLine2="OpenSky card is good to go before shipment."
                appAdditionalContent={
                    <div className="text-start">
                        {getCardImageByCardType()}
                        <h2 className="ginto-header">Congrats, <span style={{textTransform: 'capitalize'}}>{firstName}</span>,</h2>
                        <h2 className="ginto-header gradient">you're approved!</h2>
                        <p>We've received your security deposit and have<br />
                        opened an OpenSky account just for you.</p>
                        <p className="bottom-36">You'll receive an email at {emailAddress} from OpenSky letting you
                        know when to look out for your new card.</p>
                        <h2 className="ginto-header bottom-16"  style={{textAlign: 'left'}}>What is a secured card?</h2>
                        <p className="bottom-36"  style={{textAlign: 'left'}}>Secured cards are a type of credit card that are<br />
                        backed by a cash deposit, but otherwise work<br />
                        just like unsecured cards. The refundable deposit<br />
                        used to fund your OpenSky card is equal to your<br />
                        spending limit. Using it for everyday purchases<br />
                        and making on-time monthly payments helps to<br />
                        grow and improve your score over time.</p>
                        <Button
                            className="bottom-36"
                            disabled={false}
                            onClick={() => window.location.href = `${process.env.REACT_APP_HOMEPAGE_URL}/know-your-card`}>
                            Learn more
                        </Button>
                        <div className="card dark card-tall-padding bottom-36 card-width">
                            <h2 className="ginto-header large-h2 bottom-24 center-text">Quick Tips to Build<br />Credit Fast</h2>
                            <div className="icon-card">
                                <img alt="calendar icon" src={iconCalendar} className="icon" /><span className="text">Make on-time monthly<br />payments.</span>
                            </div>
                            <div className="icon-card">
                                <img alt="payment icon" src={iconCreditcardPayment} className="icon" /><span className="text">Use less than 30% of your<br />available credit.</span>
                            </div>
                            <div className="icon-card">
                                <img alt="graph icon" src={iconGraph} className="icon" /><span className="text">Opt-in on your OpenSky<br />account to track your FICO<br />score for free.</span>
                            </div>
                        </div>
                    </div>
                }
                appMessageAdditionalClasses="force-text-left"
                appMessageAdditionalGradiantClasses="force-text-left"
                appDetailAdditionalClasses="force-text-left"
                onNext={() => window.location.href = `${process.env.REACT_APP_HOMEPAGE_URL}`}
                buttonText="Ok, Got it"
            />
        </LandingPageLayout>
    );
};

export default ApplicationDepositApproved;
