import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as Yup from 'yup';
import {
    AddressForm,
    DatePicker,
    NumberFormatInput,
    TextInput,
} from '../../components';
import { ApplicationFlowLayout } from '../../layouts';
import { applicationState, configurationResponseState, gaDataLayerState } from '../../store/atoms';
import { isIncentiveFeatureActive, getPrn } from '../../utils/incentives';
import { pushToDataLayer } from '../../utils/analytics';
import { IdentityVerificationModel, IncentiveFeatures } from '../../types';
import { maxBirthdate } from '../../utils/dates';
import {
    isValidName,
    isValidSSNorITIN,
    isNotPOBoxPattern,
    invalidNameCharacters,
    zipCodePattern
} from '../../utils/validations';
import IdentityVerificationHelp from './identity-verification-help';
import { routeHashes } from '../../app-routes';
import * as FullStory from '@fullstory/browser';

const IdentityVerification = () => {
    const [application, setApplication] = useRecoilState(applicationState);
    const history = useHistory();
    const configurationResponse = useRecoilValue(configurationResponseState);    
    let [gaDataLayer, setGADataLayer] = useRecoilState(gaDataLayerState);

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('First Name is required')
            .test(
                'contains-alpha-characters-firstname',
                `First Name should only contain alphabetical characters`,
                (value) => isValidName(value || '')
            )
            .default(() => application.firstName),
        lastName: Yup.string()
            .required('Last Name is required')
            .test(
                'contains-alpha-characters-lastname',
                `Last Name should only contain alphabetical characters`,
                (value) => isValidName(value || '')
            )
            .test(
                'firstLastMaxLength',
                'First Name and Last Name combined exceeds maximum characters',
                function(item) {
                    return (this.parent.firstName.length + this.parent.lastName.length) < 27
                }
            )
            .default(() => application.lastName),
        dateOfBirth: Yup.date()
            .typeError('Birthday is required.')
            .max(maxBirthdate, 'Birthday must be at least 18 years in the past')
            .required('Birthday is required'),
        ssn: Yup.string()
            .required('SSN is required')
            .typeError('SSN is required')
            .test('ssn-valid', 'Invalid SSN or ITIN', (value) => isValidSSNorITIN(value?.toString() || ''))
            .test('ssn-length', 'Invalid SSN or ITIN', (value) => value?.length === 9)
            .matches(/\d{9}/, 'Invalid SSN or ITIN')
            .default(() => application.ssn),
        ssnConfirm: Yup.string()
            .required('SSN confirmation is required')
            .typeError('SSN confirmation is required')
            .oneOf([Yup.ref('ssn'), null], 'Confirm SSN must match SSN')
            .default(() => application.ssnConfirm),
        homeAddress: Yup.object().shape({
            addressLine1: Yup.string()
                .required('Address is required')
                .max(26, 'Address line must be at most 26 characters')
                .matches(isNotPOBoxPattern, 'PO Box addresses are not allowed as Home Address')
                .test(
                    'address',
                    'Invalid Primary Address',
                    (value) => value !== ''
                )
                .default(() => application.homeAddress?.addressLine1 || ''),
            city: Yup.string()
                .required('City is required')
                .max(18, "City must be less than 18 characters")
                .test('city', 'City is required', (value) => value !== '')
                .default(() => application.homeAddress?.city || ''),
            state: Yup.string()
                .required('State is required')
                .test('state', 'State is required', (value) => value !== '')
                .default(() => application.homeAddress?.state || ''),
            zipCode: Yup.string()
                .required('Zip Code is required')
                .min(5, 'Zip Code is required')
                .max(10, 'Zip Code is required')
                .test(
                    'zipcode',
                    'Zip Code is required',
                    (value) => value !== ''
                )
                .matches(zipCodePattern, 'Must be a U.S. Zip Code')
                .test('first-five-valid-range', 'Must be a U.S. Zip Code', 
                    (value) =>  {       
                        let zipCodeValue = parseInt((value ?? '0').substring(0,5));                 
                        return zipCodeValue > 500 && zipCodeValue < 99951; 
                })
                .default(() => application.homeAddress?.zipCode || ''),
            addressLine2: Yup.string().default(
                () => application.homeAddress?.addressLine2 || ''
            ),
        }),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const {
        register,
        handleSubmit,
        setValue,
        trigger,
        watch,
        formState: { errors, isValid },
    } = useForm<IdentityVerificationModel>({
        ...formOptions,
        defaultValues: validationSchema.getDefault(),
        mode: 'all',
    });
    
    const onDateChange = async (date?: Date) => {
        if (!!date) {            
            trigger();
        }
    };

    const onSubmit = async (event: IdentityVerificationModel) => {
        switch (application?.cardType) {
            case "basic": {
                FullStory.event('PIIEntryNext', { leadId: application.leadId });
                break;
            }
            case "plus": {
                FullStory.event('Plus_PIIEntryNext', { leadId: application.leadId });
                break;
            }
            case "launch": {
                FullStory.event('Launch_PIIEntryNext', { leadId: application.leadId });
                break;
            }
            default: {
                FullStory.event('PIIEntryNext', { leadId: application.leadId });
                break;
            }
        }
        const updateDataLayer = {
            ...gaDataLayer,
            'step_name': 'ID Verify',
        };
        setGADataLayer(updateDataLayer);
        pushToDataLayer("application_step_4", updateDataLayer);

        setApplication((current) => ({
            ...current,
            ...event,
        }));
        history.push(routeHashes['/identity-confirm']);
    };

    const ssn = watch('ssn');
    const ssnConfirm = watch('ssnConfirm');

    useEffect(() => {
        if (ssnConfirm !== null) {
            trigger();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ssn, ssnConfirm]);

    return (
        <ApplicationFlowLayout
            showBackButton
            HelpComponent={IdentityVerificationHelp}
            progress="50%"
            cardType={application.cardType}
            currentStep="5"
            promo={isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn(application.cardType), configurationResponse)}
            showBasicInfoFooter={true}
        >
            <Row className="no-bottom-margin">
                <Col>
                    <div id="header" className="no-bottom-margin">
                        <h1 className="no-bottom-margin">Almost there, let's verify your info.</h1>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <fieldset style={{marginTop:'15px'}}>
                        <legend className="field-header">
                            Full Legal Name
                        </legend>
                        <TextInput
                            type="text"
                            name="firstName"
                            label="First Name"
                            aria-label="First Name"
                            errors={errors}
                            register={register}
                            maxLength="20"
                            onChange={(event) =>                                                        
                                event.target.value = event.target.value.replace(invalidNameCharacters, "")
                            }
                        />
                        <TextInput
                            type="text"
                            name="lastName"
                            label="Last Name"
                            aria-label="Last Name"
                            errors={errors}
                            register={register}
                            maxLength="20"
                            onChange={(event) =>                                                        
                                event.target.value = event.target.value.replace(invalidNameCharacters, "")
                            }
                        />
                    </fieldset>
                    <fieldset>
                        <legend
                            className="field-header"
                            style={{ marginBottom: '-2px' }}
                        >
                            Birthday
                        </legend>
                        <DatePicker  
                            name="dateOfBirth"
                            isBirthday={true}
                            initialValue={application.dateOfBirth}
                            onDateChange={onDateChange}
                            setValue={setValue}
                            errors={errors}
                            trigger={trigger}
                        />
                    </fieldset>
                    <fieldset>
                        <legend className="field-header">
                            Social Security (SSN or ITIN) Number
                        </legend>
                        <NumberFormatInput
                            secure
                            type="text"
                            format="###-##-####"
                            name="ssn"
                            label="SSN or ITIN"
                            aria-label="SSN or ITIN"
                            instructionText=""
                            defaultValue={application?.ssn}
                            errors={errors}
                            register={register}       
                        />
                        <NumberFormatInput
                            secure
                            type="text"
                            format="###-##-####"
                            name="ssnConfirm"
                            label="Confirm your SSN or ITIN"
                            aria-label="Confirm your SSN or ITIN"
                            defaultValue={application?.ssnConfirm}
                            errors={errors}
                            register={register}
                            onCopy={(event) => {                                
                                event.preventDefault();
                                return false;
                            }}
                            onPaste={(event) => {                                
                                event.preventDefault();
                                return false;
                            }}
                        />
                    </fieldset>
                    <fieldset>
                        <legend className="field-header">Home Address</legend>
                        <AddressForm
                            register={register}
                            errors={errors}
                            name="homeAddress"
                            labels={{
                                addressLookupLabel:
                                    'Address where you currently live',
                                addressLookupFloatingLabel:
                                    'Search for home address',
                            }}
                            initialAddress={application.homeAddress}
                            setValue={setValue}
                            trigger={trigger}
                            validPattern={isNotPOBoxPattern}
                        />
                    </fieldset>
                </Col>
            </Row>
            <Row className="row mt-36">
                <Col className="col text-center">
                    <p>
                        OpenSky's identity verification will not impact your
                        credit score.
                    </p>
                </Col>
            </Row>
            <Row className="row mt-36">
                <Col className="col text-center">
                    <Form.Group>
                        <Button
                            disabled={!isValid}
                            onClick={handleSubmit(onSubmit)}
                        >
                            Next
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
        </ApplicationFlowLayout>
    );
};

export default IdentityVerification;
