import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { useSetRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { iconError } from '../../assets/img';
import { TextInput } from '../../components';
import { applicationState } from '../../store/atoms';
import { ApplicationModel } from '../../types';
import { LandingPageLayout } from '../../layouts';
import { routeHashes } from '../../app-routes';

const InvalidEmail: FC = () => {
    const setApplication = useSetRecoilState(applicationState);
    const history = useHistory();

    const validationSchema = Yup.object().shape({
        emailAddress: Yup.string()
            .email('Invalid Email Address!')
            .required('Email Address is required'),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const {  handleSubmit, register, formState: { errors, isValid } } = useForm<{ emailAddress: string }>({
        ...formOptions,
        defaultValues: validationSchema.getDefault(),
        mode: 'all',
    });

    const onSubmit = async (event: { emailAddress: string }) => {
        setApplication((current: ApplicationModel) => ({
            ...current,
            ...event,
        }));
        history.push(routeHashes['/submit-application']);
    };

    return (
        <LandingPageLayout>
            <Alert variant="danger">
                <img
                    alt="error"
                    src={iconError}
                    role="presentation"
                    className="icon-error"
                />
                An error has occurred
            </Alert>
            <Row>
                <Col>
                    <div id="header">
                        <h1>Please re-enter your email address</h1>
                        <p>
                            You may have entered some information incorrectly.
                            Please provide a valid email address.
                        </p>
                    </div>
                    <Form>
                        <fieldset>
                            <legend>Email Address</legend>
                            <TextInput
                                type="email"
                                name="emailAddress"
                                label="Email Address"
                                aria-label="Email Address"
                                errors={errors}
                                register={register}
                            />
                        </fieldset>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col className="text-center">
                    <Form.Group>
                        <Button
                            type="submit"
                            disabled={!isValid}
                            onClick={handleSubmit(onSubmit)}
                        >
                            Next
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
        </LandingPageLayout>
    );
};

export default InvalidEmail;
