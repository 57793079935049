import React, { FC, useState, useEffect } from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';
import { SelectOptions } from './types';
import {
    FieldErrorsImpl,
    UseFormRegister,
    UseFormSetValue,
} from 'react-hook-form';

type SelectDropdownProps = {
    id?: string;
    label?: string;
    initialValue?: string;
    onChange?: (value: string) => void; // ((value?:string) => void);
    options?: SelectOptions[];
    className?: string;
    value?: string;
    register?: UseFormRegister<any>;
    errors?: FieldErrorsImpl<any>;
    name?: string;
    setValue?: UseFormSetValue<any>;
};

const SelectDropdown: FC<SelectDropdownProps> = ({
    id,
    label,
    initialValue,
    onChange,
    options,
    className,
    value,
    register,
    name,
    setValue
}) => {
    const [selectedValue, setSelectedValue] = useState<string | undefined>(
        initialValue || value
    );

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setSelectedValue(value);
        onChange && onChange(value);
        registerOnChange && registerOnChange(event);
        // console.log('changing select value', value);
        // name && setValue?.(name, value);
    };

    useEffect(() => {
        setSelectedValue(value);
    }, [value]);

    const { onChange: registerOnChange, ...restRegisterProps } =
        (register && register(name || '')) || {};

    return (
        <div className={`select-dropdown-container ${className}`}>
            <FloatingLabel
                className={selectedValue === '' ? 'no-select' : undefined}
                controlId={id}
                label={label}
            >
                <Form.Select
                    value={selectedValue}
                    data-testid={label || ''}
                    onChange={handleChange}
                    {...restRegisterProps}
                >
                    <option value={''}></option>
                    {(options || []).map((option, index) => (
                        <option key={index} value={option.value}>
                            {option?.label || option.value}
                        </option>
                    ))}
                </Form.Select>
            </FloatingLabel>
        </div>
    );
};

export default SelectDropdown;
