import React from 'react';
import { HelpTray } from '../../components';

const DebtToIncomeHelp = () => {
    return (
        <HelpTray>
            <HelpTray.Header>
                Financial Information Help
            </HelpTray.Header>
            <HelpTray.Section>
                <HelpTray.SectionTitle>Monthly Housing/Rent Payment</HelpTray.SectionTitle>
                <HelpTray.SectionText>
                    This is your combined monthly payment(s) for where you currently reside. If you 
                    share a housing payment, enter the amount that you are personally responsible for.
                </HelpTray.SectionText>
            </HelpTray.Section>
            <HelpTray.Section>
                <HelpTray.SectionTitle>Yearly Income</HelpTray.SectionTitle>
                <HelpTray.SectionText>
                    Your “total yearly income” is the amount you grossed/earned or reasonably expect to earn 
                    in a calendar year.
                </HelpTray.SectionText>
                <HelpTray.SectionText>
                    You may include personal income, which is income you have earned, including full-time, part-time, 
                    or seasonal jobs, salary, wages, shared income that is regularly deposited in to an account you 
                    can access. If you are 21 and older, you can include income that you can reasonably access to 
                    pay your bills. You may also include alimony, child support or separate maintenance income, but 
                    it need not be revealed if you do not wish to have it considered as a basis for repaying this
                    obligation.
                </HelpTray.SectionText>
            </HelpTray.Section>
        </HelpTray>
    );
}

export default DebtToIncomeHelp;
