import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LandingPageLayout } from '../../layouts';
import { DatePicker, NumberFormatInput } from '../../components';
import {
    selectedApplicationIdState,
    decisionsResponseState,
    responseCodeState
} from '../../store/atoms';
import { ErrorPageProps, ResponseCodes, SubmitMiskeyRequest } from '../../types';
import { useSubmitMiskey } from './hooks';
import { useProcessResponseCode } from '../../hooks';
import { maxBirthdate } from '../../utils/dates';
import { zipCodePattern } from '../../utils/validations';
import { iconError } from '../../assets/img';
import { checkForUnauthorized, routeHashes } from '../../app-routes';

const zipCodeResponseCodes = [ResponseCodes.MiskeyDOBAndZIP, ResponseCodes.MiskeyZIP];
const dateOfBirthResponseCodes = [ResponseCodes.MiskeyDOBAndZIP, ResponseCodes.MiskeyDOB];

type MiskeyData = {
    hasDateOfBirth: boolean;
    dateOfBirth?: Date;
    hasZipCode: boolean;
    zipCode?: string;
};

const LexisMiskey = () => {
    const [responseCode, setResponseCode] = useRecoilState(responseCodeState);
    const applicationId = useRecoilValue(selectedApplicationIdState);
    const [, setDecisionsResponse] = useRecoilState(decisionsResponseState);
    const [responseCodeToProcess, setResponseCodeToProcess] = useState<string>();

    const history = useHistory();
    const submitMiskeyMutation = useSubmitMiskey();

    const hasDateOfBirth = dateOfBirthResponseCodes.includes(responseCode as ResponseCodes);
    const hasZipCode = zipCodeResponseCodes.includes(responseCode as ResponseCodes);

    // process response code and redirect
    useProcessResponseCode(responseCodeToProcess);

    const validationSchema = Yup.object().shape({
        hasDateOfBirth: Yup.boolean(),
        dateOfBirth: Yup.date()
            .when('hasDateOfBirth', {
                is: true,
                then: Yup.date()
                    .max(maxBirthdate, 'Birthday must be at least 18 years in the past')
                    .required('Birthday is required'),
            }),
        hasZipCode: Yup.boolean(),
        zipCode: Yup.string()
            .when('hasZipCode', {
                is: true,
                then: Yup.string()
                    .required('Zip Code is required')
                    .min(5, 'Zip Code is required')
                    .max(10, 'Zip Code is required')
                    .test(
                        'zipcode',
                        'Zip Code is required',
                        (value) => value !== ''
                    )
                    .matches(zipCodePattern, 'Must be a U.S. Zip Code')
                    .test('first-five-valid-range', 'Must be a U.S. Zip Code', 
                        (value) =>  {       
                            let zipCodeValue = parseInt((value ?? '0').substring(0,5));                 
                            return zipCodeValue > 500 && zipCodeValue < 99951; 
                    }),
            }) 
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, setValue,  handleSubmit, trigger, formState: { errors, isValid } } = useForm<MiskeyData>({
        ...formOptions,
        defaultValues: {
            hasDateOfBirth,
            hasZipCode,
        },
        mode: 'all',
    });

    const onSubmit = async (event: MiskeyData) => {
        const payload: SubmitMiskeyRequest = {
            applicationID: applicationId!,
            newZIPCode: event.zipCode,
            newDOB: event.dateOfBirth?.toISOString().split('T')[0],
        };
        submitMiskeyMutation.mutate(payload, {
            onSuccess: (response) => {
                setDecisionsResponse(response.data.done);
                setResponseCode(response.data.responseCode);
                setResponseCodeToProcess(response.data.responseCode);
            },
            onError: (error: any) => {
                if (error?.response?.data?.responseCode) {
                    // some error responses will still have a response with responseCode
                    setResponseCode(error.response.data.responseCode);
                    setResponseCodeToProcess(error.response.data.responseCode);
                } else {
                    if (!checkForUnauthorized(error, history)) {
                        // no responseCode found, so show generic error page with option to try again
                        history.replace(routeHashes['/error'], {
                            title: 'Oops, something went wrong processing your application.',
                            buttonText: 'Submit again',
                            navigateTo: routeHashes['/submit-application'],
                        } as ErrorPageProps);
                    }
                }
            },
        });
    };
    
    return (
        <LandingPageLayout>
            <Row>
                <Alert variant='danger'>
                    <img alt='error' src={iconError} role='presentation' className='icon-error' />
                    <span style={{'paddingLeft': '14px'}}>An error has occurred </span>
                </Alert>
            </Row>
            <Row>
                <Col>
                    <div id="header">
                        <h1>Please re-enter the below fields</h1>
                        <div>You may have entered some information incorrectly.</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                {hasZipCode &&
                    <fieldset>
                        <NumberFormatInput
                            type='text'
                            // format="#####-####"
                            noMaterialStyle
                            name='zipCode'
                            placeholder='Zipcode (residential address)'
                            label='Zipcode'
                            aria-label='Zipcode (residential address)'
                            maxLength='10'
                            errors={errors}
                            register={register}
                        />
                    </fieldset>
                }
                {hasDateOfBirth &&
                    <fieldset>
                    <legend className='field-header' style={{ marginBottom: '-2px' }}>Birthday</legend>
                    <DatePicker  
                        name="dateOfBirth"
                        isBirthday={true}
                        setValue={setValue}
                        errors={errors}
                        trigger={trigger}
                    />
                </fieldset>
                }
                </Col>
            </Row>
            <Row className="row mt-36">
                <Col className="col text-center">
                    <Form.Group>
                        <Button
                            disabled={!isValid}
                            onClick={handleSubmit(onSubmit)}
                        >
                            Submit
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
        </LandingPageLayout>
    )   
}

export default LexisMiskey
