import React from 'react';
import NumberFormatInput from '../number-format-input';
import { applicationState } from '../../../store/atoms';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Alert } from 'react-bootstrap';
import { iconError } from '../../../assets/img';
import { IncomeVerificationModel } from '../../types';
import '../../scss/main.scss';

type IncomeFormProps = {
    incomeSatisfied?: boolean;
};

export const IncomeForm = ({ incomeSatisfied }: IncomeFormProps) => {
    const application = useRecoilValue(applicationState);

    const {
        register,
        formState: { errors },
    } = useFormContext<IncomeVerificationModel>();

    return (
        <div className="form-layout">
            <NumberFormatInput
                secure
                spellCheck={false}
                name="housingPayment"
                label="Monthly Rent/Mortgage"
                type="text"
                inputSize="long"
                prefix={'$'}
                suffix={'/mo'}
                allowNegative={false}
                decimalScale={2}
                fixedDecimalScale={true}
                description={
                    <>
                        <label className="label">
                            Monthly Housing/Rent Payment
                        </label>
                        <div>
                            <span className="small-text">
                                This is your share of your current monthly
                                housing payment.
                            </span>
                        </div>
                    </>
                }
                defaultValue={application?.housingPayment}
                stripFormatting={true}
                errors={errors}
                register={register}
            />
            <NumberFormatInput
                secure
                spellCheck={false}
                name="annualIncome"
                label="Total Annual Income"
                type="text"
                inputSize="long"
                prefix={'$'}
                allowNegative={false}
                decimalScale={2}
                fixedDecimalScale={true}
                description={
                    <>
                        <label className="label">Yearly Income</label>
                        <div>
                            <span className="small-text">
                                This is your total income for the year,
                                including earnings from jobs (full-time,
                                part-time, or seasonal), salary, wages, and
                                shared income you can access. You may include
                                alimony, child support, or separate maintenance
                                income, but this is optional.
                            </span>
                        </div>
                    </>
                }
                defaultValue={application?.annualIncome}
                stripFormatting={true}
                errors={errors}
                register={register}
            />
            {!incomeSatisfied && (
                <>
                    <div style={{ paddingTop: '15px' }}>
                        <Alert variant="danger">
                            <img
                                alt="error"
                                src={iconError}
                                role="presentation"
                                className="icon-error"
                            />
                            <span className="total-income-amount-error-message">
                                <strong>Oops!</strong> It looks like you might
                                be forgetting some of your
                                <strong> Total Yearly Income</strong>
                            </span>
                        </Alert>
                    </div>
                </>
            )}
        </div>
    );
};