import React from 'react';
import { HelpTray } from '../../components';

const CreditLineConfirmHelp = () => {
    return (
        <HelpTray>
            <HelpTray.Header>
                Credit Line Confirmation Help
            </HelpTray.Header>
            <HelpTray.Section>
                <HelpTray.SectionText>
                    By submitting your application you are authorizing OpenSky to process the full security deposit, 
                    placing an immediate hold on the funds selected as your credit line. If your application is not 
                    approved, your funds will be posted back to your account within 5 days.
                </HelpTray.SectionText>
            </HelpTray.Section>
            <HelpTray.Section>
            <HelpTray.SectionTitle>Fund my card at a later date</HelpTray.SectionTitle>
                <HelpTray.SectionText>
                    Not a problem - you can still complete this application, but your Secured Visa card will only be sent 
                    to you after you pay your security deposit.
                </HelpTray.SectionText>
            </HelpTray.Section>
        </HelpTray>
    );
}

export default CreditLineConfirmHelp;
