import { BreakPoints } from '../types';

const EMPTY_VALUE = ''

const DELETE = 'Delete'
const BACK_SPACE = 'Backspace'
const ARROW_LEFT = 'ArrowLeft'
const ARROW_RIGHT = 'ArrowRight'
const ARROW_UP = 'ArrowUp'
const ARROW_DOWN = 'ArrowDown'

const KEYLIST = {
    DELETE,
    BACK_SPACE,
    ARROW_LEFT,
    ARROW_RIGHT,
    ARROW_UP,
    ARROW_DOWN
}

const ARROWS = {
    ARROW_DOWN,
    ARROW_LEFT,
    ARROW_RIGHT,
    ARROW_UP
}

export { EMPTY_VALUE, KEYLIST, ARROWS };

export const breakpointSizes = {
    [BreakPoints.xs]: 0,
    [BreakPoints.sm]: 576,
    [BreakPoints.md]: 768,
    [BreakPoints.lg]: 992,
    [BreakPoints.xl]: 1200,
    [BreakPoints.xxl]: 1400,
}

export const queryKeys = {
    addressLookup: ['addressLookup'],
    calculateIncome: ['calculateIncome'],
    getApplicationsStatus: ['getApplicationsStatus'],
    sendCheckAppStatusOtp: ['sendCheckAppStatusOtp'],
    getApplicationPaymentStatus: ['getApplicationPaymentStatus'],
};

export enum CardTypes {
    basic = 'basic',
    plus = 'plus',
    launch = 'launch'
}