import { selector } from 'recoil';
import { CheckApplicationResponse, ConfigurationResponse } from '../types';
import {
    applicationState,
    decisionsResponseState,
    responseCodeState,
    selectedApplicationIdState,
    configurationResponseState
} from './atoms';

export const configurationResponse = selector<ConfigurationResponse | undefined>({
   key: 'configurationResponse' ,
   get: ({ get }) => {
    const configurationResponse = get(configurationResponseState);
    return configurationResponse;
   }
});

export const isLeadSubmitted = selector<boolean | undefined>({
    key: 'isLeadSubmitted',
    get: ({ get }) => {
        const decisionsResponse = get(decisionsResponseState);
        const applicationId = get(selectedApplicationIdState);
        const responseCode = get(responseCodeState);

        return !!(applicationId || decisionsResponse || responseCode);
    }
});

export const selectedApplicationResponse = selector<CheckApplicationResponse | undefined>({
    key: 'selectedApplication',
    get: ({ get }) => {
        const decisionsResponse = get(decisionsResponseState);
        const applicationId = get(selectedApplicationIdState);
        return decisionsResponse?.checkAppResponseList?.find(x => x.applicationID === applicationId);
    }
});

export const emailExists = selector<boolean | undefined>({
    key: 'emailExists',
    get: ({ get }) => {
        return !!get(applicationState).emailAddress;
    }
});
