import React from 'react';
import ApplicationFlowLayout from '../../layouts/application-flow';
import '../../scss/main.scss';
import './fund-later.scss';
import { useRecoilState, useRecoilValue } from 'recoil';
import { applicationState } from '../../../store/atoms';
import { CardTypes } from '../../../utils/constants';
import { Heading, PerkItem } from '../../components';
import {
    iconChart,
    iconCredit,
    iconHouse,
    iconExclamationPink,
} from '../../../assets/img';
import { useHistory } from 'react-router';
import { routeHashes } from '../../../app-routes';
import '../../scss/main.scss';
import { selectedApplicationResponse } from '../../../store/selectors';

const FundLater = () => {
    const history = useHistory();
    const [application] = useRecoilState(applicationState);
    const selectedApplication = useRecoilValue(selectedApplicationResponse);

    const firstName = application.firstName ?? selectedApplication?.firstName;

    const handleFundMyCardButtonClick = () => {
        history.push(routeHashes['/v1_5/funding']);
    };

    const handleFundLaterButtonClick = () => {
        window.location.href =
            process.env.REACT_APP_HOMEPAGE_URL ?? 'https://openskycc.com';
    };

    return (
        <>
            <ApplicationFlowLayout
                hideNavigation={true}
                hideProgress={true}
                progress="100%"
                cardType={application.cardType as CardTypes}
                displayVisaLogo
            >
                <div className="fund-later-layout">
                    <img
                        src={iconExclamationPink}
                        alt="Exclamation pink icon"
                    />
                    <Heading
                        title="Forget to fund?"
                        smallText={
                            firstName +
                            ", you're almost there. Let's get<br/> your card funded."
                        }
                        centerText
                    />
                    <div className="fund-later-perks-container">
                        <div className="fund-later-perks">
                            <div className="fund-later-perks-heading">
                                You're potentially missing out on:
                            </div>
                            <PerkItem
                                heading="Boosting your credit score"
                                smallText="You’ll have an easier time renting an apartment"
                                icon={iconChart}
                            />
                            <PerkItem
                                heading="Gaining access to credit"
                                smallText="Open the door to easy car rentals and hotel stays"
                                icon={iconCredit}
                            />
                            <PerkItem
                                heading="Saving money every month"
                                smallText="Reduce your monthly cost of living with better credit"
                                icon={iconHouse}
                            />
                        </div>
                    </div>
                    <div className="fund-later-footer">
                        <button
                            className="button-no-witdh"
                            onClick={handleFundMyCardButtonClick}
                        >
                            Fund my card
                        </button>
                        <button
                            className="transparent-button-underline-text"
                            onClick={handleFundLaterButtonClick}
                        >
                            I’ll fund later
                        </button>
                    </div>
                </div>
            </ApplicationFlowLayout>
        </>
    );
};

export default FundLater;
