import React, { useEffect, useState } from 'react';
import ApplicationFlowLayout from '../../layouts/application-flow';
import { useRecoilState, useRecoilValue } from 'recoil';
import { applicationState, gaDataLayerState } from '../../../store/atoms';
import { CardTypes } from '../../../utils/constants';
import {
    PageLink,
    Subheadline,
    SecuredSsnEncryptionImage,
    NameModal,
    PhoneModal
} from '../../components';
import {
    phoneAction,
    lockIcon,
    idCardIcon,
    mailboxIn,
    iconCalendarDate,
    cashPayment,
} from '../../../assets/img';
import '../../scss/main.scss';
import './review.scss';
import { useHistory } from 'react-router';
import { routeHashes } from '../../../app-routes';
import { formatRawPhoneNumber } from '../../../utils/phone-numbers';
import Address from '../address';
import Birthdate from '../birthdate';
import Ssn from '../ssn';
import Income from '../income';
import { pushToDataLayer } from '../../../utils/analytics';

const Review = () => {
    const history = useHistory();
    const application = useRecoilValue(applicationState);
    const [homeAddressSmallText, setHomeAddressSmallText] = useState('');
    const [displayNameModal, setDisplayNameModal] = useState(false);
    const [displayPhoneModal, setDisplayPhoneModal] = useState(false);
    const [displayAddressModal, setDisplayAddressModal] = useState(false);
    const [displayBirthdateModal, setDisplayBirthdateModal] = useState(false);
    const [displaySsnModal, setDisplaySsnModal] = useState(false);
    const [displayIncomeModal, setDisplayIncomeModal] = useState(false);
    const [gaDataLayer, setGADataLayer] = useRecoilState(gaDataLayerState);

    useEffect(() => {
        let smallText = application?.homeAddress?.addressLine1 + '<br/>';

        if (application?.homeAddress?.addressLine2)
            smallText += application?.homeAddress?.addressLine2 + '<br/>';

        smallText +=
            application?.homeAddress?.city +
            ', ' +
            application?.homeAddress?.state +
            ', ' +
            application?.homeAddress?.zipCode;
        setHomeAddressSmallText(smallText);
    }, [application?.homeAddress]);

    const handleEditNameButtonClick = () => {
        setDisplayNameModal(true);
    }

    const handleEditPhoneButtonClick = () => {
        setDisplayPhoneModal(true);
    }

    const handleEditAddressButtonClick = () => {
        setDisplayAddressModal(true);
    }

    const handleEditBirthdateButtonClick = () => {
        setDisplayBirthdateModal(true);
    }

    const handleEditSsnButtonClick = () => {
        setDisplaySsnModal(true);
    }

    const handleEditIncomeButtonClick = () => {
        setDisplayIncomeModal(true);
    }

    const handleNextButtonClick = () => {
        const updateDataLayer = {
            ...gaDataLayer,
            'step_name': 'Review',
        };

        setGADataLayer(updateDataLayer);
        pushToDataLayer("application_step_6", updateDataLayer);

        history.push(routeHashes['/v1_5/terms']);
    }

    const handleModalClose = () => {
        if (displayNameModal) {
            setDisplayNameModal(false);
        }
        else if (displayPhoneModal) {
            setDisplayPhoneModal(false);
        }
        else if (displayAddressModal) {
            setDisplayAddressModal(false);
        }
        else if (displayBirthdateModal) {
            setDisplayBirthdateModal(false);
        }
        else if (displaySsnModal) {
            setDisplaySsnModal(false);
        }
        else if (displayIncomeModal) {
            setDisplayIncomeModal(false);
        }
    }

    return (
        <>
            {!displayNameModal &&
             !displayPhoneModal && 
             !displayAddressModal &&
             !displayBirthdateModal &&
             !displaySsnModal &&
             !displayIncomeModal &&
             (<ApplicationFlowLayout
                isArrowBackButton={true}
                cardType={application.cardType as CardTypes}
                displayVisaLogo={true}
                currentStep="7"
                progress="87%"
            >
                <div className="padding-left-24">
                    <div className="main-container">
                        <Subheadline headline="Let's confirm your details" />
                        <div className="page-links-layout">
                            <PageLink
                                icon={idCardIcon}
                                title="Legal Name"
                                smallText={
                                    application.firstName +
                                    ' ' +
                                    application.lastName
                                }
                                buttonText="Edit"
                                onButtonLinkClicked={handleEditNameButtonClick}
                            />
                            <PageLink
                                icon={phoneAction}
                                title="Mobile Number"
                                smallText={formatRawPhoneNumber(application?.primaryPhone || "")}
                                buttonText="Edit"
                                onButtonLinkClicked={handleEditPhoneButtonClick}
                            />
                            <PageLink
                                icon={mailboxIn}
                                title="Home Address"
                                smallText={homeAddressSmallText}
                                buttonText="Edit"
                                onButtonLinkClicked={handleEditAddressButtonClick}
                            />
                            <PageLink
                                icon={iconCalendarDate}
                                title="Date of Birth"
                                smallText={application?.dateOfBirth?.toLocaleDateString(
                                    undefined,
                                    {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    }
                                )}
                                buttonText="Edit"
                                onButtonLinkClicked={handleEditBirthdateButtonClick}
                            />
                            <PageLink
                                icon={lockIcon}
                                title="SSN or ITIN"
                                smallText={application?.ssn}
                                buttonText="Edit"
                                onButtonLinkClicked={handleEditSsnButtonClick}
                            />
                            <PageLink
                                icon={cashPayment}
                                title="Income & Housing"
                                smallText={`$${application.annualIncome}/yr (income)<br/>$${application.housingPayment}/mo (housing)`}
                                buttonText="Edit"
                                onButtonLinkClicked={handleEditIncomeButtonClick}
                            />
                        </div>
                        <SecuredSsnEncryptionImage />
                        <button className="button" onClick={handleNextButtonClick}>Looks good</button>
                    </div>
                </div>
            </ApplicationFlowLayout>)}
            {displayNameModal && <NameModal onClose={handleModalClose} />}
            {displayPhoneModal && <PhoneModal onClose={handleModalClose} />}
            {displayAddressModal && <Address isModal onModalClose={handleModalClose} />}
            {displayBirthdateModal && <Birthdate isModal onModalClose={handleModalClose} />}
            {displaySsnModal && <Ssn isModal onModalClose={handleModalClose} />}
            {displayIncomeModal && <Income isModal onModalClose={handleModalClose} />}
        </>
    );
};

export default Review;