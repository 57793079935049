import React from 'react';
import { LandingPageLayout } from '../../layouts';
import './process-application.scss';

const ProcessApplication = () => {
    return (
        <LandingPageLayout>
            <div className="process-application-container">
                <div className="spinner">
                    <div className="inner-circle" />
                    <div className="circle-corner" />
                </div>
                <div id="header">
                    <h1>Please wait as we process your application</h1>
                    <p>Shouldn't be long, please keep this page open.</p>
                </div>
            </div>
        </LandingPageLayout>
    );
};
export default ProcessApplication;
