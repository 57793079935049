import React from 'react';
import './disclosures.scss';

type PromoDisclosureOptions = {
    listIndexSymbol?: string;    
};

const ApprovalDisclosure : React.FC<PromoDisclosureOptions> = ({listIndexSymbol}) => {
    return (
        <>
            <li style={{listStyle: 'none'}}>
                <p className='disclosures-text'>{listIndexSymbol ?? '*'}Your application may be rejected if any of the following apply: You are a non-United States resident; you are under 18 years old; the social security number you provide is invalid or we are unable to verify your identity under federal law; your monthly expenses are greater than your monthly income; you lack sufficient income or assets to pay the monthly payment required for this card; you have two or more open credit card accounts with Capital Bank; or your account history on any current or previous relationship with Capital Bank is not in good standing.</p>
            </li>
        </>
    );
}

export default ApprovalDisclosure;
