import React from "react";
import "./value-props.scss";

interface ValuePropItem {
    valuePropIconSource: string;
    valuePropText: string;
}

interface ValueProps {
    valuePropItems: ValuePropItem[];
}

const ValueProps: React.FC<ValueProps> = ({
    valuePropItems
}) => {
    const renderValueProps = () => {
        return valuePropItems.map((value, index) => {
            return (
                <div className='value-prop-item' key={index}>
                    <img src={value.valuePropIconSource} className='value-prop-icon' />
                    <p className='value-prop-text'>{value.valuePropText}</p>
                </div>)
        });
    }

    return (
        <div className='value-props-container'>
            {renderValueProps()}
        </div>);
}

export default ValueProps;