import React from 'react';
import { Alert } from 'react-bootstrap';
import { iconError } from '../../../assets/img';

const FundingErrorAlert = () => {
    return (
        <>
            <Alert variant="danger">
                <img
                    alt="error"
                    src={iconError}
                    role="presentation"
                    className="icon-error"
                />
                <span style={{ paddingLeft: '14px' }}>
                    An error has occurred! We were unable to process your card payment. The information you provided was not verified by your bank. Please review your billing and card information for accuracy.
                </span>
            </Alert>
        </>
    );
};

export default FundingErrorAlert;
