import { useQuery } from "react-query";
import { smartyAutocompleteClient } from '../api-clients';
import { queryKeys } from '../utils/constants';
import { debuglog } from '../utils/debug';


type Address = {
    street_line: string,
    secondary: string,
    city: string,
    state: string,
    zipcode: string,
    entries: number,
}

type AddressLookupData = {
    suggestions: Address[],
};

const smartyApiKey = process.env.REACT_APP_SMARTY_API_KEY;

const fetchAddressLookup = async (search?: string): Promise<AddressLookupData> => {
    debuglog('fetching address...');
    const res = await smartyAutocompleteClient.get<AddressLookupData>(`/lookup?key=${smartyApiKey}&search=${search}`);
    debuglog('res', res);
    
    const suggestions = res.data.suggestions;

    return { suggestions };
};

export const useAddressLookup = (search?: string, validPattern?: RegExp) => {
    let keepPreviousData;
    if (search && validPattern && !validPattern.test(search)) {
        search = '';
        keepPreviousData = false;
    } else {
        keepPreviousData = true;
    }

    return useQuery<AddressLookupData, Error>(
        [...queryKeys.addressLookup, search],
        () => fetchAddressLookup(search),
        {
            staleTime: Infinity,
            cacheTime: Infinity,
            retry: false,
            keepPreviousData: keepPreviousData,
            enabled: !!search
        }
    );
};
