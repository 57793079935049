import React from "react";
import { creditKarmaLogo, experianLogo, visaLogoBlack } from "../../assets/img";
import './affiliate-logo.scss';

type AffiliateLogoOptions = {
    source?: string
}

const AffiliateLogo: React.FC<AffiliateLogoOptions> = ({ source }) => {
    let imageLogo;

    switch(source?.toLowerCase().replace(/ /g, '')){
        case 'creditkarma': 
            imageLogo = creditKarmaLogo;
            break;
        case 'experian':
            imageLogo = experianLogo;
            break;
        default:
            imageLogo = visaLogoBlack;
    }

    return (
    <>
        <img src={imageLogo}
             alt={imageLogo !== visaLogoBlack ? source : 'Visa'}
             className={imageLogo !== visaLogoBlack ? 'affiliate-logo' : ''}
            />
    </>)
}

export default AffiliateLogo;