import React from 'react';
import { HelpTray } from '../../components';

const MailingAddressHelp = () => {
    return (
        <HelpTray>
            <HelpTray.Header>
                Mailing Address Help
            </HelpTray.Header>
            <HelpTray.Section>
                <HelpTray.SectionTitle>
                    Federal law requires us to obtain, verify, and record information that identifies 
                    each person who opens an account.
                </HelpTray.SectionTitle>
            </HelpTray.Section>
            <HelpTray.Section>
                <HelpTray.SectionTitle>Home Address / P.O. Box</HelpTray.SectionTitle>
                <HelpTray.SectionText>
                    In order to help the government fight the funding of terrorism and money laundering 
                    activities, your home street address is required for identification purposes. You may 
                    provide a P.O Box in addition to your home street.
                </HelpTray.SectionText>
            </HelpTray.Section>
        </HelpTray>
    );
}

export default MailingAddressHelp;
