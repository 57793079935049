const currentYear = new Date().getFullYear();

const maxBirthdate = new Date();
maxBirthdate.setFullYear(maxBirthdate.getFullYear() - 18);

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const daysInMonth = (year?: string, monthIndex?: string) => {
    if (!monthIndex) {
        return 31;
    }
    if (!year) {
        // use a leap year to make sure Feb offers 29 days if no year selected
        return new Date(2020,  parseInt(monthIndex, 10) + 1, 0).getDate();
    }
    return new Date(parseInt(year, 10),  parseInt(monthIndex, 10) + 1, 0).getDate();
}

export {
    currentYear,
    daysInMonth,
    maxBirthdate,
    monthNames,
};
