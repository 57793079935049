import crypto from 'crypto';

export const generateSHA256Hash = (input: string) => {
    if (input === '') {
        return undefined;
    }
    const hash = crypto.createHash('sha256');
    hash.update(input);
    return hash.digest('hex');
}
