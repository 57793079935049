import React from 'react';
import { HelpTray } from '../../components';
import { iconMastercard, iconVisa } from '../../assets/img';

const FundingSourceHelp = () => {
    return (
        <HelpTray>
            <HelpTray.Header>
                Funding Source Help
            </HelpTray.Header>
            <HelpTray.Section>
                <HelpTray.SectionText>
                    Using your debit card is the most convenient way to fund your security deposit which 
                    equals your credit line amount. By submitting your application you are authorizing OpenSky 
                    to process the full security deposit, placing an immediate hold on the funds selected as 
                    your credit line. If your application is not approved, your funds will be posted back to 
                    your account within 5 days. 
                </HelpTray.SectionText>
            </HelpTray.Section>
            <HelpTray.Section>
                <HelpTray.SectionTitle>Accept Debit Cards</HelpTray.SectionTitle>
                <HelpTray.SectionText>
                <img src={iconVisa} alt='' />
                <img src={iconMastercard} alt='' />
                </HelpTray.SectionText>
            </HelpTray.Section>
        </HelpTray>
    );
}

export default FundingSourceHelp;
