import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ApplicationFlowLayout from '../../layouts/application-flow';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    applicationState,
    decisionsResponseState,
    gaDataLayerState,
    responseCodeState,
    selectedApplicationIdState,
    sendCheckAppStatusOtpRequestState,
} from '../../../store/atoms';
import { CardTypes } from '../../../utils/constants';
import { ProcessingMessage } from '../../components';
import './processing-application.scss';
import { useHistory } from 'react-router';
import { useSubmitApplication } from '../../../pages/submit-application/hooks';
import { useProcessResponseCode } from '../../../hooks';
import {
    ErrorPageProps,
    FundingPageProps,
    MakePaymentRequest,
    ResponseCodes,
    SubmitLeadRequest,
    YesNo,
} from '../../../types';
import { checkForUnauthorized, routeHashes } from '../../../app-routes';
import { unformatSSN } from '../../utils/formats';
import '../../scss/main.scss';
import { useMakePayment } from '../../../pages/funding-source/hooks';
import { pushToDataLayer } from '../../../utils/analytics';

const ProcessingApplication = () => {
    const [, setResponseCode] = useRecoilState(responseCodeState);
    const application = useRecoilValue(applicationState);
    const [, setApplicationId] = useRecoilState(selectedApplicationIdState);
    const [, setDecisionsResponse] = useRecoilState(decisionsResponseState);
    const [responseCodeToProcess, setResponseCodeToProcess] =
        useState<string>();
    const sendCheckAppStatusOtpRequest = useRecoilValue(
        sendCheckAppStatusOtpRequestState
    );
    const applicationId = useRecoilValue(selectedApplicationIdState);

    const location = useLocation();
    const isDeposit = location.pathname.includes('transaction');

    const history = useHistory();
    const submitApplicationMutation = useSubmitApplication();
    const makePaymentMutation = useMakePayment();
    const [gaDataLayer, setGADataLayer] = useRecoilState(gaDataLayerState);

    useProcessResponseCode(responseCodeToProcess, '1.5');

    const updateDataLayerAndPush = (responseCode: ResponseCodes) => {
        if(isDeposit){
            const updateDataLayer = {
                ...gaDataLayer,
                'card_fund_result': responseCode === ResponseCodes.ApplicationInGoodFunds ? 'funded' : 'see_email',
                'step_name': 'App Funded',
            }
        
            setGADataLayer(updateDataLayer);
        
            pushToDataLayer('application_step_10', updateDataLayer);
        }
        else{
            const updateDataLayer = {
                ...gaDataLayer,
                'step_name': 'App Submitted',
                'card_fund_result': responseCode === ResponseCodes.ApplicationInWaitingForFunds ? undefined : 'see_email'
            }
        
            setGADataLayer(updateDataLayer);
        
            pushToDataLayer('application_step_8', updateDataLayer);
        }
    }

    useEffect(() => {
        const month = (application.dateOfBirth?.getMonth() ?? 0) + 1;
        const dobMonth = month.toString().padStart(2, '0');
        const dobDay = application.dateOfBirth
            ?.getDate()
            ?.toString()
            ?.padStart(2, '0');

        if (isDeposit) {
            const makePaymentPayload: MakePaymentRequest = {
                /* TODO: Check if the billing address name is where the name is transmitted on the back end */
                // cardHolderFirstName: event.cardHolderFirstName!,
                // cardHolderLastName: event.cardHolderLastName,
                cardNumber: application.cardNumber?.replace(/\s+/g, "")!,
                expirationDateMMYY: application.cardExpiration!,
                cvv: application.cardSecurityCode!,
                amount: application.amount ?? 0,
                email:
                    application?.emailAddress ??
                    sendCheckAppStatusOtpRequest?.emailAddress ??
                    '',
                billingAddress: {
                    firstName: application.cardHolderFirstName ?? '',
                    lastName: application.cardHolderLastName ?? '',
                    line1: application.bankingAddress?.addressLine1!,
                    city: application.bankingAddress?.city!,
                    state: application.bankingAddress?.state!,
                    zip: application.bankingAddress?.zipCode!,
                },
                attemptNumber: 0,
                isFullyFunded: true,
                version: 1.5
            };

            makePaymentMutation.mutate(
                { applicationId: applicationId!, payload: makePaymentPayload },
                {
                    onSuccess: (response) => {
                        updateDataLayerAndPush(response.data.responseCode as ResponseCodes);
                        setResponseCodeToProcess(response.data.responseCode);
                    },
                    onError: (error: any) => {
                        updateDataLayerAndPush(error.response.data.responseCode as ResponseCodes as ResponseCodes);
                        if (
                            error?.response?.data?.responseCode ===
                            ResponseCodes.ExceededDailyPaymentTransactions
                        ) {
                            setResponseCodeToProcess(
                                error.response.data.responseCode
                            );
                        } else if (!checkForUnauthorized(error, history)) {
                            history.replace(routeHashes['/v1_5/funding'], {
                                paymentError: true,
                            } as FundingPageProps);
                        }
                    },
                }
            );
        } else {
            const submitApplicationPayload: SubmitLeadRequest = {
                instanceId: application.instanceId,
                id: application.leadId!,
                isEmailVerified: !application.phoneConsent,
                applicationId: null,
                ignoreEmailVerificationErrors: true,
                submitPayment: true,
                ignorePaymentErrors: false,
                incentiveId: application.incentiveId ?? null,
                incentiveName: application.incentiveName ?? null,
                downsellFrom: application.downsellFrom,
                basicInfo: {
                    firstName: application.firstName!,
                    lastName: application.lastName!,
                    emailAddress: application.emailAddress!,
                    primaryPhone: application.primaryPhone!,
                    primaryPhoneType: application.primaryPhoneType!,
                    phoneConsent: application.phoneConsent!,
                },
                dti: {
                    monthlyHousingPayment: application.housingPayment!,
                    income: application.annualIncome!,
                    minimumEligibility: application.minimumIncome === YesNo.Yes,
                    incomeConfirmation: application.annualIncomeValidated, //TODO: set to true in income page,
                },
                creditLine: {
                    creditLimit: null,
                },
                payment: {
                    debitCardNumber: null,
                    debitCvv2CvcCode: null,
                    debitExpirationMonth: null,
                    debitExpirationYear: null,
                    debitAmount: null,
                    debitFirstName: null,
                    debitLastName: null,
                    attemptNumber: 0,
                    fundingType: 'FDL',
                    isFullyFunded: false,
                    debitTransId: null,
                    debitAuthCode: null,
                    debitAuthorizedFlag: null,
                    debitSettleFlag: null,
                    debitAuthApprovedAmount: null,
                    debitAuthorizationCode: null,
                    debitAuthRefTransId: null,
                    debitAuthRequestedAmount: null,
                    debitAvsResponse: null,
                    debitCavResponse: null,
                },
                terms: {
                    esignConsent: application.eSignConsent!,
                    termsConsent: application.termsAndConditions!,
                },
                mailToType: 'home',
                mailingAddress: application.mailingAddress
                    ? {
                          mailingAddress:
                              application.mailingAddress?.addressLine1!,
                          mailingAptSuite:
                              application.mailingAddress?.addressLine2 ?? null,
                          mailingCity: application.mailingAddress?.city!,
                          mailingState: application.mailingAddress?.state!,
                          mailingZip: application.mailingAddress?.zipCode!,
                      }
                    : null,
                homeAddress: {
                    homeAddress: application.homeAddress?.addressLine1!,
                    aptSuite: application.homeAddress?.addressLine2 ?? null,
                    city: application.homeAddress?.city!,
                    state: application.homeAddress?.state!,
                    zipCode: application.homeAddress?.zipCode!,
                },
                billingAddress: {
                    debitFirstName: application.firstName!,
                    debitLastName: application.lastName!,
                    debitAddress: application.bankingAddress?.addressLine1!,
                    debitAptSuite:
                        application.bankingAddress?.addressLine2 ?? null,
                    debitCity: application.bankingAddress?.city!,
                    debitState: application.bankingAddress?.state!,
                    debitZip: application.bankingAddress?.zipCode!,
                },
                pii: {
                    ssn: unformatSSN(application.ssn || ''),
                    dateOfBirth: `${dobMonth}/${dobDay}/${application.dateOfBirth?.getFullYear()}`,
                },
                version: 1.5,
            };

            submitApplicationMutation.mutate(submitApplicationPayload, {
                onSuccess: (response) => {
                    const baseUrl = window.location.origin;
                    const isLowerEnv =
                        baseUrl.includes('localhost') ||
                        baseUrl.includes('uat') ||
                        baseUrl.includes('dev');

                    setApplicationId(response.data.applicationId);
                    setDecisionsResponse(response.data.done);
                    updateDataLayerAndPush(response.data.responseCode as ResponseCodes);
                    setResponseCode(response.data.responseCode);

                    // Only for testing purposes in lower envs.
                    if (
                        isLowerEnv &&
                        application.firstName === 'Crystal' &&
                        application.lastName === 'Goodley' &&
                        response.data.responseCode ===
                            ResponseCodes.ApplicationInDocumentRequest
                    ) {
                        history.push(routeHashes['/v1_5/funding']);
                    } else {
                        setResponseCodeToProcess(response.data.responseCode);
                    }
                },
                onError: (error: any) => {
                    if (error?.response?.data?.responseCode) {
                        setResponseCode(error.response.data.responseCode);
                        updateDataLayerAndPush(error.response.data.responseCode as ResponseCodes);
                        setResponseCodeToProcess(
                            error.response.data.responseCode
                        );
                    } else {
                        if (!checkForUnauthorized(error, history)) {
                            if (error?.response?.status === 400) {
                                history.replace(routeHashes['/error'], {
                                    title: 'Oops, something went wrong processing your application.',
                                    buttonText: 'Ok, Got it',
                                    navigateTo: `${process.env.REACT_APP_HOMEPAGE_URL}`,
                                } as ErrorPageProps);
                            } else {
                                history.replace(routeHashes['/error'], {
                                    title: 'Oops, something went wrong processing your application.',
                                    buttonText: 'Submit again',
                                    navigateTo:
                                        routeHashes['/submit-application'],
                                } as ErrorPageProps);
                            }
                        }
                    }
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [application]);

    return (
        <ApplicationFlowLayout
            hideProgressBar
            isArrowBackButton={!isDeposit}
            cardType={application.cardType as CardTypes}
            displayVisaLogo
            hideProgress={isDeposit}
            currentStep={isDeposit ? '' : '8'}
            hideNavigation={isDeposit}
        >
            <div className="center-content">
                <div className="processing-application-layout">
                    {isDeposit ? (
                        <ProcessingMessage
                            message="Making your deposit"
                            smallText="Shouldn’t be long, please keep this page open."
                        />
                    ) : (
                        <ProcessingMessage
                            message="Please wait as we<br/> process your<br/> application"
                            smallText="Shouldn’t be long, please keep this page open."
                        />
                    )}
                </div>
            </div>
        </ApplicationFlowLayout>
    );
};

export default ProcessingApplication;
