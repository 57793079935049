import React from 'react';
import { LandingPageLayout } from '../../layouts';
import { Intro } from '../../components';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { applicationState, launchCardTunerdOnState } from '../../store/atoms';
import { routeHashes } from '../../app-routes';
import { useHistory } from 'react-router-dom';
import { configurationResponseState } from '../../store/atoms'
import { currentCardInfo } from '../../utils/product-info';
import { iconBlueCheck } from '../../assets/img';
import { isIncentiveFeatureActive, getPrn } from '../../utils/incentives';
import { IncentiveFeatures } from '../../types';
import * as FullStory from '@fullstory/browser';
import { useLocation } from 'react-router-dom';

const IntroGeneric = () => {
    const history = useHistory();
    const location = useLocation();
    const setApplication = useSetRecoilState(applicationState);

    const configurationResponse = useRecoilValue(configurationResponseState);
    const launchCardTunedOn = useRecoilValue(launchCardTunerdOnState);
    
    const basicCardInfo = currentCardInfo('basic', configurationResponse?.securedProducts);
    const plusCardInfo = currentCardInfo('plus', configurationResponse?.securedProducts);
    const launchCardInfo = currentCardInfo('launch', configurationResponse?.securedProducts);

    const basicAnnualFee = '35';
    const plusAnnualFee = '0';
    const launchAnnualFee = '24';

    const basicMinimumDeposit = 200;
    const plusMinimumDeposit = 300;
    const launchMinimumDeposit = 100;

    const launchCreditLimit = 100;
    const basicCreditLimit = 200;
    const plusCreditLimit = 300;

    const launchProgramFee = 19.95;

    const isNewVersion = location.pathname.includes('/v1_5/');

    const basicButtonClick = () => {
        setApplication((current) => {
            return {
                ...current,
                ...{
                    cardType: 'basic',
                    fundToday: true,
                    annualFee: basicAnnualFee,
                    defaultCreditLimit: basicCreditLimit,
                    amount: undefined
                },
            };
        });

        FullStory.event('AppIntroGetStarted', {});

        if(isNewVersion)
        {
            history.push(routeHashes['/v1_5/basic-info']);
        }
        else
        {
            history.push(routeHashes['/basic-info']);
        }
    }

    const plusButtonClick = () => {
        setApplication((current) => ({
            ...current,
            ...{
                cardType: 'plus',
                fundToday: true,
                annualFee: plusAnnualFee,
                defaultCreditLimit: plusCreditLimit,
                amount: undefined
            },
        }));

        FullStory.event('Plus_AppIntroGetStarted', { });

        if(isNewVersion)
        {
            history.push(routeHashes['/v1_5/basic-info']);
        }
        else
        {
            history.push(routeHashes['/basic-info']);
        }
    }

    const launchButtonClick = () => {
        setApplication((current) => ({
            ...current,
            ...{
                cardType: 'launch',
                fundToday: true,
                annualFee: launchAnnualFee,
                programFee: launchProgramFee,
                defaultCreditLimit: launchCreditLimit,
                securityDeposit: launchMinimumDeposit,
            },
        }));

        FullStory.event('Launch_AppIntroGetStarted', { });

        if(isNewVersion)
        {
            history.push(routeHashes['/v1_5/basic-info']);
        }
        else
        {
            history.push(routeHashes['/basic-info']);
        }
    }

    return (
        <LandingPageLayout
            showLaunchAnnualFeeDisclosure={true}
            showRewardsDisclosure={true}
            showBasicEligibleDisclosure={isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn("basic"), configurationResponse)}
            showPlusEligibleDisclosure={isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn("plus"), configurationResponse)}
            promoLaunchAnnualFeeDisclosureIndex='**'
            promoRewardsDisclosureIndex='*'
            promoBasicEligibleDisclosureIndex='***'
            promoPlusEligibleDisclosureIndex='****'
            showFDIC={true}
        >
            <div className="row">
                <div className="col" id="header">
                    <h1>
                        Choose your path
                    </h1>
                    <p className="intro" style={{fontWeight: 'bold'}}><img src={iconBlueCheck} alt=""/> Zero risk to apply.</p>
                </div>
            </div>
            <Intro
                cardType={'basic'}
                annualFee={basicAnnualFee}
                //minimumDeposit={basicCardInfo.minimumDeposit}
                minimumDeposit={basicMinimumDeposit}
                apr={basicCardInfo.annualPercentageRate}
                buttonClick={basicButtonClick}
                buttonText={'Get Started'}
                promo={isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn("basic"), configurationResponse)}
                tclink={basicCardInfo.currentTermsConditions}
            />
            <div className="intro-gap"></div>
            {!isNewVersion && launchCardTunedOn && (<Intro
                cardType={'launch'}
                annualFee={launchAnnualFee}
                //minimumDeposit={launchCardInfo.minimumDeposit}
                minimumDeposit={launchMinimumDeposit}
                apr={launchCardInfo.annualPercentageRate}
                buttonClick={launchButtonClick}
                buttonText={'Get Started'}
                programFee={launchProgramFee}
                promo={isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn("launch"), configurationResponse)}
                tclink={launchCardInfo.currentTermsConditions}
            />)}
            <div className="intro-gap"></div>
            {!isNewVersion && <Intro
                cardType={'plus'}
                annualFee={plusAnnualFee}
                //minimumDeposit={plusCardInfo.minimumDeposit}
                minimumDeposit={plusMinimumDeposit}
                apr={plusCardInfo.annualPercentageRate}
                buttonClick={plusButtonClick}
                buttonText={'Get Started'}
                promo={isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn("plus"), configurationResponse)}
                tclink={plusCardInfo.currentTermsConditions}
            />}
        </LandingPageLayout>
    );
};

export default IntroGeneric;
