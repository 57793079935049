import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import * as Yup from 'yup';
import { safety } from '../../assets/img';
import { AddressForm } from '../../components';
import { ApplicationFlowLayout } from '../../layouts';
import { applicationState, configurationResponseState, gaDataLayerState } from '../../store/atoms';
import { IncentiveFeatures, MailingAddressModel } from '../../types';
import { isPOBoxRRPattern, zipCodePattern } from '../../utils/validations';
import { isIncentiveFeatureActive, getPrn } from '../../utils/incentives';
import { pushToDataLayer } from '../../utils/analytics';
import MailingAddressHelp from './mailing-address-help';
import { ModalPage } from '../../components';
import { useWindowDimensions } from '../../hooks';
import { routeHashes } from '../../app-routes';
import './mailing-address.scss';
import * as FullStory from '@fullstory/browser';

enum MailToType {
    Home = 'home',
    POBox = 'pobox',
};

const MailingAddress = () => {
    const [application, setApplication] = useRecoilState(applicationState);
    const [showAddressLookup, setShowAddressLookup] = useState(false);
    const [showCaution, setShowCaution] = useState(false);
    let [gaDataLayer, setGADataLayer] = useRecoilState(gaDataLayerState);
    const history = useHistory();
    const dimensions = useWindowDimensions();
    const configurationResponse = useRecoilValue(configurationResponseState);

    const validationSchema = Yup.object().shape({
        mailToType: Yup.string()
            .oneOf(Object.values(MailToType))
            .required()
            .default(() => application.mailToType),
        mailingAddress: Yup.object()
            .when('mailToType', {
                is: MailToType.POBox,
                then: Yup.object()
                    .shape({
                        addressLine1: Yup.string()
                            .required('Please enter your street address')
                            .matches(/^(PO Box|RR)/ , 'Addresses must start with PO Box or RR.')
                            .matches(isPOBoxRRPattern , 'Addresses must start with PO Box or RR.')
                            .max(50, 'Total Exceeds Maximum Characters')
                            .default(() => application.homeAddress?.addressLine1 || ''),
                        city: Yup.string()
                            .required('City is required')
                            .matches(/^[A-Za-z0-9 -,.]+$/ , 'Must contain only letters and numbers')
                            .max(18, 'Total Exceeds Maximum Characters')
                            .default(() => application.homeAddress?.city || ''),
                        state: Yup.string()
                            .required('State is required')
                            .matches(/^[A-Za-z]+$/ , 'Must contain only letters')
                            .max(2, 'Total Exceeds Maximum Characters')
                            .default(() => application.homeAddress?.state || ''),
                        zipCode: Yup.string()
                            .required('Zip Code is required')
                            .min(5, 'Zip Code is required')
                            .max(10, 'Zip Code is required')
                            .test(
                                'zipcode',
                                'Zip Code is required',
                                (value) => value !== ''
                            )
                            .matches(zipCodePattern, 'Must be a U.S. Zip Code')
                            .test('first-five-valid-range', 'Must be a U.S. Zip Code', 
                                (value) =>  {       
                                    let zipCodeValue = parseInt((value ?? '0').substring(0,5));                 
                                    return zipCodeValue > 500 && zipCodeValue < 99951; 
                            })
                            .default(() => application.homeAddress?.zipCode || ''),
                        addressLine2: Yup.string().default(
                            () => application.homeAddress?.addressLine2 || ''
                        ),
                    })
            }),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const {
        handleSubmit,
        setValue,
        register,
        trigger,
        formState: { isValid, errors },
    } = useForm<MailingAddressModel>({
        ...formOptions,
        defaultValues: validationSchema.getDefault(),
        mode: 'all',
    });

    const onSubmit = async (event: MailingAddressModel) => {
        const mailingAddress = event.mailToType === MailToType.POBox
            ? event.mailingAddress
            : undefined;
        const mailToType = event.mailToType;

        switch (application?.cardType) {
            case "basic": {
                FullStory.event('SendCardNext', { leadId: application.leadId });
                break;
            }
            case "plus": {
                FullStory.event('Plus_SendCardNext', { leadId: application.leadId });
                break;
            }
            case "launch": {
                FullStory.event('Launch_SendCardNext', { leadId: application.leadId });
                break;
            }
            default: {
                FullStory.event('SendCardNext', { leadId: application.leadId });
                break;
            }
        }

        const updateDataLayer = {
            ...gaDataLayer,
            'step_name': 'Mailing Address',
        };
        setGADataLayer(updateDataLayer);
        pushToDataLayer("application_step_6", updateDataLayer);

        setApplication((current) => ({
            ...current,
            mailingAddress,
            mailToType,
        }));
        //Forward Credit-Line-Confirm & funding section to end of process
        history.push(routeHashes['/funding-source']);
    };

    const handleEditHomeAddress = () => {
        history.replace(routeHashes['/identity-verification']);
    };

    const handleCloseCaution = () => {
        setShowCaution(false);
    };

    return (
        <ApplicationFlowLayout
            showBackButton
            HelpComponent={MailingAddressHelp}
            progress="70%"
            cardType={application.cardType}
            currentStep="7"
            promo={isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn(application.cardType), configurationResponse)}
            showBasicInfoFooter={true}
        >
            {!showCaution && <Row>
            <Row className="no-bottom-margin">
                <Col>
                    <div id="header" className="no-bottom-margin">
                        <h1>Where should we send your card?</h1>
                        <p>
                            We will use this address to send all OpenSky mail, such
                            as cards, statements and documents.
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className="fs-exclude">
                <Col>
                    <div className="option-container">
                        <Form.Group>
                            <Form.Check
                                {...register('mailToType')}
                                type="radio"
                                label={
                                    <p>
                                        {application.firstName}{' '}
                                        {application.lastName}{' '}
                                        <span
                                            className="edit-address"
                                            onClick={() => setShowCaution(true)}
                                        >
                                            Edit
                                        </span>
                                        <br />
                                        {application.homeAddress?.addressLine1}
                                        <br />
                                        {!!application.homeAddress
                                            ?.addressLine2 && (
                                            <>
                                                {
                                                    application.homeAddress
                                                        ?.addressLine2
                                                }
                                                <br />
                                            </>
                                        )}
                                        {application.homeAddress?.city},{' '}
                                        {application.homeAddress?.state}{' '}
                                        {application.homeAddress?.zipCode}
                                    </p>
                                }
                                value="home"
                                id="homeAddress"
                                name="mailToType"
                                onClick={() => setShowAddressLookup(false)}
                            />
                            <hr />
                            <Form.Check
                                {...register('mailToType')}
                                type="radio"
                                label={'Send my card to a P.O. Box or Rural Route Address'}
                                value="pobox"
                                id="poboxAddress"
                                name="mailToType"
                                onClick={(e) => setShowAddressLookup(true)}
                            />

                            {/* <FormErrorMessage errors={errors} name={name} /> */}
                        </Form.Group>
                        {showAddressLookup && (
                            <fieldset>
                                <legend className="field-header">
                                    P.O. Box / R.R. Route Address Only
                                </legend>
                                <AddressForm
                                    labels={{
                                        addressLookupLabel:
                                            'Enter your P.O. or R.R. Route address',
                                        addressLookupFloatingLabel:
                                            'Search for P.O./R.R. Route Address',
                                        addressLine1Label:
                                            'PO/RR Route Address',
                                    }}
                                    register={register}
                                    name="mailingAddress"
                                    setValue={setValue}
                                    errors={errors}
                                    trigger={trigger}
                                    validPattern={isPOBoxRRPattern}
                                />
                            </fieldset>
                        )}
                    </div>
                </Col>
            </Row>
            <Row className="row mt-36">
                <Col className="col text-center">
                    <Form.Group>
                        <Button
                            type="submit"
                            disabled={!isValid}
                            onClick={handleSubmit(onSubmit)}
                        >
                            Next
                        </Button>
                    </Form.Group>
                </Col>
            </Row>
                </Row>}
            {showCaution && (
                <ModalPage breakpoint={dimensions.breakpoint}>
                    <Row>
                        <Col className="help-body">
                            <img className={`${dimensions.breakpoint > 0 ? "center": "safety-uncentered"}`} alt="safety" src={safety} />
                            <br />
                            <p className="intro text-center">
                                For your security and safety, we only send your card to
                                a Home Address, P.O. Box or Rural Route Address that is registered to
                                you.
                            </p>
                            <hr />
                            <p className="body-message">
                                To edit your mailing address please update the home
                                address you provided on the verification information
                                page.
                            </p>
                            <Button className='center' onClick={handleEditHomeAddress}>
                                Edit my home address
                            </Button>
                            <Button className='center' onClick={handleCloseCaution} variant="outline-dark">
                                Keep my address the same
                            </Button>
                        </Col>
                    </Row>
                </ModalPage>
            )}
        </ApplicationFlowLayout>
    );
};

export default MailingAddress;
