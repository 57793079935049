import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { iconChevronLeft } from '../../assets/img';
import { 
    Banner,
    ModalPage,
    BasicEligibleDisclosure,
    PlusEligibleDisclosure,
    PreApprovedDisclosure,
    AlternateEligibleDisclosure,
    TestimonialDisclosure,
    IncreaseLimitDisclosure,
    RewardsDisclosure,
    AffiliateLogo,
} from '../../components';
import { useWindowDimensions } from '../../hooks';
import { BreakPoints, ProgressUnits, ErrorPageProps } from '../../types';
import { timeoutExpiredState, utmSourceNameState } from '../../store/atoms'
import { routeHashes } from '../../app-routes';
import { fdic, logoOpenskyNoPadding } from '../../assets/img';
import './application-flow.scss';
import { debuglog } from '../../utils/debug';
import versionInfo from '../../buildVersion.json';

const webVersion: string = (versionInfo) ? `${versionInfo.buildVersionMajor}.${versionInfo.buildMinorVersion}.${versionInfo.buildRevision}` : "1-0-350";

export type ApplicationFlowLayoutProps = {
    showBackButton?: boolean;
    showHelpNow?: boolean;
    showBasicInfoFooter?: boolean;
    footerBasicEligibleDisclosure?: boolean;
    standaloneBasicEligibleDisclosure?: boolean;
    footerPlusEligibleDisclosure?: boolean;
    standalonePlusEligibleDisclosure?: boolean;
    footerPreApprovedDisclosure?: boolean;
    standalonePreApprovedDisclosure?: boolean;
    footerRewardsDisclosure?: boolean;
    standaloneRewardsDisclosure?: boolean;
    footerAlternateEligibleDisclosure?: boolean;
    standaloneAlternateEligibleDisclosure?: boolean;
    footerTestimonialDisclosure?: boolean;
    standaloneTestimonialDisclosure?: boolean;
    footerIncreaseLimitDisclosure?: boolean;
    standaloneIncreaseLimitDisclosure?: boolean;
    onHelpTrayClosed?: () => void;
    HelpComponent?: React.ComponentType;
    progress?: ProgressUnits;
    currentStep?: string;
    backgroundImage?: string;
    cardType?: string;
    promo?: boolean;
    promoBasicEligibleDisclosureIndex?: string;
    promoPlusEligibleDisclosureIndex?: string;
    promoPreApprovedDisclosureIndex?: string;
    promoRewardsDisclosureIndex?: string;
    promoAlternateEligibleDisclosureIndex?: string;
    promoTestimonialDisclosureIndex?: string;
    promoIncreaseLimitDisclosureIndex?: string;
    hideCardTypeNameInHeader?: boolean;
};

const ApplicationFlowLayout: React.FC<ApplicationFlowLayoutProps> = ({
    showBackButton,
    showHelpNow,
    showBasicInfoFooter,
    onHelpTrayClosed,
    HelpComponent,
    progress,
    cardType,
    currentStep,
    children,
    backgroundImage,
    promo,
    footerBasicEligibleDisclosure,
    standaloneBasicEligibleDisclosure,
    footerPlusEligibleDisclosure,
    standalonePlusEligibleDisclosure,
    footerPreApprovedDisclosure,
    standalonePreApprovedDisclosure,
    footerRewardsDisclosure,
    standaloneRewardsDisclosure,
    footerAlternateEligibleDisclosure,
    standaloneAlternateEligibleDisclosure,
    footerTestimonialDisclosure,
    standaloneTestimonialDisclosure,
    footerIncreaseLimitDisclosure,
    standaloneIncreaseLimitDisclosure,
    promoBasicEligibleDisclosureIndex,
    promoPlusEligibleDisclosureIndex,
    promoPreApprovedDisclosureIndex,
    promoAlternateEligibleDisclosureIndex,
    promoTestimonialDisclosureIndex,
    promoIncreaseLimitDisclosureIndex,
    promoRewardsDisclosureIndex,
    hideCardTypeNameInHeader,
}) => {
   
    
    const history = useHistory();
    const dimensions = useWindowDimensions();

    const [showHelpTray, setShowHelpTray] = useState(false);
    const [showChildren, setShowChildren] = useState(true);

    const timeoutExpired = useRecoilValue(timeoutExpiredState);    
    const utmSourceName = useRecoilValue(utmSourceNameState);

    useEffect(() => {
        console.log("Version:", webVersion);
        debuglog('page-flow:', {
            currentStep: currentStep,
            progress: progress,
           footerBasicEligibleDisclosure: footerBasicEligibleDisclosure,
           standaloneBasicEligibleDisclosure: standaloneBasicEligibleDisclosure,
           footerPlusEligibleDisclosure: footerPlusEligibleDisclosure,
           standalonePlusEligibleDisclosure: standalonePlusEligibleDisclosure,
           footerAlternateEligibleDisclosure: footerAlternateEligibleDisclosure,
           standaloneAlternateEligibleDisclosure: standaloneAlternateEligibleDisclosure,
           footerPreApprovedDisclosure: footerPreApprovedDisclosure,
           standalonePreApprovedDisclosure: standalonePreApprovedDisclosure,
           footerIncreaseLimitDisclosure: footerIncreaseLimitDisclosure,
           standaloneIncreaseLimitDisclosure: standaloneIncreaseLimitDisclosure,
           footerTestimonialDisclosure: footerTestimonialDisclosure,
           standaloneTestimonialDisclosure: standaloneTestimonialDisclosure,
           promoBasicEligibleDisclosureIndex: promoBasicEligibleDisclosureIndex,
           promoPlusEligibleDisclosureIndex: promoPlusEligibleDisclosureIndex,
           promoAlternateEligibleDisclosureIndex: promoAlternateEligibleDisclosureIndex,
           promoPreApprovedDisclosureIndex: promoPreApprovedDisclosureIndex,
           promoIncreaseLimitDisclosureIndex: promoIncreaseLimitDisclosureIndex,
           promoTestimonialDisclosureIndex: promoTestimonialDisclosureIndex,
        });
        debuglog()
        setShowChildren(
            !showHelpTray || dimensions.breakpoint < BreakPoints.sm
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dimensions.breakpoint, showHelpTray]);

    useEffect(() => {
        setShowHelpTray(!!showHelpNow);
    }, [showHelpNow]);

    useEffect(() => {
        if (timeoutExpired) {
            history.replace(routeHashes['/error'], {
                title: 'For security reasons, your session with OpenSky has expired.',
                buttonText: 'Ok, Got it',
                navigateTo: process.env.REACT_APP_HOMEPAGE_URL,
            } as ErrorPageProps);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeoutExpired]);


    const closeHelpTray = () => {
        setShowHelpTray(false);
        onHelpTrayClosed && onHelpTrayClosed();
    }

    const goBack = () => {
        history.goBack();
    };

    return (
        <>
            <span className={'web_version_with_promo_' + webVersion.replaceAll('.', '_')}></span>
            <div className="progress page-progress">
                <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={10}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: progress ?? '25%' }}
                ></div>
            </div>
            <div className="navbar" style={{ minHeight: '66px' }}>
                {showBackButton ? (
                    <button className="link-button" onClick={() => goBack()}>
                        <img src={iconChevronLeft} alt="Back" />
                    </button>
                ) : (
                    <span>&nbsp;</span>
                )}
                <div className="navbar-logo">
                    <img src={logoOpenskyNoPadding} alt="OpenSky" width="77" height="25" />
                    <div className="divider">&nbsp;<span></span>&nbsp;</div>
                    <AffiliateLogo source={utmSourceName}></AffiliateLogo>
                </div>
                {!!currentStep ? (
                    <span className="link-button-right">{currentStep} of 9</span>
                ) : (
                    <span>&nbsp;</span>
                )}
            </div>
            <Banner cardType={cardType ?? 'basic'} />
            <main id="main" className="main" style={{ background: `url(${backgroundImage})`, marginTop: '0px' }}>
                {showChildren && children}
                {showHelpTray && (
                    <ModalPage breakpoint={dimensions.breakpoint}>
                        {HelpComponent && <HelpComponent />}
                        <Button onClick={() => closeHelpTray()} className="btn-outline-dark" >
                            Back to Application
                        </Button>
                    </ModalPage>
                )}
            </main>
            <footer>
                {!showHelpTray && HelpComponent && (
                    <button
                        className="link-button"
                        onClick={() => setShowHelpTray(!showHelpTray)}
                    >
                        Help
                    </button>
                )}                
            </footer>
            {showBasicInfoFooter && (
                <div className='opensky-footer'>                        
                    <div className="applicationFlowLayout-footersection">
                    <hr/>
                        <div className="applicationFlowLayout-disclaimers">
                            
                            <ol>
                                {footerPreApprovedDisclosure && (
                                    <PreApprovedDisclosure
                                        listIndexSymbol={promoPreApprovedDisclosureIndex}
                                    />
                                )}
                                {footerRewardsDisclosure && (
                                    <RewardsDisclosure
                                        listIndexSymbol={promoRewardsDisclosureIndex}
                                    />
                                )}
                                {footerBasicEligibleDisclosure && (
                                    <BasicEligibleDisclosure
                                        listIndexSymbol={promoBasicEligibleDisclosureIndex}
                                    />
                                )}
                                {footerPlusEligibleDisclosure && (
                                    <PlusEligibleDisclosure
                                        listIndexSymbol={promoPlusEligibleDisclosureIndex}
                                    />
                                )}
                                {footerAlternateEligibleDisclosure && (
                                    <AlternateEligibleDisclosure
                                        listIndexSymbol={promoAlternateEligibleDisclosureIndex}
                                    />
                                )}
                                {footerIncreaseLimitDisclosure && (
                                    <IncreaseLimitDisclosure
                                        listIndexSymbol={promoIncreaseLimitDisclosureIndex}
                                    />
                                )}
                                {footerTestimonialDisclosure && (
                                    <TestimonialDisclosure
                                        listIndexSymbol={promoTestimonialDisclosureIndex}
                                    />
                                )}
                            </ol>                                                              
                        </div>
                        <div className="applicationFlowLayout-copyright">
                            &copy; {new Date().getFullYear()} OpenSky is a division of Capital Bank, N.A. All rights reserved.
                        </div>
                        <img className="applicationFlowLayout-fdic" src={fdic} alt="" />
                    </div>
                </div>
            )}
            {standalonePreApprovedDisclosure && (
                <div className='opensky-footer'>
                    <div className="applicationFlowLayout-footersection">
                        <div className="applicationFlowLayout-disclaimers">
                            <ol>
                                <PreApprovedDisclosure
                                    listIndexSymbol={promoPreApprovedDisclosureIndex}
                                />
                            </ol>
                        </div>
                    </div>
                </div>
            )}
            {standaloneRewardsDisclosure && (
                <div className='opensky-footer'>
                    <div className="applicationFlowLayout-footersection">
                        <div className="applicationFlowLayout-disclaimers">
                            <ol>
                                <RewardsDisclosure
                                    listIndexSymbol={promoRewardsDisclosureIndex}
                                />
                            </ol>
                        </div>
                    </div>
                </div>
            )}
            {standaloneBasicEligibleDisclosure && (
                <div className='opensky-footer'>
                    <div className="applicationFlowLayout-footersection">
                        <div className="applicationFlowLayout-disclaimers">
                            <ol>
                                <BasicEligibleDisclosure
                                    listIndexSymbol={promoBasicEligibleDisclosureIndex}
                                />
                            </ol>
                        </div>
                    </div>
                </div>
            )}
            {standalonePlusEligibleDisclosure && (
                <div className='opensky-footer'>
                    <div className="applicationFlowLayout-footersection">
                        <div className="applicationFlowLayout-disclaimers">
                            <ol>
                                <PlusEligibleDisclosure
                                    listIndexSymbol={promoPlusEligibleDisclosureIndex}
                                />
                            </ol>
                        </div>
                    </div>
                </div>
            )}
            {standaloneAlternateEligibleDisclosure && (
                <div className='opensky-footer'>
                    <div className="applicationFlowLayout-footersection">
                        <div className="applicationFlowLayout-disclaimers">
                            <ol>
                                <AlternateEligibleDisclosure
                                    listIndexSymbol={promoAlternateEligibleDisclosureIndex}
                                />
                            </ol>
                        </div>
                    </div>
                </div>
            )}
            {standaloneIncreaseLimitDisclosure && (
                <div className='opensky-footer'>
                    <div className="applicationFlowLayout-footersection">
                        <div className="applicationFlowLayout-disclaimers">
                            <ol>
                                <IncreaseLimitDisclosure
                                    listIndexSymbol={promoIncreaseLimitDisclosureIndex}
                                />
                            </ol>
                        </div>
                    </div>
                </div>
            )}
            {standaloneTestimonialDisclosure && (
                <div className='opensky-footer'>
                    <div className="applicationFlowLayout-footersection">
                        <div className="applicationFlowLayout-disclaimers">
                            <ol>
                                <TestimonialDisclosure
                                    listIndexSymbol={promoTestimonialDisclosureIndex}
                                />
                            </ol>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
};

export default ApplicationFlowLayout;
