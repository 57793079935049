import React from 'react';
import './billing-address.scss';
import StaticRadioButton from '../static-radio-button';
import '../../scss/main.scss';

interface BillingAddressProps {
    billingAddressText: string;
    onClickUseDifferentAddress: () => void;
}

const BillingAddress: React.FC<BillingAddressProps> = ({
    billingAddressText,
    onClickUseDifferentAddress
}) => {
    return (
        <div className="billing-address-container">
            <StaticRadioButton label="Billing address" value={billingAddressText} />
            <div className="button-layout">
                <button className="transparent-blue-text-button" onClick={onClickUseDifferentAddress}>
                    + Use a different address
                </button>
            </div>
        </div>
    );
};

export default BillingAddress;
