import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ResponseCodes, ErrorPageProps, FundingPageProps } from '../types';
import { decisionsResponseState } from '../store/atoms';
import { routeHashes } from '../app-routes';
import { ReviewCardProps } from '../v1_5/types';

export const useProcessResponseCode = (
    responseCode?: string,
    version?: string
) => {
    const history = useHistory();
    const decisionsResponse = useRecoilValue(decisionsResponseState);

    const processOnePointOVersionResponseCode = (responseCode: string) => {
        // redirect based on (adjusted) response code
        switch (responseCode) {
            // Decisions Response Codes
            case ResponseCodes.ApplicationInDocumentRequest:
                history.push(routeHashes['/documents-requested']);
                break;
            case ResponseCodes.ApplicationInWaitingForFunds:
                history.push(routeHashes['/app-approved-waiting-for-funds']);
                break;
            case ResponseCodes.ApplicationInGoodFunds:
                history.push(routeHashes['/app-approved-in-good-funds']);
                break;
            case ResponseCodes.ApplicationHasBeenApproved:
                history.push(routeHashes['/app-approved-deposit-approved']);
                break;
            case ResponseCodes.ApplicationClosed:
                history.push(routeHashes['/app-closed']);
                break;
            case ResponseCodes.ApplicationExpiredOrIncomplete:
                history.push(routeHashes['/app-closed-expired-or-incomplete']);
                break;
            case ResponseCodes.ApplicationDeclined:
                history.push(routeHashes['/app-denied']);
                break;
            case ResponseCodes.ApplicationWithdrawn:
                history.push(routeHashes['/app-closed-withdrawn']);
                break;
            case ResponseCodes.ApplicationApprovedAndAccountBoarded:
                history.push(routeHashes['/app-approved-boarded']);
                break;
            case ResponseCodes.DecisionsNotAccessible:
                history.push(routeHashes['/app-pending-api-failed']);
                break;
            case ResponseCodes.APIConnectivityError:
                history.push(routeHashes['/app-pending-api-failed']);
                break;
            case ResponseCodes.MiskeyDOBAndZIP:
                history.push(routeHashes['/field-corrections']);
                break;
            case ResponseCodes.MiskeyDOB:
                history.push(routeHashes['/field-corrections']);
                break;
            case ResponseCodes.MiskeyZIP:
                history.push(routeHashes['/field-corrections']);
                break;
            case ResponseCodes.ApplicationInManualReview:
                history.push(routeHashes['/app-pending-manual-review']);
                break;
            case ResponseCodes.ApplicationInManualDocumentReview:
                history.push(
                    routeHashes['/app-pending-manual-document-review']
                );
                break;
            case ResponseCodes.ApplicationInSecurityDepositRefund:
                history.push(routeHashes['/app-closed-waiting-on-refund']);
                break;

            // UnprocessableResults Response Codes
            case ResponseCodes.FundFailure:
                history.push(routeHashes['/funding-source']);
                break;
            case ResponseCodes.FundFailureFinal:
                history.push(routeHashes['/app-funding-fail']);
                break;
            case ResponseCodes.InvalidEmail:
                history.push(routeHashes['/invalid-email']);
                break;
            case ResponseCodes.ExceededDailyPaymentTransactions:
                history.replace(routeHashes['/error'], {
                    title: 'An error occurred with your payment.',
                    message:
                        'You have exceeded the maximum number of transactions in a single day. Return as soon as tomorrow to make another payment.',
                    buttonText: 'Ok, Got it',
                    navigateTo: 'https://www.openskycc.com',
                } as ErrorPageProps);
                break;

            // Placeholder for future code(s) to come for funding declined
            // case 'FundingDeclined':
            //     history.push(routeHashes['/app-funding-declined']);
            //     break;

            default:
                // log to console and send to standard API Error screen
                console.error(
                    `Unexpected/unhandled response code ${responseCode}`
                );
                history.replace(routeHashes['/app-pending-api-failed']);
        }
    };

    const processOnePointFiveVersionResponseCode = (responseCode: string) => {
        // redirect based on (adjusted) response code
        switch (responseCode) {
            // Decisions Response Codes
            case ResponseCodes.ApplicationInDocumentRequest:
                history.push(routeHashes['/documents-requested']);
                break;
            case ResponseCodes.ApplicationInWaitingForFunds:
                history.push(routeHashes['/v1_5/funding']);
                break;
            case ResponseCodes.ApplicationInGoodFunds:
                history.push(routeHashes['/v1_5/review-card']);
                break;
            case ResponseCodes.ApplicationHasBeenApproved:
                history.push(routeHashes['/v1_5/review-card'], {
                    isBoarded: true
                } as ReviewCardProps);
                break;
            case ResponseCodes.ApplicationClosed:
                history.push(routeHashes['/app-closed']);
                break;
            case ResponseCodes.ApplicationExpiredOrIncomplete:
                history.push(routeHashes['/app-closed-expired-or-incomplete']);
                break;
            case ResponseCodes.ApplicationDeclined:
                history.push(routeHashes['/app-denied']);
                break;
            case ResponseCodes.ApplicationWithdrawn:
                history.push(routeHashes['/app-closed-withdrawn']);
                break;
            case ResponseCodes.ApplicationApprovedAndAccountBoarded:
                history.push(routeHashes['/app-approved-boarded']);
                break;
            case ResponseCodes.DecisionsNotAccessible:
                history.push(routeHashes['/app-pending-api-failed']);
                break;
            case ResponseCodes.APIConnectivityError:
                history.push(routeHashes['/app-pending-api-failed']);
                break;
            case ResponseCodes.MiskeyDOBAndZIP:
                history.push(routeHashes['/field-corrections']);
                break;
            case ResponseCodes.MiskeyDOB:
                history.push(routeHashes['/field-corrections']);
                break;
            case ResponseCodes.MiskeyZIP:
                history.push(routeHashes['/field-corrections']);
                break;
            case ResponseCodes.ApplicationInManualReview:
                history.push(routeHashes['/app-pending-manual-review']);
                break;
            case ResponseCodes.ApplicationInManualDocumentReview:
                history.push(
                    routeHashes['/app-pending-manual-document-review']
                );
                break;
            case ResponseCodes.ApplicationInSecurityDepositRefund:
                history.push(routeHashes['/app-closed-waiting-on-refund']);
                break;

            // UnprocessableResults Response Codes
            case ResponseCodes.FundFailure:
                history.push(routeHashes['/v1_5/funding'], {
                    paymentError: true,
                } as FundingPageProps);
                break;
            case ResponseCodes.FundFailureFinal:
                history.push(routeHashes['/v1_5/funding'], {
                    paymentError: true,
                } as FundingPageProps);
                break;
            case ResponseCodes.InvalidEmail:
                history.push(routeHashes['/invalid-email']);
                break;
            case ResponseCodes.ExceededDailyPaymentTransactions:
                history.replace(routeHashes['/error'], {
                    title: 'An error occurred with your payment.',
                    message:
                        'You have exceeded the maximum number of transactions in a single day. Return as soon as tomorrow to make another payment.',
                    buttonText: 'Ok, Got it',
                    navigateTo: 'https://www.openskycc.com',
                } as ErrorPageProps);
                break;

            // Placeholder for future code(s) to come for funding declined
            // case 'FundingDeclined':
            //     history.push(routeHashes['/app-funding-declined']);
            //     break;

            default:
                // log to console and send to standard API Error screen
                console.error(
                    `Unexpected/unhandled response code ${responseCode}`
                );
                history.replace(routeHashes['/app-pending-api-failed']);
        }
    };

    useEffect(() => {
        if (responseCode) {
            // adjust to api error code when response code is present but
            // expected decisions response is not there
            let adjustedResponseCode = responseCode;
            if (['WT_DOC'].includes(responseCode) && !decisionsResponse) {
                adjustedResponseCode = ResponseCodes.APIConnectivityError;
            }

            switch (version) {
                case '1.5':
                    processOnePointFiveVersionResponseCode(
                        adjustedResponseCode
                    );
                    break;
                default:
                    processOnePointOVersionResponseCode(adjustedResponseCode);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseCode]);
};
