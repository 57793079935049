import React from 'react';
import { Button, Card } from 'react-bootstrap';
import {
    creditcard,
    creditcardLaunch,
    creditcardPlusNoRibbon,
    noAnnualFeeRibbon
} from '../../assets/img';
import { useRecoilValue } from 'recoil';
import { PromotionSpecific } from '../../components';
import { configurationResponseState } from '../../store/atoms';
import { isIncentiveFeatureActive, getPrn, getIncentiveDetail } from '../../utils/incentives';
import { IncentiveFeatures } from '../../types';
import { debuglog } from '../../utils/debug';
import './intro.scss';

type IntroProps = {
    cardType: string,
    gradientBorder?: boolean,
    cardBadge?: boolean,
    annualFee?: any,
    programFee?: any,
    minimumDeposit: number,
    apr: string,
    buttonClick: any,
    buttonText: string,
    promo?: boolean,
    tclink?: string,
};

const Intro = (props: IntroProps) => {
    const configurationResponse = useRecoilValue(configurationResponseState);
    debuglog('Intro Card Args', props);

    const annualFeeText = props.annualFee && props.annualFee !== '0' ? '$' + props.annualFee + ' annual fee' : 'No annual fee';

    return (
        <>
            <div className="row intro-container">
                <div className={ props.cardBadge ? 'card-badge' : 'hidden' }>Current Card</div>
                <Card className="promoBorder">
                    <Card.Body>
                        <div className="col">
                            {props.cardType === 'plus' && <img src={noAnnualFeeRibbon} alt="no annual fee" className='no-annual-fee-ribbon'/>}
                            <div className="img-text-lockup">
                                <div className="text" data-testid='intro-component-card-type'>
                                    <span className="card-type-header" style={{whiteSpace: "pre-line"}}>{
                                        props.cardType.toLowerCase() === 'basic' ? 'OpenSky\n Secured Visa®' :
                                        props.cardType.toLowerCase() === 'plus' ? 'OpenSky Plus Secured Visa®':
                                        'OpenSky\n Launch Visa®'
                                    }</span>
                                    <span className="label">
                                        {props.cardType.toLowerCase() === 'launch' ? '' : annualFeeText}
                                    </span>
                                </div>
                                <div className="image">
                                    <img
                                        src={props.cardType.toLowerCase() === 'basic' ? creditcard :
                                            props.cardType.toLowerCase() === 'plus' ? creditcardPlusNoRibbon :
                                            creditcardLaunch}
                                        alt="cool blue credit card"
                                    />
                                </div>
                            </div>
                            <PromotionSpecific
                                cardType={props.cardType.toLowerCase()}
                                incentiveFeatureName={IncentiveFeatures.Any}
                                config={configurationResponse}
                            >
                                 <Button className="purple-button-hollow">
                                    {/* TODO: how many * for launch card? */}
                                    {props.cardType.toLowerCase() === 'basic' ?
                                        <span>Limited Time Offer***</span>
                                    :
                                        <span>Limited Time Offer****</span>
                                    }
                                </Button>     
                            </PromotionSpecific>
                            <PromotionSpecific
                                cardType={props.cardType.toLowerCase()}
                                incentiveFeatureName={IncentiveFeatures.Any}
                                config={configurationResponse}
                            >
                                <div className="limited-offer-text">
                                    {isIncentiveFeatureActive(IncentiveFeatures.DepositReductionAmount, getPrn(props.cardType.toLowerCase()), configurationResponse) && (
                                        <>                                        
                                        <span>Get a $200 Credit Line for <span className="purple-highlight">${200 - 
                                            (Number(
                                                getIncentiveDetail(IncentiveFeatures.DepositReductionAmount, getPrn(props.cardType.toLowerCase())
                                                , configurationResponse)?.value ?? 0) ?? 0)}</span></span>                                                
                                        </>
                                    )}
                                    {isIncentiveFeatureActive(IncentiveFeatures.MinimumDepositAmount, getPrn(props.cardType.toLowerCase()), configurationResponse) && (
                                        <> 
                                        <span>Minimum deposit <span className="strike">$300</span> <span className="purple-highlight">${ 
                                            (getIncentiveDetail(IncentiveFeatures.MinimumDepositAmount, getPrn(props.cardType.toLowerCase())
                                                , configurationResponse)?.value ?? 'N/A')}</span></span>                                             
                                        </>
                                    )}
                                    <div className="limited-offer-subtext">When you apply and fund.</div>
                                </div>      
                            </PromotionSpecific>
                            
                        </div>
                        <div className="col">
                            <div>
                            <ul className="checklist">
                                {isIncentiveFeatureActive(IncentiveFeatures.DepositReductionAmount, getPrn(props.cardType.toLowerCase()), configurationResponse) && (
                                    <>                                        
                                    <li><span>Minimum security deposit required</span>
                                        <span className="blue-text">${(props.minimumDeposit) - 
                                        (Number(
                                            getIncentiveDetail(IncentiveFeatures.DepositReductionAmount, getPrn(props.cardType.toLowerCase())
                                            , configurationResponse)?.value ?? 0) ?? 0)}</span></li>
                                    </>
                                )}
                                {isIncentiveFeatureActive(IncentiveFeatures.MinimumDepositAmount, getPrn(props.cardType.toLowerCase()), configurationResponse) && (
                                    <> 
                                    <li><span>Minimum security deposit required</span><span>
                                        <span className="gray-strike">${props.minimumDeposit}</span>
                                        <span className="blue-text"> ${ 
                                        (getIncentiveDetail(IncentiveFeatures.MinimumDepositAmount, getPrn(props.cardType.toLowerCase())
                                            , configurationResponse)?.value ?? 'N/A')}</span></span></li>
                                    </>
                                )}
                                {!isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn(props.cardType.toLowerCase()), configurationResponse) && (
                                    <>                                        
                                    <li><span>Minimum security deposit required</span>
                                        <span className="blue-text">${props.minimumDeposit}</span></li>
                                    </>
                                )}
                                {props.cardType.toLowerCase() === 'launch' ? (
                                    <li><span>Monthly Fee**</span><span className="blue-text">$2</span></li>
                                ) : (
                                    <li><span>Annual Fee</span><span className="blue-text">${props.annualFee}</span></li>
                                )}
                                    <li><span>Up to 10% cash back rewards*</span></li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div className="col">
                            <div className="text-center">
                                <Button
                                    className="btn btn-primary"
                                    onClick={props.buttonClick}
                                >
                                    {props.buttonText} <span className={"arrow"}></span>
                                </Button>
                            </div>
                            <div className="button-fine-print">
                                <div>{props.apr}% Annual percentage rate</div>
                                <div><a href={props.tclink}>+See terms and conditions</a></div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

export default Intro;
