import { useMutation } from "react-query";
import { microserviceClient } from '../../api-clients';
import { CreateLeadRequest, CreateLeadResponse } from '../../types';

const createLead = (payload: CreateLeadRequest) =>
    microserviceClient.post<CreateLeadResponse>('/api/application/createLead', payload);

export const useCreateLead = () => {  
    return useMutation(
        createLead,
        {
            onError: (err, variables, context) => {
                console.error('Error creating lead: ', err);
            },
        }
    );
};
