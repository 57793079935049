import { useMutation } from 'react-query';
import { microserviceClient } from '../../api-clients';
import {
    ResendCheckAppStatusOtpRequest,
    ResendLeadOtpRequest,
    VerifyCheckAppStatusOtpRequest,
    VerifyLeadOtpRequest,
} from '../../types';

export const useResendOtpCode = (isAppStatusCheck: boolean) => {
    const endpoint = isAppStatusCheck
        ? '/api/security/resendCheckAppStatusOtp'
        : '/api/security/resendLeadOtp';

    return useMutation(
        (payload: ResendLeadOtpRequest | ResendCheckAppStatusOtpRequest) => microserviceClient.post(endpoint, payload),
        {
            onError: (err, variables, context) => {
                console.log('Error resending OTP Code', err);
            },
        }
    );
};

export const useVerifyOtpCode = (isAppStatusCheck: boolean) => {
    const endpoint = isAppStatusCheck
        ? '/api/security/verifyCheckAppStatusOtp'
        : '/api/security/verifyLeadOtp';
        
    return useMutation(
        (payload: VerifyLeadOtpRequest | VerifyCheckAppStatusOtpRequest) =>  microserviceClient.post(endpoint, payload),
        {
            onError: (err, variables, context) => {
                console.log('Error verifying OTP Code', err);
            },
        }
    );
};
