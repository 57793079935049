import React from "react";
import * as Yup from 'yup';
import ApplicationFlowLayout from "../../layouts/application-flow";
import { useRecoilState } from "recoil";
import { applicationState, gaDataLayerState } from "../../../store/atoms";
import { CardTypes } from "../../../utils/constants";
import { Form, Modal, SsnForm, Subheadline } from "../../components";
import { lockIcon } from "../../../assets/img";
import "../../scss/main.scss";
import { SsnVerificationModel } from "../../types";
import { validationErrors } from "../../utils/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { isValidSSNorITIN } from "../../../utils/validations";
import { useHistory } from "react-router";
import { routeHashes } from "../../../app-routes";
import { pushToDataLayer } from "../../../utils/analytics";
import * as FullStory from '@fullstory/browser';

interface SsnPageProps {
    isModal?: boolean;
    onModalClose?: () => void;
}

const Ssn: React.FC<SsnPageProps> = ({ isModal, onModalClose }) => {
    const [application, setApplication] = useRecoilState(applicationState);
    const [gaDataLayer, setGADataLayer] = useRecoilState(gaDataLayerState);
    const history = useHistory();

    const validationSchema = Yup.object().shape({
        ssn: Yup.string()
            .required(validationErrors.ssnRequiredError)
            .typeError(validationErrors.ssnRequiredError)
            .test('ssn-valid', validationErrors.invalidSsnOrItinError, (value) => isValidSSNorITIN(value?.toString() || ''))
            .default(() => application.ssn)
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm<SsnVerificationModel>({
        ...formOptions,
        defaultValues: validationSchema.getDefault(),
        mode: 'all',
    });

    const onSubmit = async (event: SsnVerificationModel) => {
        switch (application?.cardType) {
            case "basic": {
                FullStory.event('IncomeNext', { leadId: application.leadId });
                FullStory.event('HousingRentNext', { leadId: application.leadId });
                break;
            }
            case "plus": {
                FullStory.event('Plus_IncomeNext', { leadId: application.leadId });
                FullStory.event('Plus_HousingRentNext', { leadId: application.leadId });
                break;
            }
            case "launch": {
                FullStory.event('Launch_IncomeNext', { leadId: application.leadId });
                FullStory.event('Launch_HousingRentNext', { leadId: application.leadId });
                break;
            }
            default: {
                FullStory.event('IncomeNext', { leadId: application.leadId });
                FullStory.event('HousingRentNext', { leadId: application.leadId });
                break;
            }
        }

        setApplication((current) => ({
            ...current,
            ...event,
        }));

        const updateDataLayer = {
            ...gaDataLayer,
            'step_name': 'Ssn',
        };

        setGADataLayer(updateDataLayer);
        pushToDataLayer("application_step_4", updateDataLayer);

        history.push(routeHashes['/v1_5/income']);
    };

    const onSubmitModal = async (event: SsnVerificationModel) => {
        setApplication((current) => ({
            ...current,
            ...event,
        }));

        onModalClose && onModalClose();
    };

    return (
        <>
            {!isModal && (
                <ApplicationFlowLayout isArrowBackButton={true} cardType={application.cardType as CardTypes} displayVisaLogo={true} currentStep="5" progress="62.5%" >
                    <div className="main-container">
                        <Subheadline headline="What's your Social Security<br/> Number or ITIN?" headlineIcon={lockIcon} />
                        <Form onSubmit={handleSubmit(onSubmit)} submitText="Next" isSubmitDisabled={!isValid}>
                            <SsnForm register={register} errors={errors} />
                        </Form>
                    </div>
                </ApplicationFlowLayout>)}
            {isModal && (
                <Modal onClose={onModalClose || (() => { })}>
                    <Subheadline headline="What's your Social Security<br/> Number or ITIN?" headlineIcon={lockIcon} />
                    <Form onSubmit={handleSubmit(onSubmitModal)} submitText="Save" isSubmitDisabled={!isValid}>
                        <SsnForm register={register} errors={errors} />
                    </Form>
                </Modal>
            )}
        </>);
}

export default Ssn;