import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { applicationState, configurationResponseState, } from '../../store/atoms';
import {
    arrivalPurple,
    creditcard,
    creditcardPlusNoRibbon,
    creditcardLaunch,
    iconCashback,
    increasePurple,
    iconLockPurple,
    noAnnualFeePurple,
} from '../../assets/img';
import { IncentiveFeatures, IncentiveResponse } from '../../types';
import { PromotionSpecific } from '../../components';
import { getDetailFromIncentive } from '../../utils/incentives';
import { debuglog } from '../../utils/debug';

type CardPerksOptions = {
    showFundText?: boolean;
    showFundToggle?: boolean;
    amount?: number;
    activeIncentive?: IncentiveResponse;
    cardType?: string;
};

const CardPerks: React.FC<CardPerksOptions> = ({
    showFundToggle,
    showFundText,
    amount,
    activeIncentive,
    cardType,
}) => {
    const configurationResponse = useRecoilValue(configurationResponseState);
    const application = useRecoilValue(applicationState);
    const [fundToday, setFundToday] = useState(application.fundToday ?? true);
    const showBasedOnCardType = cardType ?? 'none';

    debuglog('card-perks->amount', amount);
    debuglog('card-perks->cardType', showBasedOnCardType);
    const toggleFundToday = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFundToday(!!e.target.checked);
    };

    // const isIncentiveActive = (incentiveFeature: IncentiveFeatures, incentive: IncentiveResponse) => {        
    //     return (getDetailFromIncentive(incentiveFeature, incentive)?.incentiveTypeName);
    // }

    // const getFundingHeaderCardClass = (incentive: IncentiveResponse) => {
    //     debuglog('getFundingHeaderCardClass incentive', incentive);
    //     if (isIncentiveActive(IncentiveFeatures.DepositReductionAmount, incentive)) {
    //         return 'promo-border payment';
    //     }        
    //     else {
    //         return 'gradient-border payment';
    //     }
    // };

    // const getPromotionValueDepositReduction = (incentive: IncentiveResponse, amount: number) => {
    //     debuglog('Application amount:', amount);
    //     const depositReductionIncentive = getDetailFromIncentive(IncentiveFeatures.DepositReductionAmount, incentive);
    //     debuglog('depositReductionIncentive', depositReductionIncentive);
        
    //     let calculatedValue = (amount - Number(depositReductionIncentive?.value));
    //     debuglog('calculatedValue', calculatedValue);

    //     return calculatedValue.toString();
    // }

    const getCreditCardImage = () => {
        switch(showBasedOnCardType){
            case 'plus':
                return creditcardPlusNoRibbon;
            case 'launch':
                return creditcardLaunch;
            default:
                return creditcard;
        }
    }
    
    debuglog('card-perks, activeIncentive', activeIncentive);
    return (
        <>
            <Card
                className={'mt-24'}
                style={{ border: '0px' }}
            >
                <Card.Body>
                    <Card className="light-gray-background">
                            <PromotionSpecific
                                cardType={showBasedOnCardType}
                                incentiveFeatureName={IncentiveFeatures.DepositReductionAmount}
                                config={configurationResponse}
                                appliedIncentive={activeIncentive}
                            >
                                <div className="flag-right">
                                    Save ${getDetailFromIncentive(IncentiveFeatures.DepositReductionAmount, activeIncentive)?.value}**
                                </div>
                            </PromotionSpecific>
                        
                         <div className="img-text-lockup">
                            <div className="image">
                                <img
                                    src={getCreditCardImage()}
                                    alt=""
                                    style={{ height: '73px', width: '116px', marginLeft: '16px' }}
                                />
                            </div>
                            <div className="text">
                                <span className="number" style={{ fontWeight: '536' }}>
                                    ${amount}
                                </span>
                                <span className="label">Credit Line</span>
                            </div>
                        </div>
                        <div className="sub-img-text">
                            <PromotionSpecific
                                className='promo-deposit-reduction-amount'
                                cardType={showBasedOnCardType}
                                incentiveFeatureName={IncentiveFeatures.DepositReductionAmount}
                                config={configurationResponse}
                                appliedIncentive={activeIncentive}
                            >
                                <div className="promo-description">
                                    With a refundable security deposit of ${(amount! - 
                                        Number(getDetailFromIncentive(IncentiveFeatures.DepositReductionAmount, 
                                        activeIncentive)?.value ?? '0')).toString()}
                                </div>
                            </PromotionSpecific>
                            <PromotionSpecific
                                className='promo-minimum-deposit-amount'
                                cardType={showBasedOnCardType}
                                incentiveFeatureName={IncentiveFeatures.MinimumDepositAmount}
                                config={configurationResponse}       
                                appliedIncentive={activeIncentive}                     
                            >
                                <div className="promo-description">
                                    With a refundable security deposit of ${amount}
                                </div>
                            </PromotionSpecific>
                            <PromotionSpecific
                                showIfPromoNotActive={true}
                                className='promo-none-active'
                                cardType={showBasedOnCardType}
                                incentiveFeatureName={IncentiveFeatures.Any}
                                config={configurationResponse}       
                                appliedIncentive={activeIncentive}                     
                            >
                                <div className="promo-description">
                                    With a refundable security deposit of ${amount}
                                </div>
                            </PromotionSpecific>
                        </div>
                    </Card>
                    
                    {/*
                    <Card
                        className={getFundingHeaderCardClass()}
                        style={{ borderRadius: '5px' }}
                    >
                    <PromotionSpecific
                        cardType={showBasedOnCardType}
                        incentiveFeatureName={IncentiveFeatures.DepositReductionAmount}
                        config={configurationResponse}
                        setPromoValue={setPromoValueDepositReduction}
                    >
                        <div className="flag-right">Save ${promoValueDepositReduction}*</div>
                    </PromotionSpecific>
                    <Card.Body
                        style={{
                            borderTopLeftRadius: '4px',
                            borderTopRightRadius: '4px',
                            borderBottomLeftRadius: '0',
                            borderBottomRightRadius: '0',
                        }}
                    >
                        <img src={showBasedOnCardType === 'basic' ? creditcard : creditcardPlusNoRibbon} alt="" />
                        <div className="card-body-info">
                            <h1 className="reduced">${amount ?? 0}</h1>
                            <p>Credit Line</p>
                        </div>
                    </Card.Body>
                    {(isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn(showBasedOnCardType), configurationResponse) && (
                        <>
                        <Card.Footer className="card-footer">
                            <PromotionSpecific
                                cardType={showBasedOnCardType}
                                incentiveFeatureName={IncentiveFeatures.DepositReductionAmount}
                                config={configurationResponse}
                                setPromoValue={setPromoValueDepositReduction}
                            >
                                <div className="promo-description">With a refundable security deposit of ${(application.amount! - Number(promoValueDepositReduction)).toString()}</div>
                            </PromotionSpecific>
                            <PromotionSpecific
                                cardType={showBasedOnCardType}
                                incentiveFeatureName={IncentiveFeatures.MinimumDepositAmount}
                                config={configurationResponse}
                                setPromoValue={setPromoValueMinimumAmount}
                            >
                                <div className="promo-description">With a refundable security deposit of ${application.amount!}</div>
                            </PromotionSpecific>
                        </Card.Footer>
                        </>
                    )
                    )}
                    </Card>
                    */}
                    {showBasedOnCardType === 'basic' ? (
                        <ul className="features mt-36">
                            <li>
                                <span>
                                    <img
                                        src={arrivalPurple}
                                        role="presentation"
                                        alt="plan arriving at destination"
                                    />
                                </span>
                                <span className="text">
                                    Build credit on arrival
                                    <span className="description">
                                        Start building credit as soon as your card arrives,
                                        just activate and spend responsibly
                                    </span>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <img
                                        src={iconCashback}
                                        role="presentation"
                                        alt="earn cash back rewards"
                                    />
                                </span>
                                <span className="text">
                                    Earn cash back rewards*
                                    <span className="description">
                                        Earn up to 10% cash back on purchases at
                                        40,000+ merchants with OpenSky Rewards
                                    </span>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <img
                                        src={increasePurple}
                                        role="presentation"
                                        alt="barchart with inreasing trend"
                                    />
                                </span>
                                <span className="text">
                                    Get considered for a credit line increase
                                    <span className="description">
                                    After 6 months, you may be considered for a credit limit increase
                                    with no additional deposit required{activeIncentive ? '***' : '**'}
                                    </span>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <img
                                        src={iconLockPurple}
                                        role="presentation"
                                        alt="open padlock"
                                    />
                                </span>
                                <span className="text">
                                    Get considered for unsecured
                                    <span className="description">                                        
                                    In as few as 6 months, you may be considered for the 
                                    unsecured OpenSky Visa® Gold Credit Card**
                                </span>
                                </span>
                            </li>
                        </ul>
                    ) : (
                        <ul className="features mt-36">
                            {showBasedOnCardType !== 'launch' && (
                                <li>
                                    <span>
                                        <img
                                            src={noAnnualFeePurple}
                                            role="presentation"
                                            alt=""
                                        />
                                    </span>
                                    <span className="text">
                                        No annual fee
                                        <span className="description">
                                            Focus on your financial future without
                                            the worry of an annual fee
                                        </span>
                                    </span>
                                </li>
                            )}
                            <li>
                                <span>
                                    <img
                                        src={arrivalPurple}
                                        role="presentation"
                                        alt="plan arriving at destination"
                                    />
                                </span>
                                <span className="text">
                                    Build credit on arrival
                                    <span className="description">
                                        Start building credit as soon as your card arrives,
                                        just activate and spend responsibly
                                    </span>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <img
                                        src={iconCashback}
                                        role="presentation"
                                        alt="earn cash back rewards"
                                    />
                                </span>
                                <span className="text">
                                    Earn cash back rewards*
                                    <span className="description">
                                        Earn up to 10% cash back on purchases at
                                        40,000+ merchants with OpenSky Rewards
                                    </span>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <img
                                        src={increasePurple}
                                        role="presentation"
                                        alt="barchart with inreasing trend"
                                    />
                                </span>
                                <span className="text">
                                    Get considered for a credit line increase
                                    <span className="description">
                                    After 6 months, you may be considered for a limit increase
                                    with no additional deposit required**
                                    </span>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <img
                                        src={iconLockPurple}
                                        role="presentation"
                                        alt="open padlock"
                                    />
                                </span>
                                <span className="text">
                                    Get considered for unsecured
                                    <span className="description">
                                    In as few as 6 months, you may be considered for the
                                    OpenSky Gold Unsecured Card*
                                    </span>
                                </span>
                            </li>
                        </ul>
                    )}
                    {showFundText &&
                        <div className="card-bottom">
                            <hr />
                            <div className="text">
                                <span className="label">Easily fund today with debit card</span>
                            </div>
                        </div>
                    }
                </Card.Body>
                {showFundToggle &&
                    <Card.Footer
                        className={fundToday ? ' gradient-light' : ''}
                    >
                        <Form.Group className="input-group">
                            <div style={{ flexGrow: '1' }}>
                                I'm ready to fully fund my card today
                            </div>
                            <Form.Check
                                inline
                                type="switch"
                                name="confirmCreditLine"
                                id="confirmCreditLine"
                                defaultChecked={!!fundToday}
                                onChange={toggleFundToday}
                                label=""
                                className={
                                    !fundToday ? 'form-switch-disabled' : ''
                                }
                            />
                        </Form.Group>
                    </Card.Footer>
                }
            </Card>
        </>
    );
};

export default CardPerks;
