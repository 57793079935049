import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { isIncentiveFeatureActive, getPrn } from '../../utils/incentives';
import { applicationState, gaDataLayerState } from '../../store/atoms';
import { ApplicationFlowLayout } from '../../layouts';
import { NumberFormatInput } from '../../components';
import CreditLineHelp from './credit-line-help';
import { PromotionSpecific } from '../../components';
import { IncentiveFeatures,  CreditLineModel } from '../../types';
import { routeHashes } from '../../app-routes';
import { configurationResponseState } from '../../store/atoms'
import { currentCardInfo } from '../../utils/product-info';
import * as FullStory from '@fullstory/browser';
import { debuglog } from '../../utils/debug';
import { pushToDataLayer } from '../../utils/analytics';

const CreditLineCustom = () => {
    const [application, setApplication] = useRecoilState(applicationState);
    const history = useHistory();
    const configurationResponse = useRecoilValue(configurationResponseState);

    const cardInfo = currentCardInfo(application.cardType as string, configurationResponse?.securedProducts);
    const formattedMaximumCreditLimit = new Intl.NumberFormat('en-US').format(cardInfo.maximumCreditLimit);

    const [promoValueMinimumAmount, setPromoValueMinimumAmount] = useState<string>();
    const [promoValueDepositReduction, setPromoValueDepositReduction] = useState<string>();

    const [gaDataLayer, setGaDataLayer] = useRecoilState(gaDataLayerState);
         
    const handleNext = () => {
        switch (application?.cardType) {
            case "basic": {
                FullStory.event('CustomCreditLineContinue', { leadId: application.leadId });
                break;
            }
            case "plus": {
                FullStory.event('Plus_CustomCreditLineContinue', { leadId: application.leadId });
                break;
            }
            case "launch": {
                FullStory.event('Launch_CustomCreditLineContinue', { leadId: application.leadId });
                break;
            }
            default: {
                FullStory.event('CustomCreditLineContinue', { leadId: application.leadId });
                break;
            }
        }  
        
        if (typeof amount === 'string') {
            amount = parseInt(amount);
        }

        setApplication(current => ({ ...current, amount }));

        const updateDataLayer = {
            ...gaDataLayer,
            'step_name': 'Credit Line Selection',
            'credit_line_amount': amount,
            'credit_line_type': 'custom'
        }

        setGaDataLayer(updateDataLayer);

        if(application.cardType !== 'launch'){
            pushToDataLayer('application_step_3', updateDataLayer);
        }

        // if (application.cardType === 'plus' && amount && amount < minimumDeposit) {
        //     history.push(routeHashes['/credit-line-switch']);
        //     return;
        // }

        //Forward Credit-Line-Confirm & funding section to end of process
        history.push(application.cardType === 'launch' ? routeHashes['/credit-line'] : routeHashes['/identity-verification']);
    };

    /* NOTE: what is the best default value here? */
    //const PROGRAM_FEE = 19.95;

    // const [balanceDue, setBalanceDue] = useState(PROGRAM_FEE.toString());

    //const handleBalanceChange = (value: string) => {
    //    const cleanedValue = value.replace(/,/g, '');
    //    const numericValue = Number(cleanedValue);
    //    if (!Number.isNaN(numericValue)) {
    //        const newBalance = numericValue + (application?.programFee ?? PROGRAM_FEE);
    //        const formattedBalance = new Intl.NumberFormat('en-US', {
    //            minimumFractionDigits: 2,
    //           maximumFractionDigits: 2,
    //        }).format(newBalance);
    //        setBalanceDue(formattedBalance);
    //    } else {
    //        setBalanceDue("0.00");
    //    }
    //};

    //const securityDeposit = useMemo(() => {
    //   const cleanedValue = balanceDue.replace(/,/g, '');
    //    const numericValue = Number(cleanedValue);
    //    return Math.max(numericValue, MINIMUM_DEPOSIT_LAUNCH) === numericValue ? balanceDue : application.securityDeposit;
    //}, [balanceDue]);

    const promotionAdjustedMinimumDepositAmount = () => {
        if (!!promoValueMinimumAmount && Number(promoValueMinimumAmount) > 0) {
            return Number(promoValueMinimumAmount);
        }
        return cardInfo.minimumDeposit;
    };

    const validationSchema = Yup.object().shape({
        amount: Yup.number()
            .required('Credit line amount is required')
            .typeError('You must specify a numeric value')
            .min(promotionAdjustedMinimumDepositAmount(), `Amount must be above $${promotionAdjustedMinimumDepositAmount()}.00`)
            .max(cardInfo.maximumCreditLimit, `Amount must be below $${formattedMaximumCreditLimit}.00`)
            .default(() => application.amount),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, watch, formState: { errors, isValid } } =
        useForm<CreditLineModel>({
            ...formOptions,
            defaultValues: validationSchema.getDefault(),
            mode: 'all'
        });

    let amount = watch('amount');
    debuglog('errors', errors);

    const refundableSecurityDepositAmount = (enteredAmount: number | undefined) => {
        debuglog('refundableSecurityDepositAmount promoValueDepositReduction', promoValueDepositReduction);
        debuglog('explain this:', !!enteredAmount, !Number.isNaN(promoValueDepositReduction), (Number(enteredAmount) >= cardInfo.minimumDeposit));
        if (!!enteredAmount && !!promoValueDepositReduction 
            && !Number.isNaN(promoValueDepositReduction) && Number(enteredAmount) >= cardInfo.minimumDeposit) {
           return '$' + (Number(enteredAmount) - Number(promoValueDepositReduction)).toString() + '*';
        }
        if (!enteredAmount) {
            return 'the amount entered.';
        }
        debuglog('defaulted refundableSecurityDepositAmount: ', enteredAmount, Number.isNaN(promoValueDepositReduction), cardInfo.minimumDeposit);
        return '$' + enteredAmount;
    }

    return (
        <>
            <ApplicationFlowLayout
                showBackButton
                progress='40%'
                cardType={application.cardType}
                HelpComponent={CreditLineHelp}
                currentStep="4"
                promo={true}
                showBasicInfoFooter={true}
                footerAlternateEligibleDisclosure={application.cardType === "basic" && isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn("basic"), configurationResponse)}
                footerPlusEligibleDisclosure={application.cardType === "plus" && isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn("plus"), configurationResponse)}
                promoAlternateEligibleDisclosureIndex='*'
                promoPlusEligibleDisclosureIndex='*'
            >
                <Row>
                    <Col>
                        <div id="header">
                        {application.cardType === "launch" ? (
                            <h1>Choose your credit line</h1>
                        ) : (
                            <h1>Let's choose your credit line.</h1>                                
                        )}
                            <PromotionSpecific
                                cardType={application.cardType}
                                incentiveFeatureName={IncentiveFeatures.DepositReductionAmount}
                                config={configurationResponse}
                                setPromoValue={setPromoValueDepositReduction}
                                className='credit-line-limited-time-offer'
                            >
                                <>                                    
                                    <Button className="purple-button-small">
                                        Limited Time Offer*
                                    </Button>                                
                                    <Card
                                        className={'limited-time-offer-text'}
                                        style={{ border: 'none', paddingTop: '10px', paddingBottom: '15px' }}
                                    >                                            
                                        <span className='offer-highlight'></span><span style={{display: 'inline'}}>
                                            
                                        <span style={{fontWeight: 'bold'}}>Save ${promoValueDepositReduction}</span> on your refundable 
                                        security deposit for your credit line when you make 
                                        your deposit today.</span> 
                                    </Card>
                        
                                </>                                               
                            </PromotionSpecific>
                            <PromotionSpecific
                                cardType={application.cardType}
                                incentiveFeatureName={IncentiveFeatures.MinimumDepositAmount}
                                config={configurationResponse}
                                setPromoValue={setPromoValueMinimumAmount}
                                className='credit-line-limited-time-offer'
                            >
                                <>                                    
                                    <Button className="purple-button-small">
                                        Limited Time Offer*
                                    </Button>                                
                                    <Card
                                        className={'limited-time-offer-text'}
                                        style={{ border: 'none', padding: '10px 0px 20px 0px' }}
                                    >                                            
                                        <div className="limited-offer-text">
                                            <strong><span className="full-amount"> ${cardInfo.minimumDeposit}</span><span className="promo-amount"> ${promoValueMinimumAmount}</span> Minimum deposit</strong>
                                            <div className="limited-offer-subtext">Enjoy a <span style={{fontWeight: 'bold'}}>lowered minimum deposit</span> and <span style={{fontWeight: 'bold'}}>no annual fee</span> when you apply and fund.</div>


                                        </div>
                                    </Card>
                        
                                </>                                               
                            </PromotionSpecific>
                        </div>
                        <NumberFormatInput
                            type='text'
                            noMaterialStyle
                            inputGroupClassName={!errors.amount && 'gradient-border'}
                            inputGroupTypingClassName={'gradient-border'}
                            thousandSeparator=','                        
                            decimalSeparator='.'
                            prefixText='$'
                            name='amount'
                            label={
                                application.cardType === 'launch'?
                                `Your credit line will equal your deposit\n amount, starting at $${promoValueMinimumAmount ?? cardInfo.minimumDeposit} to $${formattedMaximumCreditLimit}.` :
                                `Enter any amount from $${promoValueMinimumAmount ?? cardInfo.minimumDeposit} to $${formattedMaximumCreditLimit}`
                            }
                            instructionText={
                                application.cardType === 'launch'?
                                'With a refundable security deposit of the amount entered.' :
                                `With a refundable security deposit of ${refundableSecurityDepositAmount(amount)}`
                            }
                            placeholder='Credit Line'
                            aria-label='Credit line amount'
                            value={amount || ''}
                            errors={errors}
                            register={register}
                            maxLength="5"
                        />
                    </Col>
                </Row>
                <Row className='row mt-36'>
                    <Col className='col text-center'>
                        <Form.Group>
                            <Button disabled={!(isValid)} onClick={handleNext}>
                                Continue
                            </Button>
                        </Form.Group>
                    </Col>
                </Row>
            </ApplicationFlowLayout>
        </>
    )
};

export default CreditLineCustom;
