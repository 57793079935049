import { rest } from "msw";
import { v4 as uuidv4 } from "uuid";

export const createLead = [
    rest.post(`${process.env.REACT_APP_DEFAULT_API_BASE_URL}/api/application/createLead`, async (req, res, ctx) => {
        const json = await req.json();
        await new Promise(r => setTimeout(r, 800));
        if (json.emailAddress === "throw400@test.com") {
            return res(ctx.status(400), ctx.json({}));
        }
        return res(
            ctx.status(200),
            ctx.json({
                id: uuidv4(),
                firstName: json.firstName,
                lastName: json.lastName,
                emailAddress: json.emailAddress,
                primaryPhone: json.primaryPhone,
                otpMethod: json.phoneConsent ? "Mobile" : "Email"
            })
        );
    })
];
