import React from 'react';
import { useSetRecoilState } from 'recoil';
import { applicationState } from '../../store/atoms';
import BasicInfo from '../basic-info';

const IntroLaunch = () => {
    const setApplication = useSetRecoilState(applicationState);

    const cardType = 'launch';
    const annualFee = '24';
    const programFee = 19.95;
    const creditLimit = 100;
    const securityDeposit = 100;

    setApplication((current) => ({
        ...current,
        ...{
            cardType: cardType,
            fundToday: true,
            annualFee: annualFee,
            programFee: programFee,
            defaultCreditLimit: creditLimit,
            securityDeposit: securityDeposit,
        },
    }));
    return (
        <BasicInfo/>
    );
};

export default IntroLaunch;