import React from 'react';
import './error-banner.scss';
import { iconError } from '../../../assets/img';

interface ErrorBannerProps {
    text: string;
}

const ErrorBanner: React.FC<ErrorBannerProps> = ({ text }) => {
    return (
        <div className="error-banner">
            <div className="error-banner-container">
                <div className="error-banner-content">
                    <div className="error-icon">
                        <div className="error-icon-container">
                            <img
                                src={iconError}
                                alt="error icon"
                                className="error-icon-content"
                            />
                        </div>
                        <p className="error-text">{text}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorBanner;
