import { GADataLayerModel, WindowWithDataLayer } from "../types/analytics";

declare const window: WindowWithDataLayer;

export const pushToDataLayer = (event: string, data: GADataLayerModel) => {
    if(window.dataLayer){
        window.dataLayer.push({
            event: event,
            ...data
        });
    } else {
        console.warn("dataLayer is not initilized");
    }
}
