import React, { useEffect } from 'react';
import classnames from 'classnames';
import {
    useCombobox,
    UseComboboxState,
    UseComboboxStateChange,
    UseComboboxStateChangeOptions,
} from 'downshift';
import { iconXCircleFill } from '../../assets/img';
import { Item } from './types';
import './auto-complete.scss';

type AutoCompleteProps<T extends Item = Item> = {
    label?: string;
    floatingLabel?: string;
    placeholder?: string;
    items?: T[];
    enableClear?: boolean;
    showMenuToggleButton?: boolean;
    openMenuOnKeypress?: boolean;
    initialInputValue?: string;
    // value to display when selected:
    itemToString?: (item: T | null) => string;
    onInputValueChange?: (changes: UseComboboxStateChange<T>) => void;
    onSelectChange?: (item?: T | null) => void;
    getToggleMenuButton?: (isOpen: boolean) => JSX.Element;
    DropdownFooter?: () => JSX.Element;
}

const AutoComplete = <T extends Item>({
    label,
    floatingLabel,
    placeholder = " ",
    items=[],
    enableClear = true,
    showMenuToggleButton = false,
    openMenuOnKeypress = false,
    initialInputValue,
    itemToString = (item) => item?.label || item?.value || "",
    onInputValueChange,
    onSelectChange,
    DropdownFooter
}: AutoCompleteProps<T>) => {
    // const [dropdownItems, setDropdownItems] = useState(items);

    const stateReducer: ((
        state: UseComboboxState<T>,
        actionAndChanges: UseComboboxStateChangeOptions<T>
    ) => Partial<UseComboboxState<T>>) = (state, actionAndChanges) => {
        const { type, changes } = actionAndChanges;

        switch (type) {
            case useCombobox.stateChangeTypes.ItemClick:
                if (changes.selectedItem?.shouldNotChangeInputValue) {
                    changes.selectedItem?.onSelect?.();
                    return {
                        ...state,
                        isOpen: false
                    };
                }
                return changes;
            default:
                return changes;
        }
    }

    const handleSelectedItemChange = (changes: UseComboboxStateChange<T>) => {
        const { selectedItem } = changes
        if (selectedItem?.onSelect) {
            selectedItem?.onSelect()
        } else {
            onSelectChange?.(selectedItem);
        }
    }

    const {
        isOpen,
        inputValue,
        setInputValue,
        setHighlightedIndex,
        getLabelProps,
        getComboboxProps,
        getInputProps,
        getToggleButtonProps,
        getMenuProps,
        getItemProps,
        openMenu,
        // closeMenu,
        // selectedItem,
        reset,
        highlightedIndex
    } = useCombobox<T>({
        items,
        itemToString,
        stateReducer,
        onInputValueChange: (changes: UseComboboxStateChange<T>) => {
            // TODO: add filter? depending on results from api call
            onInputValueChange && onInputValueChange(changes);
        },
        // onStateChange,
        onSelectedItemChange: handleSelectedItemChange
    });

    const clearSearch = () => {
        setInputValue('');
        setHighlightedIndex(-1);
        reset();
    }

    // const toggleMenuButtonIcon = (isOpen) => {
        //TODO: if we need toggle menu button... need more work here
    // }

    useEffect(() => {
        setInputValue(initialInputValue || "")
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialInputValue])
    return (
        <div className='auto-complete-container mb-3' data-testid='auto-complete'>
            <div
                className={classnames(
                    'auto-complete-input-container',
                    'form-floating',
                    { 'expanded': isOpen }
                )}
                {...getComboboxProps()}
            >
                <input
                    data-testid='auto-complete-input'
                    className='auto-complete-input'
                    placeholder={placeholder} {...getInputProps()}
                    // value={initialInputValue}
                    onFocus={ (openMenuOnKeypress) ? () => {} :  openMenu }
                    onKeyDown={ (openMenuOnKeypress) ? openMenu : () => {} }
                    // onBlur={closeMenu}
                    // onchange={(e) => setInputValue(e.target.value)}
                />
                {label?.length &&
                    <label
                        data-testid='auto-complete-label'
                        className='auto-complete-label'
                        style={{
                            "--autoCompleteLabelText": `"${label}"`,
                            "--autoCompleteFloatingLabelText": `"${floatingLabel || label}"`
                        }}
                        {...getLabelProps()}
                    >
                        {/* {label} */}
                    </label>
                }
                {enableClear && !!inputValue?.length &&
                    <img
                        data-testid='auto-complete-input-clear-icon'
                        className='auto-complete-input-clear-icon'
                        alt='clear search'
                        src={iconXCircleFill}
                        onClick={clearSearch}
                    />
                }
                {showMenuToggleButton &&
                    <button
                        aria-label='toggle menu'
                        className='auto-complete-input-toggle-menu-button'
                        data-testid='auto-complete-input-toggle-menu-button'
                        {...getToggleButtonProps()}
                    >
                        {isOpen ? <>&#8593;</> : <>&#8595;</>}
                    </button>
                }
            </div>
            {isOpen &&
                <div className='auto-complete-dropdown-container' data-testid='auto-complete-dropdown-container'>
                    <ul className='auto-complete-dropdown' {...getMenuProps()}>
                        {items.map((item, index) => (
                            <li
                                className={classnames(
                                    'auto-complete-dropdown-item',
                                    { 'highlighted': highlightedIndex === index }
                                )}
                                {...getItemProps({ item, index })} key={index}
                            >
                                <span>{item.display}</span>
                            </li>
                        ))}
                    </ul>
                    {DropdownFooter &&
                        <div onClick={() => { console.log("clicked") }} className='auto-complete-dropdown-footer-container'>
                            <DropdownFooter />
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export default AutoComplete;
