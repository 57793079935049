import React, { FC } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { checkAppState, configurationResponseState, downsellState, } from '../../../store/atoms';
import { creditcard, creditcardPlusNoRibbon } from '../../../assets/img';
import { CardInfo, PromotionSpecific } from '../../../components';
import { IncentiveFeatures, IncentiveResponse } from '../../../types'; 
import { isIncentiveFeatureActive, getPrn, getDetailFromIncentive, getIncentive } from '../../../utils/incentives';
import { debuglog } from '../../../utils/debug';
import { CardTypes } from '../../../utils/constants';



interface IFundingHeaderProps {
    fundWithDebit?: boolean;
    amount?: number;
    daysLeft?: number;
    fundToday: boolean;
    changeFundTodayStatus: any;
    showSlider?: boolean;
    appliedIncentive?: IncentiveResponse;
    creditLimit?: number;
    cardType?: string;
}

const FundWithoutDebit = () => {
    return (
        <div id="header">
            <h1>Let's fund your<br />pre-approved<sup>*</sup> card</h1>
            <p>
                To complete your application,<br /> we need you to add a funding
                source.
            </p>
        </div>
    );
};

const FundWithDebit: FC<{ daysLeft?: number }> = ({ daysLeft }) => {
    return (
        <div id="header">
            <h1>Fund your OpenSky card</h1>
            <p>
                Fund your security deposit to receive your OpenSky card in the
                mail. You have {daysLeft ?? 60} days left to fund.
            </p>
        </div>
    );
};

const FundLaunch = () => {
    return (
    <div id="header">
        <h1>Let's fund your<br />new OpenSky<br /> Launch Visa®</h1>
        <p>
            We just need a debit card to fund your<br/> card's refundable security deposit.
        </p>
    </div>
    );
}

const FundingHeader: FC<IFundingHeaderProps> = ({
    fundWithDebit,
    amount,
    daysLeft,
    fundToday,
    changeFundTodayStatus,
    showSlider,
    appliedIncentive,
    creditLimit,
    cardType,
}) => {
    const isCheckApp = useRecoilValue(checkAppState);
    const configurationResponse = useRecoilValue(configurationResponseState);
    const downsell = useRecoilValue(downsellState);
    const showBasedOnCardType = cardType ?? 'none';

    debuglog('Funding-header-amt', amount);
    debuglog('Funding-header-card-type:', showBasedOnCardType);

    const getFundingHeaderCardClass = () => {
        debuglog('getFundingHeaderCardClass configurationResponse.incentiveName', configurationResponse?.incentiveName);
        if (isIncentiveFeatureActive(IncentiveFeatures.DepositReductionAmount, getPrn(showBasedOnCardType), configurationResponse)) {
            debuglog("getFundingHeaderCardClass is " + IncentiveFeatures.DepositReductionAmount);
            return (!isCheckApp || (isCheckApp && fundToday))
                ? 'promo-border payment'
                : 'promo-border payment disabled';
        }
        else if (isIncentiveFeatureActive(IncentiveFeatures.MinimumDepositAmount, getPrn(showBasedOnCardType), configurationResponse)) {
            debuglog("getFundingHeaderCardClass is " + IncentiveFeatures.MinimumDepositAmount);
            return (!isCheckApp || (isCheckApp && fundToday))
                ? 'promo-border payment'
                : 'promo-border payment disabled';
        }
        // else if (isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn(showBasedOnCardType), configurationResponse)) {
        //     // return (!isCheckApp || (isCheckApp && fundToday))
        //     //     ? 'thick-black-border payment'
        //     //     : 'thick-black-border payment disabled'
        //     return (!isCheckApp || (isCheckApp && fundToday))
        //         ? 'promo-border payment'
        //         : 'promo-border payment disabled';
        // }
        else {
            debuglog("getFundingHeaderCardClass fallback against ");
            return (!isCheckApp || (isCheckApp && fundToday))
                ? 'promo-border payment'
                : 'promo-border payment disabled';
                // ? 'gradient-border payment'
                // : 'gradient-border payment disabled';
        }
    };
    const getPromotionValueDepositReduction = (incentive: IncentiveResponse | undefined, amount: number | undefined) => {
        debuglog('getPromotionValueDepositReduction incentive:', incentive);        
        debuglog('getPromotionValueDepositReduction amount:', amount);        

        if (incentive && amount) {
            const depositReductionIncentive = getDetailFromIncentive(IncentiveFeatures.DepositReductionAmount, incentive!);
            debuglog('depositReductionIncentive', depositReductionIncentive);
            
            let calculatedValue = (amount - Number(depositReductionIncentive?.value));
            debuglog('calculatedValue', calculatedValue);

            return Number(depositReductionIncentive?.value);
        } else {
            const incentive = getIncentive(IncentiveFeatures.DepositReductionAmount, getPrn(showBasedOnCardType), configurationResponse);
            if (incentive && incentive.incentiveDetails.length > 0) {
                return Number(incentive.incentiveDetails[0].value);
            }

            debuglog("ERROR: Should never not have incentive details when incentive already determined.");
            return undefined;
        }
    };

    debuglog('funding-header incentive:', appliedIncentive);
    debuglog('app-amt', amount);

    return (
        <>
            {downsell ? (
                <FundLaunch/>
            ) : fundWithDebit ? (
                <FundWithDebit daysLeft={daysLeft} />
            ) : (
                <FundWithoutDebit />
            )
            }
            {(showBasedOnCardType === 'launch') ? (
                <>
                    <CardInfo
                        //cardType={application.cardType}
                        creditLimit={amount?.toString()}
                        //balanceDue={}
                        //programFee={application.programFee}
                        //securityDeposit={}
                    />
                </>
            ) : (
                <Card
                    className={getFundingHeaderCardClass()}
                    style={{ borderRadius: '5px' }}
                >
                    {(showBasedOnCardType === 'basic' && !isCheckApp) && (
                        <PromotionSpecific
                            cardType={showBasedOnCardType}
                            incentiveFeatureName={IncentiveFeatures.DepositReductionAmount}
                            config={configurationResponse}
                            appliedIncentive={appliedIncentive}
                        >                
                            <div className="flag-right">&nbsp;Save ${getPromotionValueDepositReduction(appliedIncentive, amount)}**&nbsp;</div>
                        </PromotionSpecific>
                    )}
                    <Card.Body
                        style={{
                            borderTopLeftRadius: '4px',
                            borderTopRightRadius: '4px',
                            borderBottomLeftRadius: '0',
                            borderBottomRightRadius: '0',
                        }}
                    >
                        <img src={showBasedOnCardType === 'basic' ? creditcard : creditcardPlusNoRibbon} alt="" />
                        <div className="card-body-info">
                            <h1 className="reduced">${amount ?? 0}</h1>
                            <p>Credit Line</p>
                        </div>                    
                    </Card.Body>
                    
                    {(!appliedIncentive) && !isCheckApp && ((true || isIncentiveFeatureActive(IncentiveFeatures.Any, getPrn(showBasedOnCardType), configurationResponse)) && (
                        <>
                        <Card.Footer className="card-footer">                    
                            <PromotionSpecific
                                className='fund-header-promo-basic-deposit-reduction'
                                cardType={showBasedOnCardType}
                                incentiveFeatureName={IncentiveFeatures.DepositReductionAmount}
                                config={configurationResponse}
                                appliedIncentive={appliedIncentive}
                            >                
                                <div className="promo-description deposit-reduction">With a refundable security deposit of ${(amount! - 
                                            (getPromotionValueDepositReduction(appliedIncentive!, amount!) ?? 0)).toString()}</div>
                            </PromotionSpecific>
                            <PromotionSpecific
                            className='fund-header-promo-plus-minimum-deposit'
                                cardType={showBasedOnCardType}
                                incentiveFeatureName={IncentiveFeatures.MinimumDepositAmount}
                                config={configurationResponse}
                                appliedIncentive={appliedIncentive}
                            >                
                                <div className="promo-description minimum-deposit">With a refundable security deposit of  ${amount}</div>
                            </PromotionSpecific>
                            <PromotionSpecific
                            className='fund-header-promo-plus-minimum-deposit'
                                cardType={showBasedOnCardType}
                                incentiveFeatureName={IncentiveFeatures.Any}
                                config={configurationResponse}
                                appliedIncentive={appliedIncentive}
                                showIfPromoNotActive={true}
                            >                
                                <div className="promo-description minimum-deposit">With a refundable security deposit of  ${amount}</div>
                            </PromotionSpecific>
                        </Card.Footer>
                        </>
                    )                
                    )}
                    
                    {(!!showSlider) && (
                    <>
                    {/* TODO: delete this */}
                    <Card.Footer className={fundToday ? ' gradient-light' : ''}>
                        <Form.Group className="input-group">
                            <div style={{ flexGrow: '1' }}>
                                I'm ready to fully fund my card today
                            </div>
                            <Form.Check
                                inline
                                type="switch"
                                name="confirmCreditLine"
                                id="confirmCreditLine"
                                defaultChecked={fundToday}
                                onChange={changeFundTodayStatus}
                                label=""
                                className={
                                    !fundToday ? 'form-switch-disabled' : ''
                                }
                            />
                        </Form.Group>
                    </Card.Footer>
                    </>
                    )}
                </Card>
            )}
        </>
    );
};

export default FundingHeader;
