import { useQuery } from "react-query";
import { microserviceClient } from '../../api-clients';
import {
    SendCheckAppStatusOtpRequest,
    SendCheckAppStatusOtpResponse,

} from '../../types';
import { queryKeys } from '../../utils/constants';

const sendCheckAppStatusOtp = async (payload: SendCheckAppStatusOtpRequest): Promise<SendCheckAppStatusOtpResponse> => {
    const res = await microserviceClient.post<SendCheckAppStatusOtpResponse>('/api/security/sendCheckAppStatusOtp', payload);
    return res.data;
}

export const useSendCheckAppStatusOtp = (payload?: SendCheckAppStatusOtpRequest) => {
    return useQuery<SendCheckAppStatusOtpResponse, Error>(
        [...queryKeys.sendCheckAppStatusOtp, payload],
        () => sendCheckAppStatusOtp(payload!),
        {
            staleTime: Infinity,
            cacheTime: Infinity,
            retry: false,
            enabled: !!(payload?.last4SSN && payload.dateOfBirth && payload.emailAddress),
        }
    )
}
