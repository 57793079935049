import React from "react";
import { securedSsnEncryption } from "../../../assets/img";

const SecuredSsnEncryptionImage = () => {
    return (
        <div className="ssn-secured-encryption-image-flex">
            <img src={securedSsnEncryption} className="ssn-secured-encryption-image-size" />
        </div>);
}

export default SecuredSsnEncryptionImage;