import React, { ReactNode } from 'react';
import './text-input.scss';
import FormErrorMessage from '../form-error-message';
import { ValidatedInputProps } from '../../../types';
import '../../scss/main.scss';

type TextInputProps = ValidatedInputProps & {
    inputSize: 'short' | 'long';
    value?: string | number;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
    name: string;
    descriptionHeading?: string;
    description?: string | ReactNode;
    spellCheck?: boolean;
    registerOptions?: any;
    className?: string;
    inputClassName?: string;
};

const TextInput: React.FC<TextInputProps> = (props) => {
    const {
        label,
        name,
        errors,
        register,
        inputSize,
        type,
        value,
        onChange,
        onBlur,
        registerOptions,
        placeholder,
        descriptionHeading,
        description,
        spellCheck,
        inputClassName
    } = props;

    const { onBlur: onBlurRegister, onChange: onChangeRegister } =
        (register && register(name, registerOptions)) || {};

    const getInputClassName = () => {
        if (inputClassName) {
            return inputClassName;
        } else {
            return inputSize === 'short' ? 'short-input' : 'standard-input';
        }
    };

    const renderDescription = () => {
        if(typeof description === "string"){
            return <p className="input-small-text">{description}</p>;
        }
        else {
            return description;
        }
    }

    return (
        <div
            className={
                inputSize === 'short' ? '' : 'flex-input'
            }
        >
            <label className="label">{label}</label>
            {register ? (
                <input
                    type={type}
                    className={getInputClassName()}
                    {...register(name)}
                    value={value}
                    spellCheck={spellCheck}
                    onBlur={(e) => {
                        onBlur && onBlur(e);
                        onBlurRegister && onBlurRegister(e);
                    }}
                    onChange={(e) => {
                        onChange && onChange(e);
                        onChangeRegister && onChangeRegister(e);
                    }}
                    placeholder={placeholder}
                />
            ) : (
                <input
                    type={type}
                    className={getInputClassName()}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    spellCheck={spellCheck}
                />
            )}
            {errors && errors[name] && (
                <FormErrorMessage errors={errors} name={props.name} />
            )}
            {descriptionHeading && (
                <p className="input-heading-text">{descriptionHeading}</p>
            )}
            {description &&  renderDescription()}
        </div>
    );
};

export default TextInput;
