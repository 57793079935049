import React from 'react';
import { useHistory } from 'react-router-dom';
import { iconExclamationCircle } from '../../assets/img';
import ApplicationInfoStatus from '../../components/application-info-status';
import { LandingPageLayout } from '../../layouts';
import { routeHashes } from '../../app-routes';

const ApplicationDocumenstRequested = () => {
    const history = useHistory();

    const handleNext = () => {
        history.push(routeHashes['/document-selection']);
    }

    return (
        <LandingPageLayout showWithdrawal>
            <ApplicationInfoStatus
                appStatus="Additional information is required"
                appMessage="Your application requires additional information"
                appStatusType="docrequest"
                appStatusIcon={iconExclamationCircle}
                appDetailMessage="To continue processing your application, we need additional information to verify your identity."
                buttonText='Start Identification Process'
                onNext={handleNext}
            />
        </LandingPageLayout>
    );
};

export default ApplicationDocumenstRequested;
