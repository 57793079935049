import React, { useMemo } from 'react';
import ApplicationFlowLayout from '../../layouts/application-flow';
import { CardTypes } from '../../../utils/constants';
import valid from 'card-validator'; //import statement
import { useConfetti } from '../../hooks/useConfetti';
import * as Yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FundingVerificationModel } from '../../types';
import { FundingForm } from '../../components';
import { validationErrors } from '../../utils/constants';
import { DebitCardForm } from '../../components/debit-card-form';
import {
    isNotPOBoxPattern,
    isValidName,
    noSpecialCharactersPattern,
    zipCodePattern,
} from '../../../utils/validations';
import '../../scss/main.scss';
import './funding-page.scss';
import { useRecoilState, useRecoilValue } from 'recoil';
import { applicationState, gaDataLayerState } from '../../../store/atoms';
import { useHistory } from 'react-router';
import { routeHashes } from '../../../app-routes';
import { FundingPageProps } from '../../../types';

import { selectedApplicationResponse } from '../../../store/selectors';
import { pushToDataLayer } from '../../../utils/analytics';
import * as FullStory from '@fullstory/browser';
export const FundingPage: React.FC<FundingPageProps> = ({ paymentError }) => {
    const history = useHistory();
    const [application, setApplication] = useRecoilState(applicationState);
    const selectedApplication = useRecoilValue(selectedApplicationResponse);
    const [gaDataLayer, setGADataLayer] = useRecoilState(gaDataLayerState);

    const confettiElement = useConfetti({
        show: true,
        numberOfPieces: 400,
        recycle: false,
        gravity: 1.0,
    });

    const defaultCardHolderFirstName =
        application.cardHolderFirstName !== undefined
            ? application.cardHolderFirstName
            : '';

    const defaultCardHolderLastName =
        application.cardHolderLastName !== undefined
            ? application.cardHolderLastName
            : '';

    const defaultCardHolderFullName = `${defaultCardHolderFirstName} ${defaultCardHolderLastName}`;

    //memoize the validation schema so it only changes if app fields change that is uses
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            amount: Yup.number()
                .required(validationErrors.depositAmountRequiredError)
                .min(200, validationErrors.depositAmountMinError)
                .max(3000, validationErrors.depositAmountMaxError)
                .typeError(validationErrors.depositAmountTypeError)
                .default(() => application.amount),
            cardHolderFullName: Yup.string()
                .required(validationErrors.cardHolderFullNameRequired)
                .test(
                    'contains-alpha-characters-firstname',
                    `First Name should only contain alphabetical characters.`,
                    (value) => isValidName(value)
                )
                .default(() => defaultCardHolderFullName),
            cardNumber: Yup.string()
                .test(
                    'test-credit-card-number',
                    'Card number is invalid',
                    (value) => valid.number(value).isValid
                )
                .test(
                    'test-credit-card-type',
                    'Credit Card must be Visa or MasterCard',
                    (value) =>
                        valid.number(value).card?.type === 'visa' ||
                        valid.number(value).card?.type === 'mastercard'
                )
                .required(validationErrors.cardNumberRequiredError)
                .default(() => application.cardNumber),
            cardExpiration: Yup.string()
                .test(
                    'test-expiration',
                    'Expiration is invalid',
                    (value) => valid.expirationDate(value).isValid
                )
                .required('Expiration date is required')
                .default(() => application.cardExpiration),
            cardSecurityCode: Yup.string()
                .test(
                    'test-cvv',
                    'CVV code is invalid',
                    (value) => valid.cvv(value).isValid
                )
                .required('Security code is required')
                .default(() => application.cardSecurityCode),
            addressLine1: Yup.string()
                .required(validationErrors.streetAddressRequiredError)
                .max(26, validationErrors.streetAddressMaxCharactersError)
                .matches(
                    noSpecialCharactersPattern,
                    validationErrors.invalidCharactersError
                )
                .matches(
                    isNotPOBoxPattern,
                    validationErrors.poBoxesNotAllowedError
                )
                .test(
                    'address',
                    validationErrors.invalidPrimaryAddressError,
                    (value) => value !== ''
                )
                .default(
                    () =>
                        application.homeAddress?.addressLine1 ||
                        selectedApplication?.homeAddress
                ),
            city: Yup.string()
                .required(validationErrors.cityIsRequiredError)
                .max(18, validationErrors.cityMaxCharactersError)
                .matches(
                    noSpecialCharactersPattern,
                    validationErrors.invalidCharactersError
                )
                .test(
                    'city',
                    validationErrors.cityIsRequiredError,
                    (value) => value !== ''
                )
                .default(
                    () =>
                        application.homeAddress?.city ||
                        selectedApplication?.city
                ),
            state: Yup.string()
                .required(validationErrors.stateIsRequiredError)
                .test(
                    'state',
                    validationErrors.stateIsRequiredError,
                    (value) => value !== ''
                )
                .default(
                    () =>
                        application.homeAddress?.state ||
                        selectedApplication?.state
                ),
            zipCode: Yup.string()
                .required(validationErrors.zipCodeIsRequiredError)
                .min(5, validationErrors.zipCodeIsRequiredError)
                .max(10, validationErrors.zipCodeIsRequiredError)
                .test(
                    'zipcode',
                    validationErrors.zipCodeIsRequiredError,
                    (value) => value !== ''
                )
                .matches(zipCodePattern, validationErrors.zipCodeFormatError)
                .test(
                    'first-five-valid-range',
                    validationErrors.zipCodeFormatError,
                    (value) => {
                        let zipCodeValue = parseInt(
                            (value ?? '0').substring(0, 5)
                        );
                        return zipCodeValue > 500 && zipCodeValue < 99951;
                    }
                )
                .default(
                    () =>
                        application.homeAddress?.zipCode ||
                        selectedApplication?.zipCode
                ),
            addressLine2: Yup.string()
                .matches(
                    noSpecialCharactersPattern,
                    validationErrors.invalidCharactersError
                )
                .default(
                    () =>
                        application.homeAddress?.addressLine2 ||
                        (selectedApplication?.aptSuite ?? '')
                ),
        });
    }, [
        application.cardExpiration,
        application.cardNumber,
        application.cardSecurityCode,
        defaultCardHolderFullName,
        selectedApplication?.homeAddress,
        selectedApplication?.aptSuite,
        selectedApplication?.city,
        selectedApplication?.state,
        selectedApplication?.zipCode,
        application.homeAddress?.addressLine1,
        application.homeAddress?.addressLine2,
        application.homeAddress?.city,
        application.homeAddress?.state,
        application.homeAddress?.zipCode,
        application.amount,
    ]);

    const formOptions = { resolver: yupResolver(validationSchema) };
    const formMethods = useForm<FundingVerificationModel>({
        ...formOptions,
        defaultValues: validationSchema.getDefault(),
        mode: 'all',
    });

    const handleClickUsePreviousAddress = () => {
        formMethods.setValue(
            'addressLine1',
            application.homeAddress?.addressLine1 ||
                selectedApplication?.homeAddress ||
                ''
        );
        formMethods.resetField('addressLine2');
        formMethods.resetField('city');
        formMethods.resetField('state');
        formMethods.resetField('zipCode');
        formMethods.trigger();
    };

    const onSubmit = async (event: FundingVerificationModel) => {
        switch (application?.cardType) {
            case "basic": {
                FullStory.event('FundingSourceNext', { leadId: application.leadId });
                break;
            }
            case "plus": {
                FullStory.event('Plus_FundingSourceNext', { leadId: application.leadId });
                break;
            }
            case "launch": {
                FullStory.event('Launch_FundingSourceNext', { leadId: application.leadId });
                break;
            }
            default: {
                FullStory.event('FundingSourceNext', { leadId: application.leadId });
                break;
            }
        }

        const fullNameSplit = event.cardHolderFullName
            .split(' ')
            .filter((x) => x !== '');

        const firstName = fullNameSplit.length > 0 ? fullNameSplit[0] : '';
        const lastName = fullNameSplit.length > 1 ? fullNameSplit[1] : '';

        setApplication((current) => ({
            ...current,
            amount: event.amount,
            cardNumber: event.cardNumber,
            cardExpiration: event.cardExpiration,
            cardSecurityCode: event.cardSecurityCode,
            cardHolderFirstName: firstName,
            cardHolderLastName: lastName,
            bankingAddress: {
                addressLine1: event.addressLine1,
                addressLine2: event.addressLine2,
                city: event.city,
                state: event.state,
                zipCode: event.zipCode,
            },
        }));

        const updateDataLayer = {
            ...gaDataLayer,
            'step_name': 'Funding Source',
            'credit_line_amount': event.amount,
            'card_fund_selection': 'fund_now',
            'credit_line_type': 'custom'
        }

        setGADataLayer(updateDataLayer);

        pushToDataLayer('application_step_9', updateDataLayer);

        history.push(routeHashes['/v1_5/processing-transaction']);
    };

    const handleFundLaterClick = () => {
        const updateDataLayer = {
            ...gaDataLayer,
            'step_name': 'Funding Source',
            'card_fund_selection': 'fund_later',
        }

        setGADataLayer(updateDataLayer);

        pushToDataLayer('application_step_9', updateDataLayer);

        history.push(routeHashes['/v1_5/credit-line-fund-later']);
    };

    return (
        <>
            {!paymentError && confettiElement}
            <ApplicationFlowLayout
                hideNavigation={true}
                hideProgress={true}
                progress="100%"
                cardType={application.cardType as CardTypes}
                displayVisaLogo
            >
                <div className="funding-page-layout">
                    <FormProvider {...formMethods}>
                        <FundingForm fundingError={paymentError} />
                        <DebitCardForm
                            onClickUsePreviousAddress={
                                handleClickUsePreviousAddress
                            }
                            fundingError={paymentError}
                        />
                        <div className="funding-page-footer">
                            <button
                                className="button-no-witdh"
                                disabled={!formMethods.formState.isValid}
                                onClick={formMethods.handleSubmit(onSubmit)}
                            >
                                Fund my card
                            </button>
                            <button
                                className="transparent-button-underline-text"
                                onClick={handleFundLaterClick}
                            >
                                Fund later
                            </button>
                        </div>
                    </FormProvider>
                </div>
            </ApplicationFlowLayout>
        </>
    );
};
