import { useState, useEffect } from 'react';
import orderBy from 'lodash/orderBy';
import { BreakPoints } from '../types';
import { breakpointSizes } from '../utils/constants';

export { BreakPoints, breakpointSizes };

export type WindowDimensions = {
    width: number,
    height: number,
    breakpoint: BreakPoints,
};

const breakpoints = [
    { breakpoint: BreakPoints.xs, value: breakpointSizes[BreakPoints.xs] },
    { breakpoint: BreakPoints.sm, value: breakpointSizes[BreakPoints.sm] },
    { breakpoint: BreakPoints.md, value: breakpointSizes[BreakPoints.md] },
    { breakpoint: BreakPoints.lg, value: breakpointSizes[BreakPoints.lg] },
    { breakpoint: BreakPoints.xl, value: breakpointSizes[BreakPoints.xl] },
    { breakpoint: BreakPoints.xxl, value: breakpointSizes[BreakPoints.xxl] },
];

const getWindowDimensions = (): WindowDimensions => {
  const { innerWidth: width, innerHeight: height } = window;

  let breakpoint = BreakPoints.xxl;
  for (const item of orderBy(breakpoints, ['value'], ['desc'])) {
      if (width >= item.value) {
          breakpoint = item.breakpoint;
          break;
      }
  }

  return {
    width,
    height,
    breakpoint,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};
