import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { arrival, creditcard, creditcardPlusNoRibbon, increase, unlock } from '../../assets/img';
import { ApplicationFlowLayout } from '../../layouts';
import { applicationState } from '../../store/atoms';
import { ApplicationModel } from '../../types';
import CreditLineConfirmHelp from './credit-line-confirm-help';
import { routeHashes } from '../../app-routes';
import * as FullStory from '@fullstory/browser';

const CreditLineConfirm = () => {
    const history = useHistory();
    
    const [application, setApplication] = useRecoilState(applicationState);
    const [fundToday, setFundToday] = useState(application.fundToday ?? true);

    const toggleFundToday = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFundToday(!!e.target.checked);
    };

    const handleNext = () => {
        switch (application?.cardType) {
            case "basic": {
                FullStory.event('CrediltLineConfirm', { leadId: application.leadId });
                break;
            }
            case "plus": {
                FullStory.event('Plus_CrediltLineConfirm', { leadId: application.leadId });
                break;
            }
            case "launch": {
                FullStory.event('Launch_CrediltLineConfirm', { leadId: application.leadId });
                break;
            }
            default: {
                FullStory.event('CrediltLineConfirm', { leadId: application.leadId });
                break;
            }
        }   

        setApplication((current: ApplicationModel) => ({
            ...current,
            fundToday,
        }));
        if (fundToday) {
            history.push(routeHashes['/funding-source']);
        } else {
            history.push(routeHashes['/credit-line-fund-later']);
        }
    };

    return (
        <ApplicationFlowLayout
            showBackButton
            HelpComponent={CreditLineConfirmHelp}
            progress="80%"
            cardType={application.cardType}
            currentStep="8"
        >
            <Row>
                <Col>
                    <div id="header">
                        <h1>Just to confirm...</h1>
                        <p>Here's an overview of your account credit line.</p>
                    </div>
                    <Card
                        className={
                            fundToday ? 'gradient-border mt-24 credit-confirm' : 'mt-24 credit-confirm'
                        }
                    >
                        <Card.Body>
                            <div className="img-text-lockup">
                                <div className="image image-no-border">
                                    <img
                                        src={application.cardType === 'basic' ? creditcard : creditcardPlusNoRibbon}
                                        alt="cool blue credit card"
                                    />
                                    <span className="card-type">{application.cardType === 'basic' ? 'OpenSky' : 'OpenSky Plus'}</span>
                                </div>
                                <div className="credit-line">
                                    <hr />
                                    <div className="text">
                                        <span className="label">Credit Line</span>
                                        <span className="number">
                                            ${application.amount}
                                        </span>
                                    </div>
                                    <hr className="bottom-hr" />
                                </div>
                            </div>
                            <ul className="features mt-36">
                                <li>
                                    <span
                                        className={
                                            fundToday ? 'icon' : 'icon-gray'
                                        }
                                    >
                                        <img
                                            src={arrival}
                                            role="presentation"
                                            alt="plan arriving at destination"
                                        />
                                    </span>
                                    <span className="text">
                                        Build credit on arrival
                                        <span className="description">
                                            Start building credit as soon as your card arrives, 
                                            just activate and spend responsibly
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span
                                        className={
                                            fundToday ? 'icon' : 'icon-gray'
                                        }
                                    >
                                        <img
                                            src={increase}
                                            role="presentation"
                                            alt="barchart with inreasing trend"
                                        />
                                    </span>
                                    <span className="text">
                                    Get considered for a credit line increase
                                        <span className="description">
                                        After 6 months, you can get considered for a limit increase 
                                        with no additional deposit required*
                                        </span>
                                    </span>
                                </li>
                                <li>
                                    <span
                                        className={
                                            fundToday ? 'icon' : 'icon-gray'
                                        }
                                    >
                                        <img
                                            src={unlock}
                                            role="presentation"
                                            alt="open padlock"
                                        />
                                    </span>
                                    <span className="text">
                                        Get considered for unsecured
                                        <span className="description">
                                        In as few as 6 months, you can get considered for the 
                                        OpenSky Gold Unsecured Card*
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </Card.Body>
                        <Card.Footer
                            className={fundToday ? ' gradient-light' : ''}
                        >
                            <Form.Group className="input-group">
                                <div style={{ flexGrow: '1' }}>
                                    I'm ready to fully fund my card today
                                </div>
                                <Form.Check
                                    inline
                                    type="switch"
                                    name="confirmCreditLine"
                                    id="confirmCreditLine"
                                    defaultChecked={!!fundToday}
                                    onChange={toggleFundToday}
                                    label=""
                                    className={
                                        !fundToday ? 'form-switch-disabled' : ''
                                    }
                                />
                            </Form.Group>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>            
            <Row className="row mt-36">
                <Col className="col text-center">
                <span className="disclaimer">
                    By moving forward, upon application completion your OpenSky Securd Credit Card will be funded today.
                </span>
                </Col>
            </Row>            
            <Row className="row mt-36">
                <Col className="col text-center">
                    <Form.Group>
                        <Button onClick={handleNext}>Confirm</Button>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="row mt-36">
                <Col className="col text">
                <span className="fine-print">
                    *Getting considered for a limit increase and/or the OpenSky Gold Unsecured Card is subject to approval
                </span>
                </Col>
            </Row>
        </ApplicationFlowLayout>
    );
};

export default CreditLineConfirm;
