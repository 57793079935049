import React from 'react';
import {
    greenEllipse,
    greenVerticalLine,
    greyEllipse,
    greyVerticalLine,
} from '../../../assets/img';
import './shipping-status.scss';

interface ShippingStatusProps {
    shipDateEstimated?: boolean;
}

const ShippingStatus: React.FC<ShippingStatusProps> = ({
    shipDateEstimated,
}) => {
    const getEstimatedShipDate = (): string => {
        const today = new Date();
        const nextWeek = new Date();
        nextWeek.setDate(today.getDate() + 7);

        const options: Intl.DateTimeFormatOptions = {
            month: 'long',
            day: 'numeric',
        };

        const todayFormatted = today.toLocaleDateString('en-US', options);
        const nextWeekFormatted = nextWeek.toLocaleDateString('en-US', options);

        return `${todayFormatted} - ${nextWeekFormatted}`;
    };

    return (
        <div className="shipping-status-layout">
            <div className="shipping-status-progress-bar">
                <img
                    src={greenEllipse}
                    alt="green ellipse"
                    className="green-ellipse-icon"
                />
                <img
                    src={greenVerticalLine}
                    alt="green vertical line"
                    className="green-vertical-line"
                />
                {shipDateEstimated ? (
                    <>
                        <img
                            src={greenEllipse}
                            alt="green ellipse"
                            className="green-ellipse-icon"
                        />
                        <img
                            src={greenVerticalLine}
                            alt="green vertical line"
                            className="green-vertical-line"
                        />
                    </>
                ) : (
                    <>
                        <img
                            src={greyEllipse}
                            alt="grey ellipse"
                            className="grey-ellipse-icon"
                        />
                        <img
                            src={greyVerticalLine}
                            alt="grey vertical line"
                            className="grey-vertical-line"
                        />
                    </>
                )}
                <img
                    src={greyEllipse}
                    alt="grey ellipse"
                    className="grey-ellipse-icon"
                />
            </div>
            <div className="shipping-status-content">
                <div className="shipping-status-item-outer">
                    <div className="shipping-status-item-inner">
                        <p className="shipping-status-item-title">
                            Card funded
                        </p>
                        <p className="shipping-status-item-text">Today</p>
                    </div>
                </div>
                <div className="shipping-status-item-outer">
                    <div className="shipping-status-item-inner">
                        <p className="shipping-status-item-title">
                            Estimated ship date
                        </p>
                        <p className="shipping-status-item-text">
                            {getEstimatedShipDate()}
                        </p>
                    </div>
                </div>
                <div className="shipping-status-item-outer">
                    <div className="shipping-status-item-inner">
                        <p className="shipping-status-item-title">
                            Expected delivery
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShippingStatus;
