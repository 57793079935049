import React from 'react';
import { Alert, Col, Nav, Row, Tab } from 'react-bootstrap';
import { ModalPage } from '../../components';
import { ApplicationFlowLayout } from '../../layouts';
import { useWindowDimensions } from '../../hooks';
import { iconWarningInfo } from '../../assets/img';
import { Intro } from '../../components';
import { useHistory } from 'react-router-dom';
import { routeHashes } from '../../app-routes';
import { useRecoilState, useRecoilValue } from 'recoil';
import { applicationState } from '../../store/atoms';
import { configurationResponseState } from '../../store/atoms'
import { currentCardInfo } from '../../utils/product-info';
import { postProductUpdate } from './hooks';
import * as FullStory from '@fullstory/browser';

const CreditLineSwitch = () => {
    const dimensions = useWindowDimensions();
    const history = useHistory();
    const [application, setApplication] = useRecoilState(applicationState);

    const configurationResponse = useRecoilValue(configurationResponseState);
    const basicCardInfo = currentCardInfo('basic', configurationResponse?.securedProducts);
    const plusCardInfo = currentCardInfo('plus', configurationResponse?.securedProducts);

    const basicCardButton = () => {
        setApplication((current) => ({
            ...current,
            ...{ cardType: 'basic' },
        }));
        postProductUpdate(application?.leadId, basicCardInfo?.productId?.toString() ?? '0');
        FullStory.event('CreditLineSwitch', { leadId: application.leadId });
        history.push(routeHashes['/identity-verification']);
    };
    const plusCardButton = () => {
        setApplication((current) => ({
            ...current,
            ...{ cardType: 'plus' },
        }));
        FullStory.event('Plus_CreditLineSwitch', { leadId: application.leadId });
        history.push(routeHashes['/credit-line']);
    };

    return (
        <ApplicationFlowLayout 
            showBackButton 
            progress="40%"
            cardType={application.cardType}
            currentStep="4"
        >
            <ModalPage breakpoint={dimensions.breakpoint}>
                <Row>
                    <Col>
                        <Alert variant={'primary'} style={{ padding: '0.5rem', marginTop: '1rem' }}>
                            <img alt='' src={iconWarningInfo} role='presentation' style={{maxHeight: '25px', color: 'black'}} />
                            <span style={{ paddingLeft: '14px' }}>
                                For a ${application.amount} credit line please choose the OpenSky card.
                            </span>
                        </Alert>
                        <div id="header">
                            <h1>Do you want to switch cards?</h1>
                        </div>
                        <div className="card-toggle">
                            <Tab.Container defaultActiveKey="basic-card">
                                <Nav variant="pills" className="bottom30">
                                    <Nav.Item>
                                        <Nav.Link eventKey="basic-card">
                                            OpenSky
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="plus-card">
                                            OpenSky Plus
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="basic-card">
                                        <Intro
                                            cardType={'basic'}
                                            gradientBorder={true}
                                            annualFee={'35'}
                                            minimumDeposit={basicCardInfo.minimumDeposit}
                                            apr={basicCardInfo.annualPercentageRate}
                                            buttonClick={basicCardButton}
                                            buttonText={'Switch card'}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="plus-card">
                                        <Intro
                                            cardType={'plus'}
                                            cardBadge={true}
                                            annualFee={0}
                                            minimumDeposit={plusCardInfo.minimumDeposit}
                                            apr={plusCardInfo.annualPercentageRate}
                                            buttonClick={plusCardButton}
                                            buttonText={'Go back'}
                                        />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </Col>
                </Row>
            </ModalPage>
        </ApplicationFlowLayout>
    );
};

export default CreditLineSwitch;
