import { rest } from 'msw';
import { CheckApplicationResponse, DecisionsResponse, GetApplicationsStatusResponse } from '../types';

const response: GetApplicationsStatusResponse = {
    done: {
        applicationID: 'debug',
        daysLeftToFund: 35,
        creditLimit: 250,
        //currentBalance: 50,
        //incentiveValue: 0,
        currentBalance: 106,
        incentiveValue: 51,
        fundingHistory: [
            {
                amountReceived: 25,
            },
            {
                amountReceived: 30,
            }
        ],
        checkAppResponseList: [
            {
                applicationID: '00001',
                responseCode: 'WT_DOC',
                submittedDateUtc: new Date().toISOString(),
                productName: 'OpenSky Secured Plus Visa® Credit Card',    
                prn: '9000',            
                responseObjects: {
                    doC_AddressYN: true,
                    doC_DobYN: false,
                    doC_SsnYN: true,
                },
                version: 1
            } as CheckApplicationResponse,
            {
                applicationID: '00022',
                responseCode: 'WT_FND',
                submittedDateUtc: new Date().toISOString(),
                leadID: '749b2bc1-12b9-41d8-b6ec-08075f406fb6',
                firstName: 'John',
                lastName: 'Carter',
                primaryPhone: '',
                phoneConsent: false,
                productName: 'Basic with NO Promo', //productName: 'OpenSky Secured Visa® Credit Card',
                prn: '1000', //'9000', '1000'           
                responseObjects: {
                    doC_IncomeYN: false,
                    doC_NameYN: false,
                    doC_AddressYN: false,
                    doC_SsnYN: false,
                    doC_DobYN: false,
                    doC_BankOwnershipYN: false,
                    doC_ItinYN: false,
                    creditLimit: 250.0000000,
                },
                //no promo
                incentiveName: null,
                incentiveId: null,
                version: 1.5,
                zipCode: "91801",
                state: "CA",
                city: "Alhambra",
                aptSuite: null,
                homeAddress: "2516 Rainbow Road"
            } as CheckApplicationResponse,
            {
                applicationID: '00002',
                responseCode: 'WT_FND',
                submittedDateUtc: new Date().toISOString(),
                leadID: '749b2bc1-12b9-41d8-b6ec-08075f406fb6',
                firstName: 'John',
                primaryPhone: '',
                phoneConsent: false,
                productName: 'Basic with Promo', //'OpenSky Secured Visa® Credit Card',
                prn: '9000', //'9000' is plus, '1000' is basic
                version:1,
                responseObjects: {
                    doC_IncomeYN: false,
                    doC_NameYN: false,
                    doC_AddressYN: false,
                    doC_SsnYN: false,
                    doC_DobYN: false,
                    doC_BankOwnershipYN: false,
                    doC_ItinYN: false,
                    creditLimit: 250.0000000,
                },
                //no promo
                // incentiveName: '',
                // incentiveId: '',
                //promo
                incentiveName: 'DepositReductionAmount', // 'DepositReductionAmount', 'MinimumDepositAmount'
                incentiveId: '1234567891098496854',
                incentive: {
                    id: '1234-5678-90AB-CDEF',
                    name: 'OpenSky Nov-23 Promotion Deposit Reduction',
                    //name: 'OpenSky Nov-23 Promotion Minimum Deposit',
                    productTypeId: 5, //1 is basic, 5 is plus
                    productTypeName: '',
                    startDateUtc: '11-1-2023 00:00:00',
                    endDateUtc: '11-30-2024 00:00:00',
                    isActive: true,
                    incentiveDetails: [
                        {
                            incentiveTypeId: 1,
                            incentiveTypeName: 'DepositReductionAmount', //MinimumDepositAmount, DepositReductionAmount
                            value: '51'
                        },
                        // {
                        //     incentiveTypeId: 1,
                        //     incentiveTypeName: 'MinimumDepositAmount', //MinimumDepositAmount, DepositReductionAmount
                        //     value: '200'
                        // },
                    ]
                }
                
            } as CheckApplicationResponse,
            
            {
                applicationID: '00012',
                responseCode: 'WT_FND',
                submittedDateUtc: new Date().toISOString(),
                leadID: '749b2bc1-12b9-41d8-b6ec-08075f406fb6',
                firstName: 'John',
                primaryPhone: '',
                phoneConsent: false,
                productName: 'Plus with Promo', //productName: 'OpenSky Secured Plus Visa® Credit Card',
                prn: '9000', //'9000', '1000'
                version: 1,         
                responseObjects: {
                    doC_IncomeYN: false,
                    doC_NameYN: false,
                    doC_AddressYN: false,
                    doC_SsnYN: false,
                    doC_DobYN: false,
                    doC_BankOwnershipYN: false,
                    doC_ItinYN: false,
                    creditLimit: 350.0000000,
                },
                //no promo
                // incentiveName: '',
                // incentiveId: '',
                //promo
                incentiveName: 'MinimumDepositAmount', // 'DepositReductionAmount', 'MinimumDepositAmount'
                incentiveId: '1234567891098496854',
                incentive: {
                    id: '1234-5678-90AB-CDEF',
                    //name: 'OpenSky Nov-23 Promotion Deposit Reduction',
                    name: 'OpenSky Nov-23 Promotion Minimum Deposit',
                    productTypeId: 5,
                    productTypeName: '',
                    startDateUtc: '11-1-2023 00:00:00',
                    endDateUtc: '11-30-2024 00:00:00',
                    isActive: true,
                    incentiveDetails: [
                        // {
                        //     incentiveTypeId: 1,
                        //     incentiveTypeName: 'DepositReductionAmount', //MinimumDepositAmount, DepositReductionAmount
                        //     value: '51'
                        // },
                        {
                            incentiveTypeId: 1,
                            incentiveTypeName: 'MinimumDepositAmount', //MinimumDepositAmount, DepositReductionAmount
                            value: '200'
                        },
                    ]
                }
                
            } as CheckApplicationResponse,
            {
                applicationID: '00003',
                responseCode: 'WT_FRE',
                productName: 'OpenSky Secured Visa® Credit Card',
                prn: '1000',            
                submittedDateUtc: new Date().toISOString(),
                firstName: 'john',
            } as CheckApplicationResponse,
            {
                applicationID: '00004',
                responseCode: 'WT_FIS',
                productName: null,
                submittedDateUtc: new Date().toISOString(),
                firstName: 'John',
            } as CheckApplicationResponse,
            {
                applicationID: '00005',
                responseCode: 'END_CLS',
                //prductName: undefined,                
                submittedDateUtc: new Date().toISOString(),
            } as CheckApplicationResponse,
            {
                applicationID: '00006',
                //responseCode: 'END_DCL',
                //responseCode: 'END_ERR',
                responseCode: 'END_BRD',
                productName: 'OpenSky Secured Visa® Credit Card',
                prn: '1000',      
                submittedDateUtc: new Date().toISOString(),
                firstName: 'john',
            } as CheckApplicationResponse,
            {
                applicationID: '00007',
                //responseCode: 'END_DCL',
                //responseCode: 'END_ERR',
                responseCode: 'WT_FRE',
                productName: 'OpenSky Secured Plus Visa® Credit Card',
                prn: '9000',
                submittedDateUtc: new Date().toISOString(),
                firstName: 'john',
            } as CheckApplicationResponse,
            {
                applicationID: '00008',
                //responseCode: 'END_DCL',
                //responseCode: 'END_ERR',
                responseCode: 'WT_FIS',
                productName: 'OpenSky Secured Visa® Credit Card',
                prn: '1000',
                submittedDateUtc: new Date().toISOString(),
                firstName: 'john',
            } as CheckApplicationResponse,
        ]
    } as DecisionsResponse,
    responseCode: ''
};

export const getApplicationsStatus = [
    rest.get(`${process.env.REACT_APP_DEFAULT_API_BASE_URL}/api/application/getApplicationsStatus`, async (req, res, ctx) => {
        await new Promise(r => setTimeout(r, 1500));
        return res(
            ctx.status(200),
            ctx.json(response)
        );
    }),
];

export const getApplicationsPaymentStatus = [
    rest.get(`${process.env.REACT_APP_DEFAULT_API_BASE_URL}/api/application/:applicationId/getApplicationPaymentStatus`, async (req, res, ctx) => {
        await new Promise(r => setTimeout(r, 1500));
        return res(
            ctx.status(200),
            ctx.json(response)
        );
    }),
];
