import { IncentiveFeatures, IncentiveDetailResponse, IncentiveResponse, ProductTypePrns, ConfigurationResponse } from '../types';

export const getIfIncentiveFeatureActive  = (incentiveFeature: IncentiveFeatures, prn: ProductTypePrns, config: ConfigurationResponse | undefined, valueToGet: any, defaultValue?: any): any => {
    if (isIncentiveFeatureActive(incentiveFeature, prn, config)) {
        return valueToGet;
    }

    return defaultValue;
}

export const isIncentiveFeatureActive = (incentiveFeature: IncentiveFeatures, prn: ProductTypePrns, config: ConfigurationResponse | undefined): boolean => {   
    const activeProduct = config?.securedProducts?.find(p => p.prn === prn);   

    if (IncentiveFeatures.Any === incentiveFeature) {        
        return ((activeProduct?.incentives?.length) ?? 0) > 0;
    } else {
        const activeIncentive = getIncentiveDetail(incentiveFeature, prn, config);
        
        return (!!activeIncentive);
    }
}

export const getIncentive = (incentiveFeature: IncentiveFeatures, prn: ProductTypePrns, config: ConfigurationResponse | undefined): IncentiveResponse | null => {    
    // console.log('Looking for Incentive:', incentiveFeature);
    // console.log('on product:', prn);
    // console.log('with a config:', config);

    const activeProduct = config?.securedProducts?.find(p => p.prn === prn);
    //console.log('getIncentive activeProduct:', activeProduct);
    if (incentiveFeature === IncentiveFeatures.Any) {
        return (activeProduct?.incentives) ? (activeProduct?.incentives[0] ?? null) : null;
    }


    const activeIncentives = activeProduct?.incentives?.filter(incentive => 
        incentive.incentiveDetails?.some(activeIncentive => activeIncentive.incentiveTypeName === incentiveFeature)
    );    
      
    return activeIncentives?.[0] ?? null;
}


export const getIncentiveDetail = (incentiveFeature: IncentiveFeatures, prn: ProductTypePrns, config: ConfigurationResponse | undefined): IncentiveDetailResponse | null => {        

    const activeIncentive = getIncentive(incentiveFeature, prn, config);
    
    return activeIncentive?.incentiveDetails?.find(activeIncentive => activeIncentive.incentiveTypeName === incentiveFeature) ?? null;
}

export const getDetailFromIncentive = (incentiveFeature: IncentiveFeatures, incentive: IncentiveResponse | undefined): IncentiveDetailResponse | null => {
    if (incentiveFeature === IncentiveFeatures.Any) {
        return incentive?.incentiveDetails[0] ?? null;
    }
    return incentive?.incentiveDetails.find(activeIncentive => activeIncentive.incentiveTypeName === incentiveFeature) ?? null;
}

export const getPrn = (productShortName: string | undefined): ProductTypePrns => {
    if (!productShortName) {
        console.log('WARNING: prn not defined');
        return ProductTypePrns.Unknown;
    }
    if (productShortName.toLocaleLowerCase() === 'secured' || productShortName.toLocaleLowerCase() === 'basic')
        return ProductTypePrns.Basic;
    if (productShortName.toLocaleLowerCase() === 'plus')
        return ProductTypePrns.Plus;
    if (productShortName.toLocaleLowerCase() === 'launch')
        return ProductTypePrns.Launch;

    console.log('WARNING: unable to find matching product');
    return ProductTypePrns.Unknown;    
}


