import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Row } from 'react-bootstrap';
import { ApplicationFlowLayout } from '../../layouts';
import { iconWarning } from '../../assets/img';
import { ErrorPageProps } from '../../types';

const ErrorPage: React.FC<ErrorPageProps> = (props) => {
    const { title, message, help, navigateTo, navigationState, buttonText, errorIcon } = props;
    const history = useHistory();

    const tryAgain = () => {
        if (navigateTo.toLowerCase().startsWith('http')) {
            window.location.href = navigateTo;
        } else {
            history.replace(navigateTo, navigationState);
        }
    };

    return (
        <ApplicationFlowLayout progress='0%'>
            <Row className='mobile-centered'>
                <div>
                    <img alt='error' src={errorIcon ?? iconWarning} role='presentation' className='icon-warning' />
                    {/* TODO: should the img be centered? */}
                    <div className="heading">
                        <h1>{title}</h1>
                        {message && <p>{message}</p>}
                        {help && <p>{help}</p>}
                    </div>
                </div>
                <div className='text-center'>
                    <Button onClick={tryAgain}>{buttonText ?? "Try Again"}</Button>
                </div>
            </Row>
        </ApplicationFlowLayout>
    );
};

export default ErrorPage;
