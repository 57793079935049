import React from 'react';
import './banner.scss';

type BannerProps = {
    cardType: string,
}

const Banner = (props: BannerProps) => {
    return (
        <>
            <div className="banner-container">
                <div className="banner">
                    <div className="banner-text">{
                        props.cardType === 'basic' ? 'OpenSky Secured Card' : 
                        props.cardType === 'plus' ? 'OpenSky Plus' :
                        'OpenSky Launch Visa®'
                    }</div>
                </div>
            </div>
        </>
    );
};

export default Banner;
