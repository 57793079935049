import React from "react";
import './subheadline.scss';

interface SubheadlineProps {
    headline: string;
    headlineIcon?: string;
    smallText?: string;
}

const Subheadline: React.FC<SubheadlineProps> = ({
        headline,
        headlineIcon,
        smallText,
    }) => {
    return (
        <div className={smallText ? "subheadline-container" : "subheadline-container subheadline-no-small-text"}>
            {headlineIcon && (
                <div className="subheadline-icon-container">
                    <img src={headlineIcon} className="subheadline-icon"/>
                </div>
            )}
            <div className="subheadline-heading-container">
                <h1 className="subheadline-heading">
                    {headline.split('<br/>').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    ))}
                </h1>
                {smallText && (
                    <p className="subheadline-small-text">
                        {smallText.split('<br/>').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </p>
                )}
            </div>
        </div>
    );
}
 
export default Subheadline;
