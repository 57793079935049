import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import * as Yup from 'yup';
import { creditcard } from '../../assets/img';
import { NumberFormatInput, TextInput } from '../../components';
import { LandingPageLayout } from '../../layouts';
import { ErrorPageProps, OtpCheckProps, OtpMethods, SendCheckAppStatusOtpRequest } from '../../types';
import { useSendCheckAppStatusOtp } from './hooks';
import { sendCheckAppStatusOtpRequestState, sendCheckAppStatusOtpResponseState, checkAppState, emailAddressState  } from '../../store/atoms';
import { routeHashes } from '../../app-routes';

const CheckApplication: FC = () => {
    const history = useHistory();
    const [, sendCheckAppStatusOtpRequest] = useRecoilState(sendCheckAppStatusOtpRequestState);
    const [, sendCheckAppStatusOtpResponse] = useRecoilState(sendCheckAppStatusOtpResponseState);
    const [checkApplicationStatusRequest, setCheckApplicationStatusRequest] = useState<SendCheckAppStatusOtpRequest>();
    const [isCheckApp, setIsCheckApp] = useRecoilState(checkAppState);
    const [submittedEmailAddress, setSubmittedEmailAddress] = useRecoilState(emailAddressState);

    const validationSchema = Yup.object().shape({
        emailAddress: Yup.string()
            .email('Invalid Email Address!')
            .required('Email Address is required'),
        dateOfBirth: Yup.string()
            .matches(/\d{8}/, 'Date of Birth is required')
            .required('Date of Birth is required'),
        last4SSN: Yup.string()
            .matches(/\d{4}/, 'SSN or ITIN is required')
            .required('SSN or ITIN is required'),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, register, formState: { errors, isValid } } =
        useForm<SendCheckAppStatusOtpRequest>({
            ...formOptions,
            defaultValues: validationSchema.getDefault(),
            mode: 'all'
        });
    
    const { data: sendStatusOtpResponse, isError: isErrorResponse } = useSendCheckAppStatusOtp(checkApplicationStatusRequest);

    const handleCheckApplicationStatus = async (event: SendCheckAppStatusOtpRequest) => {
        setIsCheckApp(true);        
        setSubmittedEmailAddress(event.emailAddress);
        setCheckApplicationStatusRequest({
            ...event,
            // format mmddyyyy as mm/dd/yyyy
            dateOfBirth: event.dateOfBirth.replace(/^(\d{2})(\d{2})(\d{4})$/, '$1/$2/$3'),
        });
    };

    useEffect(() => {
        if (isErrorResponse) {
            history.replace(routeHashes['/error'], {
                title: `Sorry, please try again`,
                message: 'It looks like you may have entered something incorrectly. The information you entered does not match our records. Please close your browser and try again.',
                help: 'Still experiencing an issue? Please contact Customer Service at 855-763-6736 for assistance.',
                buttonText: 'Ok, Got it',
                navigateTo: routeHashes['/check-application-status'],
            } as ErrorPageProps);
        } else if(sendStatusOtpResponse) {
            sendCheckAppStatusOtpRequest(checkApplicationStatusRequest);
            sendCheckAppStatusOtpResponse(sendStatusOtpResponse);
            history.push(routeHashes['/otp-check'], {
                otpData: {
                    isAppStatusCheck: true,
                    emailAddress: submittedEmailAddress,
                    primaryPhone: sendStatusOtpResponse.primaryPhone,
                    otpMethod: sendStatusOtpResponse.otpMethod as OtpMethods,
                },
                navigateTo: routeHashes['/view-application'],
                otpSentTime: new Date(),
                isInitialOtp: true,   
            } as OtpCheckProps);
        } else {
            setIsCheckApp(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isErrorResponse, sendStatusOtpResponse]);

    return (
        <LandingPageLayout>
            <Row>
                <Col className="text-center">
                    <span className="icon">
                        <img
                            alt="credit card"
                            src={creditcard}
                            role="presentation"
                        />
                    </span>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div id="header">
                        <h1 className="text-center">
                            Let's check on your application status
                        </h1>
                        <p className="text-center">
                            Please fill in the form below to check the status of
                            your application.
                        </p>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form>
                        <TextInput
                            type="email"
                            name="emailAddress"
                            label="Email Address"
                            aria-label="Email Address"
                            errors={errors}
                            register={register}
                        />
                        <NumberFormatInput
                            type='text'
                            format="##-##-####"
                            name="dateOfBirth"
                            label="Date of Birth (MM-DD-YYYY)"
                            aria-label="Date of Birth (MM-DD-YYYY)"
                            secure
                            errors={errors}
                            register={register}
                        />
                        <NumberFormatInput
                            secure
                            type="text"
                            format="####"
                            name="last4SSN"
                            label="Last 4 Digits of SSN or ITIN"
                            aria-label="Last 4 Digits of SSN or ITIN"
                            instructionText=""
                            errors={errors}
                            register={register}
                            onCopy={(event) => {                                
                                event.preventDefault();
                                return false;
                            }}
                            onPaste={(event) => {                                
                                event.preventDefault();
                                return false;
                            }}
                        />
                    </Form>
                </Col>
            </Row>
            <Row className='row mt-36'>
                <Col className='col text-center'>
                    {!isCheckApp ? (
                        <Form.Group>
                            <Button disabled={!(isValid)} onClick={handleSubmit(handleCheckApplicationStatus)}>
                                Check My Application Status
                            </Button>
                        </Form.Group>
                    ) : (
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">
                                Processing...
                            </span>
                        </Spinner>
                    )}
                </Col>
            </Row>
        </LandingPageLayout>
    );
};

export default CheckApplication;
