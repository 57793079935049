import React, { MouseEventHandler, ReactNode } from 'react';
import { iconX } from '../../../assets/img';
import "./modal.scss";

interface ModalProps {
    children: ReactNode;
    onClose: MouseEventHandler<HTMLImageElement>;
}

const Modal: React.FC<ModalProps> = ({children, onClose}) => {
    return (
        <div className='modal-layout-outer'>
            <div className="modal-layout-inner">
                <div className="modal-header-layout">
                    <img src={iconX} className='modal-icon' alt='close icon' onClick={onClose}/>
                </div>
                {children}
            </div>
        </div>
    );
};

export default Modal;