import React, { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { FieldErrorsImpl, UseFormSetValue, UseFormTrigger } from "react-hook-form";
import DatePicker from "../date-picker";
import { applicationState } from "../../../store/atoms";
import { BirthdateVerificationModel } from "../../types";
import "../../scss/main.scss";

interface BirthdateFormProps {
    setValue?: UseFormSetValue<BirthdateVerificationModel>;
    trigger?: UseFormTrigger<BirthdateVerificationModel>;
    errors?: Partial<FieldErrorsImpl<BirthdateVerificationModel>>;
}

const BirthdateForm: React.FC<BirthdateFormProps> = ({ setValue, trigger, errors }) => {
    const application = useRecoilValue(applicationState);

    useEffect(() => {
        trigger && trigger();
    }, [])

    return (
        <div className="form-layout">
            <DatePicker
                name="dateOfBirth"
                isBirthday={true}
                initialValue={application.dateOfBirth}
                setValue={setValue}
                errors={errors}
                trigger={trigger}
            />
            <div className="bottom-spacing-150" />
        </div>);
}

export default BirthdateForm;