import { CardProductResponse } from '../types'

type CardInfo = {
    prn?: string;
    cardType?: string;
    annualPercentageRate?: any;
    minimumDeposit?: any;
    minimumCreditLimit?: any;
    maximumCreditLimit?: any;
    productId?: number | undefined;
    currentCardHolderAgreement?: any;
    currentTermsConditions?: any;
}

export const currentCardInfo = (cardType: string, products: CardProductResponse[] | undefined) => {
    let cardInfo: CardInfo = {};
    if (products === undefined) {
        return cardInfo;
    }

    let cardPrn = cardType.toLowerCase() === 'basic' ? '1000' :
        cardType.toLowerCase() === 'plus'? '9000' :
        '6100';
    
    for (let product of products) {
    
        if (product?.prn === cardPrn) {
            cardInfo = {
                prn: product?.prn,
                cardType: cardType,
                minimumDeposit: product?.minimumDeposit,
                minimumCreditLimit: product?.minimumCreditLimit,
                maximumCreditLimit: product?.maximumCreditLimit,
                annualPercentageRate: product?.offerCode?.annualPercentageRate,
                productId: product?.id,
                currentCardHolderAgreement: product?.offerCode?.chaDocumentPath,
                currentTermsConditions: product?.offerCode?.termsAndConditionsDocumentPath,
            };
            
            return cardInfo;
        }
    }

    return cardInfo;
}
