import axios from 'axios';

const createMicroserviceInstance = () => {
    const instance = axios.create({
        baseURL: process.env.REACT_APP_DEFAULT_API_BASE_URL,
    });

    return instance;
};

const createSmartyInstance = () => {
    const instance = axios.create({
        baseURL: process.env.REACT_APP_SMARTY_API_BASE_URL,
    });

    return instance;
};

export const microserviceClient = createMicroserviceInstance();
export const smartyAutocompleteClient = createSmartyInstance();
