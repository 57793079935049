export const validationErrors = {
    firstNameIsRequiredError: "First Name is required.",
    firstNameAlphabeticalCharactersError: "First Name should only contain alphabetical characters.",
    lastNameRequiredError: "Last Name is required.",
    lastNameAlphabeticalCharactersError: "Last Name should only contain alphabetical characters.",
    nameExceedsMaximumCharactersError: "First Name and Last Name combined exceeds maximum characters.",
    invalidEmailAddressError: "Invalid Email Address!",
    emailAddressIsRequiredError: "Email Address is required.",
    invalidPhoneNumberError: "Invalid Phone Number!",
    phoneIsRequiredError: "Phone is required.",
    streetAddressRequiredError: "Street address is required",
    streetAddressMaxCharactersError: "Street address must be at most 26 characters",
    poBoxesNotAllowedError: "PO Box addresses are not allowed as Home Address",
    invalidPrimaryAddressError: "Invalid Primary Address",
    cityIsRequiredError: "City is required",
    cityMaxCharactersError: "City must be less than 18 characters",
    stateIsRequiredError: "State is required",
    zipCodeIsRequiredError: "Zip Code is required",
    zipCodeFormatError: "Must be a U.S. Zip Code",
    ssnRequiredError: "SSN is required",
    invalidSsnOrItinError: "Invalid SSN or ITIN",
    housingPaymentRequiredError: "Monthly Rent/Mortgage is required",
    incomeRequiredError: "Total Annual Income is required",
    eSignConsentError: "Please agree to E-Sign Consent",
    termsAndConditionsError: "Please agree to terms and conditions",
    invalidCharactersError: "Invalid characters in input",
    annualIncomeMoreThanError: "Must be greater than $0.00",
    annualIncomeLessThanError: "Annual Income must be less than $1,000,000",
    housingPaymentMaximumError: "Monthly Housing Payment must be less than $1,000,000",
    depositAmountRequiredError: "Deposit Amount is required",
    depositAmountTypeError: "Deposit Amount must be a valid currency.",
    depositAmountMinError: "Deposit Amount must be at least $200.",
    depositAmountMaxError: "Deposit Amount cannot be greater than $3,000.",
    cardHolderFullNameRequired: "Card Holder Full Name is required.",
    cardNumberRequiredError: "Card Number is required.",
    cardHolderFullNameAlphaError: "Cardholder full name should only contain alphabetical characters."
}

export const apiErrors = {
    smartyEmbeddedKeyAuthNotAllowedError: "Embedded key authentication is not allowed from public cloud providers"
}