import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import App from './app';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from './scrollToTop';
import AxiosInterceptor from './axios-interceptor';

// use MSW mock api when running 'npm run start-mock-api'
if (process.env.REACT_APP_USE_MOCK_API) {
    const { worker } = require('./mocks/browser-worker');
    worker.start({
        onUnhandledRequest: 'bypass', // 'bypass' | 'warn' | 'error'
    });
}

const baseUrl =
    document.getElementsByTagName('base')[0].getAttribute('href') || undefined;
const rootElement = document.getElementById('root');

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter basename={baseUrl}>
            <ScrollToTop />
            <RecoilRoot>
                <QueryClientProvider client={new QueryClient()}>
                    <AxiosInterceptor>
                        <App />
                        <ReactQueryDevtools
                            initialIsOpen={false}
                            position="bottom-right"
                        />
                    </AxiosInterceptor>
                </QueryClientProvider>
            </RecoilRoot>
        </BrowserRouter>
    </React.StrictMode>,
    rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
