import React, { FC } from 'react';
import './loading.scss';

type LoadingProps = {
    isLoading: boolean;
    headerText: string;
    bodyText: string;
};

const Loading: FC<LoadingProps> = ({
    isLoading,
    headerText,
    bodyText,
    children,
}) => {
    return (
        <>
            {isLoading ? (
                <div className="loading-container">
                    <div className="spinner">
                        <div className="inner-circle" />
                        <div className="circle-corner" />
                    </div>
                    <h1>{headerText}</h1>
                    <p>{bodyText}</p>
                </div>
            ) : (
                children
            )}
        </>
    );
};
export default Loading;
