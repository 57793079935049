import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { BreakPoints } from '../../types';

type ModalToPageProps = {
    breakpoint: BreakPoints
};

const ModalPage: FC<ModalToPageProps> = (props) => {
    const [showModal, setShowModal] = useState(true);

    useEffect(() => {
        setShowModal(props.breakpoint < BreakPoints.sm);

        return () => {
            setShowModal(false);
        };
    }, [props.breakpoint]);

    if (showModal) {
        return (
            <Modal show={showModal}>
                <Modal.Body>
                    {props.children}
                </Modal.Body>
            </Modal>
        );
    }
    return (
        <>
            {props.children}
        </>
    );
};

export default ModalPage;
