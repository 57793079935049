import React from "react";
import "./perk-item.scss";

interface PerkItemProps {
    heading: string;
    smallText: string;
    icon: string;
}

const PerkItem: React.FC<PerkItemProps> = ({
    heading,
    smallText,
    icon
}) => {
    return (
        <div className='perk-item-container'>
            <div className="perk-item-icon"><img src={icon} alt="graph stats icon"/></div>
            <div>
                <h4 className="perk-item-heading">{heading}</h4>
                <div className="perk-item-text">{smallText}</div>
            </div>
        </div>);
}

export default PerkItem;
