import React from 'react';
import { HelpTray } from '../../components';

const BasicInfoHelp = () => {
    return (
        <HelpTray>
            <HelpTray.Header>
                Basic Information Help
            </HelpTray.Header>
            <HelpTray.Section>
                <HelpTray.SectionTitle>First and Last Name</HelpTray.SectionTitle>
                <HelpTray.SectionText>
                    The total number of characters entered in the First Name and Last Name
                    fields cannot exceed 26 characters.
                </HelpTray.SectionText>
            </HelpTray.Section>
            <HelpTray.Section>
                <HelpTray.SectionTitle>Email Address</HelpTray.SectionTitle>
                <HelpTray.SectionText>
                    A valid email address is required to complete this application as it is
                    the primary method by which we will contact you.
                </HelpTray.SectionText>
            </HelpTray.Section>
            <HelpTray.Section>
                <HelpTray.SectionTitle>Mobile Phone Number</HelpTray.SectionTitle>
                <HelpTray.SectionText>
                    By giving us a mobile number or a number later converted to a mobile number,
                    you agree that we or our service providers can contact you at the number by
                    text message, pre-recorded, autodialed or artificial voice calls. Msg&amp;Data
                    Rates May Apply. Message frequency may vary. Text HELP for help, STOP to cancel.
                </HelpTray.SectionText>
            </HelpTray.Section>
        </HelpTray>
    );
}

export default BasicInfoHelp;
