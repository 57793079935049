import React from 'react';
import './funding.scss';
import { checkmark } from '../../../assets/img';
import { DepositForm } from '../deposit-form';
import { useRecoilValue } from 'recoil';
import { applicationState } from '../../../store/atoms';
import ErrorBanner from '../error-banner';
import Heading from '../heading';
import { selectedApplicationResponse } from '../../../store/selectors';

interface FundingFormProps {
    fundingError?: boolean;
}

export const FundingForm: React.FC<FundingFormProps> = ({ fundingError }) => {
    const application = useRecoilValue(applicationState);
    const selectedApplication = useRecoilValue(selectedApplicationResponse);

    return (
        <div className="funding-container">
            {fundingError && (
                <>
                    <ErrorBanner text="An error has occurred" />
                    <Heading
                        title="Let’s double check your funding info"
                        smallText="We were unable to process your card payment. The information you provided was not verified by your bank."
                    />
                </>
            )}
            {!fundingError && (
                <>
                    <Heading
                        icon={checkmark}
                        title="You're approved!"
                        smallText={`${application.firstName ?? selectedApplication?.firstName}, you're almost there! Let's
                            get your card funded.`}
                        centerText
                    />
                    <DepositForm />
                </>
            )}
        </div>
    );
};
