import React, { FC, useEffect, useState } from 'react';
import {
    FieldErrorsImpl,
    UseFormSetValue,
    UseFormTrigger,
} from 'react-hook-form';
import { SelectOptions } from '../../../components/select-dropdown/types';
import FormErrorMessage from '../form-error-message';
import { currentYear, daysInMonth, maxBirthdate } from '../../../utils/dates';
import { isValidDate } from '../../../utils/validations';
import './date-picker.scss';
import Dropdown from '../dropdown';

export type DatePickerProps = {
    id?: string;
    name?: string;
    initialValue?: string | Date;
    isBirthday?: boolean;
    minYear?: number;
    maxYear?: number;
    onValidDateChange?: (date: Date) => void;
    onDateChange?: (date?: Date) => void;
    errors?: FieldErrorsImpl<any>;
    setValue?: UseFormSetValue<any>;
    trigger?: UseFormTrigger<any>;
};

const DatePicker: FC<DatePickerProps> = ({
    id,
    name,
    initialValue,
    isBirthday,
    minYear = 1900,
    maxYear = currentYear,
    onDateChange,
    onValidDateChange,
    errors,
    setValue,
    trigger,
}) => {
    const [year, setYear] = useState<string | undefined>();
    const [monthIndex, setMonthIndex] = useState<string | undefined>();
    const [day, setDay] = useState<string | undefined>();
    const [hasValidDateBeenSet, setHasValidDateBeenSet] = useState<boolean>();

    const calculatedMaxYear = isBirthday
        ? Math.min(maxYear, maxBirthdate.getFullYear())
        : maxYear;

    const months: SelectOptions[] = Array.from({ length: 12 }, (_, i) => ({
        value: `${i}`,
        label: new Date(2022, i, 1).toLocaleString('default', { month: 'long' })
    }));
    
    const days: SelectOptions[] = Array.from({ length: daysInMonth(year, monthIndex) }, (_, i) => ({
        label: `${(i + 1)}`,
        value: `${(i + 1)}`
    }));

    const years: SelectOptions[] = Array.from({ length: calculatedMaxYear - minYear + 1 }, (_, i) => ({
        label: `${calculatedMaxYear - i}`,
        value: `${calculatedMaxYear - i}`
    }));

    const hasErrors = (monthIndex || day || year) && errors && name && name.split('.').reduce((p, prop) => p && (p as unknown as any)[prop], errors);

    useEffect(() => {
        if (initialValue) {
            const initialDate = new Date(initialValue);
            setYear(initialDate.getFullYear().toString());
            setMonthIndex(initialDate.getMonth().toString());
            setDay(initialDate.getDate().toString());
        }
    }, [initialValue]);

    useEffect(() => {
        if (isValidDate(year, monthIndex, day)) {
            const selectedDate = new Date(
                parseInt(year!, 10),
                parseInt(monthIndex!, 10),
                parseInt(day!, 10)
            );            
            setValue && name && setValue(name, selectedDate);
            onValidDateChange && onValidDateChange(selectedDate);
            onDateChange && onDateChange(selectedDate);
            setHasValidDateBeenSet(true);
            trigger && trigger();
        } else {
            setValue && name && setValue(name, undefined);
            onDateChange && onDateChange(undefined);
            if (hasValidDateBeenSet) {
                trigger && trigger();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [monthIndex, day, year]);

    const handleDayChange = (value: string) => {
        setDay(value);
    }

    const handleMonthChange = (value: string) => {
        setMonthIndex(value);
    }

    const handleYearChange = (value: string) => {
        setYear(value);
    }

    return (
        <>
            <div className="date-picker-container">
                <Dropdown
                    label="Month"
                    selectedValue={monthIndex || ''}
                    onChange={handleMonthChange}
                    options={months}
                    name={`${name}.month`}
                    className="dropdown-large"
                />
                <Dropdown
                    label="Day"
                    selectedValue={day || ''}
                    onChange={handleDayChange}
                    options={days}
                    name={`${name}.day`}
                    className="dropdown-medium"
                />
                <Dropdown
                    label="Year"
                    selectedValue={year || ''}
                    onChange={handleYearChange}
                    options={years}
                    name={`${name}.year`}
                    className="dropdown-small"
                />
            </div>
            {hasErrors && <FormErrorMessage errors={errors} name={name} />}
        </>
    );
};

export default DatePicker;
