import React from 'react';
import { useRecoilValue } from 'recoil';
import { applicationApproved } from '../../assets/img';
import ApplicationInfoStatus from '../../components/application-info-status';
import { LandingPageLayout } from '../../layouts';
import {
    emailAddressState,
    cardTypeState,
    firstNameState
} from '../../store/atoms';
import {
    selectedApplicationResponse
} from '../../store/selectors';
import {
    creditcardTilted,
    creditcardPlusTilted,
    creditcardLaunchTilted
} from '../../assets/img';
import './index.scss';

const ApplicationInGoodFunds = () => {
    const emailAddress = useRecoilValue(emailAddressState);
    const firstName = useRecoilValue(firstNameState);
    const cardType = useRecoilValue(cardTypeState);

    const getCardImageByCardType = () => {
        switch(cardType){
            case 'launch':
                return <img alt="" src={creditcardLaunchTilted} className="bottom-36" />
            case 'plus':
                return <img alt="" src={creditcardPlusTilted} className="bottom-36" />
            default:
                return <img alt="" src={creditcardTilted} className="bottom-36" />
        }
    }

    return (
        <LandingPageLayout showPrivacyPolicy>
                <ApplicationInfoStatus
                    appAdditionalContent={
                        <>
                            <div className='local-container'>
                                {getCardImageByCardType()}
                                <div className="text-start">
                                    <h2 className="ginto-header">Congrats, <span style={{textTransform: 'capitalize'}}>{firstName}</span>,</h2>
                                    <h2 className="ginto-header gradient bottom-36">you're approved!</h2>
                                    <p className="local-paragraph-bold">
                                        Good news! We've received your deposit!<br/>
                                    </p>
                                    <p className="local-paragraph">
                                        After your deposit has cleared, we'll open your OpenSky account, create your credit card, and prepare it for mailing.<br/>
                                    </p>
                                    <p className="local-paragraph">
                                        We will email you at {emailAddress} with updates letting you know when your card is on the way.
                                    </p>
                                </div>
                            </div>
                        </>
                    }
                    appStatus="You've been approved!!!"
                    appStatusType="approved"
                    appStatusIcon={applicationApproved}
                    onNext={() => window.location.href = `${process.env.REACT_APP_HOMEPAGE_URL}`}
                    buttonText="Ok, Got it"
                />
        </LandingPageLayout>
    );
};

export default ApplicationInGoodFunds;