import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { ValidatedInputProps } from '../../types';
import TextInput from '../text-input';

export type NumberFormatInputProps = Omit<ValidatedInputProps, 'id'> & NumberFormatProps<any>;

const NumberFormatInput: React.FC<NumberFormatInputProps> = (props) => (
    <div style={{whiteSpace: "pre-line"}}>
        <NumberFormat
            customInput={TextInput}
            registerOptions={{
                // this always sets the value as number.  we may want to break out inputs by type
                // and set value differently for each type (e.g. ssn, cc#, DOB, currency, phone)
                setValueAs: (value: string) => {
                    if (props.decimalScale && props.decimalScale > 0) {
                        return value?.replace &&  
                            Math.round(parseFloat(value.replace(/[^0-9.]+/g, '')) * (10 * props.decimalScale)) / (10 * props.decimalScale);
                    } else if (props.fixedDecimalScale) {                    
                        return value?.replace && Math.round(parseFloat(value.replace(/[^0-9.]+/g, '')));
                    } else {
                        return value?.replace && value.replace(/[^0-9]+/g, '');
                    }
                }


            }}
            {...props}
        />
    </div>
);

export default NumberFormatInput;
