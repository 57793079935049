import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import * as Yup from 'yup';
import { useRecoilState } from 'recoil';
import { applicationState, gaDataLayerState } from '../../../store/atoms';
import { CardTypes } from '../../../utils/constants';
import { cashPayment } from '../../../assets/img';
import '../../scss/main.scss';
import ApplicationFlowLayout from '../../layouts/application-flow';
import { Form, Modal, Subheadline } from '../../components';
import { IncomeForm } from '../../components/income-form';
import { validationErrors } from '../../utils/constants';
import { useForm, FormProvider } from 'react-hook-form';
import { IncomeVerificationModel } from '../../types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCalculateIncome } from '../../../pages/debt-to-income/hooks';
import { routeHashes } from '../../../app-routes';
import { useHistory } from 'react-router';
import { pushToDataLayer } from '../../../utils/analytics';

interface IncomeProps {
    isModal?: boolean;
    onModalClose?: () => void;
}

const Income: React.FC<IncomeProps> = ({ isModal, onModalClose }) => {
    const [application, setApplication] = useRecoilState(applicationState);
    const submittedModel = useRef<boolean>(false);
    const history = useHistory();
    const [housingPayment, setHousingPayment] = useState<number | undefined>(
        application?.housingPayment
    );
    const [annualIncome, setAnnualIncome] = useState<number | undefined>(
        application?.annualIncome
    );
    const [isIncomeSatified, setIsIncomeSatisfied] = useState(false);
    const [gaDataLayer, setGaDataLayer] = useRecoilState(gaDataLayerState);

    //memoize the validation schema so it only changes if app fields change that is uses
    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            housingPayment: Yup.number()
                .required(validationErrors.housingPaymentRequiredError)
                .max(1000000, validationErrors.housingPaymentMaximumError)
                .typeError(validationErrors.housingPaymentRequiredError)
                .default(() => application.housingPayment),
            annualIncome: Yup.number()
                .required(validationErrors.incomeRequiredError)
                .typeError(validationErrors.incomeRequiredError)
                .moreThan(0, validationErrors.annualIncomeMoreThanError)
                .lessThan(1000000, validationErrors.annualIncomeLessThanError)
                .default(() => application.annualIncome),
        });
    }, [application.annualIncome, application.housingPayment]);

    const formOptions = { resolver: yupResolver(validationSchema) };
    const methods = useForm<IncomeVerificationModel>({
        ...formOptions,
        mode: 'all',
    });

    // reset form manually (makes it work with for/back navs)
    useEffect(() => {
        const defaults = validationSchema.getDefault();

        methods.reset({
            housingPayment: defaults.housingPayment,
            annualIncome: defaults.annualIncome,
        });
    }, [methods, methods.reset, validationSchema]);

    //watch form
    useEffect(() => {
        //watches the form in real time, we need the info to calculate the minimum income
        const sub = methods.watch((values) => {
            if (values.housingPayment) {
                setHousingPayment(values.housingPayment);
            }

            if (values.annualIncome) {
                setAnnualIncome(values.annualIncome);
            }
        });
        return () => {
            sub.unsubscribe();
        };
    }, [methods]);

    const { data: minimumIncomeAmount, isLoading: minimumIncomeLoading } =
        useCalculateIncome(housingPayment);

    //determine if the minimum income is satisfied
    useEffect(() => {
        if (minimumIncomeLoading) {
            setIsIncomeSatisfied(false);
            return;
        }
        const minIncome = minimumIncomeAmount ?? 0;
        const income = annualIncome ?? 0;
        if (minIncome > 0 && income > 0 && income > minIncome) {
            setIsIncomeSatisfied(true);
        } else {
            setIsIncomeSatisfied(false);
        }
    }, [
        minimumIncomeAmount,
        minimumIncomeLoading,
        methods.setValue,
        methods,
        annualIncome,
        housingPayment,
    ]);

    const onSubmit = useCallback(
        (event: IncomeVerificationModel) => {
            submittedModel.current = true;
            if (!methods.formState.isValid) return;

            setApplication((current) => ({
                ...current,
                ...event,
                annualIncomeValidated: isIncomeSatified,
            }));

            const updateDataLayer = {
                ...gaDataLayer,
                'step_name': 'Get Options',
                'est_housing_payment': event.housingPayment,
                'total_yearly_income': event.annualIncome
            }
    
            setGaDataLayer(updateDataLayer);
            pushToDataLayer("application_step_5", updateDataLayer);

            if (isModal) {
                onModalClose && onModalClose();
            } else history.push(routeHashes['/v1_5/review']);
        },
        [
            methods.formState.isValid,
            setApplication,
            isModal,
            history,
            onModalClose,
            isIncomeSatified,
            gaDataLayer,
            setGaDataLayer
        ]
    );

    return (
        <FormProvider {...methods}>
            {!isModal && (
                <ApplicationFlowLayout
                    isArrowBackButton={true}
                    cardType={application.cardType as CardTypes}
                    displayVisaLogo={true}
                    currentStep="6"
                    progress="75%"
                >
                    <div className="main-container">
                        <Subheadline
                            headline="What's your income?"
                            headlineIcon={cashPayment}
                            smallText="We need some financial information to evaluate your application."
                        />
                        <Form
                            onSubmit={methods.handleSubmit(onSubmit)}
                            submitText="Next"
                            isSubmitDisabled={!methods.formState.isValid}
                        >
                            <IncomeForm
                                incomeSatisfied={
                                    annualIncome ? isIncomeSatified : true
                                }
                            />
                        </Form>
                    </div>
                </ApplicationFlowLayout>
            )}
            {isModal && (
                <Modal onClose={onModalClose || (() => {})}>
                    <Subheadline
                        headline="What's your income?"
                        headlineIcon={cashPayment}
                        smallText="We need some financial information to evaluate your application."
                    />
                    <Form
                        onSubmit={methods.handleSubmit(onSubmit)}
                        submitText="Save"
                        isSubmitDisabled={!methods.formState.isValid}
                    >
                        <IncomeForm
                            incomeSatisfied={
                                annualIncome ? isIncomeSatified : true
                            }
                        />
                    </Form>
                </Modal>
            )}
        </FormProvider>
    );
};

export default Income;
